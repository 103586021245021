import fetch from 'isomorphic-fetch'
import Cookie from 'js-cookie'
import { checkHttpStatus, handleNetworkError, parseJSON, updateUserUI } from '../../utils'
import jwtDecode from 'jwt-decode';
import {fetchContacts} from '../contacts/actions'
import { StateToSessionStorage } from '../../storages/session-storage';
import TokenStorage from '../../storages/token-storage'

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST'
export const AUTH_LOGIN_REFRESH = 'AUTH_LOGIN_REFRESH'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE'
export const AUTH_REFRESH_LOGIN_FAILURE = 'AUTH_REFRESH_LOGIN_FAILURE'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'
export const USER_MAILING_LIST_PREFERENCE_REQUEST = 'USER_MAILING_LIST_PREFERENCE_REQUEST'
export const USER_MAILING_LIST_PREFERENCE_SUCCESS = 'USER_MAILING_LIST_PREFERENCE_SUCCESS'
export const USER_MAILING_LIST_PREFERENCE_FAILURE = 'USER_MAILING_LIST_PREFERENCE_FAILURE'
export const USER_LIST_ORDER_PREFERENCE_REQUEST = 'USER_LIST_ORDER_PREFERENCE_REQUEST'
export const USER_LIST_ORDER_PREFERENCE_SUCCESS = 'USER_LIST_ORDER_PREFERENCE_SUCCESS'
export const USER_LIST_ORDER_PREFERENCE_FAILURE = 'USER_LIST_ORDER_PREFERENCE_FAILURE'

export const userLoginSuccess = (token, initiatedByUser=false) => {
    TokenStorage.setItem('token', token);
    if (window.Sentry !== undefined) {
        let userData = jwtDecode(token)
        Sentry.setUser(userData)
    }
    return {
        type: AUTH_LOGIN_SUCCESS,
        data: token,
        initiatedByUser
    }
}

export const userRefreshLoginFailure = (data) => {
    TokenStorage.removeItem('token');
    return {
        type: AUTH_REFRESH_LOGIN_FAILURE,
        data: data
    }
}

export const userLoginFailure = (data) => {
    TokenStorage.removeItem('token');
    return {
        type: AUTH_LOGIN_FAILURE,
        data: data
    }
}

export const requestUser = () => {
    return {
        type: AUTH_LOGIN_REQUEST,
    };
};

export const refreshUser = () => {
    return {
        type: AUTH_LOGIN_REFRESH,
    };
};

export const performUserLogout = () => {
    TokenStorage.removeItem('token');
    setTimeout(() => {}, 50)
    StateToSessionStorage({}, 'activeCompany')
    return {
        type: AUTH_LOGOUT,
    };
};

export const userLogout = () => {
    return dispatch => {
        return fetch('/logout/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            dispatch(performUserLogout());
        })
        .catch(handleNetworkError);
    };
}

export const authenticationSuccess = (token) => {
    return {
        type: AUTH_SUCCESS
    }
}

export const authenticationFailure = () => {
    return {
        type: AUTH_FAILURE
    }
}

const userDjangoLogin = (username, password) => {
    return dispatch => {
        return fetch('/login/', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'X-CSRFToken': window.CSRF_TOKEN,
            },
            body: JSON.stringify({
                username,
                password
            })
        })
        .then(response => {
            if (response.status !== 200) {
                dispatch(userLoginFailure({}))
            } else {
                return response
            }
        })
        .then(parseJSON)
        .catch(handleNetworkError);
    };
}

export const userRefreshLogin = () => {
    return dispatch => {
        let token = TokenStorage.getItem("token");
        let shouldRefresh = true
        try {
            let userData = jwtDecode(token)
            let now = moment().unix()
            let shouldRefresh = now > (userData.exp - 300)
        } catch (e) {
            
        }

        if(window.__refreshing_login__){
            return Promise.resolve(dispatch({type: 'REFRESHING_LOGIN'}))
        }
        if (token && shouldRefresh) {
            window.__refreshing_login__ = true
            dispatch(refreshUser(token));
            return fetch('/api-token-refresh/', {
                followRedirect: false,
                method: "POST",
                credentials: "same-origin",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token,
                })
            })
            .then(parseJSON)
            .then(response => {
                try {
                    window.__refreshing_login__ = false
                    // Validate if token is valid, throws error if invalid
                    jwtDecode(response.token);
                    dispatch(userLoginSuccess(response.token));
                } catch (e) {
                    console.log(e)
                    dispatch(userRefreshLoginFailure({
                    }))
                }
            })
            .catch(handleNetworkError);
        }
        else {
            return Promise.resolve("No need to refresh login")
        }
    };
};

export const userLogin = (username, password) => {
    return dispatch => {
        dispatch(requestUser());

        return fetch('/api-token-auth/', {
            followRedirect: false,
            method: "POST",
            credentials: "same-origin",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username,
                password
            })
        })
        //.then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {

            try {
                
                // Validate if token is valid, throws error if invalid
                jwtDecode(response.token);

                dispatch(userDjangoLogin(username, password))
                .then(() => {
                    dispatch(userLoginSuccess(response.token, true));
                })
                updateUserUI(dispatch)

            } catch (e) {
                console.log(e)
                dispatch(userLoginFailure({}))
            }
        })
        .catch(handleNetworkError);
    };
};

export const authenticateUser = (username, password) => {
    return dispatch => {
        return fetch('/api-token-auth/', {
            followRedirect: false,
            method: "POST",
            credentials: "same-origin",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username,
                password
            })
        })
        .then(parseJSON)
        .then(response => {

            try {
                jwtDecode(response.token);
                dispatch(authenticationSuccess(response.token));
            } catch (e) {
                console.log(e)
                dispatch(authenticationFailure())
            }
        })
        .catch(handleNetworkError);
    }
}


export const requestSetMailingListPreference = () => {
    return {
        type: USER_MAILING_LIST_PREFERENCE_REQUEST,
    };
};

export const receiveSetMailingListPreference = (response, preference) => {
    return {
        type: USER_MAILING_LIST_PREFERENCE_SUCCESS,
        data: response,
        preference: preference,
    };
};

export const failSetMailingListPreference = (response) => {
    return {
        type: USER_MAILING_LIST_PREFERENCE_FAILURE,
        data: response,
    };
};

export const setMailingListPreference = (user, preference) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");
        
        dispatch(requestSetMailingListPreference());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.CSRF_TOKEN,
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/users/${user.id}/set_mailing_list_preference/`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({preference: preference}),
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(userRefreshLogin(TokenStorage.getItem("token")));
                dispatch(receiveSetMailingListPreference(response, preference));
            } catch (e) {
                dispatch(failSetMailingListPreference(response))
            }
        })
        .catch(handleNetworkError);
    };
}


export const requestSetListOrderPreference = () => {
    return {
        type: USER_LIST_ORDER_PREFERENCE_REQUEST,
    };
};

export const receiveSetListOrderPreference = (response, preference) => {
    return {
        type: USER_LIST_ORDER_PREFERENCE_SUCCESS,
        data: response,
        preference: preference,
    };
};

export const failSetListOrderPreference = (response) => {
    return {
        type: USER_LIST_ORDER_PREFERENCE_FAILURE,
        data: response,
    };
};


export const setListOrderPreference = (user, preference) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");
        console.log("List order: ", preference)
        
        dispatch(requestSetListOrderPreference());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.CSRF_TOKEN,
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/users/${user.id}/set_list_order/`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({list_order: preference}),
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(userRefreshLogin(TokenStorage.getItem("token")));
                dispatch(receiveSetListOrderPreference(response, preference));
            } catch (e) {
                dispatch(failSetListOrderPreference(response))
            }
        })
        .catch(handleNetworkError);
    };
}
