ProductsGrid
import React from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import inViewport from 'in-viewport'
import { fetchProducts, fetchNextProducts, requestProducts, fetchProductPrices } from '../../api/products/actions'
import Product from '../../components/product'
import ProductLarge from '../../components/product/productLarge'
import ProductSmall from '../../components/product/productSmall'
import ProductSpinner from '../../components/product-spinner'
import Modal from '../../views/modal'
import ReplacementProducts from '../../views/modal/replacementProducts'

class ProductsGrid extends React.Component {

    constructor(props) {
        super(props)
    }

    initialFetch() {
        let query = ''
        if (this.props.category) {
            query = `categories=${this.props.category}`
            if (this.props.categorySecondary) {
                query += `&categories=${this.props.categorySecondary}`
            }
            if (this.props.onlyOffersAndNew) {
                query += '&offer_and_new=true'
            }
            this.props.dispatch(fetchProducts(query, this.props.productsGridNamespace))
        }
        if (this.props.productIds !== "") {
            ("" + this.props.productIds).split(",").map((p) => {
                if (p !== "") {
                    query += `&ids=${p}`
                }
            })
            if (this.props.extraQueryParams !== "") {
                query += `&${this.props.extraQueryParams}`
            }
            this.props.dispatch(fetchProducts(query, this.props.productsGridNamespace))
        }
    }

    componentWillMount() {
        this.initialFetch()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.infiniteScroll) {
            var scrollWatch = document.getElementById('fetch-products-scrollwatch')
            $(window).on('scroll', () => {
                if (inViewport(scrollWatch)) {
                    this.fetchNext()
                }
            })
        }
        if (this.props.products.dirty && !prevProps.products.dirty && !this.props.products.isFetching) {
            this.initialFetch()
        }
        if ((this.props.user.independentUser)||(this.props.user.activeCompany && this.props.user.activeCompany.independent_company)) {
            $('#interesting_panel_individuals').removeClass("d-none")
            $('#interesting_panel').addClass("d-none")
        } else {
            $('#interesting_panel').removeClass("d-none")
            $('#interesting_panel_individuals').addClass("d-none")
        }
    }

    fetchNext() {
        if (this.props.products.isFetching) {
            return
        }
        clearTimeout(this.timer)

        if (!this.props.products.next) {
            return
        }

        this.props.dispatch(requestProducts())
        this.timer = setTimeout(() => {
            this.props.dispatch(fetchNextProducts(this.props.products.next))
        }, 1000)
    }

    hasProductsToRender() {
        return this.props.category && this.props.products || this.props.productIds && this.props.products
    }

    replaceProduct(toReplace, products) {
        console.log(products, toReplace)

        let replacingProductInList = !(+toReplace.avail_physical === 0 && toReplace.replacement_products.length > 0)
        let modalProps = {
            title: null,
            body: ReplacementProducts,
            large: true,
            bodyProps: {
                products: products,
                toReplace: toReplace,
                dispatch: this.props.dispatch,
                replacingProductInList: replacingProductInList,
                lists: this.props.lists,
            }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    renderProductsHeading(productCategory){
        let heading
        if(productCategory === "new") {
            heading = <h2>Nýjar vörur</h2>
        }
        if(productCategory === "offer") {
            heading = <h2>Spennandi tilboð</h2>
        }
        if(productCategory === "other") {
            heading = <h2 style={{borderColor: this.props.color !== "rgba(0,0,0,0)" ? this.props.color : this.props.specifficColor}}>{this.props.title}</h2>
        }

        return (
            <div className='container oc-container'>
                {heading}
            </div>
        )

    }

    renderNewProducts() {
        let productsList = this.props.products.productList

        let newList = productsList.filter(product => product.new === true)

        return newList
        
    }

    renderOfferProducts() {
        let productsList = this.props.products.productList

        let offerList = productsList.filter(product => product.offer === true)

        return offerList
    }

    renderProduct(p, view){
        return (
            <Product
                product={p}
                viewName={view}
                user={this.props.user}
                classNames={this.props.productClassNames}
                defaultProductImage={window.garriConfig.icons.defaultProductImage}
                productCallbackFn={this.props.productCallbackFn}
                cartProduct={this.props.cart.products.find((pp) => pp.product.item_id === p.item_id)}
                replaceProduct={this.replaceProduct.bind(this)}
                dispatch={this.props.dispatch}
                currentList={this.props.currentList}
                enableReplacementButton={true}
                modalSearch={this.props.modalSearch}
            />
        )
    }

    sortNewAndOfferByDate(productsList, listType){
        productsList.sort((a,b) => {
            const dateA = new Date(listType === "new" ? a.new_begins_at : a.offer_begins_at)
            const dateB = new Date(listType === "new" ? b.new_begins_at : b.offer_begins_at)

            return dateB - dateA
        })
    }


    renderProducts() {

        let view = this.props.productsGridView;
        if (this.props.productsGridView === "ProductSmall") {
            view = ProductSmall;
        } else if (this.props.productsGridView === "ProductLarge") {
            view = ProductLarge;
        }

        let toReturn = []

        let productList = []

        let overrideProductList = this.props.productList

        let newProductsList, offerProductsList

        if (overrideProductList.length > 0) {
            productList = overrideProductList
        } else {
            productList = this.props.products.productList
            if(this.props.onlyOffersAndNew && productList.length > 0) {
                newProductsList = this.renderNewProducts()
                offerProductsList = this.renderOfferProducts()
                
            }
            if (this.hasProductsToRender()) {
                try {
                    if (this.props.products[this.props.productsGridNamespace] !== undefined) {
                        productList = this.props.products[this.props.productsGridNamespace]

                        if(this.props.onlyOffersAndNew) {
                            newProductsList = this.renderNewProducts()
                            offerProductsList = this.renderOfferProducts()
                        }
                    }
                    
                } catch (e) {
                    //productList = this.props.products.productList || []
                }
            }
        }

        if (this.props.productsGridSortByIdOrder && this.props.productIds !== "" && !this.props.onlyOffersAndNew) {
            let sortOrder = ("" + this.props.productIds).split(",")
            productList.sort((a, b) => {
                return sortOrder.indexOf(a.item_id) - sortOrder.indexOf(b.item_id)
            })
        }


       !this.props.onlyOffersAndNew && productList.forEach((p, i) => {
            if ((p.avail_physical > 0 && !this.props.showZeroQty) || (this.props.showZeroQty && p.avail_physical > 0) || (this.props.showZeroQty && p.avail_physical < 1)) {
                toReturn.push(
                    <div className={`grid-product-outer-container ${this.props.containerClassNames}`} key={`${p.item_id}-${i}`}>
                        {!this.props.onlyOffersAndNew && this.renderProduct(p, view)}
                        
                    </div>
                )
            }
        })

        if(this.props.onlyOffersAndNew) {
            let newProductsListString = "new"
            let offerProductsListString = "offer"
            this.sortNewAndOfferByDate(newProductsList, newProductsListString)
            this.sortNewAndOfferByDate(offerProductsList, offerProductsListString)
            toReturn.push(<React.Fragment>
                <div className="container oc-container">
                    <h2>Nýjar vörur</h2>
                </div>
                {newProductsList && newProductsList.map((p, i) => {
                    if ((p.avail_physical > 0 && !this.props.showZeroQty) || (this.props.showZeroQty && p.avail_physical > 0) || (this.props.showZeroQty && p.avail_physical < 1)){
                        return (
                        <div className={`grid-product-outer-container ${this.props.containerClassNames}`} key={`${p.item_id}-${i}`}>
                                {this.renderProduct(p, view)}
                            </div>
                        )
                    }
                })}
                <div className="container oc-container">
                    <h2>Spennandi tilboð</h2>
                </div>
                {offerProductsList && offerProductsList.map((p, i) => {
                    if ((p.avail_physical > 0 && !this.props.showZeroQty) || (this.props.showZeroQty && p.avail_physical > 0) || (this.props.showZeroQty && p.avail_physical < 1)){
                        return (
                            <div className={`grid-product-outer-container ${this.props.containerClassNames}`} key={`${p.item_id}-${i}`}>
                                {this.renderProduct(p, view)}
                            </div>
                        )
                    }
                })}
            </React.Fragment>
        )
        }

        return (<div className="desktop-products"><div className="row">{toReturn}</div></div>)
    }


    render() {
        let { products } = { ...this.props }
        let otherProduct = "other"
        return (
            
                <React.Fragment>
                {!this.props.onlyOffersAndNew &&  this.renderProductsHeading(otherProduct)}
                <div className={`container oc-container ${this.props.containerExtraClasses}`}>
                    <div className={`productsGrid ${this.props.classNames} side-margin`}>
                        {this.renderProducts()}

                        {this.props.infiniteScroll &&
                            this.props.products.isFetching ? <ProductSpinner /> : null
                        }
                        {this.props.infiniteScroll &&
                            <div className="col-12" id="fetch-products-scrollwatch"></div>
                        }
                    </div>
                </div>
            </React.Fragment>
            
        )
    }
}

ProductsGrid.defaultProps = {
    classNames: "",
    category: undefined,
    categorySecondary: undefined,
    productList: [],
    infiniteScroll: true,
    onlyOffersAndNew: false,
    productIds: "",
    productsGridView: ProductLarge,
    showZeroQty: true,
    productCallbackFn: undefined,
    extraQueryParams: "",
    productsGridNamespace: "productList",
    productsGridSortByIdOrder: false,
    containerExtraClasses: "",
    currentList: null,
}


function mapStateToProps(state) {

    var f = {
        products: {},
    }
    f.user = (state.user === undefined) ? {} : state.user
    f.cart = (state.cart === undefined) ? {} : state.cart
    f.products = (state.products === undefined) ? {} : state.products
    return f;
}

export default connect(mapStateToProps)(ProductsGrid);

