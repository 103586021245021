import React from 'react';
import { NumToIsk, toggleCart } from '../../utils'

import Product from '../../components/product'
import Button from '../../components/button'
import ProductCart from '../../components/product/productCart'
import ProductCartMobile from '../../components/product/productCartMobile'
import { emptyCart } from '../../api/cart/actions'
import { setStep } from '../../api/checkout/actions'
import * as Ps from 'perfect-scrollbar';

class Cart extends React.Component {

    componentDidMount() {
        var ps = Ps.initialize($(this.refs.productsContainer)[0], {suppressScrollX: true})
    }


    emptyCart() {
        if (window.confirm("Ertu viss um að þú viljir tæma körfuna? Smelltu á OK til að tæma, Cancel til að hætta við.")) {
            this.props.dispatch(emptyCart(this.props.cart.cart_id))
        }
    }

    goToCheckout() {
        if (window.location.pathname === window.garriConfig.orderUrl) {
            Promise.resolve(this.props.dispatch(setStep(2)))
            .then(() => {
                toggleCart()
            })
        }
        else {
            window.location = window.garriConfig.orderUrl
        }
    }

    render() {
        const {cart} = {...this.props}
        const products = cart.products.filter((p) => p.quantity > 0)
        const numProducts = Object.keys(products).length


        return (
            <div id="cart" className={this.props.classNames}>
                <div className="header-container">
                    <h3>Karfan þín</h3>
                    {numProducts > 0 &&
                        <div className="magnify"><a href={window.garriConfig.orderUrl}>Stækka <img src={this.props.enlargeArrows} alt="Bæta við" width="14" /></a></div>
                    }
                </div>

                { cart.hasItemWeightProducts && (this.props.user.independentUser || (this.props.user.activeCompany && this.props.user.activeCompany.independent_company)) &&
                    <div className="alert alert-info p-4">
                        Þú ert með vigtarvöru í körfunni og mun endanlegt verð breytast eftir að vara hefur verið tekin til og vigtuð. Hér er áætlað verð á vörunni.
                    </div>
                }

                <div className="products" ref="productsContainer">
                { products && Object.keys(products).map((key, index) =>
                    <Product
                        dispatch={this.props.dispatch}
                        key={index}
                        product={products[key]}
                        viewName={this.props.isMobile ? ProductCartMobile : ProductCart}
                        user={this.props.user}
                    />
                )}
                {numProducts === 0 && 
                    <div className="empty-products">
                        <img src={this.props.cartEmptyBasket} alt="Karfan er tóm" />
                        <div>Karfan er tóm</div>
                    </div>
                }
                </div>
                {numProducts > 0 &&
                    <div className="total-field-container">
                        <div className="total-field">
                            <div className="total-text">Samtals{((this.props.user.activeCompany && this.props.user.activeCompany.vsk_active) && (this.props.user.independentUser || (this.props.user.activeCompany && this.props.user.activeCompany.independent_company))) ? ' m/vsk' : ' án vsk'}:</div>
                            <div className="total-fee">{NumToIsk(this.props.cart.total)} kr.</div>
                        </div>
                    </div>
                }
                <div className={`order-button-container ${numProducts === 0 ? 'blank' : ''}`}>
                    {numProducts > 0 &&
                        <Button anchor={false} handleClick={this.goToCheckout.bind(this)} classNames={"cart btn-success btn-cart-success col-12 mb-3"} innerHTML={(<div>Ganga frá pöntun<img src={this.props.arrow} alt="Ljúka pöntun" width="22" /></div>)} />
                    }
                    {numProducts > 0 &&
                        <Button classNames="cart btn-danger col-12 mb-3" innerHTML={(
                            <div><i className="fa fa-trash-o mr10"></i> Tæma</div>
                        )} handleClick={this.emptyCart.bind(this)} />
                    }
                    {numProducts === 0 &&
                        <a href={window.garriConfig.storeUrl} className="btn empty-products">Fara í vefverslun <img src={this.props.redArrowRight} alt="Fara til baka í verslun" /></a>
                    }
                </div>
            </div>
        )
    }
}

Cart.defaultProps = {
    classNames: '',
}

export default Cart;
