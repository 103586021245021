import React from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { nextStep, previousStep, submitOrder, setDeliveryData, paymentCheckout } from '../../api/checkout/actions'
import { emptyCart } from '../../api/cart/actions'
import Button from '../../components/button'
import CreateNewList from '../modal/createNewList'
import Modal from '../modal'

class CheckoutButtonBar extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidUpdate() {
        $('[data-toggle="tooltip"]').tooltip()
    }

    submitOrder() {
        let orderObj = {
            salesTable: this.props.checkout.deliveryData,
            salesLines:[],
        }
        for(var n in this.props.cart.products){
            var product = this.props.cart.products[n]
            if (product.quantity > 0) {
                let qty = product.quantity
                orderObj.salesLines.push({
                    itemId: `${product.product.item_id}`,
                    qtyOrdered: qty,
                })
            }
        }
        this.props.dispatch(submitOrder(orderObj, this.props.cart.cart_id))
    }

    nextStep() {
        if(this.props.user.activeCompany.account_id === undefined){
            return alert("Vinsamlegast veldu viðskiptavin")
        }
        if (this.props.cart.totalQty <= 0) {
            return alert("Fjöldi eininga í körfu verður að vera 1 eða meira!")
        }

        if(this.props.checkout.step === 1) {
            this.props.cart.products.map(product => {
                product.product.qtyChanged = false
            })
        }
        if(this.props.checkout.step === 3){
            return this.submitOrder()
        }
        if(this.props.checkout.step === 2){
            const {deliveryData} = this.props.checkout
            let deliveryDate = deliveryData.deliveryDate
            let pickupTime = deliveryData.pickupTime
            if (deliveryData.deliveryMode === "pickup") {
                deliveryDate.hour(pickupTime.hour())
                deliveryDate.minute(pickupTime.minute())
                deliveryDate.second(pickupTime.second())
            }
            var data = {
                'salesShippingDate': deliveryDate.format("YYYY-MM-DD[T]HH:mm"),
                'shippingDateRequested': deliveryDate.format("YYYY-MM-DD[T]HH:mm"),
                'contactPersonId': (deliveryData.contactPerson ? deliveryData.contactPerson.id : null),
                'contactPersonName': (deliveryData.contactPerson ? deliveryData.contactPerson.name : ""),
                'amount': this.props.cart.total,
                'custAccount': this.props.user.activeCompany.account_id,
                'deliveryMode': deliveryData.deliveryMode,
                'dlvModeId': deliveryData.deliveryMode === "send" ? "" : "SÓTT",
                'customerRef': deliveryData.deliveryMode === "send" ? deliveryData.message : `Sótt kl. ${deliveryDate.format("HH:mm")} ${deliveryData.message}`,
            }
            if (this.props.user.companyOverride) {
                data.salesTaker = this.props.user.axID
            }
            this.props.dispatch(setDeliveryData(data))
        }
        this.props.dispatch(nextStep())
    }

    previousStep() {
        this.props.dispatch(previousStep())
    }

    emptyCart() {
        if (window.confirm("Ertu viss um að þú viljir tæma körfuna? Smelltu á OK til að tæma, Cancel til að hætta við.")) {
            this.props.dispatch(emptyCart(this.props.cart.cart_id))
        }
    }

    createNewList() {
        var products = []
        for(var n in this.props.cart.products){
            products.push(this.props.cart.products[n])
        }
        let modalProps = {
          title: null,
          body: CreateNewList,
          bodyProps: {
            products: products,
            dispatch: this.props.dispatch,
          }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }
    render() {
        let numProducts = Object.keys(this.props.cart.products).length
        if (this.props.user.isAuthenticated) {
            if(this.props.checkout.submitted_order){
                return <div/>
            }
            return (
                <div className="next-steps">
                    <div className="container-fluid button-aligner">
                        <div className="button-outer">
                            {this.props.user.companyOverride && this.props.user.activeCompany.blocked &&
                                <div className="xs-mt-10">
                                    <p className="text-red text-right"><strong>ATH - Viðskiptavinur <br/> er lokaður</strong></p>
                                </div>                            
                            }
                            {this.props.checkout.step === 1 &&
                                <Button
                                    classNames={'checkout cancel'}
                                    innerHTML={(
                                        <div className="button-content">
                                            <img className="rotate black"src={window.garriConfig.icons.arrowGray} height="22" />
                                            <img className="rotate white"src={window.garriConfig.icons.arrow} height="22" />
                                            <div className="text">Vefverslun</div>
                                        </div>
                                    )}
                                    handleClick={() => {window.location = window.garriConfig.storeUrl}}
                                />
                            }
                            {this.props.checkout.step > 1 &&
                                <Button
                                    classNames={'checkout cancel'}
                                    innerHTML={(
                                        <div className="button-content">
                                            <img className="rotate black"src={window.garriConfig.icons.arrowGray} height="22" />
                                            <img className="rotate white" src={window.garriConfig.icons.arrow} height="22" />
                                            <div className="text">Fyrra skref</div>
                                        </div>
                                    )}
                                    handleClick={this.previousStep.bind(this)}
                                />
                            }
                            {this.props.checkout.step === 1 && numProducts > 0 &&
                                <Button
                                    classNames="checkout empty"
                                    innerHTML={(
                                        <div className="button-content">
                                            <img className="black" src={window.garriConfig.icons.trash} height="22" />
                                            <img className="white" src={window.garriConfig.icons.trashWhite} height="22" />
                                            <div className="text">Tæma körfu</div>
                                        </div>
                                    )}
                                    handleClick={this.emptyCart.bind(this)}
                                />
                            }
                            {this.props.checkout.step === 1 &&
                                <Button
                                    classNames={'checkout save-list'}
                                    innerHTML={(
                                        <div className="button-content">
                                            <img className="black" src={window.garriConfig.icons.list} height="22" />
                                            <img className="white" src={window.garriConfig.icons.listWhite} height="22" />
                                            <div className="text">Vista lista</div>
                                        </div>
                                    )}
                                    handleClick={this.createNewList.bind(this)}
                                />
                            }
                            <Button
                                classNames={'checkout next-step'}
                                innerHTML={(
                                    <div className="button-content row-reverse">
                                        <img className="black" src={window.garriConfig.icons.arrowGray} height="22" />
                                        <img className="white" src={window.garriConfig.icons.arrow} height="22" />
                                        <div className="text">
                                            {this.props.checkout.step < 3 ? 'Næsta skref' : (this.props.user.independentUser ? 'Greiða & Senda pöntun' : 'Senda pöntun')}
                                        </div>
                                    </div>
                                )}
                                handleClick={this.nextStep.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return <div/>
    }
}


function mapStateToProps(state) {

    var f = {
        user: {},
        cart: {},
        prices: {}
    }
    f.user = (state.user === undefined) ? {} : state.user
    f.cart = (state.cart === undefined) ? {} : state.cart
    f.prices = (state.prices === undefined) ? {} : state.prices
    f.checkout = (state.checkout === undefined) ? {} : state.checkout

    return f;
}

export default connect(mapStateToProps)(CheckoutButtonBar);
