import React from 'react';
import { connect } from 'react-redux'

class Intercom extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        var { user } = this.props
        setTimeout(function(){ 
            window.Intercom('boot', {
                app_id: 'vtbmal5l',
                email: user.email,
                name: user.name,
                custom_launcher_selector: '#intercom_container' 
            })
        }, 3000)
        return (<div></div>)
    }

}

Intercom.defaultProps = {}

function mapStateToProps(state) {
    var f = {
        user: {},
    }
    f.user = (state.user === undefined) ? {} : state.user
    return f;
}

export default connect(mapStateToProps)(Intercom);


