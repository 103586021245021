import React from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import SettingsBox from '../../../components/settingsBox'
import Button from '../../../components/button'
import { setDeliveryData, previousStep } from '../../../api/checkout/actions'
import { fetchContacts } from '../../../api/contacts/actions'
import Modal from '../../modal'
import CompaniesList from '../../modal/companiesList'
import DatePicker from 'react-datepicker'
import TimePicker from 'rc-time-picker'
import moment from 'moment'
import DeliveryWarning from './deliveryWarning'
import { getHolidays } from '../../../utils'

window.moment = moment

class DeliveryView extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            deliveryMode: "" // Maybe not needed, nice to have
        }

        this.disabledHours = this.disabledHours.bind(this)
        this.pickupTimeChange = this.pickupTimeChange.bind(this)
        this.renderDeliveryMode = this.renderDeliveryMode.bind(this)
        this.excludeByCutOff = this.excludeByCutOff.bind(this)
        this.isAllowedWeekday = this.isAllowedWeekday.bind(this)
        this.getFirstDeliveryDate = this.getFirstDeliveryDate.bind(this)
        this.setFirstPickupTime = this.setFirstPickupTime.bind(this)
        this.getCutOffTime = this.getCutOffTime.bind(this)
    }

    componentDidMount() {
        var deliveryData = Object.assign({
            deliveryDate: this.getFirstDeliveryDate('send'),
            pickupTime: this.setFirstPickupTime(),
            deliveryMode: ["SÓTT", "HEIMSENT"].includes(this.props.user.activeCompany.delivery_mode.code) ? 'pickup' : 'send',
            contactPerson: this.getContactPerson(),
            message: ``,
        }, this.props.checkout.deliveryData)
        this.props.dispatch(setDeliveryData(deliveryData))
        this.fetchContacts(this.props.user.activeCompany.account_id)
        this.checkDeliveryDate()
        this.checkPickupTime()
        $(window).scrollTop(0)

        this.setState({ deliveryMode: deliveryData.deliveryMode})
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user.activeCompany.account_id !== this.props.user.activeCompany.account_id) {
            this.fetchContacts(nextProps.user.activeCompany.account_id)
            this.props.dispatch(previousStep())
        }
    }


    setFirstPickupTime() {
        let time = moment().add(1, 'hours')
        let remainder = time.minute() % 15
        if (remainder >= 10) {
            time.add(15 - remainder, 'minutes')
        } else {
            time.subtract(remainder, 'minutes')
        }
        return time
    }

    getContactPerson() {
        if (this.props.checkout.deliveryData.contactPersonId !== undefined) {
            return this.props.contacts.contacts.results.find((c) => c.id === this.props.checkout.deliveryData.contactPersonId)
        }
        return undefined
    }

    getFirstDeliveryDate(deliveryMode) {
        let { user } = this.props

        if (this.props.checkout.deliveryData.salesShippingDate !== undefined) {
            return moment(this.props.checkout.deliveryData.salesShippingDate, "YYYY-MM-DD")
        }

        let delivery_mode_rule = user.activeCompany.delivery_mode.delivery_mode_rule
        if (deliveryMode === 'pickup') {
            delivery_mode_rule = window.garriConfig.pickupDeliveryMode
            if (user.activeCompany.independent_company) {
                delivery_mode_rule = window.garriConfig.pickupDeliveryModeIndividual
            }
        }

        let i = 0

        let firstDate = moment();

        let checking = true
        do {
            if (this.filterDates(firstDate, deliveryMode)) {
                checking = false
            } else {
                if (delivery_mode_rule.cut_off_add_days > 0) {
                    firstDate.add(delivery_mode_rule.cut_off_add_days, 'days')
                } else {
                    firstDate.add(1, 'days')
                }
            }
            i++
        } while (checking && i < 90)

        return firstDate
    }

    checkDeliveryDate() {
        if (!this.filterDates(this.props.checkout.deliveryData.deliveryDate, this.state.deliveryMode)) {
            this.props.dispatch(setDeliveryData({
                // deliveryDate: this.getFirstDeliveryDate(this.props.checkout.deliveryData.deliveryMode)
                deliveryDate: this.getFirstDeliveryDate(this.state.deliveryMode)
            }))
        }
    }

    checkPickupTime() {
        if (this.props.checkout.deliveryData.deliveryDate.day() === 6 || this.props.checkout.deliveryData.deliveryDate.day() === 0) {
            if (this.props.checkout.deliveryData.pickupTime.hour() >= 13 || this.props.checkout.deliveryData.pickupTime.hour() < 10) {
                this.props.dispatch(setDeliveryData({
                    pickupTime: moment().set('hour', 10).set('minute', 0),
                }))
            }
            if (this.disabledHours().includes(this.props.checkout.deliveryData.pickupTime.hour())) {
                this.props.dispatch(setDeliveryData({
                    pickupTime: moment().set('hour', 10).set('minute', 0),
                }))
            }
        }
        else {
            if (this.props.checkout.deliveryData.pickupTime.hour() >= 16 || this.props.checkout.deliveryData.pickupTime.hour() < 8) {
                this.props.dispatch(setDeliveryData({
                    pickupTime: moment().set('hour', 8).set('minute', 0),
                }))
            }
            if (this.disabledHours().includes(this.props.checkout.deliveryData.pickupTime.hour())) {
                this.props.dispatch(setDeliveryData({
                    pickupTime: moment().set('hour', 8).set('minute', 0),
                }))
            }
        }
    }


    pickupTimeChange(value) {
        let deliveryDate = this.props.checkout.deliveryData.deliveryDate
        // if (this.props.checkout.deliveryData.deliveryMode === "pickup") {
        if (this.state.deliveryMode === "pickup") {
            deliveryDate.hour(value.hour())
            deliveryDate.minute(value.minute())
            deliveryDate.second(value.second())
        }
        this.props.dispatch(setDeliveryData({
            pickupTime: value,
            deliveryDate: deliveryDate,
        }))
    }

    disabledHours() {
        // Sunday as 0 and Saturday as 6.
        let weekday = this.props.checkout.deliveryData.deliveryDate.day()
        let allHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
        let allowedHours = []
        let currentHour = moment().hour()
        let now = moment()

        if (weekday >= 1 && weekday <= 4) {
            // monday - thursday, 8-17
            allowedHours = [8, 9, 10, 11, 13, 14, 15]
        } else if (weekday === 5) {
            // friday
            allowedHours = [8, 9, 10, 11, 13, 14, 15]
        } else if (weekday === 6) {
            // saturday
            allowedHours = [10, 11, 12]
        }
        if (this.props.checkout.deliveryData.deliveryDate.format("MM-DD") === "12-31") {
            allowedHours = [8,9,10,11]

        }

        let hoursToReturn = []

        for (let hour of allHours) {
            if (this.props.checkout.deliveryData.deliveryDate.isSame(now, 'day')) {

                if (hour <= currentHour || !allowedHours.includes(hour)) {
                    hoursToReturn.push(hour)
                }
            } else {
                if (!allowedHours.includes(hour)) {
                    hoursToReturn.push(hour)
                }
            }
        }

        return hoursToReturn
    }

    includeDates() {
        let range = []
        let current = moment()
        for (let i = 1; i < 21; i++) {
            range.push(current)
            current = current.clone().add(1, 'days')
        }
        return range
    }

    isWeekday(date) {
        let day = date.day();
        return day !== 0 && day !== 6;
    }

    getCutOffTime(delivery_mode_rule, date) {
        let day = date.day();
        let cutOffByWeekDay = delivery_mode_rule.cut_off_time;
        if (delivery_mode_rule.cut_off_time_by_weekday) {
            cutOffByWeekDay = delivery_mode_rule.cut_off_time_by_weekday[day];
        }
        let cutOffTime = moment(cutOffByWeekDay, ["HH:mm:ss", "HH:mm"]);
        return cutOffTime;
    }

    isAllowedWeekday(date, deliveryMode) {
        let day = date.day();
        let { user } = this.props

        // If dates are off limits (holidays etc.)
        for (let d of this.excludeByCutOff()) {
            if (d.isSame(date, 'day')) {
                return false
            }
        }

        if (user.activeCompany && user.activeCompany.delivery_mode && user.activeCompany.delivery_mode.delivery_mode_rule) {

            let delivery_mode_rule = user.activeCompany.delivery_mode.delivery_mode_rule

            // Ath. Gömul gögn í bæði state og props
            // if (this.props.checkout.deliveryData.deliveryMode === 'pickup') {
            if (deliveryMode === 'pickup') {
                delivery_mode_rule = window.garriConfig.pickupDeliveryMode
                if (user.activeCompany.independent_company) {
                    delivery_mode_rule = window.garriConfig.pickupDeliveryModeIndividual
                }
            }
            let cutOffTime = moment(this.getCutOffTime(delivery_mode_rule, date), ["HH:mm:ss", "HH:mm"])
            if (moment().isSame(date, 'day') && delivery_mode_rule.allowed_weekdays[day]) {
                if (delivery_mode_rule.allow_same_day) {
                    if (moment().hour() >= cutOffTime.hour()) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return false
                }
            }
            return delivery_mode_rule.allowed_weekdays[day]
        }

        return true
    }

    excludeByCutOff() {
        let { user } = this.props

        let excludeDates = getHolidays(moment().year()).concat(getHolidays(moment().year() + 1))
        // excludeDates.push(moment("2022-06-08"))
        let noDelivery = window.garriConfig.noDeliveryDates
        noDelivery.forEach((day) => {
            excludeDates.push(moment(day, 'DD-MM-YYYY'))
        })

        if (user.activeCompany.delivery_mode === null || user.activeCompany.delivery_mode.delivery_mode_rule === null) {
            return []
        }

        let delivery_mode_rule = user.activeCompany.delivery_mode.delivery_mode_rule
        if (this.props.checkout.deliveryData.deliveryMode === 'pickup') {
            delivery_mode_rule = window.garriConfig.pickupDeliveryMode
            if (user.activeCompany.independent_company) {
                delivery_mode_rule = window.garriConfig.pickupDeliveryModeIndividual
            }
        }

        let cutOffTime = this.getCutOffTime(delivery_mode_rule, moment())

        let end = moment().add(delivery_mode_rule.cut_off_add_days, 'days')

        if (moment().hour() >= cutOffTime.hour()) {
            if (moment().minute() >= cutOffTime.minute()) {
                for (var m = moment(); m.isBefore(end); m.add(1, 'days')) {
                    excludeDates.push(m.clone().add(1, 'days'))
                }
            }
        }

        if (user.activeCompany.delivery_mode && user.activeCompany.delivery_mode.code === "HEIMSENT") {
            excludeDates = excludeDates.concat(this.props.shipping.exclude.map(d => moment(d)))
        }
        return excludeDates
    }

    filterDates(date, deliveryMode) {
        if (deliveryMode === undefined) {
            deliveryMode = this.state.deliveryMode
        }

        return this.isAllowedWeekday(date, deliveryMode)
    }

    handleDeliveryChange(e) {
        // this.checkDeliveryDate()
        let obj = { deliveryMode: e.target.value }
        this.setState({ deliveryMode: e.target.value })
        if (e.target.value === "pickup") {
            obj.deliveryDate = this.getFirstDeliveryDate("pickup")
        } else {
            obj.deliveryDate = this.getFirstDeliveryDate(e.target.value)
        }
        
        this.props.dispatch(setDeliveryData(obj))
    }

    handleInput(e) {
        let message = e.target.value
        let limit = +$(this.refs.message).attr('maxlength')
        message = message.substr(0, limit)
        this.props.dispatch(setDeliveryData({ message: message }))
    }


    fetchContacts(account_id) {
        if (account_id) {
            this.props.dispatch(fetchContacts(account_id)).then(() => {
                if (this.props.checkout.deliveryData.contactPersonId === undefined) {
                    let contact = this.props.contacts.contacts.results.find((c) => c.email === this.props.user.email)
                    if (contact === undefined) {
                        contact = this.props.contacts.contacts.results[0]
                    }
                    this.props.dispatch(setDeliveryData({ contactPerson: contact }))
                }
            })
        }
    }

    setContactPerson(contact) {
        this.props.dispatch(setDeliveryData({ contactPerson: contact }))
    }

    dateChange(deliveryDate) {
        let deliveryModeData = { deliveryDate: deliveryDate }
        if (deliveryDate.format("MM-DD") === "12-31") {
            deliveryModeData.deliveryMode = "pickup"
        }

        Promise.resolve(this.props.dispatch(setDeliveryData(deliveryModeData)))
        .then(data => {
            this.checkPickupTime()
        })
    }

    openContactsModal() {
        let modalProps = {
            title: "Viðskiptavinir",
            body: CompaniesList,
            callback: (() => { }),
            bodyProps: {},
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    renderAddress() {
        const { activeCompany } = this.props.user
        // if (this.props.checkout.deliveryData.deliveryMode === "pickup") {
        if (this.state.deliveryMode === "pickup") {
            return (
                <SettingsBox
                    image={window.garriConfig.icons.home}
                    innerHTML={
                        <div>
                            <p className="title editable">Afhendingarstaður</p>
                            <p>Garri ehf.</p>
                            <p>Hádegismóum 1<br />110 Reykjavík</p>
                        </div>
                    }
                />
            )
        } else {
            if (activeCompany.address) {
                return (
                    <SettingsBox
                        image={window.garriConfig.icons.home}
                        innerHTML={
                            <div>
                                <p className="title editable">Afhendingarstaður</p>
                                <p>{activeCompany.name}</p>
                                <p dangerouslySetInnerHTML={{ __html: activeCompany.address.split("\n").join("<br/>") }} />
                            </div>
                        }
                    />
                )
            } else {
                return (
                    <SettingsBox
                        image={window.garriConfig.icons.home}
                        innerHTML={
                            <div>
                                <p className="title editable">Afhendingarstaður</p>
                                <p>{activeCompany.name}</p>
                                <p dangerouslySetInnerHTML={{ __html: activeCompany.address.split("\n").join("<br/>") }} />
                            </div>
                        }
                    />
                )
            }
        }
    }

    renderDeliveryMode() {
        if (this.props.user.activeCompany.delivery_mode === null || this.props.user.activeCompany.delivery_mode.delivery_mode_rule === null) {
            return ''
        }
        // if (this.props.checkout.deliveryData.deliveryMode === "send") {
        if (this.state.deliveryMode === "send") {
            return ` (${this.props.user.activeCompany.delivery_mode.delivery_mode_rule.name})`
        }
    }

    render() {
        const { contactPerson, deliveryMode, deliveryDate, pickupTime, message } = this.props.checkout.deliveryData
        const { activeCompany } = this.props.user
        return (
            <div className="row delivery-view">
                <div className="col-12 col-xxl-9 order-container">
                    <div className="checkout-header">
                        <div className="header-element">
                            <h2>Afhendingarstaður</h2>
                        </div>
                        <div className="header-element">
                            <div className="checkout-state-container">
                                <div className="checkout-state-header">
                                    <div className="active">Vörur</div><div className="active">Afhending</div><div>Yfirferð</div>
                                </div>
                                <div className="checkout-state-loc">
                                    <div className="line first green" /><div className="line second" />
                                    <div className="loc green"></div><div className="loc green"></div><div className="loc"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row settings-box-container">
                        {this.renderAddress()}
                        {contactPerson &&
                            <SettingsBox
                                image={window.garriConfig.icons.userIcon}
                                innerHTML={
                                    <div>
                                        <div className="title editable dropdown">
                                            Tengiliður
                                            <i className="btn fa fa-pencil pointer" id="contacts-dropdown" data-toggle="dropdown" aria-haspopup="true"></i>
                                            <ul className="dropdown-menu" aria-labelledby="contacts-dropdown">
                                                {this.props.contacts.contacts.results && this.props.contacts.contacts.results.map((contact) =>
                                                    <li key={`user-contact-${contact.id}`} onClick={this.setContactPerson.bind(this, contact)}>{contact.name} <span>({contact.email})</span></li>
                                                )}
                                            </ul>
                                        </div>
                                        <p>{contactPerson.name}</p>
                                        <p>{contactPerson.email || 'Netfang ekki skráð'}</p>
                                        {this.props.user.companyOverride &&
                                            <div>
                                                <div className="title">Sölumaður</div>
                                                <p>{this.props.user.name}</p>
                                            </div>
                                        }
                                    </div>
                                }
                            />
                        }
                        {!contactPerson &&
                            <SettingsBox
                                image={window.garriConfig.icons.userIcon}
                                innerHTML={
                                    <div>
                                        <p className="title">Tengiliður</p>
                                        <p>Engir tengiliðir í boði</p>
                                        {this.props.user.companyOverride &&
                                            <div>
                                                <div className="title">Sölumaður</div>
                                                <p>{this.props.user.name}</p>
                                            </div>
                                        }
                                    </div>
                                }
                            />
                        }
                        <SettingsBox
                            image={window.garriConfig.icons.building}
                            innerHTML={
                                <div className="form-check">
                                    <p className="title">Afhendingarmáti</p>
                                    {(this.props.user.activeCompany.delivery_mode.code === "HEIMSENT") ?
                                        <p>Sækja í vöruhús {deliveryDate ? deliveryDate.format('DD. MMM'): null} kl. {pickupTime ? pickupTime.format('HH:mm') : null}</p>
                                        // <React.Fragment>
                                        //     <p>Heimsending</p>
                                        //     { deliveryDate &&
                                        //         <p>Pöntun þín verður heimsend {deliveryDate.format("D. MMM")} milli klukkan 16:00 og 20:00.</p>
                                        //     }
                                        // </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {(this.props.user.activeCompany.delivery_mode.code === "SÓTT") ?
                                                <p>Sækja í vöruhús</p>
                                                :
                                                <React.Fragment>
                                                    <input className="form-check-input" type="radio" name="deliveryMode" checked={deliveryMode === 'send'} id="deliveryMode1" onChange={this.handleDeliveryChange.bind(this)} value="send" /> <label htmlFor="deliveryMode1" className="form-check-label">Senda {this.renderDeliveryMode()}</label>
                                                    <input className="form-check-input" type="radio" name="deliveryMode" checked={deliveryMode === 'pickup'} id="deliveryMode2" onChange={this.handleDeliveryChange.bind(this)} value="pickup" /> <label htmlFor="deliveryMode2" className="form-check-label">Sækja í vöruhús</label>
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    }
                                </div>
                            }
                        />
                        <SettingsBox
                            image={window.garriConfig.icons.calendar}
                            innerHTML={
                                <div>
                                    <p className="title">{deliveryMode === 'pickup' ? "Sótt í vöruhús Garra" : "Sent frá vöruhúsi Garra"}</p>
                                    <DatePicker
                                        selected={deliveryDate}
                                        onChange={this.dateChange.bind(this)}
                                        showTimeSelect={false}
                                        timeFormat="HH:mm"
                                        filterDate={this.filterDates.bind(this)}
                                        includeDates={this.includeDates()}
                                        excludeDates={this.excludeByCutOff()}
                                        timeIntervals={30}
                                        dateFormat="ddd D/M/YYYY"
                                        onFocus={this.checkDeliveryDate.bind(this)}
                                    />
                                    { deliveryMode === 'pickup' &&
                                        <TimePicker
                                            showSecond={false}
                                            minuteStep={15}
                                            disabledHours={this.disabledHours}
                                            hideDisabledOptions={true}
                                            focusOnOpen={true}
                                            value={pickupTime}
                                            onChange={this.pickupTimeChange}
                                        />
                                    }
                                    <DeliveryWarning deliveryMode={deliveryMode} deliveryDate={deliveryDate} message={message} delivery_mode={activeCompany.delivery_mode} firstDeliveryDate={this.getFirstDeliveryDate(this.props.checkout.deliveryData.deliveryMode)} />
                                </div>
                            }
                        />
                        
                    </div>
                    <div className="row message-box-container">
                        <div className="form-group col-12 col-md-6">
                            <label htmlFor="message">Athugasemd á reikning <br />(Hámark {deliveryMode === 'pickup' ? 45 : 60} slög)</label>
                            <textarea maxlength={deliveryMode === 'pickup' ? 45 : 60} type="text" ref="message" className="form-control" id="message" value={message} onChange={this.handleInput.bind(this)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default DeliveryView;
