import React from 'react';
import ReactDOM from 'react-dom';
import { addProduct } from '../../api/cart/actions';
import { store } from '../../store';
import { getProductQty, quantityDisabled } from '../../utils';
import Modal from '../../views/modal';
import InformOnProductBack from '../../views/modal/informOnProductBack';
import PleaseSignIn from '../../views/modal/pleaseSignIn';

class OrderButton extends React.Component {

    constructor(props) {
        super(props)

        this.qtyDisabled = false
    }

    bindClick() {
        if (this.props.user && !this.props.user.isAuthenticated) {
            return null
        }

        if (this.props.selectMode) {
            return this.props.setSelectModeMessage()
        }
        if (!this.props.enabled) {
            var modalProps = {
                title: null,
                body: PleaseSignIn,
                bodyProps: {}
            }
            ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
        }

        if (this.props.enabled && !this.props.selectMode && (!this.qtyDisabled || this.props.searchResult)) {
            if (this.props.onClick) {
                this.props.onClick(this.props.product, this.props.quantity)
            } else {
                var qty = this.props.quantity
                if (qty === "-") {
                    qty = 1
                    if (this.props.product.item_weight) {
                        qty = this.props.product.item_weight
                    }
                }
                if (this.props.product.package_count > 1) {
                    qty = getProductQty(this.props.product, qty, true)
                }
                store.dispatch(addProduct(this.props.product, qty))
            }
            if (typeof this.props.callbackFn === "function") {
                this.props.callbackFn()
            }
        } else if (this.props.product.avail_physical < 1 && !this.props.searchResult && !this.props.product.ignore_stock) {
            var modalProps = {
                title: null,
                body: InformOnProductBack,
                bodyProps: {
                    product: this.props.product,
                    user: this.props.user,
                }
            }
            ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
        }
    }

    render() {
        // TODO, get quantity to add from parent component
        let classNames = "btn add-product"
        this.qtyDisabled = quantityDisabled(this.props.product, this.props.quantity)

        classNames = `${classNames} ${!(this.props.enabled && !this.props.selectMode) || this.qtyDisabled && !this.props.searchResult ? "btn-secondary" : "btn-success"}`

        if (this.props.selectMode) {
            classNames += " select-mode"
        }
        if (this.props.product.avail_physical < 1 && !this.props.searchResult && !this.props.product.ignore_stock) {
            classNames += " not-available"
        }

        if ((this.props.product.new_product_number || this.props.product.withdrawal) && this.props.product.avail_physical === 0 && !this.props.product.ignore_stock) {
            classNames += " new-number-or-withdrawal"
        }

        return (
                <button tabIndex={this.props.tabIndex} ref="order_button" className={classNames} onClick={this.bindClick.bind(this)} title={this.qtyDisabled ? "Vara er ekki til á lager sem stendur." : ""}>
                    <i className="plus"></i>
                    {(this.props.product.avail_physical < 1 && !this.props.product.ignore_stock) &&
                        <div className="button-text">
                            {this.props.user && this.props.user.isAuthenticated ?
                                <React.Fragment>Fá tilkynningu</React.Fragment>
                                :
                                <React.Fragment>Vara væntanleg</React.Fragment>
                            }
                        </div>
                    }
                </button>
        )
    }
}

OrderButton.defaultProps = {
    enabled: true,
    product: {},
    selectMode: false,
    quantity: 1,
    allowZeroQuantityAdd: false,
    tabIndex: null,
}

export default OrderButton;