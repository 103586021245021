import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { addProducts } from '../../api/cart/actions'
import { setMailingListPreference } from '../../api/user/actions'

class AddToMailingList extends React.Component {

    componentDidMount() {
    }

    setMailingListPreference(agrees) {
        const { dispatch, user } = this.props
        Promise.resolve(dispatch(setMailingListPreference(user, agrees)))
        .then(() => {
            this.props.close();
        })
    }

    render() {
        let { user } = this.props

        return (
            <div className="checkout-add-list-confirmation">
                <h2>Fréttabréf Garra</h2>
                <p>Fréttabréfið okkar inniheldur upplýsingar um tilboð og nýjar vörur sem gætu hentað þínum rekstri.</p>
                <p>Við viljum biðja þig að staðfesta samþykki þitt fyrir móttöku reglulegs fréttabréfs í tölvupósti hér fyrir neðan. Sjá <a href="/fyrirtækið/persónuvernd/">persónuverndarstefnu Garra</a> fyrir upplýsingar um meðhöndlun persónuupplýsinga.</p>
                <div className="row xs-mt-30">
                    <div className="col-6">
                        <button type="button" className="btn btn-danger checkout-btn-danger" onClick={this.setMailingListPreference.bind(this, false)}>Hafna</button>
                    </div>
                    <div className="col-6">
                        <button type="button" className="btn btn-success checkout-btn-success" onClick={this.setMailingListPreference.bind(this, true)}>Samþykkja</button>
                    </div>
                </div>
            </div>

        )
    }
}

module.exports = AddToMailingList;