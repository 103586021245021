import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom'
import Modal from '../modal'

import { connect } from 'react-redux'
import { fetchListSale, setActiveList, fetchSingleList, fetchFrequentProducts, fetchLists } from '../../api/lists/actions'
import ProductsGrid from '../../views/productsGrid/productsGrid'
import ListQuickOrderButton from './listQuickOrderButton'
import OwlCarousel from 'react-owl-carousel';
import { resetCarousel } from '../../api/carousel/actions'
import { Container, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { trackGAEvent } from '../../utils'
import { sortListByOrder } from '../../utils/index.js'
import { store } from '../../store';
import { getActiveCompany } from '../../utils';

import CreateNewList from '../modal/createNewList'
import { fetchProductPrices } from "../../api/products/actions.js";

class QuickOrder extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedListIndex: null,
            carouselMounted: false,
            dropdownOpen: false
        }
        this.owl = null
        // this.onListItemSelect = this.onListItemSelect.bind(this)
    }

    onListItemSelect(list) {

        var k = list.sale_id ? 'sale_id' : 'id';
        var v = list[k]

        if (list.name === "Mest pantað") {
            k = "name"
            v = "Mest pantað"
        }

        this.props.dispatch(resetCarousel())

        this.props.dispatch(setActiveList({ key: k, value: v }))
        
        if (k === 'sale_id') {
            if (list && list.sale_id) {
                this.props.dispatch(fetchListSale(v))
            }
        } else {
            if (list.products.length === 0 && list.name !== "Mest pantað" && list.fetched !== true) {
                this.props.dispatch(fetchSingleList(list.id))
            }
            if (list.name === "Mest pantað" && list.products.length === 0) {
                this.props.dispatch(fetchFrequentProducts())
            }
            // debugger
            if (list.is_promotion_page_list) {
                let state = store.getState()
                let account_id = getActiveCompany(state)
                this.props.dispatch(fetchProductPrices(list.products.map(p => p.product.item_id), account_id))
            }
        }

        if (list.is_promotion_page_list) {
            trackGAEvent('view_promotion', {
                'promotions': [
                    {
                        creative_slot: 'hradpontun',
                        name: list.name,
                    }
                ],
                'event_label': list.name,
            })
        }

        let { listOrder } = this.props.user
        const { lists, promotions } = this.props.lists
        let sortedLists = [...lists]
        sortedLists.sort(sortListByOrder(listOrder, 'id'))
        let allLists = []
        if ( lists && promotions ) {
            allLists = sortedLists.concat(promotions)
        }

        this.setState({ selectedListIndex: allLists.indexOf(list) })
    }

    hasLists() {
        return this.props.lists && this.props.lists.lists && this.props.lists.lists.length > 0
    }

    createNewList() {
        let modalProps = {
            title: null,
            body: CreateNewList,
            bodyProps: {
                products: [],
                dispatch: this.props.dispatch,
            }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }



    handleClick(e, idx){
        if (!$(e.target).hasClass('drag-handle')){
            this.onListItemSelect(idx)
        }
    }

    render() {
        let { listOrder } = this.props.user
        const { lists, promotions } = this.props.lists
        let sortedLists = [...lists]
        sortedLists.sort(sortListByOrder(listOrder, 'id'))
        let allLists = []
        if ( lists && promotions ) {
            allLists = sortedLists.concat(promotions)
        }
        let productList = []
        var selectedList = allLists[this.state.selectedListIndex]
        var numProducts = 0
        var listIndex = 0
        if ( sortedLists && sortedLists.length > 0 && selectedList) {
            productList = selectedList.products.map((listItem) => {
                return listItem.product
            })
            numProducts = selectedList.products.length
            listIndex = this.state.selectedListIndex
        }
        
        return (
            <div id="quick-order" className={`row quickOrder ${this.props.user.isAuthenticated && this.props.user.canPurchase && this.hasLists() ? "open" : "closed"}`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 carousel-container">
                                <Container className="container oc-container">
                                    <ButtonDropdown className="quickorder-dropdown" isOpen={this.state.dropdownOpen} toggle={() => { this.setState({ dropdownOpen: !this.state.dropdownOpen }) }}>
                                        <DropdownToggle>
                                            <h3>Mínir listar</h3>
                                            <img src={window.garriConfig.icons.caret} alt="Listarnir mínir" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            
                                            { sortedLists.map((list, idx) =>
                                                <DropdownItem onClick={this.onListItemSelect.bind(this, list)}>
                                                
                                                    <div className="name">
                                                        {list.name === "Mest pantað" &&
                                                            <img className="crown" src={window.garriConfig.icons.crown} alt="Flestar pantaðar vörur" />
                                                        }
                                                        {list.name !== "Mest pantað" && !list.is_promotion_page_list &&
                                                            <img className="heart" src={window.garriConfig.icons.heart2Color} alt="Líkaði við vörur" />
                                                        }
                                                        {list.is_promotion_page_list &&
                                                            <img className="star" src={window.garriConfig.icons.starGreen} alt="Stjörnumerktar vörur" />
                                                        }
                                                        {list.name}
                                                    </div>
                                                    <div className="num">{list.is_promotion_page_list ? <b>Kynning</b> : `${list.product_count} vörur`}</div>
                                                </DropdownItem>
                                            )}
                                            <button className="dropdown-item" onClick={this.createNewList.bind(this)}>

                                                <div className="name">
                                                    <img src={window.garriConfig.icons.plusGreen} height="16" alt="Búðu til nýjan lista" />
                                                    <strong>Búa til nýjan lista</strong>
                                                </div>
                                            </button>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                    <OwlCarousel
                                        items={5}
                                        slideBy={5}
                                        loop={false}
                                        margin={40}
                                        nav={true}
                                        mouseDrag={false}
                                        autoWidth={true}
                                        startPosition={listIndex}
                                        className='owl-theme lists-container'
                                        responsive={{
                                            0: {
                                                items: 1,
                                                slideBy: 1,
                                                autoWidth: false
                                            },
                                            575: {
                                                items: 2,
                                                slideBy: 2
                                            },
                                            1009: {
                                                items: 2,
                                                slideBy: 2
                                            },
                                            1389: {
                                                items: 2,
                                                slideBy: 2
                                            },
                                            1769: {
                                                items: 3,
                                                slideBy: 3
                                            },
                                            2149: {
                                                items: 4,
                                                slideBy: 4
                                            }
                                        }}
                                    >
                                        {/* {this.props.lists.lists.map((list, idx) => { */}
                                        { allLists.map((list, idx) => {
                                            let shouldShowList = true

                                            if ( list.is_promotion_page_list && ((this.props.user.independentUser)||(this.props.user.activeCompany && this.props.user.activeCompany.independent_company)) && list.category !== 'individuals') {
                                                shouldShowList = false
                                            }
                                            if ( list.is_promotion_page_list && !((this.props.user.independentUser)||(this.props.user.activeCompany && this.props.user.activeCompany.independent_company)) && list.category !== 'companies') {
                                                shouldShowList = false
                                            }
                                            if ( list.is_promotion_page_list && list.category === 'everybody') {
                                                shouldShowList = true
                                            }

                                            if (shouldShowList) {

                                                return <div className={`list-box list-type ${list.is_promotion_page_list ? 'promotion-page-list' : ''}`} key={`${list.name}-${idx}`} style={{ width: '340px' }}>
                                                    <div className={`inner-container ${list.image ? 'has-bg' : ''}`} onClick={this.onListItemSelect.bind(this, list)} style={{ 'backgroundImage': (list.image ? 'url(' + list.image.large + ')' : '') }}>
                                                        { list.is_promotion_page_list &&
                                                            <div className="kynning">Kynning</div>
                                                        }

                                                        {list.name === "Mest pantað" &&
                                                            <img className="crown" src={window.garriConfig.icons.crown} alt="Flestar pantaðar vörur"  />
                                                        }
                                                        {list.name !== "Mest pantað" && !list.is_promotion_page_list &&
                                                            <img className="heart" src={window.garriConfig.icons.heart2Color} alt="Líkaði við vörur" />
                                                        }
                                                        <div className="list-name">{list.name}</div>
                                                        {list.is_promotion_page_list &&
                                                            <div className="list-descr">{list.description}</div>
                                                        }
                                                        {!list.is_promotion_page_list &&
                                                            <div className="num-products">{list.product_count} vörur</div>
                                                        }
                                                    </div>
                                                    <ListQuickOrderButton
                                                        productList={list.products}
                                                        selectedList={list}
                                                        store={this.props.store}
                                                    />
                                                </div>
                                            }
                                        })}
                                    </OwlCarousel>
                                </Container>
                            </div>
                        </div>
                        <div className="row">
                            {!this.props.loading && productList.length > 0 &&
                                <div style={{ 'width': '100%' }}>
                                    <div className="container oc-container">
                                        <h3>{selectedList.name}</h3>
                                    </div>
                                    <ProductsGrid
                                        defaultProductImage={window.garriConfig.icons.defaultProductImage}
                                        infiniteScroll={false}
                                        productsGridView={"ProductLarge"}
                                        classNames={"product-list"}
                                        productList={productList}
                                        store={this.props.store}
                                        // lists={this.props.lists}
                                        lists={this.props.lists.lists}
                                        currentList={selectedList}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
        )
    }
}

function mapStateToProps(state) {
    var f = {
        lists: {},
        user: {},
        cart: {},
        loading: true,
    }
    f.user = (state.user === undefined) ? {} : state.user
    f.cart = (state.cart === undefined) ? {} : state.cart
    // f.lists = (state.lists === undefined) ? {} : state.lists.lists.filter((list) => !list.is_promotion_list)
    f.lists = (state.lists === undefined) ? {} : state.lists
    f.loading = (state.lists === undefined) ? {} : state.lists.isFetching || state.lists.isFetchingFrequent || state.lists.isFetchingSale
    f.carousel = (state.carousel === undefined) ? {} : state.carousel
    return f;
}

export default connect(mapStateToProps)(QuickOrder);
