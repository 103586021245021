import React from 'react';
import ReactDOM from 'react-dom'
import Modal from '../../views/modal'
import WarningModal from '../../views/modal/warning'
import { updateUser } from '../../api/users/actions'
import { authenticateUser, userLogout, userLogin } from '../../api/user/actions'

class User extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            email_error: null,
            password_error: null,
            user: {
                username: '',
                first_name: '',
                last_name: '',
                emailchange: '',
                telephone: '',
            }
        }
    }

    setUser(props) {
        var user = {...this.state.user}
        if(user.username){
            return user
        }
        var find = props.users.users.find((usr) => +usr.id === +props.user.id)
        if(find){
            user = Object.assign(user, find)
        }
        if (user.active_company_id && !user.activeCompany && user.companies.length > 0) {
            let comp = user.companies.filter(f => f.account_id === user.active_company_id)
            
            if (comp.length > 0) {
                user.activeCompany = comp[0]
                user.telephone = user.activeCompany.telephone
                user.address = user.activeCompany.address
            }
        }
        return user
    }

    componentWillReceiveProps(nextProps) {
        this.setState({user: this.setUser(nextProps)})
    }

    userPasswordChange(){
        let modalProps = {
            title: null,
            body: WarningModal,
            bodyProps: {
                jsx: (<h3 style={{'marginBottom': '50px'}}>Lykilorði hefur verið breytt.</h3>),
                confirmText: 'Loka',
                confirm: (() => {window.location.reload()}),
                dispatch: this.props.dispatch,
            }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    submit() {
        const {user} = this.props
        var email = user.email
        var emailChanged = false
        if(this.refs.emailchange.value || this.refs.email_confirmation.value){
            if(this.refs.emailchange.value === this.refs.email_confirmation.value && confirm("Vinsamlegast athugaðu að þú munt verða útskráður ef að þú breytir netfangi þínu.")){
                email = this.refs.emailchange.value
                emailChanged = true
            }else{
                return this.setState({email_error: "Staðfesting netfangs passar ekki við netfang"})
            }
        }

        var userObj = {
            first_name: this.refs.first_name.value || user.first_name,
            last_name: this.refs.last_name.value || user.last_name,
            email: email,
            id: user.id,
            is_active: this.props.users.users.find((usr) => +usr.id === +user.id).is_active,
            telephone: this.refs.telephone.value,
            address: this.refs.address.value
        }

        if(emailChanged){
            userObj.username = email
        }

        var password = null, old_password = null
        if(this.refs.old_password.value || this.refs.password.value){
            if(this.refs.password.value === this.refs.password_confirmation.value && confirm("Ertu viss um að þú viljir breyta lykilorði þínu?")){
                password = this.refs.password.value
                old_password = this.refs.old_password.value

                return this.setState({email_error: null, password_error: null, current_password_error: null}, () => {
                    this.props.dispatch(authenticateUser(this.props.user.username, old_password))
                        .then(() => {
                            if(this.props.user.password_change_auth_success){
                                userObj.username = email
                                this.props.dispatch(updateUser({...userObj, password: password}))
                                    .then(() => {
                                        if(emailChanged){
                                            return this.props.dispatch(userLogout())
                                        }
                                        this.props.dispatch(userLogin(userObj.email, password)).then(() => {
                                            this.userPasswordChange()
                                        })
                                    })
                            }else{
                                this.setState({current_password_error: "Núverandi lykilorð er ekki rétt."})
                            }
                        })
                })
            }else{
                return this.setState({password_error: "Staðfesting lykilorðs passar ekki við lykilorð"})
            }
        }

        this.setState({email_error: null, password_error: null, current_password_error: null}, () => {
            this.props.dispatch(updateUser(userObj)).then(() => {
                if(emailChanged){
                    this.props.dispatch(userLogout())
                }
            })
        })
    }

    onChange(e) {
        var stateObj = {...this.state}
        stateObj.user[e.target.id] = e.target.value
        this.setState(stateObj)
    }

    render() {
        const {user} = this.state
        return (
            <div>
                <h2>Notendastillingar</h2>
                <form>
                    <div className="row">
                        <div className="form-group col-lg-6 col-md-8 col-sm-10 col-xs-12">
                            <label htmlFor="first_name">Fornafn</label>
                            <input type="text" className="form-control" id="first_name" ref="first_name" onChange={this.onChange.bind(this)} value={user.first_name} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-6 col-md-8 col-sm-10 col-xs-12">
                            <label htmlFor="last_name">Eftirnafn</label>
                            <input type="text" className="form-control" id="last_name" ref="last_name" onChange={this.onChange.bind(this)} value={user.last_name} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-6 col-md-8 col-sm-10 col-xs-12">
                            <label htmlFor="emailshow">Netfang</label>
                            <input type="text" className="form-control" id="emailshow" ref="emailshow" disabled value={user.email} />
                        </div>
                    </div>

                    { this.state.user.independent_user && this.state.user.activeCompany &&
                        <React.Fragment>
                            <div className="row">
                                <div className="form-group col-lg-6 col-md-8 col-sm-10 col-xs-12">
                                    <label htmlFor="email">Farsímanúmer</label>
                                    <input type="text" className="form-control" id="telephone" ref="telephone" onChange={this.onChange.bind(this)} value={user.telephone} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-lg-6 col-md-8 col-sm-10 col-xs-12">
                                    <label htmlFor="email">Heimilisfang</label>
                                    <textarea type="text" className="form-control" id="address" ref="address" onChange={this.onChange.bind(this)}>{user.address}</textarea>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    
                    <h2>Breyta lykilorði</h2>
                    <div className="row">
                        <div className={`form-group ${this.state.current_password_error ? 'has-danger' : ''} col-lg-6 col-md-8 col-sm-10 col-xs-12`}>
                            <label htmlFor="old_password">Núverandi lykilorð</label>
                            <input type="password" className={`form-control ${this.state.current_password_error ? 'form-control-danger' : ''}`} ref="old_password" id="old_password" aria-describedby="nameHelp" />
                            <small className="form-control-feedback">{this.state.current_password_error}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className={`form-group col-lg-6 col-md-8 col-sm-10 col-xs-12`}>
                            <label htmlFor="password">Nýtt lykilorð</label>
                            <input type="password" className={`form-control`} ref="password" id="password" aria-describedby="nameHelp" />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`form-group ${this.state.password_error ? 'has-danger' : ''} col-lg-6 col-md-8 col-sm-10 col-xs-12`}>
                            <label htmlFor="password_confirmation">Nýtt lykilorð endurtekið</label>
                            <input type="password" className={`form-control ${this.state.password_error ? 'form-control-danger' : ''}`} ref="password_confirmation" id="password_confirmation" aria-describedby="nameHelp" />
                            <small className="form-control-feedback">{this.state.password_error}</small>
                        </div>
                    </div>
                    <h2>Breyta netfangi</h2>
                    <div className="row">
                        <div className="form-group col-lg-6 col-md-8 col-sm-10 col-xs-12">
                            <label htmlFor="emailchange">Netfang</label>
                            <input type="text" className="form-control" id="emailchange" ref="emailchange" onChange={this.onChange.bind(this)} value={user.emailchange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`form-group ${this.state.email_error ? 'has-danger' : ''} col-lg-6 col-md-8 col-sm-10 col-xs-12`}>
                            <label htmlFor="email_confirmation">Netfang endurtekið</label>
                            <input type="text" className={`form-control ${this.state.email_error ? 'form-control-danger' : ''}`} id="email_confirmation" ref="email_confirmation" />
                            <small className="form-control-feedback">{this.state.email_error}</small>
                        </div>
                    </div>

                    <input type="button" className="btn btn-success btn-submit" value="Vista" onClick={this.submit.bind(this)} />
                </form>
            </div>
        )
    }
}

export default User;