import React, { useRef, useState, useEffect } from 'react'
import { useDrag, useDrop } from 'react-dnd-cjs'
import ProductImage from '../product/productImage'
import YourPrice from '../product/yourPrice'
import { throttle } from 'lodash'

export const Product = ({ index, product, moveListItem, saveUpdateCallback, listOfProducts, removeProductCallback }) => {
    // export const ListItem = ({ text, index, moveListItem }) => {

    // useDrag - the list item is draggable
    const [{ isDragging }, dragRef] = useDrag({
        item: {
            type: 'product_item',
            index: index
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const throttleHover = throttle( (item, dragIndex, dropIndex) => {
        if (dragIndex !== dropIndex) {
            moveListItem(dragIndex, dropIndex);
        }
    }, 50 )


    // useDrop - the list item is also a drop area
    const [spec, dropRef] = useDrop({
        accept: 'product_item',
        hover: (item, monitor) => {
            const dragIndex = item.index
            const hoverIndex = index
            const hoverBoundingRect = ref.current.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top

            // if dragging down, continue only when hover is smaller than middle Y
            if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
            // if dragging up, continue only when hover is bigger than middle Y
            if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

            // throttleHover(dragIndex, hoverIndex)
            moveListItem(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
        drop: (item, monitor) => {
            saveUpdateCallback()
        }
    })


    // Join the 2 refs together into one (both draggable and can be dropped on)
    const ref = useRef(null)
    const dragDropRef = dragRef(dropRef(ref))

    // Make items being dragged transparent, so it's easier to see where we drop them
    const opacity = isDragging ? 0 : 1
    return (
        <tr ref={dragDropRef} style={{ opacity }}>
            <td className="image">
                <ProductImage product={product} attrs={{ className: 'tinyimage' }} />
            </td>
            <td className="name">
                {window.garriConfig.displayProductLinks ?
                    <a href={`/vara/${product.item_id}`}>{product.product_name}</a>
                    :
                    product.product_name
                }
            </td>
            <td><a href={`/vara/${product.item_id}`}>{product.item_id}</a></td>
            <td>{product.package_description}</td>
            <td className="quantity-container d-block d-md-table-cell">
                {product.deleted && product.replacement_products.length > 0 &&
                    <div>
                        <button className="btn btn-success btn-sm" onClick={this.replaceProduct.bind(this, product, product.replacement_products)}>Annað í boði</button>
                    </div>
                }
                {product.item_weight ? `${product.item_weight} ` : ''}{product.qty_unit}
            </td>
            <td><YourPrice product={product} quantity={1} /></td>
            <td onClick={(e) => removeProductCallback(product)} className="remove"><i className="fa fa-close"></i></td>
        </tr>
    )
}
