import fetch from 'isomorphic-fetch'
import jwtDecode from 'jwt-decode';
import qs from 'qs'
import TokenStorage from '../../storages/token-storage'

import { checkHttpStatus,
    handleNetworkError,
    parseJSON,
    getStore,
} from '../../utils'

export const REQUEST_FETCH_SHIPPING = 'REQUEST_FETCH_SHIPPING'
export const RECEIVE_SHIPPING_SUCCESS = 'RECEIVE_SHIPPING_SUCCESS'
export const RECEIVE_SHIPPING_ERROR = 'RECEIVE_SHIPPING_ERROR'

const requestFetchShipping = () => {
    return {
        type: REQUEST_FETCH_SHIPPING
    }
}

const receiveShippingSuccess = (response) => {
    return {
        type: RECEIVE_SHIPPING_SUCCESS,
        data: response
    }
}

const receiveShippingError = (response) => {
    return {
        type: RECEIVE_SHIPPING_ERROR
    }
}

export const fetchShipping = (data) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        dispatch(requestFetchShipping());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/shipping/`, {
            method: 'GET',
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveShippingSuccess(response));
            } catch (e) {
                dispatch(receiveShippingError(response))
            }


        })
        .catch(handleNetworkError);
    };
}
