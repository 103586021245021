import React from 'react';
import { connect } from 'react-redux'
import { findDOMNode } from 'react-dom';
import Product from '../product'
import ProductTiny from '../product/productTiny'
import * as Ps from 'perfect-scrollbar';

class SearchResults extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        Ps.initialize(findDOMNode(this.refs.searchedProducts));
    }

    addProductFromSearch(product) {
        this.props.addProduct(product)
    }

    render() {
        return (
            <div ref="searchedProducts" className={`searched-products table-striped ps ${this.props.search_results.length === 0 ? "no-border" : ""}`}>
                { this.props.search_results.length > 0 && this.props.search_results.map((el) =>
                    <Product
                        dispatch={this.props.dispatch}
                        key={`product-tiny-${el.item_id}`}
                        product={el}
                        viewName={ProductTiny}
                        user={this.props.user}
                        onClick={this.addProductFromSearch.bind(this)}
                    />
                )}
            </div>
        )
    }
}

export default SearchResults