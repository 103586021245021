import React from 'react'
import ReactDOM from 'react-dom'
import Checkout from './checkout'
import { store } from '../../store'

$.fn.checkout = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(Checkout, options), this);
  });
};

/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-checkout]').checkout();
});