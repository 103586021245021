import React from 'react';
import * as Ps from 'perfect-scrollbar';
import { setActiveCompany } from '../../api/companies/actions';
import { fetchServerCartItems, fetchOtherCarts } from '../../api/cart/actions';
import { updateUser } from '../../api/users/actions'
import { emptyCart } from '../../api/cart/actions'
import { closeCartList } from '../../utils'

class CartList extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
        }
        this.emptyCart = this.emptyCart.bind(this)
    }

    selectCompany = (company) => (e) => {
        var userObj = {
            username: this.props.user.username,
            first_name: this.props.user.first_name,
            last_name: this.props.user.last_name,
            id: this.props.user.id,
            active_company_id: company.account_id,
            is_active: true
        }
        Promise.resolve(this.props.dispatch(setActiveCompany(company)))
        .then(() => {
          this.props.dispatch(updateUser(userObj))
          this.props.dispatch(fetchServerCartItems(company.account_id));
          this.props.dispatch(fetchOtherCarts());
          closeCartList();
        })
      }

      emptyCart(cartId) {
        if (window.confirm("Ertu viss um að þú viljir tæma körfuna? Smelltu á OK til að tæma, Cancel til að hætta við.")) {
            Promise.resolve(this.props.dispatch(emptyCart(cartId)))
            .then(() => {
                this.props.dispatch(fetchOtherCarts());
                closeCartList();
            })
        }
    }

    render() {
        const {carts} = {...this.props}
        return (
            <div id="cart-list" className={this.props.classNames}>
                <div className="header-container">
                    <h3>Ófrágengnar körfur</h3>
                </div>

                <div className="companies">{carts.other_carts.filter((c) => c.company.account_id !== this.props.user.activeCompany.account_id).map((cart) => {
                    return <div className="company" >
                        <div className="company-name" onClick={this.selectCompany(cart.company)}>
                            <div>{ cart.company.name }</div>
                            <div className="cart-count">{cart.item_count} vörur</div>
                        </div>
                        <div className="remove-price" onClick={() => this.emptyCart(cart.cart_id)}><i className="fa fa-close"></i></div>
                    </div>
                })}</div>
            </div>
        )
    }
}

CartList.defaultProps = {
    classNames: '',
}

export default CartList;
