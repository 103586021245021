import React, { useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import ListDropDown from '../listDropDown'
import Button from '../button'
import Product from '../product'
import ProductSpinner from '../product-spinner'
import ProductSearchResult from '../product/ProductSearchResult'
import Modal from '../../views/modal'
import CreateNewList from '../../views/modal/createNewList'
import EditList from '../../views/modal/editList'
import DeleteList from '../../views/modal/deleteList'
import EditListOrder from '../../views/modal/editListOrder'
import SearchContainer from '../search'
import { patchList, fetchLists, fetchSingleList } from '../../api/lists/actions'
import CheckoutAddList from '../../views/modal/checkoutAddList'
import { addProducts } from '../../api/cart/actions'

import CopyListToCompany from './copyListToCompany'
import DragDropTable from '../dragDropTable';
import { sortListByOrder } from '../../utils/index.js'


class MyLists extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedListIndex: 0,     
            selectedListId: 0,      // Current myList id
            selectedList: [],       // Current myList
            headers: [],            // table headers
            ordLists: []            // myLists ordered by preference
        }
    }

    componentDidMount(){
        const { listOrder } = this.props.user

        var ordLists = this.props.lists.lists

        ordLists.sort(sortListByOrder(listOrder, 'id'))
        this.setState({selectedList: ordLists[0]})
        this.setState({ordLists: ordLists})

        if(ordLists && ordLists[0]){
            this.props.dispatch(fetchSingleList(ordLists[0].id))
        }

        // Set table headers
        this.setState({headers: ['Mynd', 'Heiti', 'Vörunúmer', 'Magn í Pakkningu', 'Eining', 'Verð', '']})
    }

    componentWillReceiveProps(nextProps, nextContext) {
        var { lists } = this.props.lists;
        var { selectedListId, selectedList } = this.state;

        // Order list by preference if myLists are deleted or rearranged
        if (lists.length !== nextProps.lists.lists.length || this.props.user.listOrder !== nextProps.user.listOrder) {
            let ordLists = nextProps.lists.lists
            ordLists.sort(sortListByOrder(nextProps.user.listOrder, 'id'))

            // If a list has been selected, find it in new ordered list by id
            if(selectedListId){
                let selList = ordLists.find(x => x.id === selectedListId)
                this.setState({ selectedList: selList })
                this.props.dispatch(fetchSingleList(selectedListId))
            } else {
                this.setState({ selectedList: ordLists[0] })
                this.setState({ selectedListId: ordLists[0].id })
                this.props.dispatch(fetchSingleList(ordLists[0].id))
            }
            this.setState({ ordLists: ordLists })

        } 
    }


    componentDidUpdate(prevProps, prevState){
        var { lists } = this.props.lists;
        var { selectedListId, selectedList } = this.state;
        
        if(selectedList){
            // Find currently selected list in new list
            let selList = lists.find(x => x.id === selectedListId)
            if(selList.products !== selectedList.products){
                // List has changed
                this.setState({ selectedList: selList })
                this.props.dispatch(fetchSingleList(selList.id))
            }
        }
    }

    getListText() {
        if (this.state.selectedList) {
            return `${this.state.selectedList.name} (${this.state.selectedList.product_count})`
        }
        return `Veldu Lista`
    }

    addProduct(product) {
        var list = this.state.selectedList
        list.show_steps = list.products.length === 0

        var exists_in_list = list.products.find((prod_el) => prod_el.product.item_id === product.item_id)
        if (exists_in_list) {
            return
        }
        list.products.push({ product: product, quantity: 1 })
        this.props.dispatch(patchList({
            id: list.id,
            products: list.products,
            show_steps: list.show_steps,
        }))

    }

    onListItemSelect(index) {
        this.setState({
            selectedListIndex: index,
            // list: this.props.lists.lists[index]
            selectedListId: this.state.ordLists[index].id,
            selectedList: this.state.ordLists[index]
        })
        this.props.dispatch(fetchSingleList(this.state.ordLists[index].id))
    }

    onListsUpdated() {
        let { selectedListId } = this.state;
        this.props.dispatch(fetchLists())
        .then(() => {
            const { listOrder } = this.props.user
            

            var ordLists = this.props.lists.lists
            ordLists.sort(sortListByOrder(listOrder, 'id'))

            this.setState({ordLists: ordLists})
            let selList = ordLists.find(x => x.id === selectedListId)
            // this.setState({ list: this.props.lists.lists[this.state.selectedListIndex] })
            this.setState({ selectedList: selList })
            this.setState({ selectedListId: selList.id })
        })
    }

    editListNameModal() {
        let modalProps = {
            title: null,
            body: EditList,
            callback: this.onListsUpdated.bind(this),
            bodyProps: {
                list: this.state.selectedList,
                dispatch: this.props.dispatch,
            }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    createNewListModal() {
        let modalProps = {
            title: null,
            body: CreateNewList,
            callback: this.onListsUpdated.bind(this),
            bodyProps: {
                products: [],
                dispatch: this.props.dispatch,
            }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    copyList(){
      let modalProps = {
        title: null,
        body: CopyListToCompany,
        callback: this.onListsUpdated.bind(this),
        bodyProps: {
            dispatch: this.props.dispatch,
            list: this.props.lists.lists[this.state.selectedListIndex],
        }
      }
      ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    deleteListModal() {
        let modalProps = {
            title: null,
            body: DeleteList,
            callback: this.onListsUpdated.bind(this),
            bodyProps: {
                dispatch: this.props.dispatch,
                list: this.props.lists.lists[this.state.selectedListIndex],
            }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    updateShowSteps(list, show_steps) {
        list.show_steps = show_steps
        this.props.dispatch(patchList({
            id: list.id,
            show_steps,
        }))
    }

    addListToCart() {
        var selectedList = this.props.lists.lists[this.state.selectedListIndex]
        if (Object.keys(this.props.cart.products).length === 0) {
            Promise.resolve(this.props.dispatch(addProducts(selectedList.products, false)))
            return true
        }
        let modalProps = {
            title: null,
            body: CheckoutAddList,
            bodyProps: {
                products: selectedList.products,
                redirect: true,
                dispatch: this.props.dispatch,
            }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    editListOrderModal(lists) {
        let modalProps = {
            title: null,
            body: EditListOrder,
            callback: this.onListsUpdated.bind(this),
            bodyProps: {
                user: this.props.user,
                list: lists,
                dispatch: this.props.dispatch,
            }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    moveListItem(newOrderedList){
        const list = this.state.selectedList
        list.products = newOrderedList.map((p, i) => {
            p.sort_order = i;
            return p
        })
        this.setState({selectedList: list})
        this.props.dispatch(patchList({
            id: list.id,
            products: list.products,
        }))
    }

    

    hasLists = () => (this.props.lists && this.props.lists.lists && this.props.lists.lists.length > 0)

    render() {
        var lists = this.state.ordLists
        return (
            <div>
                {/* {list && list.name && list.products && this.props.myListsVisible &&
                    <StepsContainer list={list} enabled={list.show_steps} updateShowSteps={this.updateShowSteps.bind(this)} />
                } */}
                <h3>Valinn listi</h3>
                <div className="row">
                    <div className="col-md-4 list-selector-wrapper">
                        <ListDropDown
                            lists={this.state.ordLists}
                            selectedIndex={this.state.selectedListIndex}
                            onListItemSelect={this.onListItemSelect.bind(this)}
                            textValue={this.getListText()}
                            classNames={'btn settings btn-default btn-products-list xl-m-icon'}
                        />
                        {this.hasLists() &&
                            <Button classNames={'settings btn-default xs-ml-10'} innerHTML={(
                                <div><i className="fa fa-pencil"></i></div>
                            )} handleClick={this.editListNameModal.bind(this)} />
                        }
                    </div>
                    <div className="col-md-8 text-center text-lg-right md-padding-top list-actions">
                        {this.hasLists() &&
                            <Button classNames={'settings btn-default xs-ml-10'} innerHTML={(
                                <div><i className="fa fa-pencil mr10"></i>Breyta listaröðun</div>
                            )} handleClick={this.editListOrderModal.bind(this, lists)} />
                        }
                        {this.hasLists() &&
                            <Button classNames={'settings btn-default xs-ml-10'} innerHTML={(
                                <div><i className="fa fa-cart-plus mr10"></i>Bæta í körfu</div>
                            )} handleClick={this.addListToCart.bind(this)} />
                        }
                        {this.hasLists() && this.props.user.companyOverride &&
                            <Button classNames={'settings btn-default xs-ml-10'} innerHTML={(
                                <div><i className="fa fa-copy mr10"></i>Afrita lista</div>
                            )} handleClick={this.copyList.bind(this)} />
                        }
                        {this.hasLists() &&
                            <Button classNames={'settings btn-danger btn-delete-list xs-ml-10'} innerHTML={(
                                <div><i className="fa fa-trash-o mr10"></i>Eyða lista</div>
                            )} handleClick={this.deleteListModal.bind(this)} />
                        }
                        <Button classNames={'settings btn-success xs-ml-10'} innerHTML={(
                            <div><i className="fa fa-plus mr10"></i>Nýr listi</div>
                        )} handleClick={this.createNewListModal.bind(this)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="table-responsive">
                            {this.state.selectedList && this.state.selectedList.products &&
                                <DragDropTable 
                                    headers={this.state.headers} 
                                    listId={this.state.selectedList.id} 
                                    content={this.state.selectedList.products} 
                                    dispatch={this.props.dispatch} 
                                    callback={this.moveListItem.bind(this)} 
                                    isProduct={true}>
                                </DragDropTable>
                            }
                        </div>
                    </div>
                </div>
                {this.state.selectedList && this.state.selectedList.products &&
                    <div className="add-products row">
                        <div className="row search">
                            <div className="container xs-p-10">
                                <SearchContainer q={this.props.q} showResults={false} skipRedirect={true} />
                            </div>
                        </div>
                        <div className="row products">
                            <div className="container">
                                <div ref="searchedProducts" id="searchedProducts" className={`row desktop-products searched-products`}>
                                    <table className="table-searched-products table-striped">
                                        <tbody>
                                            {this.props.search_products.search_products.length > 0 && this.props.search_products.search_products.map((el) =>
                                                <Product
                                                    dispatch={this.props.dispatch}
                                                    key={`product-tiny-${el.item_id}`}
                                                    product={el}
                                                    viewName={ProductSearchResult}
                                                    user={this.props.user}
                                                    skipCallback={true}
                                                    onClick={this.addProduct.bind(this)}
                                                    showPrice={this.props.user.isAuthenticated}
                                                    allowZeroQuantityAdd={true}
                                                />
                                            )}
                                        </tbody>
                                    </table>
                                    {this.props.search_products.isFetching &&
                                        <div className="search-fetching-container">
                                            <ProductSpinner />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <hr />
                <hr />
            </div>
        )
    }
}


export default MyLists;