import React from 'react';

import { NumToIsk } from '../../utils'
import ProductImage from './productImage'

class ProductSale extends React.Component {

    render() {
        const {product, quantity} = {...this.props.product}
        return (
            <tr className={`product productSale ${this.props.classNames}`}>
                <td className="product-image">
                    <ProductImage product={product} attrs={{width: "35"}} />
                </td>
                <td className="product-name">
                    {window.garriConfig.displayProductLinks ?
                        <a href={`/vara/${product.item_id }`}>{ product.product_name }</a>
                    :
                        product.product_name
                    }
                </td>
                <td className="product-id"><a href={`/vara/${product.item_id }`}>{ product.item_id }</a></td>
                <td className="product-qty-unit">{product.item_weight ? `${product.item_weight} ` : ''}{product.qty_unit}</td>
                <td className="qty">{quantity}</td>
                <td className="product-your-price">{ NumToIsk(product.sales_price) } kr.</td>
            </tr>
        )
    }
}

ProductSale.defaultProps = {
    classNames: ''
}

export default ProductSale;
