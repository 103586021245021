import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import SearchBar from '../searchBar/searchBar'

class ProductSearch extends React.Component {

  componentDidMount(){
  }

  render(){
    return (
      <SearchBar store={this.props.store} modalSearch={true} />
    )
  }
}

module.exports = ProductSearch;