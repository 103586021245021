import moment from 'moment';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { fetchReport } from '../../api/salesReport/actions';
import { NumToIsk } from '../../utils';

class SalesReport extends React.Component {

    constructor(props) {
        super(props)

        this.state = { 
          months: ["Janúar", "Febrúar", "Mars", "Apríl", "Maí", "Júní", "Júlí", "Ágúst", "September", "Október", "Nóvember", "Desember"],
          fetchedData: false,
          colors: {
            "blue": [103, 214, 240],
            "green": [119, 226, 146]
          },
          colorCurrYear: "green",
          colorPrevYear: "blue",
          currYearColors: {},
          prevYearColors: {}
        }
        this.getOptions = this.getOptions.bind(this)
        this.getColors = this.getColors.bind(this)
    }

    componentDidMount(){
        this.fetchReport(this.props.user.activeCompany.account_id)
        this.state.currYearColors  = this.getColors(this.state.colorCurrYear)
        this.state.prevYearColors  = this.getColors(this.state.colorPrevYear)
    }

    getColors(color){
      var col = this.state.colors[color]
      var bgCol = "rgba(" + col[0] + "," + col[1] + "," + col[2] + ", 1)"
      var bdCol = "rgba(" + col[0] + "," + col[1] + "," + col[2] + ", 1)"
      var hoverBgCol = "rgba(" + col[0] + "," + col[1] + "," + col[2] + ", 0.4)"
      var hoverBdCol = "rgba(" + col[0] + "," + col[1] + "," + col[2] + ", 1)"
      var topColumnBg = "rgba(" + col[0] + "," + col[1] + "," + col[2] + ", 0.2)"

      return { 
        "bgCol" : bgCol, 
        "bdCol": bdCol, 
        "hoverBgCol": hoverBgCol, 
        "hoverBdCol": hoverBdCol, 
        "topColumnBg": topColumnBg 
      }
    }

    getData(){
      let data = this.props.report.report
      let currYear = data.currentYear
      let prevYear = data.lastYear
      let structuredData = []
      let currDateY = moment().year()
      let prevDateY = moment().subtract(1, 'years').year()

      if(currYear && prevYear){
        let currYearDiscount = []
        let currYearFull = []
        let prevYearDiscount = []
        let prevYearFull = []

        for (var obj of currYear) {
          currYearDiscount.push(obj.salesAmountIncDiscount)
          currYearFull.push(obj.salesAmount - obj.salesAmountIncDiscount)
        }
        for (var obj of prevYear) {
          prevYearDiscount.push(obj.salesAmountIncDiscount)
          prevYearFull.push(obj.salesAmount - obj.salesAmountIncDiscount)
        }
        
        let currCol = this.state.currYearColors
        let prevCol = this.state.prevYearColors

        structuredData = {
          labels: this.state.months,
          datasets: [
            {
              label: currDateY, // + " með afsl.",
              stack: currDateY,
              backgroundColor: currCol["bgCol"],
              borderColor: currCol["bdCol"],
              borderWidth: 1,
              hoverBackgroundColor: currCol["hoverBgCol"],
              hoverBorderColor: currCol["hoverBdCol"],
              data: currYearDiscount
            },
            // {
            //   label: currDateY, // + " fullt verð",
            //   stack: currDateY,
            //   backgroundColor: currCol["bgCol"],
            //   borderColor: currCol["bdCol"],
            //   borderWidth: 1,
            //   hoverBackgroundColor: currCol["hoverBgCol"],
            //   hoverBorderColor: currCol["hoverBdCol"],
            //   data: currYearFull
            // },
            {
              label: prevDateY, // + " með afsl.",
              stack: prevDateY,
              backgroundColor: prevCol["bgCol"],
              borderColor: prevCol["bdCol"],
              borderWidth: 1,
              hoverBackgroundColor: prevCol["hoverBgCol"],
              hoverBorderColor: prevCol["hoverBdCol"],
              data: prevYearDiscount
            },
            // {
            //   label: prevDateY, // + " fullt verð",
            //   stack: prevDateY,
            //   backgroundColor: prevCol["bgCol"],
            //   borderColor: prevCol["bdCol"],
            //   borderWidth: 1,
            //   hoverBackgroundColor: prevCol["hoverBgCol"],
            //   hoverBorderColor: prevCol["hoverBdCol"],
            //   data: prevYearFull
            // },
          ]
        };
      }
      return structuredData
    }

    getOptions(){
      return {
        // maintainAspectRatio: false,
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ 
            stacked: true,
            ticks: {
              callback: function(value, index, values){
                if(value > 0){
                  value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") //+ "*"
                }
                return value
              }
            }
          }]
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    var label = data.datasets[tooltipItem.datasetIndex].label || '';

                    if (label) {
                        label += ': ';
                    }
                    label += NumToIsk(parseInt(Math.round(tooltipItem.yLabel))) ;
                    return label;
                }
            }
        }
      }
    }

    fetchReport(account_id){
        var data = {}
        if(account_id){
            data['custAccount'] = account_id
        }
        this.props.dispatch(fetchReport(data))
    }
    
    getSumSalesAmount(year) {
      let sumSalesAmount = 0;
      year.map((month) => {
        sumSalesAmount = sumSalesAmount + month.salesAmountIncDiscount
      })
      return NumToIsk(sumSalesAmount)
    }

    render() {

        let currentYear = moment().year()
        let lastYear = moment().subtract(1, 'years').year()
  
        let { isFetching, report } = this.props.report
        console.log(report)
        return (
          <React.Fragment>
          { !isFetching && Object.keys(report).length > 0 &&
            <table class="table sales-report-table">
              <thead>
                <th></th><th className="text-right"><span className="year-dot current-year-dot"></span> {currentYear}</th><th className="text-right"><span className="year-dot last-year-dot"></span>{lastYear}</th>
              </thead>
              <tbody>
                { this.state.months.map((val, index) => {
                  return <tr>
                    <td className="text-right">{this.state.months[index]}</td><td className="text-right">{report.currentYear && NumToIsk(report.currentYear[index].salesAmountIncDiscount)} kr.</td><td className="text-right">{report.lastYear && NumToIsk(report.lastYear[index].salesAmountIncDiscount)} kr.</td>
                  </tr>
                })}
                <tr className="sum-line">
                    <td className="text-right">Samtals</td><td className="text-right">{report.currentYear && this.getSumSalesAmount(report.currentYear)} kr.</td><td className="text-right">{report.lastYear && this.getSumSalesAmount(report.lastYear)} kr.</td>
                  </tr>
              </tbody>
            </table>
          }

          <div>
            { !isFetching && Object.keys(report).length > 0 &&
              <Bar
                data={this.getData.bind(this)}
                width={100}
                height={50}
                options={this.getOptions()}
              />
            }
          </div>
          {/* <div className="chart-info">
            <p className="small">* Upphæðir eru gefnar í 1000 ISK.</p>
          </div> */}
          </React.Fragment>
        )
    }
}


function mapStateToProps(state) {
  var f = {
      report: {},
  }
  f.report = (state.report === undefined) ? {} : state.report
  return f;
}

export default connect(mapStateToProps)(SalesReport);