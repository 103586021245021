var moment = require('moment')
moment.locale('is')
require('bootstrap')
import 'owl.carousel';
window.kennitala = require('kennitala');
var MobileDetect = require('mobile-detect')
window.md = new MobileDetect(window.navigator.userAgent);


var data = $('.interesting-panel-container').owlCarousel({
    items: 5,
    slideBy: 5,
    loop: false,
    margin: 40,
    nav: true,
    mouseDrag : false,
    autoWidth: true,
    responsive:{
        0:{
            items: 1,
            autoWidth: false,
            slideBy: 1
        },
        575: {
            items: 2,
            slideBy: 2
        },
        1009:{
            items: 2,
            slideBy: 2
        },
        1389:{
            items:2,
            slideBy: 2
        },
        1769:{
            items:3,
            slideBy: 3
        },
        2149:{
            items: 4,
            slideBy: 4
        }
    }
})

require('./components/product')
require('./components/cart')
require('./views/userbar')
require('./views/productsGrid')
require('./views/quickOrder')
require('./views/checkout')
require('./views/checkoutButtonBar')
require('./views/selectedProductsBar')
require('./views/searchBar')
require('./views/settings')
require('./views/signup')
require('./views/headingImage')
require('./views/singleProduct')
require('./views/intercom')
require('./views/quantityGroupModal')
require('./views/valitorResponse')