import React from 'react'
import ReactDOM from 'react-dom'
import CheckoutButtonBar from './checkoutButtonBar'
import { store } from '../../store'

$.fn.checkoutButtonBar = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(CheckoutButtonBar, options), this);
  });
};

/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-checkout-button-bar]').checkoutButtonBar();
});