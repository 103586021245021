import React from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { userLogin, userLogout } from '../../api/user/actions'
import Cart from '../../components/cart'
import CartList from '../../components/cart-list'
import LoginForm from './loginForm'
import MainMenuButton from './mainMenuButton'
import { toggleCart } from '../../utils'
import UserDisplay from '../../components/user/userDisplay'
import Modal from '../../views/modal'
import ProductSearch from '../../views/modal/productSearch'
import { fetchSearchProducts, clearSearchProducts } from '../../api/products/actions'


class UserbarMobile extends React.Component {

  

    constructor(props) {
        super(props)
        this.state = {
            cartOpen: false
        }
    }

    componentDidMount() {
        mobileMenu();
        window.iOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream;
        $(document).click((e) => {
          if(e.target.href && e.target.href === '#my-menu' || $.contains($("#my-menu")[0], e.target)){
            return;
          }
          if($('body').hasClass('login-open') && !($.contains($('#user-sidebar-mobile')[0], e.target) || $.contains($('.userbar-mobile-container')[0], e.target))) {
            $('body').removeClass('login-open')
            this.setBodyPosition('');
          }
          if($('body').hasClass('sidebar-open') && !($.contains($('#sidebar')[0], e.target) || $.contains($('.userbar-mobile-container')[0], e.target))) {
            $('body').removeClass('sidebar-open')
          }
        })
        this.setBodyPosition()
    }

    handleLogout(e) {
        this.props.dispatch(userLogout())
    }

    numberOfProducts() {
        return this.props.cart.products.filter((p) => p.quantity > 0).length
    }

    otherCartsNum() {
      if (this.props.carts.other_carts.length > 0) {
          return this.props.carts.other_carts.filter((c) => c.company.account_id !== this.props.user.activeCompany.account_id).length
      } else {
          return 0
      }
  }


    openSearchModal() {
      this.props.dispatch(clearSearchProducts())
      let modalProps = {
        title: null,
        body: ProductSearch,
        large: true,
        bodyProps: {
          store: this.props.store
        }
      }
      ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
  }


    setBodyPosition(bodyPositionStyle){
      if (window.iOS) {
        //document.body.style.overflow = 'hidden';
        $("body").addClass("ios-active");
        document.body.style.height = '100%';
        document.body.style.width = '100%';
        //document.body.style.position = bodyPositionStyle;
        if(bodyPositionStyle === 'fixed'){
          $("body").addClass("ios-login-mode");
          setTimeout(function(){
            $(window).scrollTop(0)
          }, 200)
        }else{
          $("body").removeClass("ios-login-mode");
        }
      }
    }

    toggleCart() {
      $("body").toggleClass("cart-open")
      $("body").removeClass("login-open")
      $("body").removeClass("cart-list-open")
      this.setBodyPosition('');
    }

    toggleCartList() {
      $("body").toggleClass("cart-list-open")
      $("body").removeClass("login-open")
      $("body").removeClass("cart-open")
      this.setBodyPosition('');
    }

    toggleLogin() {
      $("body").toggleClass("login-open")
      $("body").removeClass("cart-open")
      $("body").removeClass("cart-list-open")
      setTimeout(() => {
        if($("body").hasClass("login-open")){
          this.setBodyPosition('fixed');
        }else{
          this.setBodyPosition('');
        }
      }, 400)
    }

    getLink() {
      var pathArr = window.location.pathname.split('/')
      var storeLinks = [
        'vefverslun',
        'vara',
        'stillingar',
        'pontun',
        'karfa',
      ]
      if (pathArr.length > 1 && storeLinks.indexOf(pathArr[1])>-1){
        window.location.href='/vefverslun/'
      } else {
        window.location.href='/'
      }
    }

    render() {
        return (
          <div className="userbar-mobile-container">
            <a href="#my-menu" className="burger" data-menu-toggler>
              <div className="img-container"><img src={this.props.burger} width="20" className="burger-icon" alt="Valmynd"/><img src={this.props.close} width="20" className="close-icon" alt="Loka valmynd"/></div>
            </a>
            <span className="search-icon-container" onClick={this.openSearchModal.bind(this)}>
              <i className="search-icon-dark"></i>
            </span>
            <a className="logo" onClick={this.getLink.bind(this)}>
              <img src={this.props.logo} width="80" alt="Garri"/>
            </a>
            <div className="user-basket-container">
              {!this.props.user.isAuthenticated && addToHomescreen.OS === 'ios' && 
                <a data-to-homescren-logo className="to-homescreen-logo" onClick={() => {toHomescreenClick()}}>
                  <div className="img-container">
                    <img src={this.props.addToHomescreen} width="30" alt="Bæta við sem appi"/>
                  </div>
                </a>
              }
              {this.otherCartsNum() > 0 &&
                <a className="cart-list cart-list-icon" onClick={this.toggleCartList.bind(this)}><div className="img-container"><img src={this.props.multiBaskets} className="multi-basket" alt="Körfulisti" width="25"/></div><div className="count-badge">{this.otherCartsNum()}</div></a>
              }
              {this.props.user.isAuthenticated && this.props.user.canPurchase &&
                <a className="cart-logo" onClick={this.toggleCart.bind(this)}><div className="img-container"><img src={this.props.basket} className="basket-icon" alt="Karfa" width="25"/><img src={this.props.basketRed} className="basket-red" alt="Basket" width="25"/></div><div className="count-badge">{this.numberOfProducts()}</div></a>
              }
              <a className="user-logo" onClick={this.toggleLogin.bind(this)}><div className="img-container"><img src={this.props.userIcon} className="user-icon" alt="Notandi" width="22"/><img src={this.props.userIconRed} className="user-red" width="22" alt="Notandi"/></div></a>
            </div>
            { this.props.user.canPurchase &&
              <Cart classNames={"mobile-cart"} isMobile={true} cart={this.props.cart} user={this.props.user} arrow={this.props.arrow} dispatch={this.props.dispatch} enlargeArrows={this.props.enlargeArrows} cartEmptyBasket={this.props.cartEmptyBasket} redArrowRight={this.props.redArrowRight} />
            }
            {this.otherCartsNum() > 0 &&
                <CartList classNames={"mobile-cart-list"} carts={this.props.carts} user={this.props.user} dispatch={this.props.dispatch} toggleCartList={this.handleToggleCartList}/>
            }
            <UserSidebar
              user={this.props.user}
              dispatch={this.props.dispatch}
              handleLogout={this.handleLogout.bind(this)}
            />
          </div>
        )
    }
}

class UserSidebar extends React.Component {
  render() {
    if(this.props.user.isAuthenticated){
      return (
        <div id="user-sidebar-mobile">
          <div className="header">
            <UserDisplay user={this.props.user} />
            <div><a className="btn-logout" onClick={this.props.handleLogout}>Útskrá</a></div>
          </div>
          <ul className="links">
            <li><a href={`${window.garriConfig.settingsUrl}#user`}>Notandi</a></li>
            { this.props.user.canPurchase &&
                <li><a href={`${window.garriConfig.settingsUrl}#mylists`}>Mínir listar</a></li>
            }
            { ((this.props.user.companyAdmin || this.props.user.companyOverride) && !this.props.user.independentUser) &&
                <li><a href={`${window.garriConfig.settingsUrl}#accesses`}>Aðgangar</a></li>
            }
            { (this.props.user.canSeeInvoices || this.props.user.canPurchase) &&
              <li><a href={`${window.garriConfig.settingsUrl}#orders`}>Pantanir</a></li>
            }
            { (this.props.user.canSeeInvoices || this.props.user.canPurchase) &&
              <li><a href={`${window.garriConfig.settingsUrl}#invoices`}>Reikningar</a></li>
            }
            { this.props.user.canSeeInvoices &&
                <li><a href={`${window.garriConfig.settingsUrl}#transaction`}>Heyfingar</a></li>
            }
            { this.props.user.canSeeInvoices &&
                <li><a href={`${window.garriConfig.settingsUrl}#claims`}>Kröfur</a></li>
            }
            { this.props.user.companyOverride &&
                <li><a href={`${window.garriConfig.settingsUrl}#salesReport`}>Söluskýrsla</a></li>
            }
            { this.props.user.activeCompany && this.props.user.activeCompany.customer_contact &&
                <li><a href={`${window.garriConfig.settingsUrl}#customerContact`}>Tengiliður</a></li>
            }
          </ul>
        </div>
      )
    }
    return (
      <div id="user-sidebar-mobile">
        <div className="header">
          <h3>Innskráning</h3>
        </div>
        <LoginForm
            user={this.props.user}
            dispatch={this.props.dispatch}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {

    var f = {
        user: {},
        cart: {},
        carts: {},
    }
    f.user = (state.user === undefined) ? {} : state.user
    f.cart = (state.cart === undefined) ? {} : state.cart
    f.carts = (state.carts === undefined) ? {} : state.carts
    return f;
}

export default connect(mapStateToProps)(UserbarMobile);
