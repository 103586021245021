import React from 'react';
import ReactDOM from 'react-dom'
import User from '../user'
import UserManage from '../user/userManage'
import Modal from '../../views/modal'
import UserNew from '../user/userNew'

class Accesses extends React.Component {

    constructor(props) {
        super(props)
    }

    newUser() {
        let modalProps = {
          title: null,
          body: User,
          large: true,
          bodyProps: {
            products: [],
            dispatch: this.props.dispatch,
            viewName: UserNew
          }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    render() {
        return (
            <div>
                <div className="row">
                { this.props.user.companyAdmin &&
                        <div className="col-md-3 text-center order-md-2 mb-5">
                            <button className="btn btn-success btn-new-user" onClick={this.newUser.bind(this)}><i className="fa fa-plus"></i>Nýr notandi</button>
                        </div>
                    }
                    <div className="col-md-9 order-md-1">
                        {this.props.users.users.length > 0 &&
                            this.props.users.users.map((user) =>
                                +user.id !== this.props.user.id &&
                                <User
                                    dispatch={this.props.dispatch}
                                    key={`manage-user-${user.id}-${user.is_active}`}
                                    user={user}
                                    current_user={this.props.user}
                                    fetchUsers={this.props.fetchUsers}
                                    viewName={UserManage}
                                />
                            )
                        }
                    </div>
                    
                </div>
            </div>
        )
    }
}


export default Accesses;