import jwtDecode from 'jwt-decode';
import moment from 'moment'
import { GetSessionStorageState, StateToSessionStorage } from '../../storages/session-storage'

import { SET_ACTIVE_COMPANY } from '../companies/actions'

import {
    REQUEST_LISTS,
    RECEIVE_LISTS,
    REQUEST_LISTS_OVERVIEW,
    RECEIVE_LISTS_OVERVIEW,
    REQUEST_LISTS_SINGLE,
    RECEIVE_LISTS_SINGLE,
    REQUEST_CREATE_LIST,
    CREATE_LIST_SUCCESS,
    RECEIVE_FREQUENT_PRODUCTS,
    REQUEST_FREQUENT_PRODUCTS,
    RECEIVE_FREQUENT_PRODUCTS_OVERVIEW,
    REQUEST_FREQUENT_PRODUCTS_OVERVIEW,
    RECEIVE_RECENT_SALES,
    REQUEST_LIST_SALE,
    RECEIVE_LIST_SALE_SUCCESS,
    RECEIVE_LIST_SALE_ERROR,
    SET_ACTIVE_LIST,
    REQUEST_PROMOTION_LISTS,
    RECEIVE_PROMOTION_LISTS,
    RECEIVE_PROMOTION_LISTS_ERROR
} from './actions'

import { 
    AUTH_LOGOUT,
    AUTH_LOGIN_SUCCESS,
} from '../user/actions'

const _emptyState = {
    isFetching: false,
    isFetchingFrequent: false,
    isFetchingSale: false,
    lists: [],
}

export function lists(state = _emptyState, action) {
    switch(action.type) {
        case CREATE_LIST_SUCCESS:
            state = {...state,
                lists: state.lists.map((list) => {
                    if(list.id === action.data.id){
                        list.show_steps = action.data.show_steps,
                        list.products = action.data.products
                        list.product_count = action.data.products.length
                    }
                    return list
                })
            }

            StateToSessionStorage(state, 'lists')
            return state

        case REQUEST_FREQUENT_PRODUCTS:
            state = {...state,
                isFetchingFrequent: true,
            }

        return state
        case REQUEST_CREATE_LIST:
            state = {...state}
            return state
        case REQUEST_LISTS:
            state = {...state,
                isFetching: true,
            }
            return state

        case RECEIVE_LISTS_OVERVIEW:
            let frequentOverview = state.lists.find((el) => el.name === "Mest pantað")

            state = {...state,
                isFetching: false
            }

            const newLists = action.data.map((el) => {
                const find = state.lists.find((existingList) => existingList.id === el.id)
                if (find) {
                    return {
                        ...find,
                        product_count: el.product_count,
                    }
                }
                return el;
            });
            if (frequentOverview) {
                state.lists = [frequentOverview].concat(newLists)
            } else {
                state.lists = newLists
            }

            state.lists = state.lists.map((list) => {
                if (list.products === undefined) {
                    list.products = []
                }
                return list
            })

            return state


        case RECEIVE_LISTS:

            let frequent = state.lists.find((el) => el.name === "Mest pantað")

            state = {...state,
                isFetching: false
            }
            var active_list = state.lists.find((el) => el.active)
            
            if (frequent) {
                state.lists = [frequent].concat(action.data.results)
            } else {
                state.lists = action.data.results
            }
            
            if(active_list){
                state.lists = state.lists.map((el) => {
                    if(el.id && el.id === active_list.id){
                        el.active = true
                    }else if(el.sale_id && el.sale_id === active_list.id){
                        el.active = true
                    }
                    return el
                })
            }

            state.lists = state.lists.map((list) => {
                if (list.products !== undefined) {
                    list.product_count = list.products.length
                }
                return list
            })

            return state

        case RECEIVE_LISTS_SINGLE:

            state = {...state,
                isFetching: false
            }

            state.lists = state.lists.map((el) => {
                if(el.id && el.id === action.data.id){
                    el.products = action.data.products
                    el.product_count = action.data.products.length
                    el.fetched = true
                }
                
                return el
            })

            

            return state

        case REQUEST_PROMOTION_LISTS:
            state = {...state,
                isFetching: true,
            }
            return state

        case RECEIVE_PROMOTION_LISTS:
            state = {...state,
                isFetching: false
            }

            const listSort = (a, b) => {
                if (a.name === "Mest pantað") {
                    return -1
                } else {
                    return 1
                }
            }

            // "Kynningarlistar" bætt við "Mínir listar" - skiptir máli upp á uppröðun hvort er concat
            // state.lists = [...state.lists].concat(action.data).sort(listSort)
            state.promotions = action.data

            return state

        case RECEIVE_FREQUENT_PRODUCTS_OVERVIEW:
            state = {...state,
                isFetchingFrequent: false,
            }

            let _frequent = [{
                name: "Mest pantað",
                products: action.data.products,
                product_count: action.data.count,
                show_steps: false,
            }]

            if (state.lists.length > 0) {
                var existingFrequent = state.lists.find((el) => el.name === "Mest pantað")
                if (existingFrequent) {
                    state.lists[0] = _frequent[0]
                } else {
                    state.lists = _frequent.concat(state.lists)
                }
            } else {
                state.lists = _frequent
            }


            return state

    
        case RECEIVE_FREQUENT_PRODUCTS:
            state = {...state,
                isFetchingFrequent: action.data.next === null ? false : true,
            }
            
            var frequent = []
            if (action.data.results.length > 0) {

                if (action.extra) {
                    
                    frequent = state.lists.find((el) => el.name === "Mest pantað")

                    frequent.products = frequent.products.concat(action.data.results.map((p) => {
                        let pr = p.product
                        pr.avail_physical = +p.product.avail_physical
                        pr.sales_price = +p.product.sales_price
                        pr.sales_price_vat = +p.product.sales_price_vat
                        return {
                            product: pr,
                            quantity: 0,
                        }
                    }))
                    //frequent.product_count += action.data.count
                    frequent = [frequent]
                } else {
                    frequent = [{
                        name: "Mest pantað",
                        products: action.data.results.map((p) => {
                            let pr = p.product
                            pr.avail_physical = +p.product.avail_physical
                            pr.sales_price = +p.product.sales_price
                            pr.sales_price_vat = +p.product.sales_price_vat
                            return {
                                product: pr,
                                quantity: 0,
                            }
                        }),
                        product_count: action.data.count,
                        show_steps: false,
                    }]
               }

               if (state.lists.length > 0) {
                    var existingFrequent = state.lists.find((el) => el.name === "Mest pantað")
                    if (existingFrequent) {
                        state.lists[0] = frequent[0]
                    } else {
                        state.lists = frequent.concat(state.lists)
                    }
                } else {
                    state.lists = frequent
                }

            }

            return state

        case SET_ACTIVE_COMPANY:
            state = {
                ...state,
                lists: [],
            }

            return state
        case RECEIVE_RECENT_SALES:
            state = {...state,
                isFetchingSale: false,
            }

            var lists = []
            var frequent = state.lists.find((el) => el.name === "Mest pantað")

            if(frequent){
                lists = [frequent]
            }

            for(var i = 0; i < action.data.results.length; i++){
                var sale = action.data.results[i]
                lists.push({
                    name: `Pöntun (${moment(sale.shipping_date).format('ll')})`,
                    sale_id: sale.sale_id,
                    products: [],
                    show_steps: false
                })
            }

            if (state.lists.length > 0) {
                state.lists = lists.concat(state.lists.filter((el) => el.name !== "Mest pantað"))
            } else {
                state.lists = lists
            }

            return state
        case REQUEST_LIST_SALE:
            state = {...state,
                isFetchingSale: true
            }

            return state

        case RECEIVE_LIST_SALE_SUCCESS:
            state = {...state,
                isFetchingSale: false
            }

            var list = state.lists.find((el) => el.sale_id === action.sale_id)
            if (action.data.count > 0) {
                list.products = [...action.data.results]
            }

            return state

        case RECEIVE_LIST_SALE_ERROR:
            state = {...state,
                isFetchingSale: false
            }

            var list = state.lists.find((el) => el.sale_id === action.sale_id)
            if (list) {
                list.products = []
            }

            return state
        
        case RECEIVE_PROMOTION_LISTS_ERROR:
            state = {...state,
                isFetching: false
            }

            return state

        case SET_ACTIVE_LIST:
            var state = {...state}
            var active_list = state.lists.find((el) => el.active)
            if(active_list){
                active_list.active = undefined
            }

            var new_active_list = state.lists.find((el) => el[action.key] === action.value)
            if(new_active_list){
                new_active_list.active = true
            }

            return state

        case AUTH_LOGOUT:
            state = _emptyState;

            return state;

        default:
            return state
    }

}