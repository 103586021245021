import React from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { NumToIsk } from '../../../utils'
import SettingsBox from '../../../components/settingsBox'
import Button from '../../../components/button'
import Product from '../../../components/product'
import ProductCheckoutConfirm from '../../../components/product/productCheckoutConfirm'
import moment from 'moment'
import DeliveryWarning from './deliveryWarning'
import {addProduct} from '../../../api/cart/actions'

class ConfirmView extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        $(window).scrollTop(0)
        if (this.props.checkout.deliveryData.deliveryMode!== "pickup" && this.props.user.activeCompany.delivery_mode && this.props.user.activeCompany.delivery_mode.delivery_mode_rule && this.props.user.activeCompany.delivery_mode && !this.props.user.activeCompany.delivery_mode.delivery_mode_rule.is_free && this.props.cart.total < this.props.user.activeCompany.delivery_mode.delivery_mode_rule.minimum_amount) {
            if (this.props.user.activeCompany.delivery_mode.code === "HEIMSENT" && this.props.cart.products.filter(p => p.product.item_id === this.props.user.activeCompany.delivery_mode.delivery_mode_rule.delivery_fee_item_id).length === 0) {
                this.props.dispatch(addProduct({
                    display_on_web: true,
                    is_shipping_fee: true,
                    package_count: 0,
                    addon_products: [],
                    product_name: "Heimsendingargjald",
                    item_id: this.props.user.activeCompany.delivery_mode.delivery_mode_rule.delivery_fee_item_id,
                    sales_price_vat: +this.props.user.activeCompany.delivery_mode.delivery_mode_rule.delivery_fee_amount,
                    image: {
                        large: window.garriConfig.icons.shippingImage,
                        thumb: window.garriConfig.icons.shippingImage,
                        thumb_large: window.garriConfig.icons.shippingImage
                    }
                }, 1))
            }
        }
    }

    getContactPerson() {
        var contact = undefined
        if(this.props.contacts.contacts && this.props.contacts.contacts.results && this.props.checkout.deliveryData.contactPersonId){
            var contact = this.props.contacts.contacts.results.find((c) => 
                c.id === this.props.checkout.deliveryData.contactPersonId
            )
        }

        return contact
    }

    getProducts() {
        return Object.keys(this.props.cart.products).map((k) => this.props.cart.products[k]).filter((p) => p.quantity > 0)
    }

    renderDeliveryMode() {
        if (this.props.user.activeCompany.delivery_mode === null || this.props.user.activeCompany.delivery_mode.delivery_mode_rule === null) {
            return ''
        }
        if (this.props.checkout.deliveryData.deliveryMode === "send") {
            return ` (${this.props.user.activeCompany.delivery_mode.delivery_mode_rule.name})`
        }
    }

    render() {
        var contactPerson = this.getContactPerson()
        var pickupTime = moment(this.props.checkout.deliveryData.salesShippingDate)
        const {activeCompany} = this.props.user

        return (
            <div className="row confirm-view">
                <div className="col-12 col-xxl-9 order-container">
                    <div className="checkout-header">
                        <div className="header-element">
                            <h2>Yfirferð</h2>
                        </div>
                        <div className="header-element">
                            <div className="checkout-state-container">
                                <div className="checkout-state-header">
                                    <div className="active">Vörur</div><div className="active">Afhending</div><div className="active">Yfirferð</div>
                                </div>
                                <div className="checkout-state-loc">
                                    <div className="line first green" /><div className="line second green" />
                                    <div className="loc green"></div><div className="loc green"></div><div className="loc green"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.checkout.deliveryData.customerRef !== "" &&
                        <div className="col-12">
                            <p><strong>Athugasemd á reikning</strong> {this.props.checkout.deliveryData.customerRef} </p>
                        </div>
                    }
                    <div className="products-container">
                        <table className="table-products">
                            <thead>
                                <tr>
                                    <th>Mynd</th>
                                    <th>Vöruheiti</th>
                                    <th>Vörunúmer</th>
                                    <th>Stærð</th>
                                    <th>Fj. í pakkningu</th>
                                    <th>Magn</th>
                                    <th>Verð</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.getProducts().map((p, index) =>
                                    <Product
                                        dispatch={this.props.dispatch}
                                        key={index}
                                        product={p}
                                        viewName={ProductCheckoutConfirm}
                                        user={this.props.user}
                                    />
                                )}
                            </tbody>
                        </table>
                        <div className="mobile-products">
                            { this.getProducts().map((p, index) =>
                                <Product
                                    dispatch={this.props.dispatch}
                                    key={index}
                                    product={p}
                                    viewName={ProductCheckoutConfirm}
                                    isMobile={true}
                                    user={this.props.user}
                                />
                            )}
                        </div>
                        <hr/>
                        <hr/>
                        <div className="total-container row">                                
                            <div className="total">Samtals{((this.props.user.activeCompany && this.props.user.activeCompany.vsk_active) && ((this.props.user.independentUser)||(this.props.user.activeCompany && this.props.user.activeCompany.independent_company))) ? ' m/vsk' : ' án vsk'}: <span className="total-text">{NumToIsk(this.props.cart.total)} kr.</span></div>
                        </div>
                        <div className="row settings-box-container">
                            <SettingsBox
                                image={window.garriConfig.icons.home}
                                classNames={'read-only'}
                                innerHTML={
                                    <div>
                                        <p className="title">Afhendingarstaður</p>
                                        <p>{this.props.checkout.deliveryData.deliveryMode === "send" ? activeCompany.name : "Garri ehf"}</p>
                                        { this.props.checkout.deliveryData.deliveryMode === "send" ?
                                            <p dangerouslySetInnerHTML={{__html: activeCompany.address.split("\n").join("<br/>")}} />
                                            :
                                            <p>Hádegismóum 1<br />110 Reykjavík</p>
                                        }
                                    </div>
                                }
                            />
                            <SettingsBox
                                image={window.garriConfig.icons.userIcon}
                                classNames={'read-only'}
                                innerHTML={
                                    <div>
                                        { contactPerson &&
                                            <div>
                                                <p className="title">Tengiliður</p>
                                                <p>{contactPerson.name}</p>
                                                <p>{contactPerson.email || 'Netfang ekki skráð'}</p>
                                                <p>{contactPerson.phone}</p>
                                            </div>
                                        }
                                        { this.props.user.companyOverride &&
                                            <div>
                                                <p className="title">Sölumaður</p>
                                                <p>{this.props.user.name}</p>
                                            </div>
                                        }
                                    </div>
                                }
                            />
                            <SettingsBox
                                image={window.garriConfig.icons.calendar}
                                classNames={'read-only'}
                                innerHTML={
                                    <div>
                                        <p className="title">Dagsetning</p>
                                        {this.props.checkout.deliveryData.deliveryMode === "send" ?
                                            <p>{pickupTime.format("D. MMM YYYY")}</p>
                                            :
                                            <p>{pickupTime.format("D. MMM YYYY [kl.] HH:mm")}</p>
                                        }
                                    </div>
                                }
                            />
                            <SettingsBox
                                image={window.garriConfig.icons.building}
                                classNames={'read-only'}
                                innerHTML={
                                    <div>
                                        <p className="title">Afhendingarmáti</p>
                                        <p>{this.props.checkout.deliveryData.deliveryMode === "send" ? `Senda${this.renderDeliveryMode()}` : "Sækja"}</p>
                                    </div>
                                }
                            />
                            <div className="col-12">
                                <DeliveryWarning deliveryMode={this.props.checkout.deliveryData.deliveryMode} deliveryDate={pickupTime} message={this.props.checkout.deliveryData.customerRef} />
                                { this.props.checkout.deliveryData.deliveryMode!== "pickup" && activeCompany.delivery_mode && activeCompany.delivery_mode.delivery_mode_rule && activeCompany.delivery_mode && !activeCompany.delivery_mode.delivery_mode_rule.is_free && this.props.cart.total < activeCompany.delivery_mode.delivery_mode_rule.minimum_amount &&
                                    <div className="alert alert-danger extra-transfer-cost" dangerouslySetInnerHTML={{__html: activeCompany.delivery_mode.delivery_mode_rule.transport_fee_text}} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.checkout.submitting_order &&
                    <div className="submitting-order-container">
                        <h2>Sendi pöntun</h2>
                        <i className="delivering"></i>
                        <p>Ekki slökkva á vafranum fyrr en þessi mynd er horfin af skjánum.</p>
                    </div>
                }
            </div>
        )
    }
}

export default ConfirmView;
