import React from 'react';
import ReactDOM from 'react-dom'
import Modal from '../../views/modal'
import { connect } from 'react-redux'
import inViewport from 'in-viewport'
import Button from '../../components/button'
import { fetchProduct, fetchNextProducts, requestProducts } from '../../api/products/actions'
import Product from '../../components/product'
import ProductSingle from '../../components/product/productSingle'
// import ReplacementButton from "../../components/product/replacementButton"
import ReplacementProducts from '../../views/modal/replacementProducts'


class SingleProduct extends React.Component {

    constructor(props) {
        super(props)
        this.goBackFunction=this.goBackFunction.bind(this)
    }

    componentWillMount() {
        if (this.props.productId) {
            this.props.dispatch(fetchProduct(`${this.props.productId}`))
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.productMeta.dirty && !prevProps.productMeta.dirty && !this.props.productMeta.isFetching) {
            setTimeout(() => {
                this.props.dispatch(fetchProduct(`${this.props.productId}`))
            }, 200);
        }
    }

    getBackUrl(){
        if(window.history.length > 1){
            return document.referrer
        }
        return window.garriConfig.storeUrl
    }

    getBackText(){
        if(window.history.length > 1){
            return 'Til baka'
        }
        return 'Aftur í vefverslun'
    }

    goBackFunction(){
        if(window.history.length > 1) {
            history.back();
        } else {
            window.location.href=window.garriConfig.storeUrl;
        }
    }

    replaceProduct(toReplace, products) {
      console.log(products, toReplace)

      let replacingProductInList = !(+toReplace.avail_physical === 0 && toReplace.replacement_products.length > 0)
      let modalProps = {
          title: null,
          body: ReplacementProducts,
          large: true,
          bodyProps: {
              products: products,
              toReplace: toReplace,
              dispatch: this.props.dispatch,
              replacingProductInList: replacingProductInList,
              lists: this.props.lists,
          }
      }
      ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
  }
  
    
    render() {
        let {product} = {...this.props}
        return (
            <div className={`productsGrid row ${this.props.classNames}`} style={{margin: '0'}}>
                <Product
                    key={product.item_id} product={product}
                    viewName={ProductSingle}
                    classNames={this.props.productClassNames+' large'}
                    user={this.props.user}
                    dispatch={this.props.dispatch}
                    replaceProduct={this.replaceProduct.bind(this)}
                />
                <Button
                    anchor={false}
                    // href={this.getBackUrl()}
                    handleClick={() => this.goBackFunction()}
                    classNames={'checkout cancel btn-default mb-4 mw-100'}
                    innerHTML={(<div><img className="rotate" src={window.garriConfig.icons.arrowGray} width="22" alt="Til baka" /> {this.getBackText()}</div>)}
                />
            </div>
        )
    }
}

SingleProduct.defaultProps = {
    classNames: "",
    category: undefined,
    product: {},
    productClassNames: "",
    infiniteScroll: true,
}


function mapStateToProps(state) {

    var f = {}
    f.productMeta = (state.product === undefined) ? {} : state.product
    f.product = (state.product === undefined) ? {} : state.product.product
    f.user = (state.user === undefined) ? {} : state.user
    return f;
}

export default connect(mapStateToProps)(SingleProduct);
