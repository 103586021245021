import React from 'react';

import { NumToIsk } from '../../utils'
import YourPrice from './yourPrice'
import ProductImage from './productImage'
import {useShallowEqual} from 'shouldcomponentupdate-children';

class ProductCheckoutConfirmMobile extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            quantity: this.props.quantity
        }
    }


    render() {
        const {quantity, product} = {...this.props.product}
        return (
            <div className={`product productCheckoutConfirmMobile ${this.props.classNames}`}>
                <div className="product-image">
                    <div className="image">
                        <ProductImage product={product}  />
                    </div>
                </div>
                <div className="product-info">
                    <div className="flex-row">
                        <div className="id"><a href={`/vara/${product.item_id }`}>{product.item_id}</a></div>
                        <div className="product-package-description">{product.package_description}</div>
                        <div className="qty-unit">{product.item_weight ? `${product.item_weight} ` : ''}{product.qty_unit}</div>
                    </div>
                    <div className="flex-row">
                        <div className="name">
                            {window.garriConfig.displayProductLinks ?
                                <a href={`/vara/${product.item_id }`}>{ product.product_name }</a>
                            :
                                product.product_name
                            }
                        </div>
                        <div className="quantity">{quantity}</div>
                        <div className="price"><YourPrice product={product} quantity={quantity} /></div>
                    </div>
                </div>
            </div>
        )
    }
}

ProductCheckoutConfirmMobile.defaultProps = {
    classNames: '',
}

export default useShallowEqual(ProductCheckoutConfirmMobile);