import React from 'react'
import ReactDOM from 'react-dom'
import QuantityGroupModal from './quantityGroupModal'
import { store } from '../../store'

$.fn.quantityGroupModal = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(QuantityGroupModal, options), this);
  });
};

/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-quantity-group-modal]').quantityGroupModal();
});