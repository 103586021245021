import React from 'react';
import { updateUser } from '../../api/users/actions'

class UserManage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            edit: false,
            user: {
                first_name: this.props.user.first_name,
                last_name: this.props.user.last_name
            }
        }
    }

    submit() {
        this.setState({edit: false})
        const {user} = this.props
        this.props.submit({...this.state.user,
            email: user.email,
            id: user.id,
            is_active: user.is_active,
            username: user.username
        })
    }

    toggleActivate() {
        this.props.dispatch(updateUser({...this.props.user, is_active: !this.props.user.is_active})).then(() => this.props.fetchUsers())
    }

    editButton() {
        if (this.props.current_user.companyAdmin) {
            return (<i className="fa fa-pencil" onClick={() => {this.setState({edit: !this.state.edit})}}></i>)
        }
    }

    change(e) {
        var newState = {...this.state}
        newState.user[e.target.name] = e.target.value
        this.setState(newState)
    }

    renderName() {
        if(this.state.edit){
            return (
                <div>
                    <div className="form-group">
                        <label htmlFor="user_first_name">Fornafn</label>
                        <input className="form-control" onChange={this.change.bind(this)} type="text" name="first_name" id="user_first_name" value={this.state.user.first_name}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="user_last_name">Eftirnafn</label>
                        <input className="form-control" onChange={this.change.bind(this)} type="text" name="last_name" id="user_last_name" value={this.state.user.last_name}/>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <h2>{this.props.user.first_name} {this.props.user.last_name} {this.editButton()}</h2>
                <p>{this.props.user.email}</p>
            </div>
        )
    }

    render() {
        var activated = this.props.user.is_active
        return (
            <div className="user-manage-container row">
                <img src={null} width="50"  alt='Empty image' />
                <div className="form">
                    {this.renderName()}
                    {this.state.edit &&
                        <button className="btn btn-success btn-save" onClick={this.submit.bind(this)}>Vista</button>
                    }
                    { this.props.current_user.companyAdmin && 
                        <button className="btn btn-secondary" onClick={this.toggleActivate.bind(this)}>{activated ? 'Óvirkja aðgang' : 'Virkja aðgang'}</button>
                    }
                </div>
            </div>
        )
    }
}


export default UserManage;