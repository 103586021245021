import React from 'react';

class Button extends React.Component {

    constructor(props) {
        super(props)
    }

    handleClick() {
        if(this.props.handleClick){
            return (this.props.handleClick)()
        }
        return (() => {})()
    }

    render() {
        if(this.props.anchor){
            return (
                <a href={this.props.href} className={`btn ${this.props.classNames}`}>{this.props.innerHTML}</a>
            )
        }
        return (
            <button type="button" className={`btn ${this.props.classNames}`} onClick={this.handleClick.bind(this)}>{this.props.innerHTML}</button>
        )
    }
}

Button.defaultProps = {
    classNames: '',
}


export default Button;