import React from 'react';

import { NumToIsk, animateCart } from '../../utils'
import Price from './price'
import PriceHeader from './priceHeader'
import ProductImage from './productImage'
import OrderButton from './orderButton'
import QuantityGroup from './quantityGroup'
import Ribbon from '../ribbon'
import { useShallowEqual } from 'shouldcomponentupdate-children';

class ProductSmallReplacementModal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            quantity: 1
        }

        // this.handleSubmit = this.handleSubmit.bind(this)
    }

    setQuantity(qty) {
        this.setState({ quantity: qty })
    }

    animateCart() {
        $('body').trigger('garri.place.order', $(this.refs.productElement));
    }

    click() {
        this.props.productCallbackFn(this.props.product, this.state.quantity)
    }

    render() {
        const { product } = { ...this.props }
        return (

            <div className={`product productReplacement ${this.props.classNames}`}>
                <Ribbon product={product} />


                <div className="desktop-info" ref="productElement">
                    <div className="flex-row">
                        <div className="image">
                            <ProductImage product={product} />
                        </div>
                        <div className="productname">
                            {window.garriConfig.displayProductLinks ?
                                <a href={`/vara/${product.item_id}`}>{product.product_name}</a>
                                :
                                product.product_name
                            }
                        </div>
                    </div>
                    <div className="flex-row button-container">
                        {this.props.user.isAuthenticated &&
                            <div className="price-container">
                                <PriceHeader product={product} offer={product.offer} deleted={product.deleted} user={this.props.user} />
                                <Price product={product} showPrice={this.props.user.isAuthenticated} user={this.props.user} showItemWeightUnitPrice={true} />
                            </div>
                        }
                        <div className="quantity-outer-container">
                            <QuantityGroup product={product} quantity={this.state.quantity} setQuantity={this.setQuantity.bind(this)} />
                        </div>
                        <button className="btn btn-success add-product" onClick={this.click.bind(this, product)}>Velja</button>
                    </div>
                </div>
            </div>

        )
    }
}

ProductSmallReplacementModal.defaultProps = {
    classNames: '',
}


export default useShallowEqual(ProductSmallReplacementModal);