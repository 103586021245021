import React from 'react'
import { connectWithStore } from '../../utils'
import ListDropDown from '../../components/listDropDown'
import ProductImage from '../../components/product/productImage'
import Button from '../../components/button'
import { createList } from '../../api/lists/actions'
import { fetchLists } from '../../api/lists/actions'
import CreateNewList from './createNewList'

import { store } from '../../store'


class ProductToList extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedListIndex: 0,
            list: this.props.lists.lists[0],
            createListView: false
        }
    }

    componentDidMount(){
        if(this.props.lists.lists.length === 0){
            this.props.dispatch(fetchLists(() => {
                this.setState({list: this.props.lists.lists[this.state.selectedListIndex]})
            }))
        }
    }

    getListText() {
        if(this.state.list){
            return `${this.state.list.name} (${this.state.list.products.length})`
        }
        return `Veldu Lista`
    }

    onListItemSelect(index) {
        this.setState({
            selectedListIndex: index,
            list: this.props.lists.lists[index]
        })
    }

    addToList(){
        const {product} = this.props

        var list = this.state.list
        list.show_steps = list.products.length === 0

        var exists_in_list = list.products.find((prod_el) => prod_el.product.item_id === product.item_id)
        if(exists_in_list){
            this.props.close()
            return
        }
        list.products.push({product: product, quantity: 1})
        var updateList = true
        Promise.resolve(this.props.dispatch(createList(list.name, list.products, updateList, list.id, list.show_steps))).then(() => {
            this.props.close()
        })
    }

    getListsText(){
        var str = []
        for(var list of this.props.lists.lists){
            var find = list.products.find((p) => p.product.item_id === this.props.product.item_id)
            if(find){
                str.push(<div key={`productListText-${list.name}`}>{list.name}</div>)
            }
        }

        if(str.length === 0){
            return <div/>
        }
        return (
            <div className="lists-text mt-3">
                <div><b>Þessi vara er í eftirfarandi listum: </b></div>
                <div className="list-names">{str}</div>
            </div>
        )
    }

    render(){
        const {product} = this.props
        var hasLists = this.props.lists.lists && this.props.lists.lists.length > 0

        if(this.state.createListView){
            return (
                <CreateNewList {...this.props} products={[{quantity: 1, product: product}]} />
            )
        }

        return (
            <div className="product-to-list">
                <div className="row align-center">
                    <div className="col-6">
                        <ProductImage product={this.props.product} />
                        <h4 className="mt-4">{this.props.product.product_name}</h4>
                    </div>
                    {hasLists &&
                        <div className="col-6">
                            <h4 style={{marginBottom: '10px'}}>Veldu lista</h4>
                            <ListDropDown
                                lists={this.props.lists.lists}
                                selectedIndex={this.state.selectedListIndex}
                                onListItemSelect={this.onListItemSelect.bind(this)}
                                textValue={this.getListText()}
                                classNames={'btn settings btn-default btn-products-list xl-m-icon full-width'}
                                newListButton={true}
                            />
                            {this.getListsText()}
                        </div>
                    }
                    {!hasLists &&
                        <div className="col-6 text-right">
                            <h4 style={{marginBottom: '20px'}}>Til að búa til þinn eigin innkaupalista smelltu hér fyrir neðan.</h4>
                            <Button
                                anchor={false}
                                handleClick={() => {this.setState({createListView: true})}}
                                classNames={"btn-success pull-right"}
                                innerHTML={(<div>Búa til lista</div>)}
                            />
                        </div>
                    }
                </div>
                {hasLists &&
                    <div className="row">
                        <div className="col-12" style={{marginTop: '40px'}}>
                            <Button
                                anchor={false}
                                handleClick={this.addToList.bind(this)}
                                classNames={"btn-success pull-right"}
                                innerHTML={(<div>Bæta vöru í lista</div>)}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    var f = {
        lists: {lists:[]}
    }
    f.lists = (state.lists === undefined) ? {} : {lists: state.lists.lists.filter(f => f!==null).filter((f) => f.id !== undefined)}
    return f;
}

export default connectWithStore(store, ProductToList, mapStateToProps);

