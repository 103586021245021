import React, { useCallback } from 'react';
import { DndProvider, useDrag } from 'react-dnd-cjs'
import HTML5Backend from 'react-dnd-html5-backend-cjs'
import { Item } from './item'
import { Product } from './product'
import { throttle } from 'lodash'
import { patchList } from '../../api/lists/actions'

// Works for items (list of list) or product (list of product)
// Manipulate list inside this component (move or remove),
// use callback function in saveUpdate to update in parent component

class DragDropTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            list: []    // list to iterate
        }
    }

    componentDidMount(){
        this.setState({list: this.props.content})
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.content !== this.props.content){
            this.setState({list: this.props.content})
        }
    }

    moveListItem(dragIndex, hoverIndex){
        const dragItem = this.state.list[dragIndex]
        const hoverItem = this.state.list[hoverIndex]
        const updatedArray = [...this.state.list]
        updatedArray[dragIndex] = hoverItem
        updatedArray[hoverIndex] = dragItem
        this.setState({list: updatedArray})
    }

    removeProduct(product) {
        var {list} = this.state;
    
        var products = list.filter((p) => {
          return p.product.item_id !== product.item_id
        })
        
        this.props.dispatch(
          patchList({
            id: this.props.listId,
            products: products,
            removedProducts: [{ product: { item_id: product.item_id } }]
          }))
      }
    

    // Callback to save list
    saveUpdate(){
        this.props.callback(this.state.list)
    }


    render() {
        let { headers, content } = this.props
        let list = this.state.list
        return (<table className="table">
                    <thead>
                        <tr>
                            { headers.map((text, i) => 
                                <th>{text}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        <DndProvider backend={HTML5Backend}>
                            { this.state.list && this.state.list.map((item, index) => (
                                this.props.isProduct ?
                                    <Product index={index} 
                                            product={item.product} 
                                            key={item.product.item_id} 
                                            moveListItem={this.moveListItem.bind(this)} 
                                            saveUpdateCallback={this.saveUpdate.bind(this)}
                                            removeProductCallback={this.removeProduct.bind(this)}
                                            listOfProducts={this.state.list}
                                            />
                                : 
                                    <Item itemObj={{ "name": item.name, "products": item.product_count}}
                                        key={item.id}
                                        index={index}
                                        moveListItem={this.moveListItem.bind(this)}
                                        saveUpdateCallback={this.saveUpdate.bind(this)}
                                        />
                            ))}
                        </DndProvider>  
                    </tbody>
                </table>)
    }
}

export default DragDropTable;
