import fetch from 'isomorphic-fetch'
import Cookie from 'js-cookie'
import jwtDecode from 'jwt-decode';

import { checkHttpStatus, handleNetworkError, parseJSON } from '../../utils'
import { userRefreshLogin } from '../user/actions'
import { emptyCart } from '../cart/actions'
import TokenStorage from '../../storages/token-storage'

export const CHECKOUT_SET_STEP = 'CHECKOUT_SET_STEP'
export const CHECKOUT_NEXT_STEP = 'CHECKOUT_NEXT_STEP'
export const CHECKOUT_PREVIOUS_STEP = 'CHECKOUT_PREVIOUS_STEP'
export const REQUEST_SUBMIT_ORDER = 'REQUEST_SUBMIT_ORDER'
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS'
export const SUBMIT_ORDER_ERROR = 'SUBMIT_ORDER_ERROR'
export const SUBMIT_ORDER_TO_VALITOR = 'SUBMIT_ORDER_TO_VALITOR'
export const SET_DELIVERY_DATA = 'SET_DELIVERY_DATA'
export const RECEIVE_PURCHASED_ORDER_SUCCESS = 'RECEIVE_PURCHASED_ORDER_SUCCESS'
export const RECEIVE_PURCHASED_ORDER_ERROR = 'RECEIVE_PURCHASED_ORDER_ERROR'
export const DISMISS_SALES_ERROR = 'DISMISS_SALES_ERROR'

const _nextStep = () => {
    return {
        type: CHECKOUT_NEXT_STEP
    };
}

const _previousStep = () => {
    return {
        type: CHECKOUT_PREVIOUS_STEP
    };
}

const _setStep = (step) => {
    return {
        type: CHECKOUT_SET_STEP,
        step: step,
    };
}

const requestSubmitOrder = () => {
    return {
        type: REQUEST_SUBMIT_ORDER
    }
}

export const dimissSalesError = () => {
    return {
        type: DISMISS_SALES_ERROR
    }
}

const submitOrderSuccess = (response) => {
    return {
        type: SUBMIT_ORDER_SUCCESS,
        data: response,
    };
}

const submitOrderError = (response) => {
    return {
        type: SUBMIT_ORDER_ERROR,
        data: response,
    };
}

const submitOrderToValitor = (response) => {
    return {
        type: SUBMIT_ORDER_TO_VALITOR,
        data: response,
    }
}

const receivePurchasedOrderSuccess = (response) => {
    return {
        type: RECEIVE_PURCHASED_ORDER_SUCCESS,
        data: response
    }
}

const receivePurchasedOrderError = (response) => {
    return {
        type: RECEIVE_PURCHASED_ORDER_ERROR,
        data: response
    }
}

export const setDeliveryData = (data) => {
    return {
        type: SET_DELIVERY_DATA,
        data: data
    }
}

export const previousStep = () => {
    return dispatch => {
        dispatch(userRefreshLogin(TokenStorage.getItem("token")))
        .then(response => {
            dispatch(_previousStep())
        })
    }
};

export const nextStep = () => {
    return dispatch => {
        dispatch(userRefreshLogin(TokenStorage.getItem("token")))
        .then(response => {
            dispatch(_nextStep())
        })
    }
};

export const setStep = (step) => {
    return dispatch => {
        dispatch(userRefreshLogin(TokenStorage.getItem("token")))
        .then(response => {
            dispatch(_setStep(step))
        })
    }
};

export const paymentCheckout = (orderObj) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");
        
        dispatch(requestSubmitOrder());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.CSRF_TOKEN,
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }
        let obj = {}
        for(var n in orderObj){
            if (n !== "deliveryMode") {
                obj[n] = orderObj[n]
            }
        }

        return fetch(`/api/v1/independent_make_sale/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(orderObj),
        })
        .then(response => {
            if(response.status >= 400) {
                response.json().then((object) => dispatch(submitOrderError(object)))
            } else {
                return response
            }
        })
        .then(parseJSON)
        .then(response => {
            dispatch(submitOrderError(response))
        })
        .catch(handleNetworkError);
    };
}

export const submitOrder = (orderObj, cartId) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");
        
        dispatch(requestSubmitOrder());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }
        let obj = {}
        for(var n in orderObj){
            if (n !== "deliveryMode") {
                obj[n] = orderObj[n]
            }
        }

        return fetch(`/api/create-sale/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(orderObj),
        })
        .then(response => {
            if(response.status >= 400) {
                response.json().then((object) => dispatch(submitOrderError(object)))
            } else if (response.status === 307) {
                return response.json().then((object) => dispatch(submitOrderToValitor(object)))
            } else {
                return response
            }
        })
        .then(parseJSON)
        .then(response => {
            if (response.status >= 400) {
                dispatch(submitOrderError(response))
            } else {
                try {
                    dispatch(submitOrderSuccess(response));
                    dispatch(emptyCart(cartId));
                } catch (e) {
                    dispatch(submitOrderError(response))
                }
            }
        })
        .catch(handleNetworkError);
    };
}

export const getPurchasedOrder = (sale_id) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/sales/?sale_id=${sale_id}`, {
            method: 'GET',
            headers: headers
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receivePurchasedOrderSuccess(response));
            } catch (e) {
                dispatch(receivePurchasedOrderError(response))
            }
        })
        .catch(handleNetworkError);
    };
}