export const userSerializer = (user) => {
    let serializedKeys = ['username','first_name','email','last_name','is_active', 'active_company_id', 'telephone', 'address']
    let serialized = {}
    for(var n in user){
        if(serializedKeys.indexOf(n) !== -1){
            serialized[n] = user[n]
        }
    }
    if(user.password){
        serialized.password = user.password
    }
    return serialized
}