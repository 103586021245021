export const listProductsSerializer = (products) => {
    const serializedProducts = [];
    products.map((productContainer) => {
        serializedProducts.push({
            quantity: productContainer.quantity,
            product: {
                item_id: productContainer.product.item_id
            },
            sort_order: productContainer.sort_order
        })
    });
    return serializedProducts;
}

export const listSerializer = (name, products, id=null, account_id=null, show_steps=false) => {
    let serialized = {
        name: name,
        products: [],
        show_steps: show_steps
    }
    if(id){
        serialized.id = id
    }
    if (account_id) {
        serialized.company = account_id
    }
    serialized.products = listProductsSerializer(products);
    console.log("serialized", serialized)
    return serialized
}