import { GetSessionStorageState, StateToSessionStorage } from '../../storages/session-storage'

import jwtDecode from 'jwt-decode';
import TokenStorage from '../../storages/token-storage'

import {
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGIN_FAILURE,
    AUTH_LOGIN_REQUEST,
    AUTH_LOGOUT,
    AUTH_SUCCESS,
    AUTH_FAILURE,
    AUTH_REFRESH_LOGIN_FAILURE,
    USER_MAILING_LIST_PREFERENCE_REQUEST,
    USER_MAILING_LIST_PREFERENCE_SUCCESS,
    USER_MAILING_LIST_PREFERENCE_FAILURE,
    
} from './actions'

import { SET_ACTIVE_COMPANY } from '../companies/actions'


const _initalState = {
    token: null,
    username: null,
    isAuthenticated: false,
    isAuthenticating: false,
    statusText: null,
    tokenExpires: null,
    password_change_auth_success: false,
    failed_auth: false,
    companyAdmin: false,
}

export function user(state=_initalState, action) {
    switch (action.type) {
        case AUTH_LOGOUT:
            state = {
                ...state,
                isAuthenticated: false,
                failed_auth: false,
                token: null,
                activeCompany: {},
            }
            StateToSessionStorage(state, 'user')
            return state
        case AUTH_LOGIN_REQUEST:
            state = {
                ...state,
                isAuthenticating: true,
                failed_auth: false,
            }
            return state
        case AUTH_LOGIN_FAILURE:
            state = {
                ...state,
                isAuthenticated: false,
                isAuthenticating: false,
                failed_auth: true
            }
            return state
        case AUTH_LOGIN_SUCCESS:
            let userData = jwtDecode(action.data)
            if(userData.exp*1000 < Date.now()){
                TokenStorage.removeItem('token')
                state = {
                    ...state,
                    isAuthenticated: false,
                    token: null,
                }
            }else{
                state = {
                    ...state,
                }
                
                if (state.activeCompany === undefined || Object.keys(state.activeCompany).length === 0) {
                    let activecompany = GetSessionStorageState(undefined, 'activeCompany')
                    
                    if (activecompany !== undefined && Object.keys(activecompany).length !== 0) {
                        if (activecompany.independent_company === undefined) {
                            activecompany.independent_company = false
                        }
                        state.activeCompany = activecompany
                    } else {
                        state.activeCompany = userData.default_company
                    }
                }
                state = {
                    ...state,
                    agreesToTos: userData.agrees_to_tos,
                    isAuthenticating: false,
                    isAuthenticated: true,
                    token: action.data,
                    username: userData.username,
                    id: userData.user_id,
                    email: userData.email,
                    tokenExpires: userData.exp,
                    companyAdmin: userData.company_admin,
                    companyOverride: userData.company_override,
                    firstName: userData.first_name,
                    lastName: userData.last_name,
                    canPurchase: userData.can_purchase,
                    canSeeInvoices: userData.can_see_invoices,
                    defaultCompany: userData.default_company,
                    is_superuser: userData.is_superuser,
                    name: `${userData.first_name} ${userData.last_name}`,
                    axID: userData.ax_id,
                    independentUser: userData.independent_user,
                    hasManyCompanies: userData.hasManyCompanies,
                    listOrder: userData.list_order,
                }
                if(!userData.company_override){
                    state.companies = userData.companies
                }
            }
            StateToSessionStorage(state, 'user')
            return state
        case AUTH_SUCCESS:
            return {...state,
                password_change_auth_success: true
            }
        case AUTH_FAILURE:
            return {...state,
                password_change_auth_success: false
            }
        case AUTH_REFRESH_LOGIN_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                token: null,
            }
        case SET_ACTIVE_COMPANY:
            StateToSessionStorage(action.data, 'activeCompany')
            return {...state,
                activeCompany: action.data
            }
        case USER_MAILING_LIST_PREFERENCE_SUCCESS:
            state = {
                ...state,
                agreesToTos: action.preference,
            }
            StateToSessionStorage(state, 'user')
            return state
        default:
            return state
    }
}