import React from 'react';
import ReactDOM from 'react-dom'

class ProductMessage extends React.Component {

    constructor(props) {
        super(props)
    }

    renderProductMessage() {
        let productMessage = ""
        if(this.props.productMessage){
            return this.props.productMessage
        }
        let avail = this.props.product.avail_physical
        if (this.props.quantity !== "-" && this.props.quantity > avail && !this.props.product.ignore_stock) {
            productMessage = `Tiltækt magn á lager er ${avail}`
        }
        return productMessage
    }

    render() {
        var productMessage = this.renderProductMessage()
        return (
            <div className={`product-message ${this.props.isSelected ? 'selected':''}`}>
                {productMessage && 
                    <div className="inner-message"><img src={window.garriConfig.icons.warning} alt="Viðvörun" height="22" /><div>{productMessage}</div></div>
                }
            </div>
        )
    }
}

export default ProductMessage;