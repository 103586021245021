import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import Button from '../../components/button';
import Modal from '../modal';



const key = 'garri-arshatidarferd'
const keyCount = `${key}-count`
const countThreshold = 5
const timeBetween = 12*60*60*1000
const firstDate = moment('2023-09-19')
const finalDate = moment('2023-10-02')

window.closeQuantityGroupModal = () => {
    const count = localStorage.getItem(keyCount) || 0
    localStorage.setItem(key, moment())
    localStorage.setItem(keyCount, parseInt(count) + 1)

    $('#modal').modal('hide')
}

// const BodyContent = ({}) => (
//     <div id="quantity-group-modal">
//         <h3 className="mb-3">Vörumagn er stillt á heilar pakkningar</h3>
//         <div>Slökktu á græna takkanum á vöruspjöldunum ef þú vilt panta vörur í stykkjatali.</div>
//         <div className="image-container text-center">
//             <img src={window.garriConfig.icons.quantityGroupModal} />
//         </div>
//         <div className="button-container">
//             <Button classNames="btn-success checkout" innerHTML={(
//                 <div>Í lagi</div>
//             )} handleClick={() => {window.closeQuantityGroupModal()}} />
//         </div>
//     </div>
// )

// const BodyContent = ({}) => (
//     <div id="quantity-group-modal" className="text-center">
//         <h3 className="mb-5">Opnunartímar yfir jólahátíðina</h3>
//         <div className="text">
//             <ul>
//                 <li>Aðfangadagur - <strong className="text-red">LOKAÐ</strong></li>
//                 <li>Jóladagur - <strong className="text-red">LOKAÐ</strong></li>
//                 <li>Annar í jólum - <strong className="text-red">LOKAÐ</strong></li>
//                 <li>Fimmtudagur 27. desember - <strong className="text-green">OPIÐ</strong></li>
//                 <li>Föstudagur 28. desember - <strong className="text-green">OPIÐ</strong></li>
//                 <li>Gamlársdagur - <strong className="text-green">OPIÐ 8:00 - 12:00</strong></li>
//                 <li>Nýársdagur - <strong className="text-red">LOKAÐ</strong></li>
                
//             </ul>
//         </div>
//         <div className="text">Við hvetjum viðskiptavini til að panta tímanlega svo hægt sé að afgreiða allar pantanir fyrir lokun.</div>
//         <div className="text-center green-border-top mt-5 pt-3 col-xs-6 offset-xs-3">
//             Gleðilega hátíð!<br/>
//             Starfsfólk Garra
//         </div>
//         <div className="button-container mt-5 ">
//             <Button classNames="btn-success checkout" innerHTML={(
//                 <div>Loka</div>
//             )} handleClick={() => {window.closeQuantityGroupModal()}} />
//         </div>
//     </div>
// )

// const BodyContent = ({}) => (
//     <div id="quantity-group-modal" className="text-center">
//         <h3 className="mb-5">Kæri viðskiptavinur</h3>
//         <div className="text">Það eru margir frídagar um páskana og því gott ráð að panta tímanlega.</div>
//         <div className="text mb-5">Við viljum minna á opnunartíma Garra yfir páskahátíðina sem verður sem hér segir:</div>
//         <div className="text">
//             <ul>
//                 <li>Skírdagur (Fim. 18. apríl) - <strong className="text-red">LOKAÐ</strong></li>
//                 <li>Föstudagurinn langi (19. apríl) - <strong className="text-red">LOKAÐ</strong></li>
//                 <li>Annar í páskum (Mán. 22. apríl) - <strong className="text-red">LOKAÐ</strong></li>
//                 <li>Þriðjudagur 23. apríl - <strong className="text-green">OPIÐ</strong></li>
//                 <li>Miðvikudagur 24. apríl - <strong className="text-green">OPIÐ</strong></li>
//                 <li>Sumardagurinn fyrsti (Fim. 25. apríl) - <strong className="text-red">LOKAÐ</strong></li>
//                 <li>Föstudagur 26. apríl - <strong className="text-green">OPIÐ</strong></li>
                
//             </ul>
//         </div>
//         <div className="text">Gleðilega páska og gleðilegt sumar!   </div>
//         <div className="text-center green-border-top mt-5 pt-3 col-xs-6 offset-xs-3">
//             Starfsfólk Garra
//         </div>
//         <div className="button-container mt-5 ">
//             <Button classNames="btn-success checkout" innerHTML={(
//                 <div>Loka</div>
//             )} handleClick={() => {window.closeQuantityGroupModal()}} />
//         </div>
//     </div>
// )


// const BodyContent = ({}) => (
//     <div id="quantity-group-modal" className="text-center">
//         <h3 className="mb-5">Kæri viðskiptavinur</h3>
//         <div className="text">Nú er sýnilegra en áður að vigtarvörur eru pantaðar eftir þyngd en ekki í stykkjum.</div>
//         <div className="text">Við vonum að þessar breytingar auðveldi enn frekar pantanir í vefverslun.</div>
//         <div className="text-center green-border-top mt-5 pt-3 col-xs-6 offset-xs-3">
//             Starfsfólk Garra
//         </div>
//         <div className="button-container mt-5 ">
//             <Button classNames="btn-success checkout" innerHTML={(
//                 <div>Loka</div>
//             )} handleClick={() => {window.closeQuantityGroupModal()}} />
//         </div>
//     </div>
// )


// const BodyContent = ({}) => (
//     <div id="quantity-group-modal" className="text-center">
//         <h3 className="mb-5">Kæri viðskiptavinur</h3>
//         <div className="text">Til að jafna álag í vöruhúsi og auka áreiðanleika mun móttökutími pantana breytast frá og með 7. nóvember.</div>
//         <div className="text">
//             <p><strong>Höfuðborgarsvæði</strong></p>
//             <p>Pantanir sem eru til afgreiðslu og dreifingar næsta virka dag þurfa að berast fyrir kl. 24:00 degi áður.</p>
//             <p><strong>Utan höfuðborgarsvæðisins</strong></p>
//             <p>Pantanir sem eru til afgreiðslu og dreifingar næsta virka dag þurfa að berast fyrir kl. 16:00 degi áður.</p>
//         </div>
//         <div className="text">Frekari upplýsingar um afgreiðslutíma og akstur má finna <a href="https://www.garri.is/%C3%BEj%C3%B3nusta/afgrei%C3%B0slut%C3%ADmi-aksturskerfi/">hér</a>.</div>
//         <div className="text-center green-border-top mt-5 pt-3 col-xs-6 offset-xs-3">
//             Starfsfólk Garra
//         </div>
//         <div className="button-container mt-5 ">
//             <Button classNames="btn-success checkout" innerHTML={(
//                 <div>Loka</div>
//             )} handleClick={() => {window.closeQuantityGroupModal()}} />
//         </div>
//     </div>
// )



const BodyContent = ({}) => (
    <div id="quantity-group-modal" className="text-center">
        <h3 className="mb-5">Lokun á skrifstofu Garra</h3>
        <div className="text"><b>Skrifstofa Garra lokar 11:30 þann 29. september og verður lokuð 2.október.</b></div>
        <br></br>
        <div className="text">Föstudaginn 29. september lokar skrifstofa Garra klukkan 11:30 vegna árshátíðarferðar starfsfólks. Skrifstofan verður einnig lokuð mánudaginn 2. október. <strong>Starfsemi verður í vöruhúsi og dreifingu.</strong></div>
        <br></br>
        <div className="text">Frekari upplýsingar má finna <a href="https://www.garri.is/fr%C3%A9ttir/n%C3%BDjustu-fr%C3%A9ttir/lokun-%C3%A1-skrifstofu-garra/">hér</a>.</div>
        <div className="text-center green-border-top mt-5 pt-3 col-xs-6 offset-xs-3">
        Með fyrirfram þökk,
        starfsfólk Garra.
        </div>
        <div className="button-container mt-5 ">
            <Button classNames="btn-success checkout" innerHTML={(
                <div>Loka</div>
            )} handleClick={() => {window.closeQuantityGroupModal()}} />
        </div>
    </div>
)

const checkHasShown = () => {

    if (moment() < moment(firstDate) || moment() > moment(finalDate)) {
        return true
    }

    if (moment() > lastDisplay) {
        return true
    }
    const lastDisplay = localStorage.getItem(key)
    const count = localStorage.getItem(keyCount) || 0
    if (lastDisplay === null) {
        return false
    }
    else {
        const diff = moment() - moment(lastDisplay)
        if (diff >= timeBetween && count < countThreshold) {
            return false
        }
    }
    return true
}

class QuantityGroupModal extends React.Component {

    componentDidMount(){
        var hasShown = checkHasShown()
        if(!hasShown){
            let modalProps = {
                title: null,
                body: BodyContent,
                bodyProps: {},
                showCloseButton: false,
            }
            ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
        }
    }

    render() {
        return (
            <div/>
        )
    }
}

export default QuantityGroupModal;


