import moment from 'moment'
import React from 'react';

const getDeliveryMessage = (delivery_mode, firstDeliveryDate) => {
    if (!delivery_mode.delivery_mode_rule || !firstDeliveryDate) {
        return '';
    }
    const { cut_off_time, cut_off_add_days, allow_same_day, cut_off_text } = delivery_mode.delivery_mode_rule;

    if (cut_off_text !== "" && cut_off_text !== undefined && cut_off_text !== null) {
        return cut_off_text
    }

    const now = moment();
    const cutOffTime = moment(cut_off_time, "HH:mm:ss");
    const startMessage = `Pantaðu fyrir kl. ${cutOffTime.format('HH:mm')}`;
    const daysToAdd = moment(firstDeliveryDate.format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'), 'days');

    if (daysToAdd === 0) {
        if (cutOffTime > now) {
            return `${startMessage} í dag til þess að fá afhent í dag.`;
        } else {
            //should not happen.
        }
    } else if (daysToAdd === 1) {
        if (cutOffTime < now) {
            return `${startMessage} á morgun til þess að fá afhent á morgun.`
        }
        return `${startMessage} í dag til þess að fá afhent á morgun.`
    }
    const weekDaysToString = ['mánu', 'þriðju', 'miðviku', 'fimmtu', 'föstu', 'laugar', 'sunnu'];
    const deliveryDate = moment();
    deliveryDate.add(daysToAdd, 'days');
    const orderDate = moment(deliveryDate).subtract(cut_off_add_days, 'days');
    const orderDateDiff = moment(orderDate.format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'), 'days');
    let orderDateString = '';
    if (orderDateDiff === 0) {
        orderDateString = `í dag`;
    } else if (orderDateDiff === 1) {
        orderDateString = `á morgun`;
    } else {
        orderDateString = `${weekDaysToString[orderDate.weekday()]}daginn ${orderDate.format('DD. MMMM')}`;
    }
    const deliveryDateString = `${weekDaysToString[deliveryDate.weekday()]}daginn ${deliveryDate.format('DD. MMMM')} næstkomandi`;
    return `${startMessage} ${orderDateString} til þess að fá afhent ${deliveryDateString}.`
}

const DeliveryWarning = ({deliveryMode, deliveryDate, message, delivery_mode={}, firstDeliveryDate=null}) => {
    if (deliveryMode === "pickup" && message === "") {
        return (
            <div className="alert alert-dark">
                <span>Veldu tíma til að sækja</span>
                { deliveryDate.format("MM-DD") === "12-31" &&
                    <div><strong>ATH opið 8:00 - 12:00 á Gamlársdag</strong></div>
                }
            </div>
        )
    } else if(deliveryMode === 'send' && delivery_mode.delivery_mode_rule) {
        return (
            <p dangerouslySetInnerHTML={{__html: getDeliveryMessage(delivery_mode, firstDeliveryDate)}} />
        )
    } else {
        return (<div />)
    }
}

export default DeliveryWarning;
