import React from 'react';
import { connectWithStore, NumToIsk, Spinner, getProductUnitPrice } from '../../utils';
import { store } from '../../store'


class YourPrice extends React.Component {
    constructor(props) {
        super(props)
    }

    getPrice(exclude_item_weight=false) {
        const { product, prices, user } = this.props;
        return getProductUnitPrice({
            product,
            prices,
            user,
        }, exclude_item_weight)
    }

    renderItemWeightUnitPrice() {
        const { product, showItemWeightUnitPrice } = this.props;
        // if (showItemWeightUnitPrice) {
          if (showItemWeightUnitPrice && product.item_weight && +product.item_weight) {
            return <span style={{fontSize: '12px'}}> ({NumToIsk(this.getPrice(true))} kr/kg)</span>
        }
        return null;
    }

    render () {
        const { classNames, product, showItemWeightUnitPrice } = this.props;

        if(this.props.prices.isFetching) {
            return <Spinner />
        } else {
            return <span className={`${classNames}`}>{NumToIsk(this.getPrice(showItemWeightUnitPrice ? false : true)*this.props.quantity)} kr.{this.renderItemWeightUnitPrice()}</span>
        }

    }

}

YourPrice.defaultProps = {
    classNames: '',
    quantity: 1,
    showItemWeightUnitPrice: false,
}

function mapStateToProps(state) {
    var f = {
        prices: {},
        user: {},
    }
    f.prices = (state.prices === undefined) ? {} : state.prices
    f.user = (state.user === undefined) ? {} : state.user
    return f;
}


export default connectWithStore(store, YourPrice, mapStateToProps);