import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { patchList } from '../../api/lists/actions'

class EditList extends React.Component {

  componentDidMount(){
  }

  changeName() {
    if($(this.refs.nameValue)[0].checkValidity()){
      var newName = this.refs.nameValue.value
      var list = this.props.list
      this.props.dispatch(patchList({
        id: list.id,
        name: newName,
      })).then(() => {
        setTimeout(this.props.close, 400)
      })
    }
  }

  render(){
    return (
      <div className="manage-list-container">
        <h2 className="mb-xl">Breyta nafni fyrir <b>{this.props.list.name}</b></h2>
        <div className="new-list-name">
          <input type="text" placeholder="Sláðu inn heiti" ref="nameValue" required="required"/>
          <button type="button" className="btn btn-success checkout-btn-success" onClick={this.changeName.bind(this)}>Breyta nafni</button>
        </div>
      </div>
    )
  }
}

module.exports = EditList;