import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { deleteList } from '../../api/lists/actions'

class DeleteList extends React.Component {

  componentDidMount(){
  }

  deleteList() {
    this.props.dispatch(deleteList(this.props.list.id)).then(() => {
    setTimeout(this.props.close, 400)
    })
  }

  render(){
    let {products} = this.props

    return (
      <div className="manage-list-container">
        <h2>Ertu viss?</h2>
        <p>Þú ert að fara að eyða listanum <strong>{this.props.list.name}</strong></p>
        <button type="button" className="btn btn-danger" onClick={this.deleteList.bind(this)}>Eyða</button>
      </div>
    )
  }
}

module.exports = DeleteList;