import React from 'react';

let RIBBON_TYPES =  {
    new: {
        text: "NÝTT",
        className: "new"
    },
    offer: {
        text: "TILBOÐ",
        className: "offer"
    },
    withdrawal: {
        text: "HÆTTIR",
        className: "withdrawal"
    },
    promotion: {
        text: "KYNNING",
        className: "promotion"
    },
    deleted: {
        text: "HÆTT",
        className: "deleted"
    },
    coming_soon: {
        text: "Væntanleg",
        className: "coming-soon"
    },
    new_product_number: {
        text: "Nýtt vörunr.",
        className: "new-product-number"
    }
}


class Ribbon extends React.Component {

    constructor(props) {
        super(props)
    }


    render() {
        const {product} = this.props
        let ribbonType = "";
        if (product.new || product.new_product_number && product.new) {
            ribbonType = RIBBON_TYPES["new"];
        }
        if (product.offer) {
            ribbonType = RIBBON_TYPES["offer"];
        }
        if (product.promotion || product.promotion_independent_user){
            ribbonType = RIBBON_TYPES["promotion"]
        }
        if (product.deleted){
            ribbonType = RIBBON_TYPES["deleted"]
        }
        if (!product.deleted && product.avail_physical === 0 && !product.ignore_stock){
            ribbonType = RIBBON_TYPES["coming_soon"]
        } 
        if (product.withdrawal) {
            ribbonType = RIBBON_TYPES["withdrawal"]
        }
        if (product.new_product_number) {
            ribbonType = RIBBON_TYPES["new_product_number"]
        }
        if (ribbonType) {
            return (
                <div className="ribbon"><span className={ribbonType.className} >{ribbonType.text}</span></div>
            )
        } else {
            return (<div />)
        }
    }
}

export default Ribbon;