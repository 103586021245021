import React from 'react';
import moment from 'moment'
import { fetchClaims } from '../../api/claims/actions';
import DatePicker from 'react-datepicker'
import { Spinner } from '../../utils'
import ExcellentExport from '../../utils/ExcellentExport'


const ClaimTransactionInnerRow = props => {
    let {c, idx} = props
    
    return (
        <tr className={`claim-transaction-inner-row ${idx % 2 === 0 ? 'odd' : 'even'}`}>
            <td>
                { c.invoice !== "" ? 
                    <a href={`/api/v1/invoice-pdf/?invoice_id=${c.invoice}&invoice_date=${c.transDateOriginal}`}>{c.invoice}</a>
                    :
                    c.invoice
                }
            </td>
            <td className="text-right">{c.transDate}</td>
            <td className="text-right">{c.dueDate}</td>
            <td className="text-right">{c.amountCur}</td>
        </tr>
    )
}

const ClaimTransactions = props => {
    return (
        <tr className={`claim-transaction-row ${props.show ? '' : 'hide'}`}>
            <td colSpan="10">
                <table className="subtable">
                    <thead>
                        <tr>
                            <th>Fylgiskjal</th>
                            <th className="text-right">Dagsetning</th>
                            <th className="text-right">Gjalddagi</th>
                            <th className="text-right">Upphæð</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.claim.customerTransactions.map((c, idx) => 
                            <ClaimTransactionInnerRow
                               key={`customer-transaction-row-${c.claimTransId}-${idx}`}
                               c={c}
                               idx={idx}
                               
                            />
                        )}
                    </tbody>
                </table>
            </td>
        </tr>
    )
}


class Claims extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            fromDate: moment().subtract(1, 'months'),
            toDate: moment(),
            toggled: {}
        }
    }

    componentDidMount(){
        this.fetchClaims()
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.user.activeCompany.account_id !== this.props.user.activeCompany.account_id){
            this.fetchClaims(nextProps.user.activeCompany.account_id)
        }
    }

    fetchClaims(account_id=null){
        var data = {
            fromDate: this.state.fromDate.format('YYYY-MM-DD'),
            toDate: this.state.toDate.format('YYYY-MM-DD')
        }
        if(account_id){
            data['custAccount'] = account_id
        }
        this.props.dispatch(fetchClaims(data))
    }

    fromDateChange(d){
        this.setState({fromDate: d}, this.fetchClaims)
    }

    toDateChange(d){
        this.setState({toDate: d}, this.fetchClaims)
    }

    toggleClaim(claim_id){
        var toggled = {...this.state.toggled}
        if(toggled[claim_id]){
            toggled[claim_id] = false
        }else{
            toggled[claim_id] = true
        }
        this.setState({toggled: toggled})
    }

    renderIcon(claim_id){
        return (
            <img src={this.state.toggled[claim_id] ? window.garriConfig.icons.minusIcon : window.garriConfig.icons.plusIcon} alt={`${this.state.toggled[claim_id]  ? "Fjarlægja kröfu" : "Bæta við kröfu" }`} width="12" />
        )
    }

    csvExport () {
        ExcellentExport.csv(document.getElementById('csv-export-claims'), 'table-claims', ';')
    }

    excelExport () {
        ExcellentExport.excel(document.getElementById('excel-export-claims'), 'table-claims', `Garri kröfur ${this.state.fromDate.format("YYYY-MM-DD")} - ${this.state.toDate.format("YYYY-MM-DD")}`)
    }

    render() {
        let {isFetching, claims} = this.props.claims

        return (
            <div className="transactions-container">
                <h2>Kröfur fyrir {this.props.user.activeCompany.name} ({this.props.user.activeCompany.account_id})</h2>
                <div className="date-select-container">
                    <div className="box">
                        <div className="label">Byrjunardagsetning:</div>
                        <DatePicker
                            selected={this.state.fromDate}
                            onChange={this.fromDateChange.bind(this)}
                        />
                    </div>
                    <div className="box">
                        <div className="label">Lokadagsetning:</div>
                        <DatePicker
                            selected={this.state.toDate}
                            onChange={this.toDateChange.bind(this)}
                        />
                    </div>
                    <div>
                        Sækja sem <a id="csv-export-claims" download="krofur.csv" href="#" onClick={this.csvExport.bind(this)}> CSV</a> | <a id="excel-export-claims" download="krofur.xls" href="#" onClick={this.excelExport.bind(this)}>Excel</a>
                    </div>

                </div>
                {isFetching &&
                    <div className="spinner-container text-center">
                        <Spinner />
                    </div>
                }
                {!isFetching &&
                    <div className="table-responsive">
                        <table className="table settings-table full-w" id="table-claims">
                            <thead>
                                <tr>
                                    <th>Greiðsluháttur</th>
                                    <th>Tilvísun</th>
                                    <th>Banki</th>
                                    <th>Höfuðbók</th>
                                    <th>Kröfunúmer</th>
                                    <th>Staða kröfu</th>
                                    <th className="text-right">Gjalddagi</th>
                                    <th className="text-right">Eindagi</th>
                                    <th className="text-right">Upphæð kröfu</th>
                                    <th>Nafn</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {claims.map((el, idx) => 
                                    [<tr key={`claim-row-${idx}`} className={idx % 2 === 0 ? 'odd' : 'even'}>
                                        <td>{el.custPaymMode}</td>
                                        <td>{el.claimTransIdDisplay}</td>
                                        <td>{el.bankNum}</td>
                                        <td>{el.bankLedger}</td>
                                        <td>{el.claimId}</td>
                                        <td className={`${el.claimStatus === 20 ? 'served':''}`}>{el.claimStatusDisplay}</td>
                                        <td className="text-right">{el.dueDate}</td>
                                        <td className="text-right">{el.finalDueDate}</td>
                                        <td className="text-right">{el.claimAmount}</td>
                                        <td>{el.customerName}</td>
                                        <td className="expand" onClick={this.toggleClaim.bind(this, el.claimTransId)}>
                                            {this.renderIcon(el.claimTransId)}
                                        </td>
                                    </tr>,
                                    <ClaimTransactions
                                        key={`claim-transactions-${el.idx}`}
                                        claim={el}
                                        show={this.state.toggled[el.claimTransId]}
                                        invoices={this.props.invoices}
                                    />
                                    ]
                                )}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        )
    }
}


export default Claims;