import fetch from 'isomorphic-fetch'
import jwtDecode from 'jwt-decode';
import TokenStorage from '../../storages/token-storage'

import { checkHttpStatus,
    handleNetworkError,
    parseJSON,
    getStore,
    hasActiveCompany, 
} from '../../utils'

import { fetchSales } from '../sales/actions'

export const REQUEST_FETCH_INVOICES = 'REQUEST_FETCH_INVOICES'
export const RECEIVE_INVOICES_SUCCESS = 'RECEIVE_INVOICES_SUCCESS'
export const RECEIVE_INVOICES_ERROR = 'RECEIVE_INVOICES_ERROR'

const requestFetchInvoices = () => {
    return {
        type: REQUEST_FETCH_INVOICES
    }
}

const receiveInvoicesSuccess = (response) => {
    return {
        type: RECEIVE_INVOICES_SUCCESS,
        data: response
    }
}

const receiveInvoicesError = (response) => {
    return {
        type: RECEIVE_INVOICES_ERROR
    }
}

export const fetchInvoices = (account_id) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        dispatch(requestFetchInvoices());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/invoices/?account_id=${account_id}`, {
            method: 'GET',
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(fetchSales())
                dispatch(receiveInvoicesSuccess(response));
            } catch (e) {
                dispatch(receiveInvoicesError(response))
            }


        })
        .catch(handleNetworkError);
    };
}
