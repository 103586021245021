import {
    REQUEST_FETCH_SHIPPING,
    RECEIVE_SHIPPING_SUCCESS,
    RECEIVE_SHIPPING_ERROR
} from './actions'

const initialState = {
    exclude: [],
    isFetching: false
}

export function shipping(state = initialState, action) {

    switch(action.type) {
        case REQUEST_FETCH_SHIPPING:
            state = {...initialState,
                isFetching: true
            }
            return state

        case RECEIVE_SHIPPING_SUCCESS:
            state = {...state,
                exclude: action.data.exclude,
                isFetching: false
            }
            return state
        

        case RECEIVE_SHIPPING_ERROR:
            state = {...state,
                exclude: [],
                isFetching: false
            }
            return state

        default:
            return state
    }
}
