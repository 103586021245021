import React from "react";

import { NumToIsk, SaleStatus, Spinner } from "../../utils"
import { getCompleteSale, removeCompleteSale } from "../../api/sales/actions"
import moment from "moment"

class SaleLine extends React.Component {

    toggleCompleteSaleRow(sale_id) {
        if(this.props.sale.complete_sale){
            return this.props.dispatch(removeCompleteSale(sale_id))
        }
        this.props.dispatch(getCompleteSale(sale_id))
    }

    renderIcon() {
        let {sale} = this.props
        if (sale.isFetching) {
            return <Spinner />
        } else {
            return <img src={sale.complete_sale ? window.garriConfig.icons.minusIcon : window.garriConfig.icons.plusIcon} alt={`${sale.complete_sale ? "Eyða" : "Bæta við"}`} width="12" />
        }
    }

    render() {
        let {sale} = this.props
        return (
            <tr id={`sale-line-${sale.sale_id}`} className={this.props.idx % 2 === 0 ? "odd" : ""}>
                <td>{moment(sale.shipping_date).format("DD.MM.YYYY")}</td>
                <td>{sale.sale_id}</td>
                <td className={SaleStatus(sale.status).className}>{SaleStatus(sale.status).text}</td>
                <td className="expand" onClick={this.toggleCompleteSaleRow.bind(this, sale.sale_id)}>
                    {this.renderIcon()}
                </td>
            </tr>
        )
    }
}

export default SaleLine;
