export default Intercom;

import React from 'react'
import ReactDOM from 'react-dom'
import Intercom from './intercom'
import { store } from '../../store'


$.fn.intercom = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(Intercom, options), this);
  });
};

/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  if( window.garriConfig.enableIntercom ) {
    $('[data-intercom]').intercom();
  }  
});