import React from 'react'
import ReactDOM from 'react-dom'
import { connectWithStore } from '../../utils'
import { fetchSearchCompanies, clearSearchCompanies, setActiveCompany } from '../../api/companies/actions'
import { updateUser } from '../../api/users/actions'
import { fetchLists } from '../../api/lists/actions'
import { store } from '../../store'
import SearchInput from '../../components/search/searchInput'
import { fetchServerCartItems } from '../../api/cart/actions'


class CompaniesList extends React.Component {

  selectActiveCompany = (company) => (e) => {
    var userObj = {
        username: this.props.user.username,
        first_name: this.props.user.first_name,
        last_name: this.props.user.last_name,
        id: this.props.user.id,
        active_company_id: company.account_id,
        is_active: true
    }
    Promise.resolve(this.props.dispatch(setActiveCompany(company)))
    .then(() => {
      this.props.dispatch(fetchLists(`company=${company.account_id}`))
      this.props.dispatch(updateUser(userObj))
      this.props.dispatch(clearSearchCompanies())
      this.props.dispatch(fetchServerCartItems(company.account_id))
      this.props.close()
    })
  }
  // componentDidMount() {
  //   if (this.props.user.hasManyCompanies) {
  //     alert("test")
  //   }
  // }

  render(){
    var selectableCompanies;
      if(this.props.search_companies && this.props.search_companies.search_companies){
        selectableCompanies = this.props.search_companies.search_companies
      }

    return (
      <div className="companiesList-container">
        <p>Þú ert að panta fyrir <strong>{this.props.user.activeCompany.name} ({this.props.user.activeCompany.account_id})</strong></p>
        { this.props.user.companyOverride && this.props.user.activeCompany.blocked &&
          <p className="text-red"><strong>ATH - Viðskiptavinur er lokaður</strong></p>
        }
        <p className="text-green"><strong>ATH karfa tæmist þegar skipt er um viðskiptavin</strong></p>
        <div className="companiesList-results">
        {this.props.user.companyOverride && 
          <SearchInput showSpinner skipRedirect={true} hideClear={false} fetchMethod={fetchSearchCompanies} clearMethod={clearSearchCompanies} placeholder="Leita að viðskiptavini" searchResultsAttributeName="search_companies" showResults={false} modalSearch={true} />
        }
        {!this.props.user.companyOverride && this.props.user.hasManyCompanies && 
          <SearchInput showSpinner skipRedirect={true} hideClear={false} fetchMethod={fetchSearchCompanies} clearMethod={clearSearchCompanies} placeholder="Leita að viðskiptavini" searchResultsAttributeName="search_companies" showResults={false} allowEmptyString={true} searchOnLoad={true} modalSearch={true}  />
        }
        <ul className="companiesList">
            { selectableCompanies &&
              selectableCompanies.map((company) => 
                <li key={company.account_id}>
                  <a href="#" onClick={this.selectActiveCompany(company)}>{ company.name }
                  { this.props.user.companyOverride && company.blocked &&
                    <strong className="text-red"> (lokaður)</strong>
                  }
                  </a>
                </li>
              )
            }
        </ul>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
        var f = {}
        f.search_companies = (state.search_companies === undefined) ? {} : state.search_companies
        f.user = (state.user === undefined) ? {} : state.user
        return f;
}

export default connectWithStore(store, CompaniesList, mapStateToProps);

