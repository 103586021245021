import React from "react";
import ReactDOM from "react-dom";
import {
  NumToIsk,
  showProductQuantityWarning,
  getProductQty
} from "../../utils";
import Price from "./price";
import QuantityGroup from "./quantityGroup";
import QuantityWarning from "./quantityWarning";
import QuantityGroupToggler from "./quantityGroupToggler";
import ProductImage from "./productImage";
import ProductCheckoutMobile from "./productCheckoutMobile";
import OrderButton from "./orderButton"
import { removeProduct, setProductQuantity } from "../../api/cart/actions";
import { setProductQuantityGroup } from "../../api/products/actions";
import YourPrice from "./yourPrice";
import Modal from "../../views/modal";
import ReplacementProducts from "../../views/modal/replacementProducts";

class ProductCheckout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: this.props.quantity
    };
  }

  setQuantity(qty, increment = false) {
    const { dispatch } = this.props;
    const { product } = this.props.product;
    if (+qty !== 0) {
      qty = getProductQty(product, qty, increment);
    }
    dispatch(setProductQuantity(qty, product.item_id));
    this.setState({ quantity: qty });
  }

  quantityGroupIncrement(checked) {
    const { dispatch } = this.props;
    dispatch(
      setProductQuantityGroup(this.props.product.product.item_id, checked)
    );
  }

  removeProduct() {
    const { dispatch } = this.props;
    dispatch(removeProduct(this.props.product.product.item_id));
  }

  replacementProducts(toReplace, products) {
    let modalProps = {
      title: null,
      body: ReplacementProducts,
      large: true,
      bodyProps: {
        products: products,
        toReplace: toReplace,
        dispatch: this.props.dispatch
      }
    };
    ReactDOM.render(
      React.createElement(Modal, modalProps),
      document.getElementById("modal-container")
    );
  }

  renderProductName(product) {
    if (window.garriConfig.displayProductLinks) {
      return (
        <React.Fragment>

        <a tabIndex={-1} target="_blank" href={`/vara/${product.item_id}`}>
          {product.product_name}
        </a>
        {this.renderQuantityInfo()}
        </React.Fragment>
      );
    } else {
      return product.product_name;
    }
  }

  renderQuantityInfo(){
    const { product } = { ...this.props.product };
    if(product.qtyChanged){
      return (
        <span className="mt-3 d-block update-qty-info text-green">Magn á lager: {product.avail_physical}</span>
      )
    }
  }

  render() {
    const { product, quantity } = { ...this.props.product };
    var hideElement =
      this.props.searchVal &&
      product.product_name
        .toLowerCase()
        .indexOf(this.props.searchVal.toLowerCase()) === -1;

    if (this.props.isMobile) {
      if (hideElement) {
        return <div />;
      }
      return <ProductCheckoutMobile {...this.props} />;
    }
    if (hideElement) {
      return (
        <tr className={`product productCheckout ${this.props.classNames}`} />
      );
    }

    return (
      <tr
        className={`product productCheckout ${this.props.classNames} ${
          showProductQuantityWarning(this.props.product)
            ? "packaging-quantity"
            : ""
          }`}
      >
        <td className="image">
          <ProductImage product={product} />
        </td>
        <td className="d-block d-md-none mobile-cell">
          <div>
            <a tabIndex={-1} target="_blank" href={`/vara/${product.item_id}`}>
              {product.item_id}
            </a>
            {product.item_weight ? `${product.item_weight} ` : ''}{product.qty_unit}
          </div>
          <strong>{this.renderProductName(product)}</strong>
        </td>
        <td className="name d-none d-md-table-cell">
          {this.renderProductName(product)}
        </td>
        <td className="d-none d-md-table-cell">
          <a target="_blank" tabIndex={-1} href={`/vara/${product.item_id}`}>
            {product.item_id}
          </a>
        </td>
        <td className="d-none d-lg-table-cell">
          {product.package_description}
        </td>
        <td className="d-none d-md-table-cell">
          <QuantityGroupToggler
            tabIndex={-1}
            key={`productCheckout-qty-group-toggler-${product.item_id}`}
            product={product}
            quantityGroupIncrement={this.quantityGroupIncrement.bind(this)}
            dispatch={this.props.dispatch}
          />
        </td>
        <td className="quantity-container d-block d-md-table-cell">
          {product.avail_physical < 1 && !product.ignore_stock ? (
            <div>
              {product.replacement_products.length > 0 && (
                <button
                  className="btn btn-success btn-sm"
                  onClick={this.replacementProducts.bind(
                    this,
                    product,
                    product.replacement_products
                  )}
                >
                  Annað í boði
                </button>
              )}
            </div>
          ) : (
            <QuantityGroup
              tabIndex={0}
              key={"productCheckout-qty-group-" + product.item_id}
              product={product}
              quantity={quantity}
              setQuantity={this.setQuantity.bind(this)}
            />
          )}
          <QuantityGroupToggler
            tabIndex={-1}
            key={`productCheckoutmobile-qty-group-toggler-${product.item_id}`}
            product={product}
            quantityGroupIncrement={this.quantityGroupIncrement.bind(this)}
            dispatch={this.props.dispatch}
            classNames={"mobile"}
          />
        </td>
        <td className="d-none d-lg-table-cell">{product.qty_unit}</td>
        <td className="text-right price-container">
          {product.avail_physical < 1 && !product.ignore_stock ? (
            <OrderButton
              product={product}
              enabled={
                this.props.user.isAuthenticated &&
                this.props.user.canPurchase
              }
              user={this.props.user}
              tabIndex={-1}
            />
          ) : (
              <React.Fragment>
                {product.deleted || !product.display_on_web ? (
                  <span>Ekki á lager</span>
                ) : (
                    <YourPrice product={product} quantity={quantity} />
                  )}
              </React.Fragment>
            )}
        </td>
        <td onClick={this.removeProduct.bind(this)} className="remove">
          <i className="fa fa-close"></i>
        </td>
        <td className="empty">
          <QuantityWarning
            product={this.props.product}
            dispatch={this.props.dispatch}
          />
        </td>
      </tr>
    );
  }
}

ProductCheckout.defaultProps = {
  classNames: ""
};

export default ProductCheckout;
