export default class TokenStorage {
    static getItem(key) {
        let value = localStorage.getItem(key)
        if (value === null) {
            value = sessionStorage.getItem(key)
        }
        return value
    }

    static setItem(key, value) {
        console.log("setItem " + key)
        return localStorage.setItem(key, value)
    }

    static removeItem(key) {
        console.log("removeItem " + key)
        sessionStorage.removeItem(key)
        return localStorage.removeItem(key)
    }
}