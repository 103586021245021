import React from 'react';
import { connect } from 'react-redux'
import Button from '../../components/button'

class Signup extends React.Component {

    componentDidMount() {
        this.checkSignupForm()
    }

    componentDidUpdate(){
        this.checkSignupForm()
    }

    checkSignupForm(){
        if(this.props.user && this.props.user.token && this.props.user.companyOverride){
            $("#user_signup_form_container").removeClass('d-none')
            if($("#user_signup_form_container").length === 0){
                window.location.href = window.location.pathname
            }
        }else{
            $("#user_signup_form_container").remove()
        }
    }

    render() {
        if(this.props.user && this.props.user.token && this.props.user.companyOverride){
            return <div/>
        }
        return (
            <div className="text-justify">
                <h2>Viðskiptavinir Garra ehf</h2>
                <p>Aðeins skráðir viðskiptavinir Garra ehf geta pantað í gegnum þessa gátt.</p>
                <Button anchor={true} classNames={"checkout btn-success mb-4 mw-100"} innerHTML={(<div>Sækja um aðgang<img src={window.garriConfig.icons.arrow} width="22" alt="Sækja um aðgang" /></div>)} href={"/nýskrá/"} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    var f = {
        user: {},
        users: {}
    }
    
    f.user = (state.user === undefined) ? {} : state.user
    f.users = (state.users === undefined) ? {} : state.users
    return f;
}

export default connect(mapStateToProps)(Signup);
