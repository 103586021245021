import React from 'react';

class WeightIcon extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        
        let message = ""

        if (this.props.inList) {
            if (this.props.independentUser){
                message = "Endanlegt verð á vigtarvörum mun breytast eftir að vara hefur verið tekin til og vigtuð. Hér er áætlað verð á vörunni."
            } else {
                message = "Endanlegt verð á vigtarvörum mun breytast eftir að vara hefur verið tekin til og vigtuð. Hér birtist kílóverð á vörunni."
            }
        } else {
            message = "Varan er vigtuð"
        }

        if (this.props.product && this.props.product.categories && this.props.product.qty_unit == 'kíló') {
            return (
                <div className={`weight-icon${this.props.product.categories.filter((el) => el === "Vegan").length > 0 ? " also-vegan" : ""}`} data-toggle="kg-tooltip" title={message}>
                    <img className="weight-img" alt={message} src={window.garriConfig.icons.weightIcon} width="20" />
                </div>
            )
        } else {
            return null
        }
    }
}

WeightIcon.defaultProps = {
    product: {
        categories: []
    },
    list: false,
    independentUser: false
}

export default WeightIcon;