import React from 'react';

class ProductSpinner extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className={`product-spinner ${this.props.dark?'dark':''}`}>
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        )
    }
}

ProductSpinner.defaultProps = {
    dark: false
}


export default ProductSpinner;