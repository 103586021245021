import React from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { addProducts } from '../../api/cart/actions'
import { updateList, patchList } from '../../api/lists/actions'
import { clearSelectedProducts } from '../../api/products/actions'
import Button from '../../components/button'
import CreateNewList from '../modal/createNewList'
import ListDropDown from '../../components/listDropDown'
import Modal from '../modal'
import CheckoutAddList from '../modal/checkoutAddList'
import { fetchListsOverview, fetchFrequentProductsOverview, fetchListSale, setActiveList } from '../../api/lists/actions'

class SelectedProductsBar extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedListIndex: 0,
            list: this.props.lists.lists[0]
        }
    }

    componentWillMount() {
        this.props.dispatch(fetchListsOverview())
        this.props.dispatch(fetchFrequentProductsOverview())
    }

    onListItemSelect(index) {
        var list = this.props.lists.lists[index]
        this.setState({
            selectedListIndex: index,
            list: list
        })

        for(var p of this.props.selected_products.products){
            var exists = list.products.find((_p) => _p.product.item_id === p.product.item_id)
            if(exists){
                continue
            }
            list.products.push({product: p.product, quantity: 1})
        }
        Promise.resolve(this.props.dispatch(patchList(list)))
    }

    addToCart(){
        if(Object.keys(this.props.cart.products).length === 0) {
            Promise.resolve(this.props.dispatch(addProducts(this.props.selected_products.products, false)))
            //.then(() => {
                //window.location.href = window.garriConfig.orderUrl
            //})
            return true
        }
        let modalProps = {
          title: null,
          body: CheckoutAddList,
          bodyProps: {
            products: this.props.selected_products.products,
            redirect: false,
            dispatch: this.props.dispatch,
          }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    createNewList(){
        let modalProps = {
          title: null,
          body: CreateNewList,
          bodyProps: {
            products: this.props.selected_products.products,
            callBack: (() => {this.clearSelectedProducts()}),
            dispatch: this.props.dispatch,
          }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    clearSelectedProducts(){
        this.props.dispatch(clearSelectedProducts())
    }


    render() {
        let {products} = this.props.selected_products
        if(products.length > 0){
            return (
                <div className="selected-products-container">
                    <div className="content">
                        <div className="content-box">
                            <div className="num-products">{products.length}</div>
                            <div>Vörur valdar</div>
                        </div>
                        <div className="content-box">
                            <ListDropDown
                                lists={this.props.lists.lists}
                                selectedIndex={this.state.selectedListIndex}
                                onListItemSelect={this.onListItemSelect.bind(this)}
                                textValue={(<div className='d-none d-md-block'>Bæta á lista</div>)}
                                classNames={'btn select-products-bar btn-default btn-products-list xl-m-icon'}
                            />
                        </div>
                        <div className="content-box">
                            <Button
                                classNames={'btn-default bold'}
                                innerHTML={(
                                    <div className="button-content">
                                        <img src={window.garriConfig.icons.plusGreen} height="16" alt="Búa til nýjan lista" />
                                        <div className="d-none d-md-block">Búa til nýjan lista</div>
                                    </div>
                                )}
                                handleClick={this.createNewList.bind(this)}
                            />
                        </div>
                        <div className="content-box">
                            <Button
                                classNames={'btn-default bold'}
                                innerHTML={(
                                    <div className="button-content">
                                        <img src={window.garriConfig.icons.clear} height="20" alt="Hreinsa val" />
                                        <div className="d-none d-md-block">Hreinsa val</div>
                                    </div>
                                )}
                                handleClick={this.clearSelectedProducts.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="content">
                        <div className="content-box">
                            <Button
                                classNames={'btn-success bold'}
                                innerHTML={(
                                    <div className="button-content">
                                        <img src={window.garriConfig.icons.basketWhite} height="16" style={{marginTop: '-4px'}} alt="Bæta í körfu" />
                                        <div className="d-none d-md-block">Setja í körfu</div>
                                    </div>
                                )}
                                handleClick={this.addToCart.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return <div/>
    }
}

SelectedProductsBar.defaultProps = {
    fetchListsOnMount: false
}


function mapStateToProps(state) {

    var f = {
        user: {},
        cart: {},
        lists: {},
        selected_products: {}
    }

    f.user = (state.user === undefined) ? {} : state.user
    f.cart = (state.cart === undefined) ? {} : state.cart
    f.lists = (state.lists === undefined) ? {} : {lists: state.lists.lists.filter(f => f!== undefined).filter(f => f!==null).filter((f) => f.id !== undefined)}
    f.selected_products = (state.selected_products === undefined) ? {} : state.selected_products

    
    return f;
}

export default connect(mapStateToProps)(SelectedProductsBar);
