import React from 'react';

class ReplacementButton extends React.Component {

    constructor(props) {
        super(props)
    }


    render() {
        if (this.props.replaceProduct) {
            return (
                <button className="btn btn-success btn-sm" onClick={this.props.replaceProduct.bind(this, this.props.product, this.props.product.replacement_products)}>Annað í boði</button>
            )
        } else {
            return <React.Fragment></React.Fragment>
        }
    }
}

ReplacementButton.defaultProps = {
    enabled: true,
    product: {},
    selectMode: false,
    quantity: 1,
    allowZeroQuantityAdd: false,
}

export default ReplacementButton;