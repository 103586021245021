import React from 'react';
import ReactDOM from 'react-dom'
import { NumToIsk, productQuantityMatchesPackaging, showProductQuantityWarning } from '../../utils'
import Price from './price'
import QuantityGroup from './quantityGroup'
import QuantityWarning from './quantityWarning'
import ProductImage from './productImage'
import { removeProduct, setProductQuantity } from '../../api/cart/actions'
import YourPrice from './yourPrice'
import { user } from '../../api/user/reducers';
import Modal from '../../views/modal'
import ReplacementProducts from '../../views/modal/replacementProducts'


class ProductCheckoutMobile extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            quantity: this.props.quantity
        }
    }

    setQuantity(qty) {
        const {dispatch} = this.props
        dispatch(setProductQuantity(qty, this.props.product.product.item_id))
        this.setState({quantity: qty})
    }

    removeProduct() {
        const {dispatch} = this.props
        dispatch(removeProduct(this.props.product.product.item_id))
    }

    replacementProducts(toReplace, products) {
        let modalProps = {
            title: null,
            body: ReplacementProducts,
            large: true,
            bodyProps: {
                products: products,
                toReplace: toReplace,
                dispatch: this.props.dispatch,
            }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    render() {
        const {product, quantity} = {...this.props.product}
        return (
            <div className={`product productCheckoutMobile ${this.props.classNames} ${showProductQuantityWarning(this.props.product) ? "packaging-quantity" : ""}`}>
                <div className="product-image">
                    <div className="image">
                        <ProductImage product={product} />
                    </div>
                </div>
                <div className="product-info">
                    <div className="flex-row">
                        <div className="id"><a href={`/vara/${product.item_id }`}>{product.item_id}</a></div>
                        <div className="qty-unit">{product.item_weight ? `${product.item_weight} ` : ''}{product.qty_unit}</div>
                    </div>
                    <div className="flex-row">
                        <div className="name">
                            {window.garriConfig.displayProductLinks ?
                                <a href={`/vara/${product.item_id }`}>{ product.product_name }</a>
                            :
                                product.product_name
                            }
                        </div>
                    </div>
                    <div className="flex-row spaced">
                        { product.avail_physical < 1 ?
                            <div className="quantity-container">
                                { product.replacement_products.length > 0 ?
                                    <button className="btn btn-success btn-sm" onClick={this.replacementProducts.bind(this, product, product.replacement_products)}>Annað í boði</button>
                                    :
                                    <span>Ekki til á lager</span>
                                }
                            </div>
                            :
                            <div className="quantity-container">
                                <QuantityGroup
                                    key={"qty-group-"+product.item_id}
                                    isMobile={true}
                                    product={product}
                                    quantity={quantity}
                                    setQuantity={this.setQuantity.bind(this)}
                                />
                                <QuantityWarning product={this.props.product} dispatch={this.props.dispatch} />
                            </div>
                        }
                        <div className="flex-row">
                            <div className="price">
                                { product.avail_physical < 1 ?
                                    <span>Ekki til á lager</span>
                                    :
                                    <YourPrice product={product} quantity={quantity} />
                                }
                            </div>
                            <div className="remove-price" onClick={this.removeProduct.bind(this)}><i className="fa fa-close"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ProductCheckoutMobile.defaultProps = {
    classNames: '',
}


export default ProductCheckoutMobile;