import React from 'react';
import { connect } from 'react-redux'
import ProductsGrid from '../productsGrid/productsGrid'
import SearchContainer from '../../components/search'
import ProductSpinner from '../../components/product-spinner'
import { addProduct } from '../../api/cart/actions'
import { fetchNextSearchProducts } from '../../api/products/actions'
import { trackGAEvent, getProductQty } from '../../utils'
import * as Ps from 'perfect-scrollbar'


class SearchBar extends React.Component {

    constructor(props) {
        super(props)
        this.searchedProducts = React.createRef()
    }

    addProductFromSearch(product) {
        const {dispatch} = this.props
        var qty = getProductQty(product, 1, true)
        dispatch(addProduct(product, qty))
    }

    fetchNext() {
        const {dispatch} = this.props
        clearTimeout(this.timer)

        if(!this.props.search_products.next){
            return
        }
        this.timer = setTimeout(() => {
            dispatch(fetchNextSearchProducts(this.props.search_products.next))
        }, 300)
    }


    componentDidMount() {
        // var element = document.querySelector("#searchedProducts")    
        // var ps = Ps.initialize(element)    
 
        var _this = this    
        $(window).on("scroll", function() {
            var el = $('.searched-products').first()
            if($(window).scrollTop() >=  (el.innerHeight() + el.offset().top - 400)) {    
                _this.fetchNext()
            }    
        })
    }

    render() {
        trackGAEvent('search', {
            search_term: this.props.search_products.search_text
        })
        if (this.props.view && this.props.view == "grid"){
            return (
                <div>
                    <div className="row search">
                        <div className="container">
                            <h2>Leit í vörulista</h2>
                            <SearchContainer q={this.props.q} searchOnLoad={this.props.searchOnLoad}/>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            <div className="container-fluid">
                                <div ref={this.searchedProducts} id="searchedProducts" className={`row desktop-products searched-products`}>
                                    {this.props.search_products.search_products.length > 0 &&
                                        <ProductsGrid
                                            defaultProductImage={window.garriConfig.icons.defaultProductImage}
                                            infiniteScroll={false}
                                            productsGridView={"ProductLarge"}
                                            classNames={"product-list"}
                                            productList={this.props.search_products.search_products}
                                            store={this.props.store}
                                            modalSearch={this.props.modalSearch}
                                        />
                                    }

                                    {this.props.search_products.isFetching &&
                                        <div className="search-fetching-container">
                                            <ProductSpinner dark={this.props.modalSearch} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        var products = []
        if(this.props.search_products.search_products.length){
            products = this.props.search_products.search_products
        }else if(this.props.modalSearch && this.props.search_products.modal_search_products.length){
            products = this.props.search_products.modal_search_products
        }
        return (
            <div className={`search`}>
                <h2>Leit í vörulista</h2>
                <SearchContainer q={this.props.q} modalSearch={this.props.modalSearch} />
                <div className="row searched-products" style={{textAlign: 'left'}}>
                    { products.length > 0 &&
                        <ProductsGrid
                            defaultProductImage={window.garriConfig.icons.defaultProductImage}
                            infiniteScroll={false}
                            productsGridView={"ProductLarge"}
                            classNames={"product-list"}
                            productList={products}
                            store={this.props.store}
                            modalSearch={this.props.modalSearch}
                        />
                    }

                    {this.props.search_products.isFetching &&
                        <div className="search-fetching-container">
                            <ProductSpinner dark={this.props.modalSearch}/>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    var f = {
        search_products: {},
        cart: {},
        user: {}
    }
    f.search_products = (state.search_products === undefined) ? {} : state.search_products
    f.cart = (state.cart === undefined) ? {} : state.cart
    f.user = (state.user === undefined) ? {} : state.user
    return f;
}

export default connect(mapStateToProps)(SearchBar);


