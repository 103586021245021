import React from 'react';

class Sale extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <this.props.viewName {...this.props} />
        )
    }
}


export default Sale;
