import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd-cjs'
// see: https://medium.com/nmc-techblog/easy-drag-and-drop-in-react-22778b30ba37


export const Item = ({ itemObj, index, moveListItem, saveUpdateCallback }) => {
// export const ListItem = ({ text, index, moveListItem }) => {

    // useDrag - the list item is draggable
    const [{ isDragging }, dragRef] = useDrag({
        item: { 
            type: 'list_item',
            index: index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })


    // useDrop - the list item is also a drop area
    const [spec, dropRef] = useDrop({
        accept: 'list_item',
        hover: (item, monitor) => {
            const dragIndex = item.index
            const hoverIndex = index
            const hoverBoundingRect = ref.current.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top

            // if dragging down, continue only when hover is smaller than middle Y
            if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
            // if dragging up, continue only when hover is bigger than middle Y
            if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

            moveListItem(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
        drop: (item, monitor) => {
            saveUpdateCallback()
        }
    })

    // Join the 2 refs together into one (both draggable and can be dropped on)
    const ref = useRef(null)
    const dragDropRef = dragRef(dropRef(ref))

    // Make items being dragged transparent, so it's easier to see where we drop them
    const opacity = isDragging ? 0 : 1
    return (
        <tr ref={dragDropRef} style={{ opacity }}>
            { Object.entries(itemObj).map(([k,v]) => 
                <td className={k}>{v}</td>
            )}
        </tr>
    )
}