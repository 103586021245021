import React from 'react';
import { NumToIsk } from '../../utils';
import YourPrice from './yourPrice'
import moment from 'moment'

class Price extends React.Component {

    constructor(props) {
        super(props)
    }


    render() {
        let independentUser = this.props.user.independentUser||(this.props.user.activeCompany && this.props.user.activeCompany.independent_company);
        if (!this.props.product.deleted) {
            if (independentUser) {
                return (
                    <div className="price flex-row">
                        {this.props.showPrice ?
                            <YourPrice classNames="newprice" product={this.props.product} showItemWeightUnitPrice={this.props.showItemWeightUnitPrice} />
                            :
                            <span>-</span>
                        }
                    </div>
                )
            }
            if (this.props.price !== undefined) {
                return (
                    <div className="price flex-row"> 
                        {!this.props.product.offer &&
                            <div className='oldprice'>
                                {this.props.showPrice ?
                                    <span>{NumToIsk(this.props.price)} kr.</span>
                                    :
                                    <span>-</span>
                                }
                            </div>
                        }
                        <div className='newprice'>
                            {this.props.showPrice ?
                                <span>{NumToIsk(this.props.price)} kr.</span>
                                :
                                <span>-</span>
                            }
                        </div>
                        {this.props.showBestBefore &&
                            <div className="best-before">
                                { moment(this.props.product.expiration_date).format("DD.MM.YY")}
                            </div>
                        }
                    </div>
                )
            } else {
                return (
                    <div className="price flex-row">
                        {!this.props.product.offer &&
                            <div className='oldprice'>
                                {this.props.showPrice ?
                                    <span>{NumToIsk(this.props.product.sales_price)} kr.</span>
                                    :
                                    <span>-</span>
                                }
                            </div>
                        }
                        <div className='newprice'>
                            {this.props.showPrice ?
                                <YourPrice product={this.props.product} />
                                :
                                <span>-</span>
                            }
                        </div>
                        {this.props.showBestBefore &&
                            <div className="best-before">
                                { moment(this.props.product.expiration_date).format('DD.MM.YY')}
                            </div>
                        }
                    </div>
                )
            }
        } else {
            return <div className="price flex-row" style={{'height': '32px'}}></div>
        }
    }
}

Price.defaultProps = {
    showItemWeightUnitPrice: false,
}

export default Price
