import React from 'react';


function asDrawer(WrappedComponent) {
  var createReactClass = require('create-react-class');
  const Drawer = createReactClass({
    getInitialState: function() {
      return {
        drawer: 'closed'
      };
    },

    onDrawerToggle: function() {
      this.setState({
        drawer: this.state.drawer == 'open' ? 'closed' : 'open'
      });
    },

    render: function() {
      // Use JSX spread syntax to pass all props and state down automatically.
      return <WrappedComponent {...this.props} {...this.state} onDrawerToggle={this.onDrawerToggle} />;
    }
  });
  return Drawer;
}

export default asDrawer;