import React from 'react';

class SettingsBox extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="col-12 col-md-6 col-xl-3">
                <div className={`settings-box ${this.props.classNames}`}>
                    <div className="icon-container"><img src={this.props.image} alt="Stillingar" height="25"/></div>
                    <div className="body-content">
                        {this.props.innerHTML}
                    </div>
                </div>
            </div>
        )
    }
}

SettingsBox.defaultProps = {
    classNames: '',
}

export default SettingsBox;