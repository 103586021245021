import {
    CAROUSEL_BACKWARD,
    CAROUSEL_FORWARD,
    CAROUSEL_RESET,
    CAROUSEL_SET_VISIBLE,
    CAROUSEL_SET_TOTAL,
} from './actions'


const initialState = {
    index: 0,
    visible: 0,
    total: 0,
}

export function carousel(state = initialState, action) {

    switch(action.type) {
        case CAROUSEL_FORWARD:
            let maxSlide = state.total - Math.min(
                state.total, state.visible
            )
            let newIndex = state.index + 1
            newIndex = Math.max(0, newIndex)
            newIndex = Math.min(maxSlide, newIndex)
            
            state = {
                ...state,
                index: newIndex
            }
            return state
        case CAROUSEL_BACKWARD:
            state = {
                ...state,
                index: Math.max(state.index - 1, 0)
            }
            return state
        case CAROUSEL_RESET:
            state = {
                ...state,
                index: 0
            }
            return state
        case CAROUSEL_SET_VISIBLE:
            state = {
                ...state,
                visible: action.data
            }
            return state
        case CAROUSEL_SET_TOTAL:
            state = {
                ...state,
                total: action.data
            }
            return state
        default:
            return state
    }
}

