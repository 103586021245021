import React from 'react';
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import { addProducts } from '../../api/cart/actions'
import Modal from '../modal'
import CheckoutAddList from '../modal/checkoutAddList'
import { fetchFrequentProducts, fetchSingleList } from '../../api/lists/actions';

class ListQuickOrderButton extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        var selectedList = this.props.selectedList
        if (selectedList.products.length === 0 && selectedList.name !== "Mest pantað" && selectedList.fetched !== true) {
            this.props.dispatch(fetchSingleList(selectedList.id))
        }
        else if (selectedList.name === "Mest pantað" && selectedList.products.length === 0) {
            //this.props.dispatch(fetchFrequentProducts())
        }
    }

    confirmModal() {
        var selectedList = this.props.selectedList

        if (Object.keys(this.props.cart.products).length === 0) {
            Promise.resolve(this.props.dispatch(addProducts(selectedList.products, false)))
                .then(() => {
                    window.location.href = window.garriConfig.orderUrl
                })
            return true
        }
        let modalProps = {
            title: null,
            body: CheckoutAddList,
            bodyProps: {
                products: selectedList.products,
                redirect: true,
                dispatch: this.props.dispatch,
            }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    render() {
        if (!this.props.user.isAuthenticated || this.props.selectedList.product_count === 0) {
            return <div />
        }
        return (
            <div className="list-quickorder-button">
                <a className="btn btn-sm btn-success" onClick={this.confirmModal.bind(this)}>
                    <img src={window.garriConfig.icons.hradpontunIcon} alt="Hraðpöntun"/>
                    <div>Hraðpöntun</div>
                </a>
            </div>
        )
    }
}

function mapStateToProps(state) {
    var f = {
        lists: {},
        user: {},
        cart: {}
    }
    f.user = (state.user === undefined) ? {} : state.user
    f.cart = (state.cart === undefined) ? {} : state.cart
    f.lists = (state.lists === undefined) ? {} : state.lists.lists
    return f;
}

export default connect(mapStateToProps)(ListQuickOrderButton);
