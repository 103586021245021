import jwtDecode from 'jwt-decode';
import { GetSessionStorageState, StateToSessionStorage } from '../../storages/session-storage'
import { shuffleArray } from "../../utils";

import {
    REQUEST_PRODUCTS,
    RECEIVE_PRODUCTS,
    REQUEST_SEARCH_PRODUCTS,
    RECEIVE_SEARCH_PRODUCTS,
    RECEIVE_SEARCH_PRODUCTS_ERROR,
    CLEAR_SEARCH_PRODUCTS,
    REQUEST_SINGLE_PRODUCT,
    RECEIVE_SINGLE_PRODUCT,
    REQUEST_PRODUCT_PRICES,
    RECEIVE_PRODUCT_PRICES,
    RECEIVE_ADDON_PRODUCTS,
    REQUEST_ADDON_PRODUCTS,
    RECEIVE_ADDON_PRODUCTS_ERROR,
    RECEIVE_PRODUCT_PRICES_ERROR,
    SET_PRODUCT_QUANTITY_GROUP,
    SELECT_PRODUCT,
    REMOVE_SELECTED_PRODUCT,
    CLEAR_SELECTED_PRODUCTS,
    UPDATE_SELECTED_PRODUCT_QUANTITY,
    CREATE_RESTOCKED_NOTIFICATION_SUCCESS,
    CLEAR_RESTOCKED_NOTIFICATION,
} from './actions'

import {
    RECEIVE_LISTS,
} from '../lists/actions'

import {
    SET_ACTIVE_COMPANY,
} from '../companies/actions'

import {
    SUBMIT_ORDER_SUCCESS,
} from '../checkout/actions'

import {
    AUTH_LOGOUT,
    AUTH_REFRESH_LOGIN_FAILURE,
    AUTH_LOGIN_SUCCESS,
    AUTH_SUCCESS,
    AUTH_LOGIN_REQUEST,
} from '../user/actions'

const initialState = {
    isFetching: false,
    productList: [],
    total: 0,
    dirty: false,
}

export function products(state = initialState, action) {

    switch (action.type) {
        case REQUEST_PRODUCTS:
            state = {
                ...state,
                isFetching: true,
            }
            if (action.namespace !== "productList") {
                state[action.namespace] = []
            }
            return state
        case AUTH_LOGIN_SUCCESS:
        case AUTH_LOGOUT:
        case SET_ACTIVE_COMPANY:
            let dirty = true
            if (action.type === AUTH_LOGIN_SUCCESS && !action.data.initiatedByUser) {
                dirty = false
            }
            state = {
                ...state,
                dirty: dirty,
            }
            return state
        case RECEIVE_PRODUCTS:
            var productList = []
            var data_products = action.data.results.map(p => {
                p.sales_price = parseFloat(p.sales_price)
                p.sales_price_vat = parseFloat(p.sales_price_vat)
                p.avail_physical = +p.avail_physical
                return p
            })
            if (action.get_next) {
                productList = [...state.productList].concat(data_products)
            } else {
                productList = data_products
            }


            state = {
                ...state,
                dirty: false,
                isFetching: false,
                next: action.data.next,
                total: action.data.count
            }
            state[action.namespace] = productList

            return state

        default:
            return state
    }

}

export function product(state = {
    isFetching: false,
    product: {},
    dirty: false,
}, action) {

    switch (action.type) {
        case AUTH_LOGIN_SUCCESS:
        case AUTH_LOGOUT:
        case AUTH_LOGIN_REQUEST:
        case SET_ACTIVE_COMPANY:
            let dirty = true
            if (action.type === AUTH_LOGIN_SUCCESS && !action.initiatedByUser) {
                dirty = false
            }
            state = {
                ...state,
                dirty: dirty,
            }
            return state

        case REQUEST_PRODUCTS:
            state = {
                ...state,
                isFetching: true,
            }
            return state

        case RECEIVE_SINGLE_PRODUCT:
            var product = action.data;
            product.sales_price = parseFloat(product.sales_price)
            product.sales_price_vat = parseFloat(product.sales_price_vat)
            product.avail_physical = +product.avail_physical

            state = {
                ...state,
                isFetching: false,
                product: product,
            }
            return state
        default:
            return state
    }

}

export function search_products(state = {
    search_products: [],
    modal_search_products: [],
    search_text: "",
    isFetching: false,
}, action) {
    switch (action.type) {
        case CLEAR_SEARCH_PRODUCTS:
            state = {
                ...state,
                search_products: [],
                modal_search_products: [],
                isFetching: false,
                search_text: "",
            }
            return state
        case REQUEST_SEARCH_PRODUCTS:
            state = {
                ...state,
                isFetching: true,
                search_text: action.data,
            }
            return state
        case RECEIVE_SEARCH_PRODUCTS:
            var search_products = []
            var modal_search_products = []
            if (action.get_next) {
                search_products = [...state.search_products].concat(action.data.results)
            } else {
                if (action.modal_search) {
                    search_products = [...state.search_products]
                    modal_search_products = action.data.results
                } else {
                    modal_search_products = []
                    search_products = action.data.results
                }
            }

            search_products = search_products.map(p => {
                p.sales_price = parseFloat(p.sales_price)
                p.sales_price_vat = parseFloat(p.sales_price_vat)
                p.avail_physical = +p.avail_physical
                if (+p.distance === 0) {
                    p.distance = 1000
                }
                return p
            }).sort((a, b) => (a.distance > b.distance) ? 1 : -1)

            state = {
                ...state,
                search_products: search_products,
                modal_search_products: modal_search_products,
                next: action.data.next,
                isFetching: false,
            }
            return state
        default:
            return state
    }
}

export function selected_products(state = {
    products: []
}, action) {
    switch (action.type) {
        case CLEAR_SELECTED_PRODUCTS:
            state = {
                ...state,
                products: []
            }
            return state

        case SELECT_PRODUCT:
            state = { ...state }
            var quantity = action.quantity || 1
            if (!state.products.find((el) => el.product.item_id === action.product.item_id)) {
                state.products.push({ product: action.product, quantity: action.quantity, packagingQty: 0 })
            }
            return state
        case REMOVE_SELECTED_PRODUCT:
            state = { ...state }
            state.products = state.products.filter((el) => el.product.item_id !== action.product.item_id)
            return state
        case UPDATE_SELECTED_PRODUCT_QUANTITY:
            state = { ...state }
            var quantity = action.quantity || 1
            state.products = state.products.map((el) => {
                if (el.product.item_id === action.product.item_id) {
                    el.quantity = quantity
                }
                return el
            })
            return state
        default:
            return state
    }
}


export function prices(state = {
    isFetching: false,
    prices: {}
}, action) {

    switch (action.type) {
        case REQUEST_PRODUCT_PRICES:
            state = {
                ...state,
                isFetching: true,
            }
            StateToSessionStorage(state, 'prices')
            return state
        case RECEIVE_PRODUCT_PRICES:
            state = {
                ...state,
                isFetching: false,
            }

            if (state.prices === undefined) {
                state.prices = {}
            }

            if (state.prices[action.data.account_id] === undefined) {
                state.prices[action.data.account_id] = {}
            }

            let pricesForCompany = Object.assign({}, state.prices[action.data.account_id])
            pricesForCompany = Object.assign(pricesForCompany, action.data.prices)

            state.prices[action.data.account_id] = pricesForCompany
            state.prices[action.data.account_id]["99111100"] = 1490

            StateToSessionStorage(state, 'prices')
            return state
        case RECEIVE_PRODUCT_PRICES_ERROR:
            state = {
                ...state,
                isFetching: false,
            }
            StateToSessionStorage(state, 'prices')
            return state
        default:
            return GetSessionStorageState(state, 'prices')
    }

}


export function addonProducts(state = {
    isFetching: false,
    productList: [],
    total: 0,

}, action) {

    switch (action.type) {
        case REQUEST_ADDON_PRODUCTS:
            state = {
                ...state,
                isFetching: true,
            }
            return state
        case RECEIVE_ADDON_PRODUCTS:
            var productList = []
            var data_products = action.data.results.map(p => {
                p.sales_price = parseFloat(p.sales_price)
                p.sales_price_vat = parseFloat(p.sales_price_vat)
                p.avail_physical = +p.avail_physical
                return p
            })
            productList = data_products

            let promotionKey = 'promotion'
            if (action.user.independentUser) {
                promotionKey = 'promotion_independent_user'
            }
            let otherProducts = productList.filter(x => (!x.promotion && !x.promotion_independent_user && !x.offer && !x.new))
            let newProducts = productList.filter(x => x.new)
            let offerProducts = productList.filter(x => x.offer)
            let promotionProducts = productList.filter(x => x[promotionKey])


            let shuffledOrderedProductList = shuffleArray(promotionProducts).concat(shuffleArray(offerProducts)).concat(shuffleArray(newProducts)).concat(shuffleArray(otherProducts))

            state = {
                ...state,
                isFetching: false,
                productList: shuffledOrderedProductList,
                next: action.data.next,
                total: action.data.count
            }
            return state
        default:
            return state
    }
}

export function quantityGroupProducts(state = {
    products: {}
}, action) {

    switch (action.type) {
        case SUBMIT_ORDER_SUCCESS:
            state = {
                ...state,
                products: {},
            }
            StateToSessionStorage(state, 'quantityGroupProducts')
            return state
        case SET_PRODUCT_QUANTITY_GROUP:
            var products = { ...state.products }
            if (action.checked) {
                products[action.item_id] = false
            } else {
                products[action.item_id] = true
            }
            state = {
                ...state,
                products: products
            }
            StateToSessionStorage(state, 'quantityGroupProducts')
            return state

        default:
            return GetSessionStorageState(state, 'quantityGroupProducts')
    }
}

export function restockedProductNotification(state = {
    message: '',
    status: 'init',
}, action) {
    switch (action.type) {
        case CLEAR_RESTOCKED_NOTIFICATION:
            state = {
                message: '',
                status: 'init',
            }
            return state
        case CREATE_RESTOCKED_NOTIFICATION_SUCCESS:
            state = {
                ...action.data,
                status: 'done'
            };
            return state
        default:
            return state
    }
}