import React from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { emptyCart } from '../../api/cart/actions'
import { getCompleteValitorSale } from '../../api/sales/actions'
import qs from 'qs'

import Product from '../../components/product'
import ProductCheckoutConfirm from '../../components/product/productCheckoutConfirm'
import { NumToIsk } from '../../utils';
import { fetchAddonProducts } from '../../api/products/actions'
import AddonProductsContainer from '../checkout/steps/addonProducts';


class ValitorResponse extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { dispatch } = this.props;
    this.doFetchAddonProducts()
    var query = qs.parse(window.location.search.replace("?", ""));
    dispatch(emptyCart(this.props.cart.cart_id));
    if (query.sale_id) {
      dispatch(getCompleteValitorSale(query.sale_id))
    }
  }

  doFetchAddonProducts() {
    let idList = []
    Object.keys(this.props.cart.products).map((k) => {
        this.props.cart.products[k].product.addon_products.map(r => idList.push(r))
    })
    this.props.dispatch(fetchAddonProducts(idList))
    }
  
  render() {

    if (this.props.sales.valitor_sale && this.props.sales.valitor_sale.sale_id) {

      return (
        <div className="row order-purchased confirm-view">
            <div className="col-12 col-xxl-9 order-container">
                <div className="checkout-header">
                    <div className="header-element">
                        <h2>Pöntun móttekin</h2>
                    </div>
                    <div className="header-element">
                        <p>Númer pöntunar: <b>{this.props.sales.valitor_sale.sale_id}</b></p>
                    </div>
                </div>
                {/* {this.props.sales.valitor_sale.deliveryData.customerRef !== "" &&
                    <div className="col-12">
                        <p><strong>Athugasemd á reikning</strong> {this.props.sales.valitor_sale.deliveryData.customerRef} </p>
                    </div>
              */}
                <div className="products-container">
                    <table className="table-products">
                        <thead>
                            <tr>
                                <th>Mynd</th>
                                <th>Vöruheiti</th>
                                <th>Vörunúmer</th>
                                <th>Stærð</th>
                                <th>Fj. í pakkningu</th>
                                <th>Eining</th>
                                <th>Verð</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.props.sales.valitor_sale.products.map((product) =>
                                <Product
                                    dispatch={this.props.dispatch}
                                    key={'no-mobile-product'+product.product.item_id}
                                    product={product}
                                    viewName={ProductCheckoutConfirm}
                                    user={this.props.user}
                                />
                            )}
                        </tbody>
                    </table>
                    <div className="mobile-products">
                        { this.props.sales.valitor_sale.products.map((product) =>
                            <Product
                                dispatch={this.props.dispatch}
                                key={'mobile-product'+product.product.item_id}
                                product={product}
                                viewName={ProductCheckoutConfirm}
                                isMobile={true}
                                user={this.props.user}
                            />
                        )}
                    </div>
                    <hr/>
                    <hr/>
                    <div className="total-container row">
                        <div className="total">Samtals{this.props.user.activeCompany && this.props.user.activeCompany.vsk_active ? " m/vsk" : ""}: <span className="total-text">{NumToIsk(this.props.sales.valitor_sale.amount)} kr.</span></div>
                    </div>
                    {/* <div className="row settings-box-container">
                        <SettingsBox
                            image={window.garriConfig.icons.home}
                            classNames={'read-only'}
                            innerHTML={
                                <div>
                                    <p className="title">Afhendingarstaður</p>
                                    <p>{this.props.sales.valitor_sale.deliveryData.deliveryMode === "send" ? activeCompany.name : "Garri ehf"}</p>
                                    { this.props.sales.valitor_sale.deliveryData.deliveryMode === "send" ?
                                        <p dangerouslySetInnerHTML={{__html: activeCompany.address.split("\n").join("<br/>")}} />
                                        :
                                        <p>Hádegismóum 1<br />110 Reykjavík</p>
                                    }
                                </div>
                            }
                        />
                        <SettingsBox
                            image={window.garriConfig.icons.userIcon}
                            classNames={'read-only'}
                            innerHTML={
                                <div>
                                    { contactPerson && 
                                        <div>
                                            <p className="title">Tengiliður</p>
                                            <p>{contactPerson.name}</p>
                                            <p>{contactPerson.email || 'Netfang ekki skráð'}</p>
                                            <p>{contactPerson.phone}</p>
                                        </div>
                                    }
                                    { this.props.user.companyOverride &&
                                        <div>
                                            <p className="title">Sölumaður</p>
                                            <p>{this.props.user.name}</p>
                                        </div>
                                    }
                                </div>
                            }
                        />
                        <SettingsBox
                            image={window.garriConfig.icons.calendar}
                            classNames={'read-only'}
                            innerHTML={
                                <div>
                                    <p className="title">Dagsetning</p>
                                    <p>{pickupTime.format("D. MMM YYYY")}</p>
                                </div>
                            }
                        />
                        <SettingsBox
                            image={window.garriConfig.icons.building}
                            classNames={'read-only'}
                            innerHTML={
                                <div>
                                    <p className="title">Afhendingarmáti</p>
                                    <p>{this.props.sales.valitor_sale.deliveryData.deliveryMode === "send" ? `Senda${this.renderDeliveryMode()}` : "Sækja"}</p>
                                </div>
                            }
                        />
                    </div> */}
                </div>
            </div>
            <AddonProductsContainer />
        </div>
      )
    } else {
      return (<div></div>)
    }
  }
}


function mapStateToProps(state) {

    var f = {
        user: {},
        sales: {},
        cart: {},
        addonProducts: {},
    }
    f.user = (state.user === undefined) ? {} : state.user
    f.sales = (state.sales === undefined) ? {} : state.sales
    f.cart = (state.cart === undefined) ? {} : state.cart
    f.addonProducts = (state.addonProducts === undefined) ? {} : state.addonProducts
    return f;
}

export default connect(mapStateToProps)(ValitorResponse);
