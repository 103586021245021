import React from 'react';
import { connect } from 'react-redux'

class MainMenuButton extends React.Component {

    constructor(props) {
        super(props)
    }

    toggleOpen() {
        $("#main-menu-button").toggleClass("open");
        $("#main-menu").toggleClass("open");
    }

    toggleClosed() {
        $("#main-menu-button").toggleClass("closed");
        $("#main-menu").toggleClass("closed");
    }

    toggleMainMenu() {
        if($("#main-menu-button").hasClass("open")) {
            this.toggleClosed();
            this.toggleOpen();
        } else {
            this.toggleOpen();
            this.toggleClosed();
        }
    }

    render() {
        return (
            <a id="main-menu-button" className="main-menu-button closed" onClick={this.toggleMainMenu.bind(this)}>
                <div className="img-container">
                    <img src={this.props.burger} width="20" className="burger-icon" alt="Opna valmynd"/>
                    <img src={this.props.close} width="20" className="close-icon" alt="Loka valmynd"/>
                </div>
            </a>
        )
    }
}


export default MainMenuButton;