import React from 'react';

class VeganIcon extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        if (this.props.product && this.props.product.categories && this.props.product.categories.filter((el) => el === "Vegan").length > 0) {
            return (
                <div className="vegan-icon" >
                    <img title="Varan er vegan" className="vegan-img" alt="Varan er vegan" src={window.garriConfig.icons.vegan} width="20" />
                </div>
            )
        } else {
            return null
        }
    }
}

VeganIcon.defaultProps = {
    product: {
        categories: []
    }
}

export default VeganIcon;