import React from 'react';

import { NumToIsk, getProductQty } from '../../utils'
import Ribbon from '../ribbon'
import Price from './price'
import QuantityGroup from './quantityGroup'
import PriceHeader from './priceHeader'
import QuantityGroupToggler from './quantityGroupToggler'
import ProductImage from './productImage'
import { removeProduct, setProductQuantity } from '../../api/cart/actions'
import { setProductQuantityGroup } from '../../api/products/actions'
import YourPrice from './yourPrice'
import {useShallowEqual} from 'shouldcomponentupdate-children';

class ProductCartMobile extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            quantity: this.props.quantity
        }
    }

    setQuantity(qty, increment=false) {
        const {dispatch} = this.props
        const {product} = this.props.product
        qty = getProductQty(product, qty, increment)
        dispatch(setProductQuantity(qty, this.props.product.product.item_id))
        this.setState({quantity: qty})
    }

    quantityGroupIncrement(checked) {
        const {dispatch} = this.props
        dispatch(setProductQuantityGroup(this.props.product.product.item_id, checked))
    }

    removeProduct() {
        const {dispatch} = this.props
        dispatch(removeProduct(this.props.product.product.item_id))
    }


    render() {
        const {product, quantity} = {...this.props.product}
        return (
            <div className={`product productCart productCartMobile ${this.props.classNames} row`}>
                <div className="flex-row">
                    <div className="image">
                        <ProductImage product={product} />
                    </div>
                    <div className="product-name">
                        {window.garriConfig.displayProductLinks ?
                            <a href={`/vara/${product.item_id }`}>{ product.product_name }</a>
                        :
                            product.product_name
                        }
                    </div>
                </div>
                <div className="flex-row">
                    <div className="quantity-container">
                        <QuantityGroup
                            key={"qty-group-"+product.item_id}
                            isMobile={true}
                            product={product}
                            quantity={quantity}
                            setQuantity={this.setQuantity.bind(this)}
                        />
                    </div>
                    <div className="group-toggler mr-auto"><QuantityGroupToggler key={`productCart-qty-group-toggler-${product.item_id}`} product={product} quantityGroupIncrement={this.quantityGroupIncrement.bind(this)} dispatch={this.props.dispatch} classNames={'smaller'}/></div>
                    <div className="price"><YourPrice product={product} quantity={quantity} /></div>
                    <div className="remove-price" onClick={this.removeProduct.bind(this)}><i className="fa fa-close"></i></div>
                </div>
            </div>

        )
    }
}

ProductCartMobile.defaultProps = {
    classNames: '',
}


export default ProductCartMobile;