import React from 'react'
import ReactDOM from 'react-dom'
import QuickOrder from './quickOrder'
import { store } from '../../store'

$.fn.quickOrder = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(QuickOrder, options), this);
  });
};

/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-quick-order]').quickOrder();
});