import React from 'react';
import ReactDOM from 'react-dom'
import { getPurchasedOrder } from '../../../api/checkout/actions'
import Product from '../../../components/product'
import ProductCheckoutConfirm from '../../../components/product/productCheckoutConfirm'
import SettingsBox from '../../../components/settingsBox'
import { NumToIsk } from '../../../utils'
import moment from 'moment'
import AddonProductsContainer from './addonProducts';
import { fetchAddonProducts } from '../../../api/products/actions'


class OrderPurchased extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.doFetchAddonProducts()
        if(this.props.checkout.submitted_order.salesTable){
            this.props.dispatch(getPurchasedOrder(this.props.checkout.submitted_order.salesTable.salesId))
        }
        $(window).scrollTop(0)
    }

    doFetchAddonProducts() {
        let idList = []
        Object.keys(this.props.cart.products).map((k) => {
            this.props.cart.products[k].product.addon_products.map(r => idList.push(r))
        })
        this.props.dispatch(fetchAddonProducts(idList))
    }

    getContactPerson() {
        var contact = undefined
        if(this.props.contacts.contacts && this.props.contacts.contacts.results && this.props.checkout.deliveryData.contactPersonId){
            var contact = this.props.contacts.contacts.results.find((c) => 
                c.party_id === this.props.checkout.deliveryData.contactPersonId
            )
        }

        return contact
    }

    getActiveCompany() {
        var company = {}
        if(this.props.user.activeCompany.account_id === this.props.checkout.deliveryData.custAccount){
            company = this.props.user.activeCompany
        }
        return company
    }


    renderDeliveryMode() {
        if (this.props.user.activeCompany.delivery_mode === null || this.props.user.activeCompany.delivery_mode.delivery_mode_rule === null) {
            return ''
        }
        if (this.props.checkout.deliveryData.deliveryMode === "send") {
            return ` (${this.props.user.activeCompany.delivery_mode.delivery_mode_rule.name})`
        }
    }


    render() {
        const contactPerson = this.getContactPerson()
        const pickupTime = moment(this.props.checkout.deliveryData.salesShippingDate)
        const activeCompany = this.getActiveCompany()

        return (
            <div className="row order-purchased confirm-view">
                <div className="col-12 col-xxl-9 order-container">
                    <div className="checkout-header">
                        <div className="header-element">
                            <h2>Pöntun móttekin</h2>
                        </div>
                        <div className="header-element">
                            <p>Númer pöntunar: <b>{this.props.checkout.submitted_order.salesTable.salesId}</b></p>
                        </div>
                    </div>
                    {this.props.checkout.deliveryData.customerRef !== "" &&
                        <div className="col-12">
                            <p><strong>Athugasemd á reikning</strong> {this.props.checkout.deliveryData.customerRef} </p>
                        </div>
                    }
                    <div className="products-container">
                        <table className="table-products">
                            <thead>
                                <tr>
                                    <th>Mynd</th>
                                    <th>Vöruheiti</th>
                                    <th>Vörunúmer</th>
                                    <th>Stærð</th>
                                    <th>Fj. í pakkningu</th>
                                    <th>Eining</th>
                                    <th>Verð</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.props.checkout.products.map((product) =>
                                    <Product
                                        dispatch={this.props.dispatch}
                                        key={'no-mobile-product'+product.product.item_id}
                                        product={product}
                                        viewName={ProductCheckoutConfirm}
                                        user={this.props.user}
                                    />
                                )}
                            </tbody>
                        </table>
                        <div className="mobile-products">
                            { this.props.checkout.products.map((product) =>
                                <Product
                                    dispatch={this.props.dispatch}
                                    key={'mobile-product'+product.product.item_id}
                                    product={product}
                                    viewName={ProductCheckoutConfirm}
                                    isMobile={true}
                                    user={this.props.user}
                                />
                            )}
                        </div>
                        <hr/>
                        <hr/>
                        <div className="total-container row">
                            <div className="total">Samtals{this.props.user.activeCompany && this.props.user.activeCompany.vsk_active ? " m/vsk" : ""}: <span className="total-text">{NumToIsk(this.props.checkout.submitted_order.salesTable.amount)} kr.</span></div>
                        </div>
    
                        <AddonProductsContainer />
    
                        <div className="row settings-box-container">
                            <SettingsBox
                                image={window.garriConfig.icons.home}
                                classNames={'read-only'}
                                innerHTML={
                                    <div>
                                        <p className="title">Afhendingarstaður</p>
                                        <p>{this.props.checkout.deliveryData.deliveryMode === "send" ? activeCompany.name : "Garri ehf"}</p>
                                        { this.props.checkout.deliveryData.deliveryMode === "send" ?
                                            <p dangerouslySetInnerHTML={{__html: activeCompany.address.split("\n").join("<br/>")}} />
                                            :
                                            <p>Hádegismóum 1<br />110 Reykjavík</p>
                                        }
                                    </div>
                                }
                            />
                            <SettingsBox
                                image={window.garriConfig.icons.userIcon}
                                classNames={'read-only'}
                                innerHTML={
                                    <div>
                                        { contactPerson && 
                                            <div>
                                                <p className="title">Tengiliður</p>
                                                <p>{contactPerson.name}</p>
                                                <p>{contactPerson.email || 'Netfang ekki skráð'}</p>
                                                <p>{contactPerson.phone}</p>
                                            </div>
                                        }
                                        { this.props.user.companyOverride &&
                                            <div>
                                                <p className="title">Sölumaður</p>
                                                <p>{this.props.user.name}</p>
                                            </div>
                                        }
                                    </div>
                                }
                            />
                            <SettingsBox
                                image={window.garriConfig.icons.calendar}
                                classNames={'read-only'}
                                innerHTML={
                                    <div>
                                        <p className="title">Dagsetning</p>
                                        <p>{pickupTime.format("D. MMM YYYY")}</p>
                                    </div>
                                }
                            />
                            <SettingsBox
                                image={window.garriConfig.icons.building}
                                classNames={'read-only'}
                                innerHTML={
                                    <div>
                                        <p className="title">Afhendingarmáti</p>
                                        <p>{this.props.checkout.deliveryData.deliveryMode === "send" ? `Senda${this.renderDeliveryMode()}` : "Sækja"}</p>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default OrderPurchased;
