import React from 'react';
import ReactDOM from 'react-dom'
import Modal from '../../views/modal'
import ProductImageModal from '../../views/modal/productImageModal'

class ProductImage extends React.Component {

    constructor(props) {
        super(props)
    }

    onClick(){
        if(this.props.zoomable){
            let image = this.props.product.image.large
            if (image === undefined) {
                image = this.props.product.image.thumb
            }
            let modalProps = {
                title: null,
                body: ProductImageModal,
                large: true,
                bodyProps: {
                    dispatch: this.props.dispatch,
                    alt: this.props.product.product_name,
                    image: image,
                    name: this.props.product.product_name,
                    attrs: this.props.attrs
                }
            }
            ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
        }
    }

    render() {
        const {attrs} = {...this.props}
        if (this.props.product.image === null || this.props.product.image === undefined) {
            return <img src={window.garriConfig.icons.defaultProductImage} alt={this.props.product.product_name} {...attrs} />
        } else {
            return (
                <img
                    src={this.props.product.image[this.props.size]}
                    onError={(e) => {e.target.onerror = null; e.target.src=window.garriConfig.icons.defaultProductImage}}
                    alt={this.props.product.product_name}
                    onClick={this.onClick.bind(this)} {...attrs}
                />
            )    
        }
    }
}

ProductImage.defaultProps = {
    attrs: {},
    size: "thumb",
}

export default ProductImage;

