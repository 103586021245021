import { CartAddProducts, CartCheckQty, CartMatchQty, CartTotalPrice, CartTotalQty } from '../../utils'
import { SUBMIT_ORDER_SUCCESS } from '../checkout/actions'
import { SET_ACTIVE_COMPANY } from '../companies/actions'
import {
    RECEIVE_PRODUCT_AVAIL_UPDATE, RECEIVE_PRODUCT_PRICES
} from '../products/actions'
import { AUTH_LOGOUT } from '../user/actions'
import {
    CART_ADD_PRODUCTS, CART_ADD_SALE_PRODUCTS, CART_FETCH_OTHERS_REQUEST, CART_FETCH_OTHERS_SUCCESS, CART_FETCH_SERVER_SUCCESS, CART_REMOVE_PRODUCT_SUCCESS, CART_UPDATE_PACKAGING, CART_UPDATE_PRODUCT_QUANTITY_SUCCESS, EMPTY_CART_SUCCESS, IGNORE_CURRENT_LOCAL_CART
} from './actions'




const initialState = {
    products: [],
    sale_products: null,
    searched_products: [],
    total: 0,
    totalQty: 0,
    hasItemWeightProducts: false,
}

export function cart(state = initialState, action) {

    switch(action.type) {
        case CART_ADD_PRODUCTS:
            state = {
                ...state
            }

            if(action.replaceProducts){
                state.products = []
            }

            state.products = CartAddProducts(action.products, state)
            state.total = CartTotalPrice(state.products)
            state.totalQty = CartTotalQty(state.products)
            state.products = CartCheckQty(state.products)
            state.hasItemWeightProducts = state.products.find((p) => +p.product.item_weight)

            state.cart_id = action.cart_id

            return state

        case EMPTY_CART_SUCCESS:
            state = {
                ...state,
                products: []
            }

            state.total = CartTotalPrice(state.products)
            state.totalQty = CartTotalQty(state.products)
            state.products = CartCheckQty(state.products)
            state.hasItemWeightProducts = state.products.find((p) => +p.product.item_weight)

            return state

        case CART_ADD_SALE_PRODUCTS:
            state = {
                ...state
            }

            state.sale_products = action.products

            return state

        case CART_REMOVE_PRODUCT_SUCCESS:
            var new_products = state.products.filter(p => p.product.item_id !== action.product_id)
            
            state = {
                ...state,
                products: new_products
            }
            if (state.products.length === 1 && state.products[0].product.is_shipping_fee) {
                state.products = []
            }


            state.total = CartTotalPrice(state.products)
            state.totalQty = CartTotalQty(state.products)
            state.products = CartCheckQty(state.products)
            state.hasItemWeightProducts = state.products.find((p) => +p.product.item_weight)

            return state

        case CART_UPDATE_PRODUCT_QUANTITY_SUCCESS:
            state = {
                ...state
            }

            var product = state.products.find((p) => p.product.item_id === action.product_id)
            if(product){
                if (+action.quantity > +product.product.avail_physical && !product.product.ignore_stock) {
                    product.quantity = parseInt(product.product.avail_physical)
                    product.product.qtyChanged = true
                    alert(`Magn á lager er ${product.product.avail_physical}`)
                } else {
                    product.quantity = action.quantity
                    product.product.qtyChanged = false
                }
                
            }
            state.total = CartTotalPrice(state.products)
            state.totalQty = CartTotalQty(state.products)
            state.products = CartCheckQty(state.products)
            state.hasItemWeightProducts = state.products.find((p) => +p.product.item_weight)
            return state

        case RECEIVE_PRODUCT_AVAIL_UPDATE:
            state = {
                ...state
            }

            state.products = state.products.map((product) => {
                let newAvail = action.data[product.product.item_id]
                if (newAvail !== undefined) {
                    product.product.avail_physical = newAvail
                }
                return product
            })

            state.total = CartTotalPrice(state.products)
            state.totalQty = CartTotalQty(state.products)
            state.products = CartCheckQty(state.products)
            state.hasItemWeightProducts = state.products.find((p) => +p.product.item_weight)

            return state
        case CART_UPDATE_PACKAGING:
            state = {
                ...state
            }
            if(action.updateAll){
                state.products = CartMatchQty(state.products)
                state.total = CartTotalPrice(state.products)
                state.totalQty = CartTotalQty(state.products)
            }else{
                state.products = CartMatchQty(state.products, action.product)
                state.total = CartTotalPrice(state.products, action.product)
                state.totalQty = CartTotalQty(state.products, action.product)
            }
            state.hasItemWeightProducts = state.products.find((p) => +p.product.item_weight)
            return state
        case RECEIVE_PRODUCT_PRICES:
            state = {
                ...state,
            }
            state.total = CartTotalPrice(state.products)
            state.totalQty = CartTotalQty(state.products)
            state.products = CartCheckQty(state.products)
            state.hasItemWeightProducts = state.products.find((p) => +p.product.item_weight)

            return state
        case SET_ACTIVE_COMPANY:
            state = {
                ...state,
                products: [],
            }
            state.total = CartTotalPrice(state.products)
            state.totalQty = CartTotalQty(state.products)
            state.products = CartCheckQty(state.products)
            state.hasItemWeightProducts = state.products.find((p) => +p.product.item_weight)

            return state
        case AUTH_LOGOUT:
            state = {...initialState}
            return state
        case SUBMIT_ORDER_SUCCESS:
            state = {...initialState}
            return state

        case CART_FETCH_SERVER_SUCCESS:
            state = {...initialState}

            state['products'] = action.products.data
            state['cart_id'] = action.products.cart_id
            state.total = CartTotalPrice(state.products)
            state.totalQty = CartTotalQty(state.products)
            state.products = CartCheckQty(state.products)
            state.hasItemWeightProducts = state.products.find((p) => +p.product.item_weight)

            return state

        case IGNORE_CURRENT_LOCAL_CART:
            return state
        
        default:
            return state
    }
}

export function carts(state = {isFetching: false, other_carts: []}, action) {

    switch(action.type) {

        case CART_FETCH_OTHERS_REQUEST:
            state = {...state, isFetching: true}
            return state
        
        case CART_FETCH_OTHERS_SUCCESS:
            state['other_carts'] = action.carts
            state = {...state, isFetching: false}
            return state

        default:
            return state
    }
}
