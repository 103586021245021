import React from 'react';
import ReactDOM from 'react-dom'
import { findDOMNode } from 'react-dom';
import { NumToIsk } from '../../../utils'
import { addProduct, addProducts, addSaleProducts } from '../../../api/cart/actions'
import { clearSearchProducts, fetchAddonProducts } from '../../../api/products/actions'
import Modal from '../../../views/modal'
import CheckoutAddList from '../../../views/modal/checkoutAddList'
import Warning from '../../../views/modal/warning'
import Button          from '../../../components/button'
import Product         from '../../../components/product'
import ProductCheckout from '../../../components/product/productCheckout'
import ProductLarge from '../../../components/product/productLarge'
import ProductSearchResult from '../../../components/product/ProductSearchResult'
import ListDropDown    from '../../../components/listDropDown'
import SearchContainer from '../../../components/search'
import SearchInput     from '../../../components/search/searchInput'
import ProductSpinner     from '../../../components/product-spinner'
import { trackGAEvent, getProductQty } from '../../../utils'
import * as Ps from 'perfect-scrollbar';
import DeliveryWarning from './deliveryWarning'
import AddonProductsContainer from './addonProducts';


class ProductsView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedListIndex: -1,
            productsToAdd: [],
            fetchedAddonProducts: false,
            searchVal: "",
        };
        this.timer = null
    }

    componentDidMount() {
        // Breaking, removing for now
        // Ps.initialize(findDOMNode(this.refs.orderSuggestions));
        // $(window).scrollTop(0)
    }


    confirmModal(products) {
        let modalProps = {
          title: null,
          body: CheckoutAddList,
          bodyProps: {
            products: products,
            dispatch: this.props.dispatch,
          }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    onListItemSelect(list_index){
        //If the selected list is an old sale then fetch the products from that sale
        if(this.props.lists[list_index].sale_id){
            this.props.dispatch(addSaleProducts(this.props.lists[list_index].sale_id)).then(() => {
                var products = this.props.cart.sale_products
                if(!products){
                    products = []
                }
                this.confirmModal(products)
            })

            return true
        }

        // if the cart is empty, just fill it with the list products
        if(Object.keys(this.props.cart.products).length === 0) {
            this.props.dispatch(addProducts(this.props.lists[list_index].products, false))
            return true
        }
        // else, let user choose, replace or add to products in cart
        return this.confirmModal(this.props.lists[list_index].products)
    }

    searchChange(e) {
        this.setState({searchVal: e.target.value})
    }

    clearSearch() {
        this.props.dispatch(clearSearchProducts())
    }

    addProductFromSearch(product) {
        var qty = getProductQty(product, 1, true)
        this.props.dispatch(addProduct(product, qty))
    }

    render() {
        let numProducts = Object.keys(this.props.cart.products).length
        return (
            <div className="row">
                <div className="col-12 col-xxl-9 order-container">
                    <div className="checkout-header">
                        <div className="header-element">
                            <h2>Pöntun</h2>
                            <input type="text" className="product-search mobile" onChange={this.searchChange.bind(this)} value={this.state.searchVal} placeholder="Sía eftir heiti" />
                        </div>
                        <div className="header-element left-align-items">
                            <div className="older-orders-container" style={{display: 'none'}}><div><b>Eldri pöntun:</b> (24. apríl 2016)<i className="fa fa-caret-down"></i></div></div>
                            <div className="add-products row">
                                <ListDropDown lists={this.props.lists} selectedIndex={this.state.selectedListIndex} onListItemSelect={this.onListItemSelect.bind(this)} newListButton={true} textValue={'Sækja vörur úr lista'} classNames={'btn checkout btn-success btn-products-list'} />
                            </div>
                        </div>
                        <div className="header-element">
                            <div className="checkout-state-container">
                                <div className="checkout-state-header">
                                    <div className="active">Vörur</div><div>Afhending</div><div>Yfirferð</div>
                                </div>
                                <div className="checkout-state-loc">
                                    <div className="line first" /><div className="line second" />
                                    <div className="loc green"></div><div className="loc"></div><div className="loc"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="table-checkout">
                        { ((this.props.user.independentUser)||(this.props.user.activeCompany && this.props.user.activeCompany.independent_company)) ?
                            <caption>*Öll verð eru með vsk</caption>
                        :
                            <caption>*Öll verð eru án vsk</caption>
                        }
                        
                        
                        <thead>
                            <tr>
                                <th>Mynd</th>
                                <th>
                                    <div className="name-inner">
                                        <div className="name">Heiti</div>
                                        <div className="search"><input type="text" className="product-search" onChange={this.searchChange.bind(this)} value={this.state.searchVal} placeholder="Sía eftir heiti" /></div>
                                    </div>
                                </th>
                                <th>Vörunúmer</th>
                                <th className="d-none d-lg-table-cell">Magn í Pakkningu</th>
                                <th className="p0 text-center">Pakkning</th>
                                <th className="p0 text-center">Magn</th>
                                <th className="d-none d-lg-table-cell">Eining</th>
                                <th className="p0 text-right">Verð*</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.cart.hasItemWeightProducts && ((this.props.user.independentUser)||(this.props.user.activeCompany && this.props.user.activeCompany.independent_company)) &&
                                <tr><td colSpan="9">
                                    <div className="col-12">
                                        <div className="alert alert-info p-3">
                                        Þú ert með vigtarvöru í körfunni og mun endanlegt verð breytast eftir að vara hefur verið tekin til og vigtuð. Hér er áætlað verð á vörunni.
                                        </div>
                                    </div>
                                </td></tr>
                            }
                            { this.props.checkout.deliveryData.deliveryMode !== "pickup" && this.props.user.activeCompany.delivery_mode && this.props.user.activeCompany.delivery_mode.delivery_mode_rule && this.props.user.activeCompany.delivery_mode && !this.props.user.activeCompany.delivery_mode.delivery_mode_rule.is_free && this.props.cart.total < this.props.user.activeCompany.delivery_mode.delivery_mode_rule.minimum_amount &&
                                <React.Fragment>
                                { false && (this.props.user.activeCompany.delivery_mode.code === "HEIMSENT" && this.props.cart.products.filter(p => p.product.item_id === this.props.user.activeCompany.delivery_mode.delivery_mode_rule.delivery_fee_item_id).length === 0) &&
                                    <tr><td colSpan="9">
                                        <div className="col-12">
                                            <div className="alert alert-success extra-transfer-cost xs-pt-20 xs-pb-0" dangerouslySetInnerHTML={{__html: this.props.user.activeCompany.delivery_mode.delivery_mode_rule.transport_fee_text}} />
                                        </div>
                                    </td></tr>
                                }
                                </React.Fragment>
                            }
                            <tr><td/><td/><td/><td/><td/><td/><td/><td/><td/></tr>
                            { this.props.cart.products && Object.keys(this.props.cart.products).map((key, index) =>
                                <Product
                                    dispatch={this.props.dispatch}
                                    key={'desktop-product-'+index}
                                    product={this.props.cart.products[key]}
                                    viewName={ProductCheckout}
                                    searchVal={this.state.searchVal}
                                    user={this.props.user}
                                />
                            )}
                        </tbody>
                    </table>
                    <div className="search-container add-products row">
                        <div className="row search">
                            <div className="container">
                                <SearchContainer q={this.props.q} showResults={false} skipRedirect={true} placeholder={'Hér getur þú bætt við pöntunina'}/>
                            </div>
                        </div>
                        <div className="row products">
                            <div className="container">
                                <div ref="searchedProducts" id="searchedProducts" className={`row desktop-products searched-products`}>
                                    <table className="table-searched-products table-striped">
                                        <tbody>
                                        { this.props.search_products.search_products.length > 0 && this.props.search_products.search_products.map((el) =>
                                            <Product
                                                dispatch={this.props.dispatch}
                                                key={`product-tiny-${el.item_id}`}
                                                product={el}
                                                viewName={ProductSearchResult}
                                                user={this.props.user}
                                                onClick={this.addProductFromSearch.bind(this)}
                                                showPrice={this.props.user.isAuthenticated}
                                                cartProduct={this.props.cart.products.find((p) => p.product.item_id === el.item_id)}
                                            />
                                        )}
                                        </tbody>
                                    </table>
                                    {this.props.search_products.isFetching &&
                                        <div className="search-fetching-container">
                                            <ProductSpinner />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <hr/>
                    <div className="total-container row">
                        <div className="total">Samtals{(this.props.user.activeCompany && this.props.user.activeCompany.vsk_active && ((this.props.user.independentUser)||(this.props.user.activeCompany && this.props.user.activeCompany.independent_company))) ? ' m/vsk' : ' án vsk'}: <span className="total-text">{NumToIsk(this.props.cart.total)} kr.</span></div>
                    </div>
                </div>

                <AddonProductsContainer />
                
                <h3 className="col-12 mobile-search-heading">Sækja vörur úr lista</h3>
                <div className="col-12 mobile-search-products">
                    <ListDropDown
                        lists={this.props.lists}
                        selectedIndex={this.state.selectedListIndex}
                        onListItemSelect={this.onListItemSelect.bind(this)}
                        textValue={'Vörur úr lista'}
                        classNames={'btn checkout btn-products-list'}
                    />
                    
                </div>
            </div>
        )
    }
}


export default ProductsView;
