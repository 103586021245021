import { GetSessionStorageState, StateToSessionStorage } from '../../storages/session-storage'

import {
    CHECKOUT_NEXT_STEP,
    CHECKOUT_PREVIOUS_STEP,
    REQUEST_SUBMIT_ORDER,
    SUBMIT_ORDER_SUCCESS,
    SUBMIT_ORDER_ERROR,
    SUBMIT_ORDER_TO_VALITOR,
    SET_DELIVERY_DATA,
    RECEIVE_PURCHASED_ORDER_SUCCESS,
    RECEIVE_PURCHASED_ORDER_ERROR,
    DISMISS_SALES_ERROR,
    CHECKOUT_SET_STEP,
} from './actions'


const initialState = {
    step: 1,
    deliveryData: {},
    submitting_order: false,
    submitted_order: null,
    order_error: null,
    form_data: null,
    products: []
}

export function checkout(state = initialState, action) {
    
    switch(action.type) {
        case CHECKOUT_NEXT_STEP:
            state = {...state,
                step: Math.min(3, state.step + 1)
            }
            return state
        case CHECKOUT_PREVIOUS_STEP:
            state = {...state,
                step: Math.max(1, state.step - 1)
            }
            return state
        case CHECKOUT_SET_STEP:
            state = {...state,
                step: action.step
            }
            return state
        case SET_DELIVERY_DATA:
            state = {...state}
            for(var n in action.data){
                state.deliveryData[n] = action.data[n]
            }
            return state
        case REQUEST_SUBMIT_ORDER:
            state =  {...state,
                submitting_order: true
            }
            return state
        case SUBMIT_ORDER_SUCCESS:
            state = {...state,
                submitting_order: false,
                submitted_order: JSON.parse(action.data),
                order_error: null
            }
            return state
        case SUBMIT_ORDER_ERROR:
            state = {...state,
                step: 1,
                submitting_order: false,
                order_error: JSON.parse(action.data),
                submitted_order: null
            }
            return state
        case SUBMIT_ORDER_TO_VALITOR:
            state = {...state,
                step: 307,
                submitted_order: null,
                form_data: action.data.form,
            }
            return state
        case RECEIVE_PURCHASED_ORDER_SUCCESS:
            state = {...state,
                products: action.data.results
            }
            return state
        
        case DISMISS_SALES_ERROR:
            state = {
                ...state,
                order_error: null
            }
            return state
        default:
            return state
    }
}

