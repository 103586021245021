import fetch from 'isomorphic-fetch'
import jwtDecode from 'jwt-decode';
import qs from 'qs'
import TokenStorage from '../../storages/token-storage'

import { checkHttpStatus,
    handleNetworkError,
    parseJSON,
    getStore,
} from '../../utils'

export const REQUEST_FETCH_CLAIMS = 'REQUEST_FETCH_CLAIMS'
export const RECEIVE_CLAIMS_SUCCESS = 'RECEIVE_CLAIMS_SUCCESS'
export const RECEIVE_CLAIMS_ERROR = 'RECEIVE_CLAIMS_ERROR'

const requestFetchClaims = () => {
    return {
        type: REQUEST_FETCH_CLAIMS
    }
}

const receiveClaimsSuccess = (response) => {
    return {
        type: RECEIVE_CLAIMS_SUCCESS,
        data: response
    }
}

const receiveClaimsError = (response) => {
    return {
        type: RECEIVE_CLAIMS_ERROR
    }
}

export const fetchClaims = (data) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        dispatch(requestFetchClaims());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/claims/?${qs.stringify(data)}`, {
            method: 'GET',
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveClaimsSuccess(response));
            } catch (e) {
                dispatch(receiveClaimsError(response))
            }


        })
        .catch(handleNetworkError);
    };
}
