import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { addProducts } from '../../api/cart/actions'

class CheckoutAddList extends React.Component {

  componentDidMount(){
  }

  addProducts(products, replaceProducts=false) {
      const {dispatch} = this.props
      if(this.props.redirect){
        Promise.resolve(dispatch(addProducts(products, replaceProducts)))
        .then(() => {
            window.location.href = window.garriConfig.orderUrl
        })
      }else{
        dispatch(addProducts(products, replaceProducts))
      }
      this.props.close()
  }

  render(){
    let {products} = this.props

    return (
      <div className="checkout-add-list-confirmation">
          {products.length === 0 &&
            <h2>Engar vörur fundust</h2>
          }
          {products.length > 0 &&
            <div>
              <h2>Vörur í körfu</h2>
              <p>Þú ert nú þegar með vörur í körfunni.</p>
              <p>Viltu bæta við vörum eða skipta út?</p>
              <div className="buttons row">
                <div className="col-6">
                  <button className="btn btn-default change-products" type="button" onClick={this.addProducts.bind(this, products, true)}>Skipta út</button>
                </div>
                <div className="col-6">
                  <button className="btn btn-success add-products" type="button" onClick={this.addProducts.bind(this, products, false)}>Bæta við</button>
                </div>
              </div>
            </div>
          }
      </div>
    )
  }
}

module.exports = CheckoutAddList;