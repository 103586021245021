import React from 'react';
import { connect } from 'react-redux'

class HeadingImage extends React.Component {
    getBackgroundImage() {
        if((this.props.titleRichText !== '' || this.props.headerLink != '') && !this.props.isHomepage){
            return this.props.specialOfferImage
        } else {
            return this.props.image
        }
    }

    render() {
        return (
                
            <div className={`heading-image ${(this.props.isHomepage == true || this.props.headerLink != '')?"":"small"}`} 
            style={{backgroundImage: `url('${this.getBackgroundImage()}')`}}>
                <div className="overlay"></div>
                <div className="overlay-text container oc-container">
                {this.props.titleRichText !== "" ?
                    <div className="promotion" dangerouslySetInnerHTML={{__html: this.props.titleRichText}} />
                    :
                    <div className="">
                        <h1>{this.props.title}</h1>
                    </div>
                }
                {this.props.headerLink != '' &&
                    <a className="btn btn-xlg btn-success" href={this.props.headerLink}>{this.props.headerLinkText}<i className="arrow-long xs-ml-10"></i></a>
                }                    
                </div>
            </div>
        )
    }
}

HeadingImage.defaultProps = {
    backgroundImage: "",
    title: "",
    titleRichText: "",
    isHomepage: false,
    headerLinkText: "Vefverslun",
}

function mapStateToProps(state) {
    var f = {
        user: {},
    }
    f.user = (state.user === undefined) ? {} : state.user
    return f;
}

export default connect(mapStateToProps)(HeadingImage);


