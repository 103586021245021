import React from 'react';
import Cookies from 'js-cookie'

class Badge extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
          showingTooltip: false
        }

        this.toolTipVisibility = this.toolTipVisibility.bind(this)
    }

    toolTipVisibility(value, setCookie=false){
      let cookieName = "expired-" + this.props.product.item_id
      this.setState({ showingTooltip: value })
      if(setCookie) {
        Cookies.set(cookieName, true, { expires: 7 })
      }
    }

    componentDidMount() {
      let cookieName = "expired-" + this.props.product.item_id
      let cookieSet = Cookies.get(cookieName)
      if (!cookieSet && this.props.initialVisible) {
        this.setState({showingTooltip: true})
      }
    }

    toolTipVisibilityIfCookieSet(value){
      let cookieName = "expired-" + this.props.product.item_id
      let cookieSet = Cookies.get(cookieName)
      if(cookieSet) {
        this.setState({ showingTooltip: value })
      }
    }


    render() {
        const {product} = this.props

        if (product.promotion_expired_product){
          return (
            <div className={`${this.state.showingTooltip ? "open" : ""} badge`}  >
                <img className="expired-img" alt="Útrunninn" src={window.garriConfig.icons.productExpiredBadge} width="20"  onMouseLeave={() => {this.toolTipVisibilityIfCookieSet(false)}} onMouseEnter={() => this.toolTipVisibility(true)} onClick={() => this.toolTipVisibility(true)} />
                { this.state.showingTooltip &&
                  <div className="rectangle" onMouseLeave={() => {this.toolTipVisibilityIfCookieSet(false)}}>
                    <img className="expired-img-close" alt="Útrunninn" src={window.garriConfig.icons.productExpiredClose} width="20" onClick={() => this.toolTipVisibility(false, true)} />
                    <p>
                      { window.garriConfig.expiredProductText }
                    </p>
                    { product.expiration_description &&
                      <p>
                        { product.expiration_description }
                      </p>
                    }
                  </div>
                }
            </div>
        )
        } else {
          return <div></div>
        }
    }
}

Badge.defaultProps = {
  initialVisible: true
}

export default Badge;