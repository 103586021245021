import React from 'react';
import ReactDOM from 'react-dom'
import Modal from '../../views/modal'
import ProductToList from '../../views/modal/productToList'


class Favorite extends React.Component {

    constructor(props) {
        super(props)
    }

    addToFavorites(e){
        let modalProps = {
          title: null,
          body: ProductToList,
          bodyProps: {
            dispatch: this.props.dispatch,
            product: this.props.product
          }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }


    render() {
        if (this.props.enabled) {
            return (
                <div className="product-favorite" onClick={this.addToFavorites.bind(this)}>
                    <img src={window.garriConfig.icons.favorite} width="20" className="blank favorite-img" alt="Bæta í uppháhalds" />
                    <img src={window.garriConfig.icons.favoriteFill} width="20" className="fill favorite-img" alt="Bæta í uppháhalds" />
                </div>
            )
        } else {
            return null
        }
    }
}

Favorite.defaultProps = {
    enabled: false
}

export default Favorite;