import React from 'react'
import { connect } from 'react-redux'

class UserNew extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      username: '',
      first_name: '',
      last_name: ''
    }
  }

  componentDidMount(){
  }

  submit() {
    if(this.state.email !== this.state.email_confirm){
      return this.setState({email_error: "Staðfesting netfangs passar ekki við netfang"})
    }
    this.props.submit(this.state)
  }

  change(e) {
    var newState = {}
    newState[e.target.name] = e.target.value
    if(e.target.name === 'email'){
      newState.username = e.target.value
    }
    this.setState(newState)
  }

  render(){
    return (
      <div className="user-form-container">
        <h2>Nýr notandi</h2>
        <div className="form-group">
          <label htmlFor="user_first_name">Fornafn</label>
          <input className="form-control" onChange={this.change.bind(this)} type="text" name="first_name" id="user_first_name" />
        </div>
        <div className="form-group">
          <label htmlFor="user_last_name">Eftirnafn</label>
          <input className="form-control" onChange={this.change.bind(this)} type="text" name="last_name" id="user_last_name" />
        </div>
        <div className='form-group'>
          <label htmlFor="user_email">Netfang</label>
          <input className='form-control' onChange={this.change.bind(this)} type="text" name="email" id="user_email" />
        </div>
        <div className={`form-group ${this.state.email_error ? 'has-danger' : ''}`}>
          <label htmlFor="user_email_confirm">Netfang endurtekið</label>
          <input className={`form-control ${this.state.email_error ? 'form-control-danger' : ''}`} onChange={this.change.bind(this)} type="text" name="email_confirm" id="user_email_confirm" />
          <small className="form-control-feedback">{this.state.email_error}</small>
        </div>
        <button onClick={this.submit.bind(this)} className="btn btn-success btn-submit">Búa Til</button>
      </div>
    )
  }
}

module.exports = UserNew;