import React from 'react'
import { connect } from 'react-redux'
import { updateUser, createUser, fetchUsers } from '../../api/users/actions'

class User extends React.Component {

  componentDidMount(){
  }

  submit(user) {
    var userFunction = user.id ? updateUser : createUser

    this.props.dispatch(userFunction(user)).then(() => {
      if(typeof this.props.close === 'function'){
        this.props.close()
      }
      this.props.dispatch(fetchUsers())
    })
  }

  render(){
    return (
      <div className="user-container">
        <this.props.viewName
          submit={this.submit.bind(this)}
          {...this.props}
        />
      </div>
    )
  }
}

module.exports = User;