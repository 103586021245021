import React from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { NumToIsk } from '../../../utils'
import SettingsBox from '../../../components/settingsBox'
import Button from '../../../components/button'
import Product from '../../../components/product'
import ProductCheckoutConfirm from '../../../components/product/productCheckoutConfirm'
import moment from 'moment'
import DeliveryWarning from './deliveryWarning'

class ValitorConfirm extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        $(window).scrollTop(0)
        $("#valitor-checkout-step form").submit();
    }

    render() {
        const { form_data, submitting_order } = this.props.checkout;
        return (
          <div className="row confirm-view">
              <div className="col-12" id="valitor-checkout-step">
                <div style={{display: 'none'}} dangerouslySetInnerHTML={{__html: form_data }} />
              </div>
              {submitting_order &&
                  <div className="submitting-order-container">
                      <h2>Sendi pöntun</h2>
                      <i className="delivering"></i>
                      <p>Ekki slökkva á vafranum fyrr en þessi mynd er horfin af skjánum.</p>
                  </div>
              }
          </div>
        )
    }
}

export default ValitorConfirm;
