import React from 'react';


class PriceHeader extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        const { product } = this.props
        let independentUser = this.props.user.independentUser||(this.props.user.activeCompany && this.props.user.activeCompany.independent_company);
        if (!this.props.deleted) {
            if (independentUser) {
                return (
                    <div className="priceHeader flex-row">
                        {(product && !!product.item_weight) ?
                            <div className='pricetitle'>Áætlað verð {this.props.user.activeCompany && this.props.user.activeCompany.vsk_active ? "m/vsk" : ""}</div>
                        :
                            <div className='pricetitle'>Verð {this.props.user.activeCompany && this.props.user.activeCompany.vsk_active ? "m/vsk" : ""}</div>
                        }
                    </div>
                )
            }
            return (
                <div className="priceHeader flex-row">
                    {!this.props.offer &&
                        // <React.Fragment>
                        //     {(product && !!product.item_weight) ?
                        //         <div className='pricetitle'>Áætlað verð</div>
                        //     :
                                <div className='pricetitle'>Verð</div>
                        //     }
                        // </React.Fragment>
                    }
                    <div className={`pricetitle ${this.props.offer ? 'red':''}`}>
                        {this.props.offer &&
                            'Tilboðsverð'
                        }
                        {!this.props.offer &&
                            'Mitt verð'
                        }
                    </div>
                </div>
            )
        }
        return null
    }
}

export default PriceHeader;

