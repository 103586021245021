import fetch from 'isomorphic-fetch'
import Cookie from 'js-cookie'
import jwtDecode from 'jwt-decode';
import TokenStorage from '../../storages/token-storage'

import { checkHttpStatus,
    handleNetworkError,
    parseJSON,
    getStore,
    hasActiveCompany, 
} from '../../utils'

import { userRefreshLogin } from '../user/actions'


export const REQUEST_FETCH_SALES = 'REQUEST_FETCH_SALES'
export const RECEIVE_SALES_SUCCESS = 'RECEIVE_SALES_SUCCESS'
export const RECEIVE_SALES_ERROR = 'RECEIVE_SALES_ERROR'
export const REQUEST_DETAILED_SALE = 'REQUEST_DETAILED_SALE'
export const RECEIVE_DETAILED_SALE_SUCCESS = 'RECEIVE_DETAILED_SALE_SUCCESS'
export const RECEIVE_DETAILED_SALE_ERROR = 'RECEIVE_DETAILED_SALE_ERROR'
export const REQUEST_DETAILED_VALITOR_SALE = 'REQUEST_DETAILED_VALITOR_SALE'
export const RECEIVE_DETAILED_VALITOR_SALE_SUCCESS = 'RECEIVE_DETAILED_VALITOR_SALE_SUCCESS'
export const RECEIVE_DETAILED_VALITOR_SALE_ERROR = 'RECEIVE_DETAILED_VALITOR_SALE_ERROR'
export const REMOVE_COMPLETE_SALE = 'REMOVE_COMPLETE_SALE'

const requestFetchSales = () => {
    return {
        type: REQUEST_FETCH_SALES
    }
}

const receiveSalesSuccess = (data) => {
    return {
        type: RECEIVE_SALES_SUCCESS,
        data: data
    };
}

const receiveSalesError = (data) => {
    return {
        type: RECEIVE_SALES_ERROR,
        data: data
    };
}

const requestDetailedSale = (id) => {
    return {
        type: REQUEST_DETAILED_SALE,
        sale_id: id
    }
}

const receiveDetailedSaleSuccess = (data, sale_id) => {
    return {
        type: RECEIVE_DETAILED_SALE_SUCCESS,
        data: data,
        sale_id: sale_id
    };
}

const receiveDetailedSaleError = (data) => {
    return {
        type: RECEIVE_DETAILED_VALITOR_SALE_ERROR,
        data: data
    };
}

const requestDetailedValitorSale = (id) => {
    return {
        type: REQUEST_DETAILED_VALITOR_SALE,
        sale_id: id
    }
}

const receiveDetailedValitorSaleSuccess = (data, sale_id) => {
    return {
        type: RECEIVE_DETAILED_VALITOR_SALE_SUCCESS,
        data: data,
        sale_id: sale_id
    };
}

const receiveDetailedValitorSaleError = (data) => {
    return {
        type: RECEIVE_DETAILED_VALITOR_SALE_ERROR,
        data: data
    };
}

export const removeCompleteSale = (sale_id) => {
    return {
        type: REMOVE_COMPLETE_SALE,
        sale_id: sale_id
    }
}

export const getCompleteSale = (sale_id) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        dispatch(requestDetailedSale(sale_id));

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/sales/?sale_id=${sale_id}`, {
            method: 'GET',
            headers: headers
        })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receiveDetailedSaleSuccess(response, sale_id));
            } catch (e) {
                dispatch(receiveDetailedSaleError(response))
            }
        })
        .catch(handleNetworkError);
    };
}

export const getCompleteValitorSale = (sale_id) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        dispatch(requestDetailedValitorSale(sale_id));

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/sales/?valitor_sale_id=${sale_id}`, {
            method: 'GET',
            headers: headers
        })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receiveDetailedValitorSaleSuccess(response, sale_id));
            } catch (e) {
                dispatch(receiveDetailedValitorSaleError(response))
            }
        })
        .catch(handleNetworkError);
    };
}

export const fetchSales = (idx=null) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");
        
        dispatch(requestFetchSales());

        let parameters = '?limit=100';
        if (idx!==null) {
            parameters += `&offset=${100*idx}`
        }

        let state = getStore().getState()
        if (hasActiveCompany(state)) {
            parameters += `&company=${state.user.activeCompany.account_id}`
        }


        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/sales/${parameters}`, {
            method: 'GET',
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveSalesSuccess(response));
            } catch (e) {
                dispatch(receiveSalesError(response))
            }


        })
        .catch(handleNetworkError);
    };
}