import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { addProduct, removeProduct } from '../../api/cart/actions'
import { createList, fetchLists } from '../../api/lists/actions'
import Product from '../../components/product';
import ProductSmallReplacementModal from '../../components/product/productSmallReplacementModal';
import ProductsGrid from '../../views/productsGrid/productsGrid';
import { store } from '../../store'

class ReplacementProducts extends React.Component {

  replace(toReplace, replacement, quantity = 1) {
    Promise.resolve(this.props.dispatch(addProduct(replacement, quantity)))
      .then(() => {
        Promise.resolve(this.props.dispatch(removeProduct(toReplace.item_id)))
          .then(() => {
            this.props.close()
          })
      })
  }

  replaceProductInList(replacementProduct) {
    let list = [];

    if (this.props.productList) {
      list = this.props.list;
    } else {
      this.props.lists.map((listItem) => {
        if (listItem.active) {
          list.push(listItem)
        }
      })
      list = list[0];
    }
    list.show_steps = list.products.length === 0

    // add the replacement product to the list
    var exists_in_list = list.products.find((prod_el) => prod_el.product.item_id === replacementProduct.item_id)
    if (exists_in_list) {
      return
    }
    list.products.push({ product: replacementProduct, quantity: 1 })

    // remove the old product from the list
    var products = list.products.filter((product) => {
      return product.product.item_id !== this.props.toReplace.item_id
    })

    var updateList = true
    // add and remove product, fetch list again and close modal
    Promise.resolve(this.props.dispatch(createList(list.name, products, updateList, list.id, list.show_steps)))
      .then(() => {
        this.props.dispatch(fetchLists())
      })
      .then(() => {
        this.props.close()
      })
  }

  render() {
    let { products } = this.props

    return (
      <div className="text-center replacement-products">
        <h2>{`Þetta eru vörur sem gætu hentað í stað þeirrar sem er ekki til`}</h2>
        <ProductsGrid
          productIds={products.join(",")}
          store={store}
          productsGridNamespace="replacementModal"
          productsGridView={ProductSmallReplacementModal}
          showZeroQty={false}
          productCallbackFn={this.props.replacingProductInList ? this.replaceProductInList.bind(this) : this.replace.bind(this, this.props.toReplace)}
        />
      </div>
    )
  }
}

module.exports = ReplacementProducts;