import React from 'react';

class EnvironmentIcon extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        if (this.props.product && this.props.product.environmental_certification && this.props.product.environmental_certification.environmental_certification !== '')  {
            return (
                <div className="environment-icon" >
                    <img title={`Varan er umhverfisvottuð ${this.props.product.environmental_certification_display}`} className="environment-img" alt={`Varan er umhverfisvottuð ${this.props.product.environmental_certification_display}`} src={window.garriConfig.icons[this.props.product.environmental_certification]}  />
                </div>
            )
        } else {
            return null
        }
    }
}

EnvironmentIcon.defaultProps = {
    product: {
        environmental_certification: ''
    }
}

export default EnvironmentIcon;