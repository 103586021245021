import React from 'react'
import ReactDOM from 'react-dom'
import Userbar from './userbar'
import UserbarMobile from './userbarMobile'
import { store } from '../../store'

$.fn.userbar = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(Userbar, options), this);
  });
};

$.fn.userbarMobile = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(UserbarMobile, options), this);
  });
};

/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-userbar]').userbar();
  $('[data-userbar-mobile]').userbarMobile();
});