import { userSerializer } from './serializers'

import {
    REQUEST_USERS,
    RECEIVE_USERS,
    REQUEST_CREATE_USER,
    CREATE_USER_SUCCESS,
} from './actions'

import { 
    AUTH_LOGOUT,
    AUTH_LOGIN_SUCCESS,
} from '../user/actions'

const _emptyState = {
    isFetching: false,
    users: [],
}

export function users(state = _emptyState, action) {
    switch(action.type) {
        case REQUEST_USERS:
            state = {...state,
                isFetching: true,
            }
            return state
        case CREATE_USER_SUCCESS:
            state.users = state.users.map((user) => {
                if(user.id === action.data.id){
                    user = userSerializer(action.data)
                    user.id = action.data.id
                }
                return user
            })
            return state
        case RECEIVE_USERS:
            state = {...state,
                isFetching: false,
                users: action.data.results,
            }
            return state
        case AUTH_LOGOUT:
            state = _emptyState;
            return state;
        default:
            return state
    }

}