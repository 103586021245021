import React from 'react'
import { connectWithStore } from '../../utils'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { createList, createPromotionList, fetchLists } from '../../api/lists/actions'
import { store } from '../../store'


class CreateNewList extends React.Component {

  componentDidMount(){
  }

  createList() {
    if($(this.refs.nameValue)[0].checkValidity()){
      var is_promotion_list = $(this.refs.is_promotion_list).is(":checked")
      var createListFunction = createList
      if(is_promotion_list){
        createListFunction = createPromotionList
      }
      Promise.resolve(this.props.dispatch(createListFunction(this.refs.nameValue.value, this.props.products)))
      .then(() => {
        Promise.resolve(this.props.dispatch(fetchLists()))
        .then(() => {
          if(typeof this.props.callBack === 'function'){
            this.props.callBack()
          }
          setTimeout(this.props.close, 400)
        })
      })
    }
  }

  render(){
    return (
      <div className="manage-list-container">
        <h2>Hvað á listinn að heita?</h2>
        <p>Dæmi: Jólalistinn, Sumarvörur, Brunch seðill.</p>
        <div className="new-list-name">
          <input type="text" placeholder="Sláðu inn heiti" ref="nameValue" required="required"/>
          {this.props.user.is_superuser &&
            <div className="check-container">
              <input type="checkbox" ref="is_promotion_list" id="is_promotion_list" />
              <label htmlFor="is_promotion_list">Kynningarlisti</label>
            </div>
          }
          <button type="button" className="btn btn-success checkout-btn-success" onClick={this.createList.bind(this)}>Búa til lista</button>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  var f = {
    lists: {lists:[]},
    user: {}
  }
  f.lists = (state.lists === undefined) ? {} : {lists: state.lists.lists.filter(f => f!==null).filter((f) => f.id !== undefined)}
  f.user = (state.user === undefined ? {} : state.user)
  return f;
}

export default connectWithStore(store, CreateNewList, mapStateToProps);