import React from 'react';
import SearchInput from './searchInput'

class SearchContainer extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="search-outer-container">
                <div className="search-container">
                    <div className="text-container">
                        <i className="search-icon"></i>
                        { (window.md.tablet() || window.md.phone()) ?
                            <SearchInput searchOnType={false} hideClear={false} q={this.props.q} skipRedirect={this.props.skipRedirect} searchOnLoad={this.props.searchOnLoad} placeholder={this.props.placeholder} modalSearch={this.props.modalSearch}/>
                            :
                            <SearchInput searchOnType={true} hideClear={false} q={this.props.q} skipRedirect={this.props.skipRedirect} searchOnLoad={this.props.searchOnLoad} placeholder={this.props.placeholder} modalSearch={this.props.modalSearch}/>
                        }
                    </div>
                </div>
            </div>
            
        )
    }
}

SearchContainer.defaultProps = {
    q: ""
}


export default SearchContainer;