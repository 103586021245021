import React, { useCallback } from 'react';
// import ReactDOM from 'react-dom'
import Button from '../../components/button'
// import { patchList } from '../../api/lists/actions'
// import { DndProvider, useDrag } from 'react-dnd-cjs'
// import Modal from '../../views/modal'
// import ReplacementProducts from '../../views/modal/replacementProducts'
import DragDropTable from '../../components/dragDropTable';
import { setListOrderPreference } from '../../api/user/actions'
import { fetchLists } from '../../api/lists/actions';
// import { RECEIVE_SHIPPING_SUCCESS } from '../../api/shipping/actions';

class EditListOrder extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            list: [],
            headers: []
        }
    }

    componentDidMount(){
        // this.setState({list: this.props.list})
        let headers = ['Heiti', 'Fjöldi vara']
        this.setState({headers: headers})
    }

    saveListOrder(){
        const { user } = this.props
        if(this.state.list.length){
            let preference = []
            for(var i=0; i < this.state.list.length; i++){
                preference.push(this.state.list[i].id)
            }
            var pref = "[" + preference.toString() + "]"
            Promise.resolve(this.props.dispatch(setListOrderPreference(user, pref)))
            .then(() => {
                this.props.dispatch(fetchLists())
            })
        }
        $('#modal').modal('hide')
    }

    onChangeCallback(list){
        console.log("Callback: ", list)
        this.setState({list: list})
    }


    render() {

        return (<div>
                {
                    <div className="manage-list-container row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <DragDropTable callback={this.onChangeCallback.bind(this)} content={this.props.list} headers={this.state.headers}></DragDropTable>
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <Button classNames={'settings btn-success xs-ml-10'} innerHTML={(
                                <React.Fragment>Vista röðun</React.Fragment>
                            )} handleClick={() => this.saveListOrder()} />
                        </div>
                    </div>
                }
        </div>)
    }
}

module.exports = EditListOrder;
