import {
    REQUEST_FETCH_TRANSACTIONS,
    RECEIVE_TRANSACTIONS_SUCCESS,
    RECEIVE_TRANSACTIONS_ERROR
} from './actions'
import { SET_ACTIVE_COMPANY } from '../companies/actions'
import { NumToIsk } from '../../utils';
import moment from 'moment'

const initialState = {
    transactions: [],
    isFetching: false
}

export function transactions(state = initialState, action) {

    switch(action.type) {
        case REQUEST_FETCH_TRANSACTIONS:
            state = {...initialState,
                isFetching: true
            }
            return state

        case RECEIVE_TRANSACTIONS_SUCCESS:
            var data = action.data.map((el) => {
                el.debet = ``
                el.kredit = ``
                el.balance = `${NumToIsk(el.balance)},00`
                el.transDateOriginal = el.transDate
                el.transDate = moment(el.transDate).format('D.M.YYYY')
                el.dueDate = moment(el.dueDate).format('D.M.YYYY')
                el.descriptionDisplay = el.description.replace("0000000000", "")

                if(el.amountCur < 0){
                    el.kredit = `${NumToIsk(el.amountCur)},00`
                }
                if(el.amountCur >= 0){
                    el.debet = `${NumToIsk(el.amountCur)},00`
                }
                return el
            }).reverse()
            
            // .sort((a,b) => {
            //     var _a = moment(a.transDate, 'D.M.YYYY')
            //     var _b = moment(b.transDate, 'D.M.YYYY')
            //     if(_a > _b){
            //         return -1
            //     }
            //     if(_a < _b){
            //         return 1
            //     }
            //     return 0
            // })
            state = {...state,
                transactions: data,
                isFetching: false
            }
            return state
        
        case SET_ACTIVE_COMPANY:
            state = {
                ...state,
                transactions: [],
                isFetching: false
            }

            return state

        case RECEIVE_TRANSACTIONS_ERROR:
            state = {...state,
                transactions: [],
                isFetching: false
            }
            return state

        default:
            return state
    }
}
