import React from 'react'
import ReactDOM from 'react-dom'
import SingleProduct from './singleProduct'
import { store } from '../../store'

$.fn.singleProduct = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(SingleProduct, options), this);
  });
};

/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-product-large]').singleProduct();
});