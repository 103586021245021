import React from 'react';
import moment from 'moment';
import { fetchInvoiceAsPDF } from '../../api/invoices/actions'
import { b64toUrl, Spinner } from '../../utils'

class Invoices extends React.Component {

    constructor(props) {
        super(props)
    }

    getPDF(invoice){
        window.location = `/api/v1/invoice-pdf/?invoice_id=${invoice.invoiceId}&invoice_date=${invoice.invoiceDate}`
    }


    render() {

        let isFetching = this.props.invoices.isFetching

        let invoices = this.props.invoices.invoices
        // let invoices = this.props.invoices.invoices.map((invoice, idx) => {
        //     let sale = this.props.sales.sales.find(s => s.sale_id == invoice.salesId)
        //     if (sale) {
        //         invoice.saleStatus = sale.status
        //     }
        //     return invoice
        // })

        

        return (
            <div>
                <h2>Síðustu reikningar</h2>
                <div className="table-responsive">
                    { isFetching ?
                        <div className="spinner-container text-center">
                            <Spinner />
                        </div>
                        :
                        <table className="table settings-table ">
                            <thead>
                                <tr>
                                    <th>Dagsetning reiknings</th>
                                    <th className="d-none d-md-table-cell">Gjalddagi</th>
                                    <th>Númer reiknings</th>
                                    <th className="d-none d-md-table-cell">Númer pöntunar</th>
                                    <th>PDF</th>
                                    <th />
                                </tr>
                            </thead>
                            

                            <tbody>
                                { invoices && invoices.map((invoice, idx) =>
                                    <tr key={`invoice-table-row-${invoice.invoiceId}`} className={idx % 2 === 0 ? 'odd' : 'even'}>
                                        <td>{moment(invoice.invoiceDate).format('DD.MM.YYYY')}</td>
                                        <td className="d-none d-md-table-cell">{moment(invoice.dueDate).format('DD.MM.YYYY')}</td>
                                        <td>{invoice.invoiceId}</td>
                                        <td className="d-none d-md-table-cell">{invoice.salesId}</td>
                                        
                                        
                                        { invoice.status && invoice.status === 3 ?
                                        <td className="pdf-container" onClick={this.getPDF.bind(this, invoice)}>
                                            <i className="fa fa-file-pdf-o"></i>
                                        </td>
                                        :
                                        <td />
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        )
    }
}


export default Invoices;