import Big from 'big.js';
import React from 'react';
import { trackGAEvent } from '../../utils/';

class QuantityGroup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            quantity: this.props.quantity
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.quantity !== this.state.quantity){
            this.setState({quantity: nextProps.quantity})
        }
    }

    validateQty(qty){
        if(qty.length > 1 && (`${qty[0]}` === '0' || `${qty[0]}` === '-')){
            qty = qty.substring(1, qty.length)
        }
        if(qty === ''){
            qty = 0
        }
        return qty
    }

    increaseQuantity() {
        let qty = 0
        let increase = 1
        if (this.props.product.item_weight > 0.01) {
            increase = this.props.product.item_weight
        }
        if (this.state.quantity === "-") {
            qty = increase
        } else {
            qty = parseFloat(Big(this.state.quantity).plus(increase).toFixed(2))
        }
        this.props.setQuantity(qty, true)
        trackGAEvent('add_to_cart', {})
    }

    decreaseQuantity() {
        let qty = 0
        let decrease = 1
        if (this.props.product.item_weight) {
            decrease = this.props.product.item_weight
        }

        if (this.state.quantity === "-") {
            qty = 0
        } else {
            qty = parseFloat(Big(this.state.quantity).minus(decrease).toFixed(2))
        }

        if (qty < 0) {
            qty = 0;
        }
        this.props.setQuantity(qty, false)
        trackGAEvent('remove_from_cart', {})
    }

    setQuantity() {
        var qty = this.validateQty(this.state.quantity)

        if((`${qty}`).match(/^\d+$/)){
            this.props.setQuantity(`${qty}`, true)
        }
    }

    inputChange(e){
        var qty = this.validateQty(e.target.value)

        if((`${qty}`).match(/^\d+(\.)?(\d+)?$/)){
            this.setState({quantity: qty})
        }
    }

    listenClick(){
        if(this.props.selectMode && !this.props.isSelected){
            this.props.setSelectModeMessage()
        }
    }

    componentWillUpdate(nextProps, nextState){
        if(this.state.quantity !== nextState.quantity){
            if(this.props.selectMode && this.props.isSelected){
                this.props.updateSelectedQuantity(nextState.quantity)
            }
        }
    }


    render() {
        if((this.props.product.avail_physical < 1 && !this.props.product.ignore_stock) || (this.props.product.deleted|| !this.props.product.display_on_web) || this.props.product.is_shipping_fee){
            return <div className="input-group group-mobile"/>
        }
        var disabled = this.props.selectMode && !this.props.isSelected
        return (
            <React.Fragment>
                <div className="input-group group-mobile" onClick={this.listenClick.bind(this)}>
                    <button tabIndex={-1} onClick={this.decreaseQuantity.bind(this)} disabled={disabled} className="btn btn-secondary left" type="button"><i className="fa fa-minus"></i></button>
                    <input tabIndex={this.props.tabIndex} ref="quantity" className="form-control quantity-input" placeholder="-" disabled={disabled} value={ this.props.product.item_weight ? this.state.quantity : (this.state.quantity !== "-" ? parseInt(this.state.quantity) : this.state.quantity) } onChange={this.inputChange.bind(this)} onBlur={this.setQuantity.bind(this)}/>
                    <button tabIndex={-1} onClick={this.increaseQuantity.bind(this)} disabled={disabled} className="btn btn-secondary right" type="button"><i className="fa fa-plus"></i></button>
                </div>
                {!!this.props.product.item_weight && this.props.product.avail_physical > 0 &&
                    <span className="weight-text">kg</span>
                }
            </React.Fragment>
        )
    }
}

QuantityGroup.defaultProps = {
    quantity: '-',
    selectMode: false,
    isSelected: false,
    tabIndex: null
}


export default QuantityGroup;

