import React from 'react';
import { NumToIsk } from '../../utils'
import asDrawer from '../drawer';
import * as Ps from 'perfect-scrollbar';

class ListDropDown extends React.Component {

    constructor(props) {
        super(props)
        this.handleSelectListItem = this.handleSelectListItem.bind(this)
        this.listDropdownRef = React.createRef()
        this.listDropdownRefBody = React.createRef()
    }

    componentDidMount() {

        $(document).click((e) => {
            if(this.props.drawer === "open" && !$.contains(this.listDropdownRef.current, e.target)) {
                this.props.onDrawerToggle()
                
            }
            if(this.props.classNames.indexOf('select-products-bar') !== -1){
                $(".listDropDownDrawer").css({'top': '-'+($(".listDropDownDrawer").height() - 36)+'px'})
            }
        })

        Ps.initialize(this.listDropdownRefBody.current);
    }

    handleSelectListItem(index) {
        this.props.onDrawerToggle()
        this.props.onListItemSelect(index)
    }

    renderLists() {
        let lists = []
        if (this.props.lists && this.props.lists.length > 0) {
            this.props.lists.map((listItem, index) => {
                if (listItem !== undefined) {
                    lists.push(
                        <li className={this.props.selectedIndex===index ? "selected" : ""} 
                            key={`${listItem.name} ${index}`} 
                            onClick={this.handleSelectListItem.bind(this, index)}>
                                { listItem.name } ({listItem.product_count})
                        </li>
                    )
                }
            })
        }
        return lists;
    }

    getTextValue() {
        return this.props.textValue || (this.props.lists && this.props.lists.length > 0 && this.props.lists[this.props.selectedIndex].name)
    }

    render() {

        return (
            <div ref={this.listDropdownRef} onClick={this.props.onDrawerToggle} className={`listDropDown listDropDown--${this.props.drawer} ${this.props.classNames}`}>
                <div className={`handle ${this.props.drawer === "open"?"open":"closed"}`}>
                    <img src={window.garriConfig.icons.favoriteFill} width="20" alt="Uppáhalds" />
                    { this.getTextValue() ?
                        this.props.textValue || (this.props.lists && this.props.lists.length > 0 && this.props.lists[this.props.selectedIndex].name)
                        :
                        "Engir listar"
                    }
                </div>
                <div className={`listDropDownDrawer ${this.props.drawer}`}>
                    <div className="header" onClick={this.props.onDrawerToggle}>Mínir listar</div>
                    <div ref={this.listDropdownRefBody} className={`body ${this.props.newListButton ? 'new-list-button' : ''}`}>
                        <ul>
                            { this.renderLists() }
                        </ul>
                    </div>
                    <div className="footer">
                        {this.props.newListButton && 
                            <a className="btn btn-success" href="/stillingar/#mylists"><i className="fa fa-plus"></i>Gera nýjan lista</a>
                        }
                    </div>
                </div>
            </div>
        )
    }
}


export default asDrawer(ListDropDown);