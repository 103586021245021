import React from 'react';
import ReactDOM from 'react-dom'
import { productQuantityMatchesPackaging, showProductQuantityWarning } from '../../utils';
import CheckoutMatchPackaging from '../../views/modal/checkoutMatchPackaging'
import Modal from '../../views/modal'

class QuantityWarning extends React.Component {

    constructor(props) {
        super(props)
    }

    updatePackaging() {
        let modalProps = {
            title: null,
            body: CheckoutMatchPackaging,
            large: false,
            bodyProps: {
                dispatch: this.props.dispatch,
                product: this.props.product
            }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    render() {
        const {product} = this.props

        return (
            <div className="quantityWarning">
                {showProductQuantityWarning(product) &&
                    <img className="cart-icon" src={window.garriConfig.icons.questionMark} aria-hidden="true" onClick={this.updatePackaging.bind(this)} alt="Lagarstaða er lág á þessari vöru" />
                }
            </div>
        )
    }
}

export default QuantityWarning


