import {
    REQUEST_FETCH_INVOICES,
    RECEIVE_INVOICES_SUCCESS,
    RECEIVE_INVOICES_ERROR
} from './actions'


const initialState = {
    invoices: [],
    isFetching: false
}

export function invoices(state = initialState, action) {

    switch(action.type) {
        case REQUEST_FETCH_INVOICES:
            state = {...initialState,
                isFetching: true
            }
            return state

        case RECEIVE_INVOICES_SUCCESS:
            state = {...state,
                invoices: action.data,
                isFetching: false
            }
            return state

        case RECEIVE_INVOICES_ERROR:
            state = {...state,
                invoices: [],
                isFetching: false
            }
            return state

        default:
            return state
    }
}
