import jwtDecode from 'jwt-decode';

import {
    REQUEST_FETCH_SALES,
    RECEIVE_SALES_SUCCESS,
    RECEIVE_SALES_ERROR,
    REQUEST_DETAILED_SALE,
    RECEIVE_DETAILED_SALE_SUCCESS,
    RECEIVE_DETAILED_SALE_ERROR,
    REQUEST_DETAILED_VALITOR_SALE,
    RECEIVE_DETAILED_VALITOR_SALE_SUCCESS,
    RECEIVE_DETAILED_VALITOR_SALE_ERROR,
    REMOVE_COMPLETE_SALE
} from './actions'


const _initalState = {
    isFetching: false,
    sales: [],
    next: null,
    previous: null,
    fetchError: null,
    valitor_sale: {},
}

export function sales(state=_initalState, action) {
    switch (action.type) {
        case REQUEST_FETCH_SALES:
            state = _initalState
            return state
        case RECEIVE_SALES_SUCCESS:
            state = {...state,
                sales: action.data.results,
                isFetching: false,
                next: action.data.next,
                previous: action.data.previous,
                pageCount: Math.ceil(action.data.count/action.data.results.length)
            }
            return state
        case RECEIVE_SALES_ERROR:
            state = {...state,
                sales: [],
                isFetching: false,
                fetchError: action.data
            }
            return state
        case REQUEST_DETAILED_SALE:
            state = {...state,
                sales: state.sales.map((sale) => {
                    if(sale.sale_id === action.sale_id){
                        sale.isFetching = true
                    }
                    return sale
                })
            }
            return state
        case RECEIVE_DETAILED_SALE_SUCCESS:
            state = {...state,
                sales: state.sales.map((sale) => {
                    if(sale.sale_id === action.sale_id){
                        sale.isFetching = false
                        sale.complete_sale = {products: action.data.results}
                    }
                    return sale
                })
            }
            return state
        case REQUEST_DETAILED_VALITOR_SALE:
            state = {...state,
                valitor_sale: {}
            }
            return state
        case RECEIVE_DETAILED_VALITOR_SALE_SUCCESS:
            state = {...state,
                valitor_sale: action.data.results[0]
            }
            return state
        case REMOVE_COMPLETE_SALE:
            state = {...state,
                sales: state.sales.map((sale) => {
                    if(sale.sale_id === action.sale_id){
                        sale.complete_sale = undefined
                    }
                    return sale
                })
            }
            return state
        default:
            return state
    }
}