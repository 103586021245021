import React from 'react';
import moment from 'moment'
import { fetchTransactions } from '../../api/transactions/actions';
import DatePicker from 'react-datepicker'
import { Spinner } from '../../utils'
import ExcellentExport from '../../utils/ExcellentExport'

class Transactions extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            fromDate: moment().subtract(1, 'months'),
            toDate: moment()
        }
    }

    componentDidMount(){
        this.fetchTransactions()
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.user.activeCompany.account_id !== this.props.user.activeCompany.account_id){
            this.fetchTransactions(nextProps.user.activeCompany.account_id)
        }
    }

    fetchTransactions(account_id=null){
        var data = {
            fromDate: this.state.fromDate.format('YYYY-MM-DD'),
            toDate: this.state.toDate.format('YYYY-MM-DD')
        }
        if(account_id){
            data['custAccount'] = account_id
        }
        this.props.dispatch(fetchTransactions(data))
    }

    fromDateChange(d){
        this.setState({fromDate: d}, this.fetchTransactions)
    }

    toDateChange(d){
        this.setState({toDate: d}, this.fetchTransactions)
    }

    renderTransactionInvoice(transactionInvoice, transactionInvoicestatus){
        var res = transactionInvoice
        let invoices = this.props.invoices.invoices.map((invoice, idx) => {
            if(transactionInvoicestatus === 3 && invoice.invoiceId === transactionInvoice){
                res = (<a href={`/api/v1/invoice-pdf/?invoice_id=${invoice.invoiceId}&invoice_date=${invoice.invoiceDate}`}>{transactionInvoice}</a>)
            }
            return invoice
        })
        return res
    }

    csvExport () {
        ExcellentExport.csv(document.getElementById('csv-export'), 'table-transactions', ';')
    }

    excelExport () {
        ExcellentExport.excel(document.getElementById('excel-export'), 'table-transactions', `Garri hreyfingar ${this.state.fromDate.format("YYYY-MM-DD")} - ${this.state.toDate.format("YYYY-MM-DD")}`)
    }


    render() {
        let {isFetching, transactions} = this.props.transactions

        return (
            <div className="transactions-container">
                <h2>Hreyfingar fyrir {this.props.user.activeCompany.name} ({this.props.user.activeCompany.account_id})</h2>
                <div className="date-select-container">
                    <div className="box">
                        <div className="label">Byrjunardagsetning:</div>
                        <DatePicker
                            selected={this.state.fromDate}
                            onChange={this.fromDateChange.bind(this)}
                        />
                    </div>
                    <div className="box">
                        <div className="label">Lokadagsetning:</div>
                        <DatePicker
                            selected={this.state.toDate}
                            onChange={this.toDateChange.bind(this)}
                        />
                    </div>
                    <div>
                        Sækja sem <a id="csv-export" download="hreyfingar.csv" href="#" onClick={this.csvExport.bind(this)}> CSV</a> | <a id="excel-export" download="hreyfingar.xls" href="#" onClick={this.excelExport.bind(this)}>Excel</a>
                    </div>
                </div>
                {this.props.transactions.isFetching &&
                    <div className="spinner-container text-center">
                        <Spinner />
                    </div>
                }
                {!this.props.transactions.isFetching &&
                    <div className="table-responsive">
                        <table className="table settings-table full-w" id="table-transactions">
                            <thead>
                                <tr>
                                    <th>Dagsetning</th>
                                    <th>Reikningur</th>
                                    <th>Lýsing</th>
                                    <th className="text-right">Gjalddagi</th>
                                    <th className="text-right">Debet</th>
                                    <th className="text-right">Kredit</th>
                                    <th className="text-right">Staða</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.transactions.transactions.map((el, idx) => 
                                    <tr key={`ransactions-row-${idx}`} className={idx % 2 === 0 ? 'odd' : 'even'}>
                                        <td>{el.transDate}</td>
                                        <td>
                                            { el.invoiceStatus === 3 ? 
                                                <a href={`/api/v1/invoice-pdf/?invoice_id=${el.invoice}&invoice_date=${el.transDateOriginal}`}>{el.invoice}</a>
                                                :
                                                el.invoice
                                            }
                                        </td>
                                        {/* <td>{this.renderTransactionInvoice(el.invoice, el.invoiceStatus)}</td> */}
                                        <td>{el.descriptionDisplay}</td>
                                        <td className="text-right">{el.dueDate}</td>
                                        <td className="text-right">{el.debet}</td>
                                        <td className="text-right">{el.kredit}</td>
                                        <td className="text-right">{el.balance}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        )
    }
}


export default Transactions;