import React from 'react';

import { NumToIsk, animateCart, getProductQty } from '../../utils'
import Price from './price'
import QuantityGroup from './quantityGroup'
import ProductImage from './productImage'
import { removeProduct, setProductQuantity } from '../../api/cart/actions'
import { setProductQuantityGroup } from '../../api/products/actions'
import OrderButton from './orderButton'
import YourPrice from './yourPrice'
import QuantityGroupToggler from './quantityGroupToggler'
import {useShallowEqual} from 'shouldcomponentupdate-children';

class ProductSearchResult extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            quantity: 1
        }
    }

    setQuantity(qty, increment=false) {
        if(qty === 0){
            this.props.dispatch(removeProduct(this.props.cartProduct.product.item_id))
            return this.setState({quantity: 1})
        }
        qty = getProductQty(this.props.product, qty, increment)
        this.props.dispatch(setProductQuantity(qty, this.props.cartProduct.product.item_id))
        this.setState({quantity: qty})
    }

    quantityGroupIncrement(checked) {
        const {dispatch} = this.props
        dispatch(setProductQuantityGroup(this.props.product.item_id, checked))
    }


    render() {
        const {product, cartProduct} = {...this.props}
        let searchResult = true
        return (
            <tr className={`product productSearchResult ${this.props.classNames}`} ref="productElement">
                <td className="product-image">
                    <ProductImage product={product} attrs={{className: 'tinyimage'}} />
                </td>
                <td className="product-name">
                    {window.garriConfig.displayProductLinks ?
                        <a href={`/vara/${product.item_id }`}>{ product.product_name }</a>
                    :
                        product.product_name
                    }
                </td>
                <td className="product-id"><a href={`/vara/${product.item_id }`}>{ product.item_id }</a></td>
                <td className="product-qty">{product.item_weight ? `${product.item_weight} ` : ''}{product.qty_unit}</td>
                <td className="product-package">{ product.package_description }</td>
                {  this.props.user.isAuthenticated && !((this.props.user.independentUser)||(this.props.user.activeCompany && this.props.user.activeCompany.independent_company)) &&
                    <td className="product-price"><span className="makeblock">Verð</span>
                        {this.props.showPrice && this.props.user.isAuthenticated ?
                            <span className="notempty">{ NumToIsk(this.props.product.sales_price) } kr.</span>
                            :
                            <span>-</span>
                        }
                    </td>
                }
                {  this.props.user.isAuthenticated &&
                    <td className="product-your-price">
                        { this.props.product.item_weight ?
                            <span className="makeblock">Áætlað einingarverð</span>
                            :
                            <span className="makeblock">Mitt verð</span>
                        }
                        {this.props.showPrice ?
                            <YourPrice product={this.props.product} classNames="notempty" />
                            :
                            <span>-</span>
                        }
                    </td>
                }
                <td className="quantity-group-container">
                    {product.package_count > 1 &&
                        <span className="makeblock">Pakkning</span>
                    }
                    <QuantityGroupToggler
                        key={`productSearchResult-qty-group-toggler-${product.item_id}`}
                        product={product}
                        quantityGroupIncrement={this.quantityGroupIncrement.bind(this)}
                        dispatch={this.props.dispatch}
                        classNames={`smaller xs`}
                        searchResult={searchResult}
                    />
                </td>
                <td className={`add-button`} style={{width: (cartProduct ? '110px' : '')}}>
                    {cartProduct &&
                        <QuantityGroup key={"productSearchResult-qty-group-"+cartProduct.product.item_id} product={cartProduct.product} quantity={cartProduct.quantity} setQuantity={this.setQuantity.bind(this)} />
                    }
                    {!cartProduct &&
                        <OrderButton quantity={"-"} product={product} searchResult={searchResult} allowZeroQuantityAdd={this.props.allowZeroQuantityAdd} enabled={this.props.user.isAuthenticated && this.props.user.canPurchase} onClick={this.props.onClick} callbackFn={(this.props.skipCallback ? () => {} : () => animateCart(this.refs.productElement))} />
                    }
                </td>
            </tr>
        )
    }
}

ProductSearchResult.defaultProps = {
    classNames: '',
    onClick: undefined,
    allowZeroQuantityAdd: false,
}


export default useShallowEqual(ProductSearchResult);
