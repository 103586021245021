import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { createList } from '../../api/lists/actions'

class WarningModal extends React.Component {


    confirm () {
        this.props.close()
        this.props.confirm()
    }

  render(){
    return (
      <div className="warning-modal-container">
        <h3>{this.props.title}</h3>
        <div className="text-center">
          { this.props.html &&
            <div dangerouslySetInnerHTML={{__html: this.props.html}} />
          }
          { this.props.jsx &&
            this.props.jsx
          }
          
          { this.props.cancelText &&
            <button type="button" className="btn btn-default mr20" onClick={this.props.close.bind(this)} >{this.props.cancelText}</button>
          }
          <button type="button" className="btn btn-success" onClick={this.confirm.bind(this)}>{this.props.confirmText}</button>
        </div>
      </div>
    )
  }
}

module.exports = WarningModal;