import React from 'react';

import ProductLarge from './productLarge'
import { NumToIsk } from '../../utils'


class Product extends React.Component {

    constructor(props) {
        super(props)

        // this.handleSubmit = this.handleSubmit.bind(this)
    }


    render() {
        const {product} = {...this.props}
        return (

            <this.props.viewName {...this.props} />

        )
    }
}


export default Product;


/* <div>
    { product.id }
    { product.item_id }
    { product.product_name }
    { product.description }
    { product.qty_unit }
    { product.sales_price }
    { product.package_description }
    { product.avail_physical }
    { product.image !== null && 
        <img src={product.image.thumb} />
    }
</div> */