export const CAROUSEL_FORWARD = 'CAROUSEL_FORWARD'
export const CAROUSEL_BACKWARD = 'CAROUSEL_BACKWARD'
export const CAROUSEL_RESET = 'CAROUSEL_RESET'
export const CAROUSEL_SET_VISIBLE = 'CAROUSEL_SET_VISIBLE'
export const CAROUSEL_SET_TOTAL = 'CAROUSEL_SET_TOTAL'

export const carouselForward = () => {
    return {
        type: CAROUSEL_FORWARD,
    };
}

export const carouselBackward = () => {
    return {
        type: CAROUSEL_BACKWARD,
    };
}

export const resetCarousel = () => {
    return {
        type: CAROUSEL_RESET,
    };
}

export const carouselSetVisible = (visibleCount) => {
    return {
        type: CAROUSEL_SET_VISIBLE,
        data: visibleCount,
    }
}

export const carouselSetTotal = (total) => {
    return {
        type: CAROUSEL_SET_TOTAL,
        data: total,
    }
}
