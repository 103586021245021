import React from 'react'
import ReactDOM from 'react-dom'

class Modal extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount(){
    var $modal = $(this.refs.modal)
    $modal.on('shown.bs.modal', () => {
      $(ReactDOM.findDOMNode(this)).find('input').focus()
    })
    .on('hidden.bs.modal', () => {
      ReactDOM.unmountComponentAtNode(ReactDOM.findDOMNode(this).parentNode)
    })
    .modal('show')
  }

  close(){
    var $modal = $(this.refs.modal)
    if(typeof this.props.callback === 'function'){
      this.props.callback()
    }
    $modal.modal('hide')
  }

  render(){
    let modalClass = "modal-dialog"
    if(this.props.large){
      modalClass += " modal-lg"
    }
    if(this.props.small){
      modalClass += " modal-sm"
    }
    return (
      <div className="modal" id="modal" ref="modal" role="dialog" autoFocus={false}>
        <div className={modalClass}>
          <div className="modal-content">
            {this.props.title && (
              <div className="modal-header">
                {this.props.title}
              </div>
            )
          }

          { this.props.showCloseButton &&
            <button id="modal-close" type="button" onClick={this.close} data-dismiss="modal" aria-label="Close">
              <i className="fa fa-close"></i>
            </button>
          }

            
            <div className="modal-body">
              <this.props.body close={this.close.bind(this)} {...this.props.bodyProps}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Modal.defaultProps = {
  showCloseButton: true
}

module.exports = Modal;