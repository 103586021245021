import React from 'react';
import ReactDOM from 'react-dom'
import { selectProduct, removeSelectedProduct } from '../../api/products/actions'
import { getProductQty } from '../../utils'

class ProductSelect extends React.Component {

    constructor(props) {
        super(props)
    }

    handleInputChange(e){
        var checked = e.target.checked
        var quantity = this.props.quantity
        if(quantity === "-"){
            quantity = getProductQty(this.props.product, 1, true)
            this.props.updateProductQty(quantity)
        }
        if(checked){
            this.props.dispatch(selectProduct(this.props.product, quantity))
        }else{
            this.props.dispatch(removeSelectedProduct(this.props.product))
        }
    }

    render() {
        if(this.props.enabled){
            return (
                <div className="product-select-container">
                    <input type='checkbox' name='thing' value='valuable' id={`product-select-${this.props.product.item_id}`} checked={this.props.selected} onChange={this.handleInputChange.bind(this)}/>
                    <label htmlFor={`product-select-${this.props.product.item_id}`}>
                        <img className="unchecked" src={window.garriConfig.icons.uncheckedCircle} alt="Haka úr" />
                        <img className="checked" src={window.garriConfig.icons.checkedCircle} alt="Haka í" />
                    </label> 
                </div>
            )
        }
        return <div/>
    }
}

export default ProductSelect;