import React from 'react';
import ReactDOM from 'react-dom'
import Modal from '../../views/modal'
import CompaniesList from '../../views/modal/companiesList'


class UserDisplay extends React.Component {

    constructor(props) {
        super(props)
        this.onClick = this.onClick.bind(this)
    }

    onUpdate() {

    }

    onClick(e)  {
        if (this.props.user.independentUser) {
            return;
        }
        if (this.props.user.companyOverride || this.props.user.companies.length > 1 || this.props.user.hasManyCompanies) {
            let modalProps = {
            title: "Viðskiptavinir",
            body: CompaniesList,
            callback: this.onUpdate.bind(this),
            bodyProps: {
                user: this.props.user
            },
            }
            ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
        }
    }

    render() {
        var { user } = this.props
        var classes = `user-name customer ${user.companyOverride || user.companies.length > 1 || this.props.user.hasManyCompanies ? '' : 'no-underline'}`
        return (
            <div>
                <div className={classes} title={user.activeCompany.name} onClick={this.onClick}>
                    <div className="thin">Viðskiptavinur</div>
                    <div className="name">{user.activeCompany.name}</div>
                </div>
                <div className="user-name">
                    <div className="thin">{ user.companyOverride ? 'Sölumaður' : 'Innskráður notandi'}</div>
                    <div className="name">{user.name}</div>
                </div>
            </div>
        )
    }
}


export default UserDisplay;