import jwtDecode from 'jwt-decode';
import { GetSessionStorageState, StateToSessionStorage } from '../../storages/session-storage'

import {
    REQUEST_COMPANIES,
    RECEIVE_COMPANIES,
    REQUEST_SEARCH_COMPANIES,
    RECEIVE_SEARCH_COMPANIES,
    RECEIVE_SEARCH_COMPANIES_ERROR,
    CLEAR_SEARCH_COMPANIES,
    SET_ACTIVE_COMPANY,
} from './actions'


const initalState = {
    companies: []
}

export function companies(state = {
    isFetching: false,
    companiesList: [],
    total: 0,

}, action) {

    switch(action.type) {
        case SET_ACTIVE_COMPANY:
            state = {...state,
                isFetching: true,
            }
            return state
        case REQUEST_COMPANIES:
            state = {...state,
                isFetching: true,
            }
            return state
        case RECEIVE_COMPANIES:
            var companiesList = []
            var data_companies = action.data.results.map(p => {
                p.sales_price = parseFloat(p.sales_price)
                p.sales_price_vat = parseFloat(p.sales_price_vat)
                return p
            })
            if(action.get_next){
                companiesList = [...state.companiesList].concat(data_companies)
            }else{
                companiesList = data_companies
            }
            state = {...state,
                isFetching: false,
                companiesList: companiesList,
                next: action.data.next,
                total: action.data.count
            }
            return state
        default:
            return state
    }

}

export function search_companies(state = {
    search_companies: [],
    search_text: "",
    isFetching: false,
}, action) {
    switch(action.type) {
        case CLEAR_SEARCH_COMPANIES:
            state = {...state,
                search_companies: [],
                isFetching: false,
                search_text: "",
            }
            return state
        case REQUEST_SEARCH_COMPANIES:
            state = {
                ...state,
                isFetching: true,
                search_text: action.data,
            }
            return state
        case RECEIVE_SEARCH_COMPANIES:
            var search_companies = []
            if(action.get_next){
                search_companies = [...state.search_companies].concat(action.data.results)
            }else{
                search_companies = action.data.results
            }
            state = {
                ...state,
                search_companies: search_companies,
                next: action.data.next,
                isFetching: false,
            }
            return state
        default:
            return state
    }

    // StateToSessionStorage(state, 'cart')
    return state
}