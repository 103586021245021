import React from 'react';
import { useShallowEqual } from 'shouldcomponentupdate-children';
import ProductCheckoutConfirmMobile from './productCheckoutConfirmMobile';
import ProductImage from './productImage';
import YourPrice from './yourPrice';


class ProductCheckoutConfirm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            quantity: this.props.quantity
        }
    }


    render() {
        const {quantity, product} = {...this.props.product}
        if(this.props.isMobile){
            return <ProductCheckoutConfirmMobile {...this.props} />
        }
        let q = quantity
        if (!product.item_weight) {
            q = parseInt(q)
        }
        return (
            <tr className={`product productCheckoutConfirm ${this.props.classNames}`}>
                <td className="product-image">
                    <ProductImage product={product} />
                </td>
                <td className="product-name">
                    {window.garriConfig.displayProductLinks ?
                        <a href={`/vara/${product.item_id }`}>{ product.product_name }</a>
                    :
                        product.product_name
                    }
                </td>
                <td className="product-id"><a href={`/vara/${product.item_id }`}>{ product.item_id }</a></td>
                <td className="product-package-description">{product.item_weight ? `${product.item_weight} ` : ''}{product.qty_unit}</td>
                <td className="product-qty-unit">{product.package_description}</td>
                <td className="qty">{product.item_id === "99111100" ? 1 : q}</td>
                <td className="product-your-price"><YourPrice product={product} quantity={product.item_id === "99111100" ? 1 : quantity} /></td>
            </tr>
        )
    }
}

ProductCheckoutConfirm.defaultProps = {
    classNames: '',
}


export default useShallowEqual(ProductCheckoutConfirm);
