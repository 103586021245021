import React from "react";
import moment from 'moment'
import { NumToIsk, animateCart, getProductQty } from "../../utils";
import Ribbon from "../ribbon";
import Badge from "../badge";
import Favorite from "../favorite";
import VeganIcon from "../vegan-icon";
import WeightIcon from "../weight-icon";
import ProductSelect from "./productSelect";
import Price from "./price";
import QuantityGroup from "./quantityGroup";
import PriceHeader from "./priceHeader";
import ProductImage from "./productImage";
import OrderButton from "./orderButton";
import ReplacementButton from "./replacementButton";
import ProductMessage from "./productMessage";
import QuantityGroupToggler from "./quantityGroupToggler";
import { useShallowEqual } from "shouldcomponentupdate-children";
import {
  setProductQuantityGroup,
  updateSelectedProductQuantity
} from "../../api/products/actions";
import { createList, patchList } from "../../api/lists/actions";
import { store } from "../../store";
import { connectWithStore } from "../../utils";
import EnvironmentIcon from "../environment-icon";

let c1 = 0;

class ProductLarge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: "-",
      productMessage: ""
    };
    this.timer = null;
    this.saveSearchString = this.saveSearchString.bind(this);
  }

  setQuantity(qty, increment = false) {
    const { product } = this.props;
    qty = getProductQty(product, qty, increment);
    this.setState({ quantity: qty });
  }

  updateProductQty(qty) {
    this.setState({ quantity: qty });
  }

  quantityGroupIncrement(checked) {
    const { dispatch } = this.props;
    dispatch(setProductQuantityGroup(this.props.product.item_id, checked));
  }

  setSelectModeMessage() {
    this.setState({
      productMessage: "Ekki er hægt að setja í körfu meðan verið er að velja"
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ productMessage: "" });
    }, 6000);
  }

  updateSelectedQuantity(qty) {
    const { product, dispatch } = this.props;
    dispatch(updateSelectedProductQuantity(product, qty));
  }

  removeProductFromListProduct(product) {
    var list = this.props.currentList;

    var products = list.products.filter((product) => {
      // This looks off
      return product.product.item_id !== product.product.item_id
    })
    this.props.dispatch(
      patchList({
        id: list.id,
        products: products,
        removedProducts: [{ product: { item_id: product.item_id } }]
      }))
  }

  saveSearchString(){
    if (this.props.search_products.search_text !== "") {
      const state = { 'search_params':  this.props.search_products.search_text, 'modalSearch': this.props.modalSearch}
      const title = ''
      const url = window.location

      window.history.pushState(state, title, url)
    }
  }

  render() {
    const { product, selected_products } = { ...this.props };
    var isSelected = false;
    var selectMode = selected_products.products.length > 0;
    if (
      selected_products.products.find(
        p => p.product.item_id === product.item_id
      )
    ) {
      isSelected = true;
    }
    let independentUser = (this.props.user.independentUser || (this.props.user.activeCompany && this.props.user.activeCompany.independent_company))
    return (
      <div className={`product productLarge`} ref="productElement">
        <div
          className={`product-inner-container ${isSelected ? "selected" : ""} ${selectMode ? "select-mode" : ""
            }`}
        >
          <VeganIcon product={product} />
          <WeightIcon product={product} inList={true} independentUser={independentUser} />
          <Badge product={product} />
          <Ribbon product={product} />
          <ProductSelect
            product={product}
            quantity={this.state.quantity}
            selected={isSelected}
            updateProductQty={this.updateProductQty.bind(this)}
            enabled={this.props.user.isAuthenticated}
            dispatch={this.props.dispatch}
          />
          <div className={`productnumber ${product.new_product_number && 'new-number'}`} >
            <span className="performance">R{++c1}</span>{" "}
            <div class={`${product.new_product_number && 'd-flex align-items-center'}`}>
              {product.new_product_number && 
                <span>
                  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" baseProfile="tiny" version="1.2" viewBox="0 0 35.2 35.2" width="15" height="15">
                  <circle id="Oval" cx="17.6" cy="17.6" r="17.6" fill="#60a52d" stroke-width="0" />
                  <g id="_" data-name="!">
                      <path id="Shape" d="M16.2,22.7v-13.9h2.8v13.9h-2.8Z" fill="#fff" stroke-width="0" />
                  </g>
                  <circle cx="17.6" cy="25.7" r="1.8" fill="#fff" stroke-width="0" />
                </svg>
                </span>
              }
              <a tabIndex={-1} href={`/vara/${product.item_id}`}>
                {product.item_id}
              </a>
            </div>
              {product.new_product_number && 
                <div className="new-number-info">
                  <span className="description-text">Nýtt vörunúmer</span>
                </div>
              }
          </div>
          { product.promotion_expired_product &&
            <div className="expiration-date">
              <p>
                Best fyrir: { moment(product.expiration_date).format("DD.MM.YY") }
              </p>
            </div>
          }
          <div className="product-info-container clearfix">
            <div className="image-container">
              <ProductImage product={product} />
              <EnvironmentIcon product={product} />
            </div>
            <div className="product-info-inner-container">
              <div className="product-info-box">
                <div>
                  <div className="productname">
                    {window.garriConfig.displayProductLinks ? (
                      <a
                        tabIndex={-1}
                        href={`/vara/${product.item_id}`}
                        title={product.product_name}
                        onClick={() => this.saveSearchString()}
                      >
                        {product.product_name}
                      </a>
                    ) : (
                        product.product_name
                      )}
                  </div>


                  <div className="productsize">
                    {product.item_weight && ((this.props.user.independentUser) || (this.props.user.activeCompany && this.props.user.activeCompany.independent_company)) ?
                      `Áætluð þyngd - ${product.item_weight} ${product.qty_unit}`
                      :
                      `Pakkning - ${product.package_description}`
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ProductMessage
            product={product}
            quantity={this.state.quantity}
            productMessage={this.state.productMessage}
            isSelected={isSelected}
          />
          <div className="price-container clearfix">
            {this.props.user.isAuthenticated && this.props.user.canPurchase ? (
              <div className="price-inner-container">
                <PriceHeader product={product} offer={product.offer} deleted={product.deleted} user={this.props.user} />
                <Price
                  product={product}
                  showPrice={this.props.user.isAuthenticated}
                  user={this.props.user}
                  showItemWeightUnitPrice={true}
                />
              </div>
            ) : (
                <div className="price-inner-container">
                  <div className="priceHeader flex-row">
                    <div className="pricetitle">&nbsp;</div>
                  </div>
                  <div className="price flex-row">
                    <div className="newprice">&nbsp;</div>
                  </div>
                </div>
              )}
          </div>
          <div className="product-footer">
            {!product.deleted && (
              <React.Fragment>
                {product.avail_physical > 0 || product.ignore_stock ? (
                  <QuantityGroupToggler
                    key={`productLarge-qty-group-toggler-${product.item_id}`}
                    product={product}
                    selectMode={selectMode}
                    isSelected={isSelected}
                    quantityGroupIncrement={this.quantityGroupIncrement.bind(
                      this
                    )}
                    dispatch={this.props.dispatch}
                    classNames={"smaller xs"}
                  />
                ) : (

                    <span class="product-available-soon-text">
                      <Ribbon product={product} />
                    </span>
                  )}
              </React.Fragment>
            )}
            <div className="right-block">
              {this.props.enableReplacementButton &&
                ((product.deleted && product.replacement_products.length > 0) ||
                  (+product.avail_physical === 0 &&
                    product.replacement_products.length > 0)) &&
                this.props.user.isAuthenticated && (
                  <ReplacementButton
                    product={product}
                    quantity={this.state.quantity}
                    enabled={
                      this.props.user.isAuthenticated &&
                      this.props.user.canPurchase
                    }
                    selectMode={selectMode}
                    setSelectModeMessage={this.setSelectModeMessage.bind(this)}
                    callBack={this.props.onListsUpdated}
                    replaceProduct={this.props.replaceProduct}
                  />
                )}
              {product.deleted &&
                product.replacement_products.length === 0 &&
                this.props.currentList !== null &&
                this.props.currentList.name !== "Mest pantað" &&
                !this.props.currentList.is_promotion_page_list && (
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={this.removeProductFromListProduct.bind(
                      this,
                      product
                    )}
                  >Fjarlægja úr lista</button>
                )}
              {!product.deleted && (
                  <QuantityGroup
                    product={product}
                    selectMode={selectMode}
                    isSelected={isSelected}
                    updateSelectedQuantity={this.updateSelectedQuantity.bind(
                      this
                    )}
                    setSelectModeMessage={this.setSelectModeMessage.bind(this)}
                    quantity={this.state.quantity}
                    setQuantity={this.setQuantity.bind(this)}
                    tabIndex={0}
                />
              )}
              
              {!product.deleted && (
                <OrderButton
                  product={product}
                  quantity={this.state.quantity}
                  enabled={
                    this.props.user.isAuthenticated &&
                    this.props.user.canPurchase
                  }
                  user={this.props.user}
                  selectMode={selectMode}
                  setSelectModeMessage={this.setSelectModeMessage.bind(this)}
                  callbackFn={() => animateCart(this.refs.productElement)}
                  tabIndex={0}
                  />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProductLarge.defaultProps = {
  classNames: "",
  enableReplacementButton: false
};

function mapStateToProps(state) {
  var f = {
    selected_products: {},
    search_products: {},
  };
  f.selected_products = state.selected_products === undefined ? {} : state.selected_products;
  f.search_products = state.search_products === undefined ? {} : state.search_products;
  return f;
}

export default connectWithStore(
  store,
  useShallowEqual(ProductLarge),
  mapStateToProps
);
