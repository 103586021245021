import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'

class PleaseSignIn extends React.Component {

  render(){

    return (
      <div className="please-sign-in">
          <div>
            <h2>Þú ert ekki með innkauparéttindi</h2>
            <p>Til þess að geta sett vörur í pöntun þarftu að vera innskráður og með innkauparéttindi.</p>
            <div className="buttons row">
              <div className="col-6">
                <button className="btn btn-default" type="button" onClick={this.props.close}>Loka</button>
              </div>
            </div>
          </div>
      </div>
    )
  }
}

module.exports = PleaseSignIn;