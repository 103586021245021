import React from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { userLogin } from '../../api/user/actions'
import MainMenuButton from './mainMenuButton'
import Warning from '../../views/modal/warning'
import Modal from '../../views/modal'

class LoginForm extends React.Component {
    constructor(props) {
        super(props)
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.dispatch(userLogin(this.refs.username.value, this.refs.password.value)).then(() => {
            if(typeof this.props.setUserbarHeight === 'function'){
              this.props.setUserbarHeight()
            }
        })
    }

    renderError() {
        if(this.props.user.failed_auth){
            let modalProps = {
                title: null,
                body: Warning,
                bodyProps: {
                    title: "Innskráning tókst ekki",
                    html: "Mögulegar ástæður: <br />• Notandanafn og lykilorð pössuðu ekki. <br />• Ekki er búið að staðfesta netfang. Athugaðu hvort staðfestingarpóstur hafi borist á netfangið þitt.<br /><br /><strong>Athugið að notendanafn er netfangið þitt.</strong><br />Vinsamlegast reyndu aftur eða endurstilltu lykilorðið þitt.<br /><br /><a href=\"/password_reset\">ENDURSTILLA LYKILORÐ</a><br /> <br />",
                    confirm: () => {},
                    confirmText: "Reyna aftur",
                }
            }
            ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
        }
        return null
    }

    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmit.bind(this)} className="login-form">
                    <input type="text" ref="username" name="username" placeholder="Notendanafn" />
                    <input type="password" ref="password" name="password" placeholder="Lykilorð" />
                    <input type="submit" className="btn btn-primary" value="innskrá" />
                    <a className="register" href="/nýskrá/">Nýskrá</a>
                    {this.renderError()}
                </form>
            </div>
        )
    }
}


export default LoginForm;