import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { connectWithStore } from '../../utils'
import { store } from '../../store'
import { createRestockedNotification, clearRestockedNotification } from '.././../api/products/actions';

class InformOnProductBack extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: props.user.email
    }
  }

  componentDidMount() {
    this.props.dispatch(clearRestockedNotification())
  }

  sendNotification() {
    this.props.dispatch(createRestockedNotification({ email: this.state.email, item_id: this.props.product.item_id }));
  }

  setEmail(e) {
    this.setState({ email: e.target.value });
  }

  render(){
    const { message, status } = this.props.restockedProductNotification;
    return (
      <div className="please-sign-in">
          <div>
            <h2>Þessi vara er ekki til í vefverslun</h2>
            <p>Settu inn viðeigandi netfang til þess að fá tilkynningu þegar varan kemur aftur.</p>
            {message &&
              <div className="alert alert-success mt-5">{message}</div>
            }
            { status === 'init' &&
              <React.Fragment>
                <div className="mt-5">
                  <input type="email" className="form-control" value={this.state.email} onChange={this.setEmail.bind(this)} />
                </div>
              
                <div className="buttons row">
                  <div className="col-6">
                    <button className="btn btn-default" type="button" onClick={this.sendNotification.bind(this)}>Fá tilkynningu</button>
                  </div>
                </div>
              </React.Fragment>
            }
          </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  var f = {};
  f.user = (state.user === undefined) ? {} : state.user;
  f.restockedProductNotification = (state.restockedProductNotification === undefined) ? {} : state.restockedProductNotification;
  return f;
}

export default connectWithStore(store, InformOnProductBack, mapStateToProps);