import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { addProducts } from '../../api/cart/actions'
import { ignoreCurrentLocalCart } from '../../api/cart/actions'
import { ClearSessionStorage } from '../../storages/session-storage'

class LocalCartAdd extends React.Component {

  componentDidMount(){
  }

  addProducts(products, replaceProducts=false) {
      const {dispatch} = this.props
      dispatch(addProducts(products, replaceProducts))
      ClearSessionStorage();
      this.props.close()
  }

  removeProducts() {
      const {dispatch} = this.props
      dispatch(ignoreCurrentLocalCart())
      ClearSessionStorage();
      this.props.close()
  }

  render(){
    let {products} = this.props

    return (
      <div className="local-cart-add-list-confirmation">
          {products.length === 0 &&
            <h2>Engar vörur fundust</h2>
          }
          {products.length > 0 &&
            <div>
              <h2>Vörur í körfu</h2>
              <p>Verið er að gera breytingar á körfuvirkni í vefverslun Garra. Framvegis mun karfan þín vistast í vefverslun og verða aðgengileg í öllum þeim tækjum sem þú skráir þig inn á.</p>
              <p>Þú ert með vörur í körfu á þessu tæki.</p>
              <p>Viltu bæta vörunum við, skipta þeim út eða eyða þeim?</p>
              <div className="buttons row">
                <div className="col-4">
                  <button className="btn btn-success add-products" type="button" onClick={this.addProducts.bind(this, products, false)}>Bæta við</button>
                </div>
                <div className="col-4">
                  <button className="btn btn-default change-products" type="button" onClick={this.addProducts.bind(this, products, true)}>Skipta út</button>
                </div>
                <div className="col-4">
                  <button className="btn btn-error delete-products" type="button" onClick={this.removeProducts.bind(this)}>Eyða</button>
                </div>
              </div>
            </div>
          }
      </div>
    )
  }
}

module.exports = LocalCartAdd;