import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { updatePackaging } from '../../api/cart/actions'

class CheckoutMatchPackaging extends React.Component {

    updatePackaging(updateAll) {
        var product = this.props.product
        Promise.resolve(this.props.dispatch(updatePackaging(product, updateAll)))
        .then(() => {
            this.props.close()
        })
    }

    render(){
        let {product} = this.props

        return (
            <div className="checkout-match-packaging">
                <div className="row">
                    <div className="col-3 text-right"><img src={window.garriConfig.icons.questionMark} alt="Pökkunarstaða" /></div>
                    <div className="col-9 text-left">
                        Ein eða fleiri vara í körfunni eru mjög nálægt því að vera heil pakkning.<br/>
                    </div>
                    <div className="col-12 text-center" style={{marginTop: '30px'}}>
                        Veldu hvort þú viljir fylla upp í fyrir <b>allar pakkningar</b> í körfunni eða aðeins fyrir <b>{product.product.product_name}</b>
                    </div>
                </div>
                <div className="buttons row">
                    <div className="col-6">
                        <button className="btn btn-default" type="button" onClick={this.updatePackaging.bind(this, true)}>Allar pakkningar</button>
                    </div>
                    <div className="col-6">
                        <button className="btn btn-success" type="button" onClick={this.updatePackaging.bind(this, false)}>Aðeins {product.product.product_name}</button>
                    </div>
                </div>
            </div>
        )
    }
}

module.exports = CheckoutMatchPackaging;