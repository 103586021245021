import React from 'react'
import ReactDOM from 'react-dom'
import HeadingImage from './headingImage'
import { store } from '../../store'

$.fn.headingImage = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(HeadingImage, options), this);
  });
};

/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-heading-image]').headingImage();
});