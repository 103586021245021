import React from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { userLogout } from '../../api/user/actions'
import Cart from '../../components/cart'
import CartList from '../../components/cart-list'
import LoginForm from './loginForm'
import MainMenuButton from './mainMenuButton'
import { toggleCart, toggleCartList } from '../../utils'
import UserDisplay from '../../components/user/userDisplay'
import Modal from '../../views/modal'
import ProductSearch from '../../views/modal/productSearch'
import AddToMailingList from '../../views/modal/addToMailingList'
import LocalCartAdd from '../../views/modal/localCartAddList'
import { clearSearchProducts } from '../../api/products/actions'
import { fetchServerCartItems, fetchOtherCarts } from '../../api/cart/actions'
import { GetSessionStorageState } from '../../storages/session-storage'

class Userbar extends React.Component {
    constructor(props) {
        super(props)
        this.openSearchModal = this.openSearchModal.bind(this);
    }
    
    setUserbarHeight() {
        if(this.props.user.failed_auth){
            $("#userbar").addClass('has-error')
        }else{
            $("#userbar").removeClass('has-error')
        }
    }

    componentDidUpdate() {
        this.setUserbarHeight();
        if ($('.cart-list').length > 0 && !$('.userbar-container').hasClass('wider')) {
            $('.userbar-container').addClass('wider');
        } 
        if ($('.cart-list').length == 0 && $('.userbar-container').hasClass('wider')) {
            $('.userbar-container').removeClass('wider');
        } 
    }

    openSearchModal() {
        this.props.dispatch(clearSearchProducts())
        let modalProps = {
          title: null,
          body: ProductSearch,
          large: true,
          bodyProps: {
            store: this.props.store
          }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    componentDidMount() {
        $(document).click((e) => {
            if($('body').hasClass('cart-open') && !($.contains($('#userbar')[0], e.target) || $.contains($('.userbar-mobile-container')[0], e.target))) {
                $('body').removeClass('cart-open')
            }
        })

        if (this.props.user.isAuthenticated) {
            let oldCart = GetSessionStorageState()
            if (oldCart && oldCart.products && oldCart.products.length > 0){
                this.oldCartModal(oldCart.products)
            }
            this.props.dispatch(fetchServerCartItems());
            this.props.dispatch(fetchOtherCarts());
        }

        if (this.props.user.isAuthenticated && this.props.user.agreesToTos === null) {
            let modalProps = {
                title: null,
                body: AddToMailingList,
                bodyProps: {
                    user: this.props.user,
                    dispatch: this.props.dispatch,
                }
            }
            ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
        }

        if(window.history.state && window.history.state.search_params !== "" && window.history.state.modalSearch) {
            this.openSearchModal()
        }
    }

    handleLogout(e) {
        this.props.dispatch(userLogout())
    }

    handleToggleCart() {
        toggleCart()
    }

    handleToggleCartList() {
        toggleCartList()
    }

    numberOfProducts() {
        return this.props.cart.products.filter((p) => p.quantity > 0).length
    }

    otherCartsNum() {
        if (this.props.carts.other_carts.length > 0) {
            return this.props.carts.other_carts.filter((c) => c.company.account_id !== this.props.user.activeCompany.account_id).length
        } else {
            return 0
        }
    }
    
    oldCartModal(products) {
        let modalProps = {
          title: null,
          body: LocalCartAdd,
          bodyProps: {
            products: products,
            dispatch: this.props.dispatch,
          }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
    }

    render() {
        if (this.props.user.isAuthenticated) {
            return (
                <div className={`userbar-container`}>
                    <div className="userbar-controls">
                        { this.otherCartsNum() > 0 &&
                            <div className="cart-list" onClick={this.handleToggleCartList.bind(this)}>
                                <div className="count-badge">{this.otherCartsNum()}</div>
                                <img src={this.props.multiBaskets} className="multi-basket" width="24" alt="Körfulisti" />
                            </div>
                        }
                        { this.props.user.canPurchase &&
                            <div className="basket" onClick={this.handleToggleCart.bind(this)}>
                                <div className="count-badge">{this.numberOfProducts()}</div>
                                <img src={this.props.basket} className="basket-black" width="24" alt="Karfa"/>
                                <img src={this.props.basketRed} className="basket-red" width="24" alt="Karfa" />
                            </div>
                        }
                        <div className="user-icon">
                            <a href={window.garriConfig.settingsUrl}><img src={this.props.userIcon} width="20" alt="Notandi" /></a>
                        </div>
                        <div className="search-container" onClick={() => this.openSearchModal()}>
                            <i className="search-dark"/>
                        </div>
                        <UserDisplay user={this.props.user} />

                        <a className="btn-logout" onClick={this.handleLogout.bind(this)}>Útskrá</a>
                    </div>
                    { this.props.user.canPurchase &&
                        <Cart classNames={"desktop-cart"} cart={this.props.cart} user={this.props.user} arrow={this.props.arrow} dispatch={this.props.dispatch} enlargeArrows={this.props.enlargeArrows} cartEmptyBasket={this.props.cartEmptyBasket} redArrowRight={this.props.redArrowRight} />
                    }
                    { this.props.carts.other_carts && this.otherCartsNum() > 0 &&
                        <CartList carts={this.props.carts} user={this.props.user} dispatch={this.props.dispatch}/>
                    }
                </div>
            )
        }
        else {
            return (
                <LoginForm
                    user={this.props.user}
                    dispatch={this.props.dispatch}
                    setUserbarHeight={this.setUserbarHeight.bind(this)}
                    burger={this.props.burger}
                    close={this.props.close}
                />
            )
        }
    }
}


function mapStateToProps(state) {

    var f = {
        user: {},
        cart: {},
        carts: {},
    }
    f.user = (state.user === undefined) ? {} : state.user
    f.cart = (state.cart === undefined) ? {} : state.cart
    f.carts = (state.carts === undefined) ? {} : state.carts
    return f;
}

export default connect(mapStateToProps)(Userbar);
