import accounting from 'accounting';
import Big from 'big.js';
import lineClamp from 'line-clamp';
import React from 'react';
import { connect } from 'react-redux';
import { fetchListsOverview } from '../api/lists/actions';
import { fetchAddonProducts, fetchProductPrices } from '../api/products/actions';
import { fetchSales } from '../api/sales/actions';
import { fetchUsers } from '../api/users/actions';
import { store } from '../store';
import { holidays } from './CalculateHolidays';
let cartOpen = false
let cartListOpen = false


console.log(holidays)



function newsPageSettings() {
  $(".exerpt").each(function () {
    lineClamp($(this)[0], { lineCount: 4 });
  });
  $(".bullets").each(function () {
    var $remove = $('[data-index="' + $(this).data('nextIndex'));
    if ($remove.length > 0) {
      $remove.remove();
    }
  })
}
(newsPageSettings)()

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export const quantityDisabled = (product, quantity) => {
  var qtyDisabled = false
  if (product.avail_physical < 1 && !product.ignore_stock) {
    qtyDisabled = true
  }
  if (quantity !== "-" && quantity > product.avail_physical && !product.ignore_stock) {
    qtyDisabled = true
  }
  if (product.deleted|| !product.display_on_web) {
    qtyDisabled = true;
  }
  return qtyDisabled
}

export const handleNetworkError = (error) => {
  if (window.Sentry !== undefined) {
    Sentry.captureException(error)
  } else {
    throw (error)
  }
}

export const b64toUrl = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return URL.createObjectURL(blob)
}

export function parseJSON(response) {
  return response.json();
}

export const Spinner = (props) => <i className="spinner fa fa-spinner fa-pulse"></i>;


export const NumToIsk = (num) => {
  return accounting.formatMoney(+num, '', 0, ".", ",")
}

export const animateCart = (itemRef) => {
  window.$('body').trigger('garri.place.order', window.$(itemRef));
}

export const openCart = () => {
  $('body').removeClass('cart-list-open')
  $('body').addClass('cart-open')
  return cartOpen = true
}
export const closeCart = () => {
  $('body').removeClass('cart-open')
  return cartOpen = false
}
export const toggleCart = () => {
  if ($('body').hasClass('cart-open')) {
    closeCart()
  } else {
    openCart()
  }
}

export const openCartList = () => {
  $('body').removeClass('cart-open')
  $('body').addClass('cart-list-open')
  return cartListOpen = true
}
export const closeCartList = () => {
  $('body').removeClass('cart-list-open')
  return cartListOpen = false
}
export const toggleCartList = () => {
  if ($('body').hasClass('cart-list-open')) {
    closeCartList()
  } else {
    openCartList()
  }
}

export const filterObject = (obj, remove_keys) => {
  var result = {}, key;

  for (key in obj) {
    if (obj.hasOwnProperty(key) && remove_keys.indexOf(key) === -1) {
      result[key] = obj[key]
    }
  }
  return result
}

export const CartTotalPrice = (products) => {
  let state = store.getState()
  var total = 0;

  for (var product_object of products) {
    let price = getProductUnitPrice({
      user: state.user,
      prices: state.prices,
      product: product_object.product,
    }, true)
    total += (product_object.quantity * price);
  }

  return total;
}

export const CartCheckQty = (products) => {
  let result = []
  let qtyChanged = false
  for (var product_object of products) {
    if (+product_object.product.avail_physical < 1 && !product_object.product.ignore_stock) {
      product_object.quantity = 0
    }
    if (+product_object.product.avail_physical < product_object.quantity && !product_object.product.ignore_stock) {
      product_object.quantity = +product_object.product.avail_physical
      product_object.product.qtyChanged = true
      qtyChanged = true
      if (product_object.quantity < 0) {
        product_object.quantity = 0
        if (+product_object.product.avail_physical < 0) {
          qtyChanged = false
        }
      }
    }
    product_object.packagingQty = productQuantityMatchesPackaging(product_object.product, product_object.quantity) ? 0 : (product_object.product.package_count - product_object.quantity % product_object.product.package_count)
    product_object.packagingQty = (product_object.packagingQty === null) ? 0 : product_object.packagingQty
    if (+product_object.product.avail_physical < (product_object.quantity + product_object.packagingQty)) {
      product_object.packagingQty = 0
    }
    result.push(product_object)
  }
  if (qtyChanged) {
    alert("Lagerstaða hefur breyst, magn í körfu hefur verið lagfært í samræmi við það.")
  }
  return result
}

export const CartTotalQty = (products) => {
  var total = 0;

  for (var product_object of products) {
    total += product_object.quantity;
  }

  return total;
}

export const CartMatchQty = (products, product = null) => {
  let result = []
  for (var product_object of products) {
    var updateProductObject = true
    if (product && product.product.item_id !== product_object.product.item_id) {
      updateProductObject = false
    }

    product_object.packagingQty = productQuantityMatchesPackaging(product_object.product, product_object.quantity) ? 0 : (product_object.product.package_count - product_object.quantity % product_object.product.package_count)
    product_object.packagingQty = (product_object.packagingQty === null) ? 0 : product_object.packagingQty
    if (+product_object.product.avail_physical >= (product_object.quantity + product_object.packagingQty) && updateProductObject) {
      product_object.quantity += product_object.packagingQty
      product_object.packagingQty = 0
    }


    result.push(product_object)
  }
  return result
}

export const CartAddProducts = (products, state = { products: [] }) => {
  var existingProductIds = state.products.map(p => p.product.item_id)
  for (var i = 0; i < products.length; i++) {

    let { product, quantity } = products[i];
    if (quantity !== 0 && !quantity) {
      quantity = Big(1);
    }
    if (existingProductIds.includes(product.item_id)) {
      let p = state.products.find(p => p.product.item_id === product.item_id)
      p.quantity = parseFloat(Big(p.quantity).add(quantity).toFixed(2))
    } else {
      state.products.push({
        product: product,
        quantity: parseFloat(quantity.toFixed(2))
      })
    }
  }
  return state.products
}

export const connectWithStore = (store, WrappedComponent, ...args) => {
  var ConnectedWrappedComponent = connect(...args)(WrappedComponent)
  return function (props) {
    return <ConnectedWrappedComponent {...props} store={store} />
  }
}

export const SaleStatus = (status) => {
  var statuses = {
    0: { text: 'Í vinnslu', className: '' },
    1: { text: 'Opin pöntun', className: '' },
    2: { text: 'Afgreitt', className: 'served' },
    3: { text: 'Innheimt', className: 'served' },
    4: { text: 'Hætt við', className: 'canceled' }
  }
  return statuses[status] || { text: status, className: '' }
}


export const updateUserUI = (dispatch) => {
  dispatch(fetchListsOverview())
  dispatch(fetchUsers())
  dispatch(fetchSales())
}

export const checkPrices = (dispatch, listType = 'products') => (response) => {

  let state = store.getState()
  let account_id = getActiveCompany(state)
  let item_ids = []
  
  if (response.results !== undefined) {
    if (listType === 'lists') {
      item_ids = response.results.map(l => l.products.map(r => r.product.item_id)).reduce((a, b) => a.concat(b), [])
    }
    if (listType === 'frequent') {
      item_ids = response.results.map(r => r.product.item_id).reduce((a, b) => a.concat(b), [])
    }
    if (listType === 'products') {
      item_ids = response.results.map((r) => r.item_id)
    }

  } else {
    if (listType === 'listsingle') {
      item_ids = response.products.map(r => r.product.item_id).reduce((a, b) => a.concat(b), [])
    }
    if (listType === 'frequent') {
      item_ids = response.products.map(r => r.product.item_id).reduce((a, b) => a.concat(b), [])
    }
    else {
      item_ids = [response.item_id];
    }
  }
  
  if (account_id !== '') {
    dispatch(fetchProductPrices(item_ids, account_id))
  }
  return response
}

export const getActiveCompany = (state) => {
  let account_id = ''
  if (state && state.user && state.user.activeCompany !== undefined && state.user.activeCompany !== {}) {
    account_id = state.user.activeCompany.account_id
  }
  return account_id
}

export const hasActiveCompany = (state) => {
  return state && state.user && state.user.activeCompany !== undefined && state.user.activeCompany !== {}
}

export function trackGAEvent(event_name, params = {}) {
  if (window.gtag) {
    gtag('event', event_name, params);
    // console.log('tracking ' + event_name)
  }
  return true;
}

export function getStore() {
  return store;
}

export const shuffleArray = arr => arr.sort(() => Math.random() - 0.5)


export const getAddonProducts = (dispatch) => {
  let state = store.getState()
  let idList = []
  Object.keys(state.cart.products).map((k) => {
    state.cart.products[k].product.addon_products.map(r => idList.push(r))
  })
  dispatch(fetchAddonProducts(idList))
}

export const getProductUnitPrice = ({ user, prices, product }, exclude_item_weight=false) => {
  let independent_user = user.independentUser||(user.activeCompany && user.activeCompany.independent_company)
  let price_key = 'sales_price'
  if (user.activeCompany && user.activeCompany.independent_company) {
      price_key = 'sales_price_vat'
  }
  let itemWeight = 1;
  let price;
  if (independent_user && !exclude_item_weight && product.item_weight && +product.item_weight) {
    itemWeight = +product.item_weight
  } 
  try {
      price = prices.prices[user.activeCompany.account_id][product.item_id] || product[price_key]
      return price * itemWeight;
  } catch (e) {
      price = product[price_key] || 0
      return price * itemWeight
  }
}


export const productQuantityMatchesPackaging = (product, quantity) => {
  if (product.package_count === null || product.package_count === undefined) {
    return true
  } else {
    return quantity % product.package_count === 0
  }
}

export const showProductQuantityWarning = (product) => {
  var showWarning = product.quantity > 0 && product.packagingQty > 0 && product.item_weight > 0
  return showWarning && (product.quantity % product.product.package_count >= Math.floor(product.product.package_count / 2))
}

export const pluralize = (count, singular, plural) => {
  return (count % 10 !== 1 || count % 100 === 11) ? plural : singular
}

export const getHolidays = (year, month) => {
  if (!year) {
    throw new Error('Year must be defined');
  }

  var h = holidays(year).filter(function (day) {
    return day.holiday === true;
  });

  if (month) {
    h = h.filter(function (day) {
      return day.date.getMonth() === month - 1;
    });
  }

  

  return h.map(d => moment(d.date) );
}

export const getProductQty = (product, qty, increment=false) => {
  let state = store.getState()
  var quantityGroupProducts = state.quantityGroupProducts.products

  qty = Big(qty)

  let incValue = 1
  if (product.item_weight) {
    incValue = product.item_weight
  }
  if(!quantityGroupProducts[product.item_id]){
      var pkCount = incValue
      if (product.package_count && !product.item_weight) {
        pkCount = product.package_count
      }
      else if (product.package_count && product.item_weight) {
        pkCount = parseFloat(Big(product.package_count).times(product.item_weight).toFixed(2))
      }
      increment ? qty = qty.minus(incValue) : qty = qty.add(incValue)
      
      var overflow = 0
      if (pkCount > 0) {
        overflow = Big(qty).mod(pkCount)
      }
      
      if (overflow > 0) {
        if (product.item_weight && (Math.abs(overflow.minus(incValue))<0.01 || overflow < 0.01)) {
          overflow = 0
        }
      }

      if(increment){
          qty = Big(Math.max(0, qty.minus(overflow)))
          qty = qty.add(pkCount)
      }else{
          if(overflow > 0){
              qty = qty.add(pkCount)
              qty = Big(Math.max(0, qty.minus(overflow)))
          }
          qty = qty.minus(pkCount)
      }
  }
  if (qty < 0)  {
    qty = Big(0)
  }
  return parseFloat(qty.toFixed(2))
}

export function sortListByOrder(order, key){
    // Promotional lists at the back
    if(order){
        let ord = JSON.parse(order)
        return function(a, b){
            if(a.is_promotion_page_list){
                return 1
            }
            else if(a.hasOwnProperty(key) && a.hasOwnProperty(key)){            
                var A = a[key], B = b[key];
                if (A && B) {
                  if (ord.indexOf(A) > ord.indexOf(B)) {
                      return 1;
                  } else {
                      return -1;
                  }
                } else {
                  return 0
                }
            }
            else {
                return -1 // Mest pantad has no id = at the front
            }
        }
    }
}

// export class SmoothScroll {

//   constructor() {
//     this.timer = null
//   }


// 	stop() {
// 		clearTimeout(this.timer);
// 	}

// 	scrollTo(id, callback) {
// 		var settings = {
// 			duration: 1000,
// 			easing: {
// 				outQuint: function (x, t, b, c, d) {
// 					return c*((t=t/d-1)*t*t*t*t + 1) + b;
// 				}
// 			}
// 		};
// 		var percentage;
// 		var startTime;
// 		var node = document.getElementById(id);
// 		var nodeTop = node.offsetTop;
// 		var nodeHeight = node.offsetHeight;
// 		var body = document.body;
// 		var html = document.documentElement;
// 		var height = Math.max(
// 			body.scrollHeight,
// 			body.offsetHeight,
// 			html.clientHeight,
// 			html.scrollHeight,
// 			html.offsetHeight
// 		);
// 		var windowHeight = window.innerHeight
// 		var offset = window.pageYOffset;
// 		var delta = nodeTop - offset;
// 		var bottomScrollableY = height - windowHeight;
// 		var targetY = (bottomScrollableY < delta) ?
// 			bottomScrollableY - (height - nodeTop - nodeHeight + offset):
// 			delta;

// 		startTime = Date.now();
//     percentage = 0;
    

// 		if (this.timer) {
// 			clearInterval(this.timer);
//     }

// 		var step = () => {
      
// 			var yScroll;
// 			var elapsed = Date.now() - startTime;

// 			if (elapsed > settings.duration) {
// 				clearTimeout(this.timer);
// 			}

// 			percentage = elapsed / settings.duration;

//       debugger

// 			if (percentage > 1) {
// 				clearTimeout(this.timer);

// 				if (callback) {
// 					callback();
// 				}
// 			} else {
// 				yScroll = settings.easing.outQuint(0, elapsed, offset, targetY, settings.duration);
// 				window.scrollTo(0, yScroll);
// 				this.timer = setTimeout(step, 10);     
// 			}
// 		}

// 		this.timer = setTimeout(step, 10);
// 	}
// };

