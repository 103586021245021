import React from "react";
import { updatePackaging } from "../../api/cart/actions";
import { store } from "../../store";
import { showProductQuantityWarning, connectWithStore } from "../../utils";

class QuantityGroupToggler extends React.Component {
  constructor(props) {
    super(props);

    this.container = React.createRef();
  }

  onCheck(e) {
    var checked = e.target.checked;
    Promise.resolve(this.props.quantityGroupIncrement(checked)).then(() => {
      var cartProduct = undefined;
      if (this.props.cart.products && this.props.cart.products.length > 0) {
        cartProduct = this.props.cart.products.find(
          p => p.product.item_id === this.props.product.item_id
        );
      }
      if (cartProduct && checked && showProductQuantityWarning(cartProduct)) {
        this.props.dispatch(updatePackaging(cartProduct));
      }

      $(this.container.current)
        .siblings()
        .find(".quantity-input")
        .focus();
      console.log($(this.container.current));
    });
  }

  render() {
    const { product } = this.props;
    var checked = !this.props.quantityGroupProducts.products[product.item_id];
    // if (product.package_count <= 1 || (product.avail_physical < 1 && !product.ignore_stock)) {
    if (product.package_count <= 1 || (product.avail_physical < 1 && !this.props.searchResult && !product.ignore_stock)) {
      return <div className="quantity-group-toggler" />;
    }
    var disabled = this.props.selectMode && !this.props.isSelected;
    return (
      <div
        ref={this.container}
        className={`quantity-group-toggler ${this.props.classNames} ${
          disabled ? "disabled" : ""
        }`}
      >
        <label className="switch">
          <input
            type="checkbox"
            onChange={this.onCheck.bind(this)}
            checked={checked}
            disabled={disabled}
          />
          <span tabIndex={-1} className="slider"></span>
        </label>
        <label className="text-label">Pakkning</label>
      </div>
    );
  }
}

QuantityGroupToggler.defaultProps = {
  classNames: "",
  selectMode: false,
  isSelected: false
};

function mapStateToProps(state) {
  var f = {
    quantityGroupProducts: { products: {} },
    cart: {},
    selected_products: {}
  };

  f.quantityGroupProducts =
    state.quantityGroupProducts === undefined
      ? {}
      : state.quantityGroupProducts;
  f.selected_products =
    state.selected_products === undefined ? {} : state.selected_products;
  f.cart = state.cart === undefined ? {} : state.cart;
  return f;
}

export default connectWithStore(store, QuantityGroupToggler, mapStateToProps);
