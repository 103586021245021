import React from 'react'
import ReactDOM from 'react-dom'
import { connectWithStore } from '../../utils'
import { fetchSearchCompanies, clearSearchCompanies, setActiveCompany } from '../../api/companies/actions'
import { createList, createPromotionList, addListToCompany, fetchLists } from '../../api/lists/actions'
import { updateUser } from '../../api/users/actions'
// import { fetchLists } from '../../api/lists/actions'
import { store } from '../../store'
import SearchInput from '../../components/search/searchInput'


class CopyListToCompany extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedCompany: "",
      addSuccess: false
    }
  }

  selectCompany = (company) => (e) => {
    this.setState({ selectedCompany: company })
    this.props.dispatch(clearSearchCompanies())
  }

  handleClick() {
    Promise.resolve(this.props.dispatch(addListToCompany(this.props.list.name, this.props.list.products, this.state.selectedCompany.account_id)))
      .then(() => {
        this.setState({ addSuccess: true })
      })
  }

  render() {
    var selectableCompanies;
    if (this.props.search_companies && this.props.search_companies.search_companies) {
      selectableCompanies = this.props.search_companies.search_companies
    }

    return (
      <div className="companiesList-container copylist">
        <p>Leitaðu að viðskiptavini sem mun fá <strong>{this.props.list.name}</strong> listann afritaðan.</p>
        <div className="companiesList-results">
          {this.props.user.companyOverride &&
            <SearchInput showSpinner skipRedirect={true} hideClear={false} fetchMethod={fetchSearchCompanies} clearMethod={clearSearchCompanies} placeholder="Leita að viðskiptavini" searchResultsAttributeName="search_companies" showResults={false} />
          }
          {!this.props.user.companyOverride && this.props.user.hasManyCompanies &&
            <SearchInput showSpinner skipRedirect={true} hideClear={false} fetchMethod={fetchSearchCompanies} clearMethod={clearSearchCompanies} placeholder="Leita að viðskiptavini" searchResultsAttributeName="search_companies" showResults={false} allowEmptyString={true} searchOnLoad={true} />
          }
          <ul className="companiesList">
            {selectableCompanies &&
              selectableCompanies.map((company) =>
                <li key={company.account_id}>
                  <a href="#" onClick={this.selectCompany(company)}>{company.name}
                    {this.props.user.companyOverride && company.blocked &&
                      <strong className="text-red"> (lokaður)</strong>
                    }
                  </a>
                </li>
              )
            }
          </ul>
          {this.state.selectedCompany &&
            <div class="xs-mb-20">Afrita lista á {this.state.selectedCompany.name} <button onClick={() => this.setState({selectedCompany: null})} class="btn btn-danger btn-sm">Hreinsa</button></div>
          }
          {this.state.addSuccess ?
            <p className="text-green"><strong>Aðgerð tókst</strong></p>
            :
            <React.Fragment>
              {this.state.selectedCompany &&
                <div className="modal-button text-center">
                  <button disabled={this.state.addSuccess} type="button" className={'btn btn-success xs-ml-10'} onClick={this.handleClick.bind(this)}>Afrita</button>
                </div>
              }
            </React.Fragment>
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  var f = {}
  f.search_companies = (state.search_companies === undefined) ? {} : state.search_companies
  f.user = (state.user === undefined) ? {} : state.user
  return f;
}

export default connectWithStore(store, CopyListToCompany, mapStateToProps);

