import React from 'react';
import { connect } from 'react-redux'
import { fetchListsOverview } from '../../api/lists/actions'
import { fetchUsers } from '../../api/users/actions'
import { fetchSales } from '../../api/sales/actions'
import { fetchInvoices } from '../../api/invoices/actions'
import { fetchContacts } from '../../api/contacts/actions'
// import * as Ps from 'perfect-scrollbar';
import User from '../../components/settings/user'
import MyLists from '../../components/settings/myLists'
import Accesses from '../../components/settings/accesses'
import Orders from '../../components/settings/orders'
import Invoices from '../../components/settings/invoices'
import Transactions from '../../components/settings/transactions'
import Claims from '../../components/settings/claims'
import SalesReport from '../../components/settings/salesReport'
import CustomerContact from '../../components/settings/customer_contact';
// import { mapOrder, sortListByOrder } from '../../utils/index.js'
// import { DragDropContext } from 'react-dnd-cjs'
// import MultiBackend from 'react-dnd-multi-backend'
// import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch'


const NavItem = ({ id, title, active }) => (
    <li className='nav-item'>
        <a className={`${active ? 'active' : ''} nav-link`} data-toggle="tab" href={`#${id}`} role="tab" aria-controls={id}>{title}</a>
    </li>
)

class Settings extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            myListsVisible: false
        }
    }

    componentDidMount() {
        this.fetchLists()
        this.fetchUsers()
        this.fetchContacts()
        this.fetchInvoices()
        var _this = this
        var hrefClick = (href) => {
            window.location.hash = href
            setTimeout(() => {
                _this.setState({ myListsVisible: href === '#mylists' })
            }, 400)
        }

        $(".nav-item a").click(function () {
            hrefClick($(this).attr('href'))
        })
        $("#user-sidebar-mobile ul li a").click(function (e) {
            e.preventDefault();
            var href = $(this).attr('href').split("#")
            href = `#${href[href.length - 1]}`
            $(".nav-item a[href='" + href + "']").click()
        })

        setTimeout(() => {
            var hash = window.location.hash
            if (hash) {
                $(`.nav-tabs .nav-item a[href='${hash}']`).click()
            }
        }, 200)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user.activeCompany.account_id !== this.props.user.activeCompany.account_id) {
            this.props.dispatch(fetchContacts(nextProps.user.activeCompany.account_id))
            this.props.dispatch(fetchInvoices(nextProps.user.activeCompany.account_id))
            this.props.dispatch(fetchSales())
        }
    }

    fetchLists() { this.props.dispatch(fetchListsOverview()) }
    fetchUsers() { this.props.dispatch(fetchUsers()) }
    fetchSales() { this.props.dispatch(fetchSales()) }
    fetchContacts() { this.props.dispatch(fetchContacts(this.props.user.activeCompany.account_id)) }
    fetchInvoices() { this.props.dispatch(fetchInvoices(this.props.user.activeCompany.account_id)) }


    render() {
        if (this.props.user.isAuthenticated) {
            return (

                <div className="row">
                    <div className="col-12" id="settings">
                        <ul className="nav nav-tabs d-none d-sm-flex" id="myTab" role="tablist">
                            <NavItem id={"user"} title={"Notandi"} active={true} />
                            {this.props.user.canPurchase &&
                                <NavItem id={"mylists"} title={"Mínir listar"} />
                            }
                            {((this.props.user.companyAdmin || this.props.user.companyOverride) && !this.props.user.independentUser) &&
                                <NavItem id={"accesses"} title={"Aðgangar"} />
                            }
                            {(this.props.user.canSeeInvoices || this.props.user.canPurchase) &&
                                <NavItem id={"orders"} title={"Pantanir"} />
                            }
                            {(this.props.user.canSeeInvoices || this.props.user.canPurchase) &&
                                <NavItem id={"invoices"} title={"Reikningar"} />
                            }
                            {this.props.user.canSeeInvoices &&
                                <NavItem id={"transactions"} title={"Hreyfingar"} />
                            }
                            {this.props.user.canSeeInvoices &&
                                <NavItem id={"claims"} title={"Kröfur"} />
                            }
                            {this.props.user.companyOverride &&
                                <NavItem id={"salesReport"} title={"Söluskýrsla"} />
                            }
                            {this.props.user.activeCompany && this.props.user.activeCompany.customer_contact &&
                                <NavItem id={"customerContact"} title={"Tengiliður"} />
                            }
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane active" id="user" role="tabpanel">
                                {this.props.user.id &&
                                    <User {...this.props} />
                                }
                            </div>
                            <div className="tab-pane" id="mylists" role="tabpanel">
                                <MyLists {...this.props} myListsVisible={this.state.myListsVisible} />
                            </div>
                            {(this.props.user.companyAdmin || this.props.user.companyOverride) &&
                                <div className="tab-pane" id="accesses" role="tabpanel">
                                    <Accesses {...this.props} lists={this.props.lists} fetchUsers={this.fetchUsers.bind(this)} />
                                </div>
                            }
                            <div className="tab-pane" id="orders" role="tabpanel">
                                <Orders {...this.props} />
                            </div>
                            <div className="tab-pane" id="invoices" role="tabpanel">
                                <Invoices {...this.props} />
                            </div>
                            <div className="tab-pane" id="transactions" role="tabpanel">
                                <Transactions {...this.props} />
                            </div>
                            <div className="tab-pane" id="claims" role="tabpanel">
                                <Claims {...this.props} />
                            </div>
                            <div className="tab-pane" id="salesReport" role="tabpanel">
                                <SalesReport {...this.props} />
                            </div>
                            {this.props.user.activeCompany && this.props.user.activeCompany.customer_contact &&
                                <div className="tab-pane" id="customerContact" role="tabpanel">
                                    <CustomerContact {...this.props} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )
        }
        else {
            window.location = window.garriConfig.storeUrl
        }
    }
}

function mapStateToProps(state) {
    var f = {
        user: {},
        users: {},
        invoices: {},
        claims: {},
        transactions: {},
        lists: {},
        search_products: {},
        sales: {}
    }

    f.user = (state.user === undefined) ? {} : state.user
    f.cart = (state.cart === undefined) ? {} : state.cart
    f.users = (state.users === undefined) ? {} : state.users
    f.invoices = (state.invoices === undefined) ? {} : state.invoices
    f.claims = (state.claims === undefined) ? {} : state.claims
    f.transactions = (state.transactions === undefined) ? {} : state.transactions
    f.lists = (state.lists === undefined) ? {} : { lists: state.lists.lists.filter(f => f !== null).filter((f) => f.id !== undefined) }
    f.search_products = (state.search_products === undefined) ? {} : state.search_products
    f.sales = (state.sales === undefined) ? {} : state.sales
    return f;
}

// export default connect(mapStateToProps)(DragDropContext(MultiBackend(HTML5toTouch))(Settings));
export default connect(mapStateToProps)(Settings);


