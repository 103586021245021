import React from "react";

class MailTo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <a href={`mailto:${this.props.email}`}>{this.props.email}</a>
    );
  }
}

class CallTo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <a href={`tel:${this.props.phone}`}>{this.props.phone}</a>
    );
  }
}

class CustomerContact extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="customer-contact-tab">
        <h3 className="mb-4">Tengiliður</h3>
        <div className="customer-contact-tab__info d-flex flex-column">
            <p>{this.props.user.activeCompany.customer_contact.name}</p>
            <MailTo email={this.props.user.activeCompany.customer_contact.email} />
            <CallTo phone={this.props.user.activeCompany.customer_contact.phone} />
        </div>

      </div>
    );
  }
}

export default CustomerContact

