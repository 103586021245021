import fetch from 'isomorphic-fetch'
import jwtDecode from 'jwt-decode';
import qs from 'qs'
import TokenStorage from '../../storages/token-storage'

import { checkHttpStatus,
    handleNetworkError,
    parseJSON,
    getStore,
} from '../../utils'

export const REQUEST_FETCH_REPORT = 'REQUEST_FETCH_REPORT'
export const RECEIVE_REPORT_SUCCESS = 'RECEIVE_REPORT_SUCCESS'
export const RECEIVE_REPORT_ERROR = 'RECEIVE_REPORT_ERROR'

const requestFetchReport = () => {
    return {
        type: REQUEST_FETCH_REPORT
    }
}

const receiveReportSuccess = (response) => {
    return {
        type: RECEIVE_REPORT_SUCCESS,
        data: response
    }
}

const receiveReportError = (response) => {
    return {
        type: RECEIVE_REPORT_ERROR
    }
}

export const fetchReport = (data) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        dispatch(requestFetchReport());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/report/?${qs.stringify(data)}`, {
            method: 'GET',
            headers: headers
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receiveReportSuccess(response));
            } catch (e) {
                dispatch(receiveReportError(response))
            }


        })
        .catch(handleNetworkError);
    };
}
