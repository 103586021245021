import React from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { fetchLists } from '../../api/lists/actions'
import { updateProducts } from '../../api/products/actions'
import { fetchContacts } from '../../api/contacts/actions'
import { fetchShipping } from '../../api/shipping/actions'
import { dimissSalesError } from '../../api/checkout/actions'

import Button from '../../components/button'
import ProductsView from './steps/productsView'
import ConfirmView from './steps/confirmView'
import DeliveryView from './steps/deliveryView'
import OrderPurchased from './steps/orderPurchased'
import ValitorConfirm from './steps/valitorConfirm';
import Warning from './../modal/warning'
import Modal from './../modal'

import { clearSearchProducts, fetchAddonProducts } from '../../api/products/actions'

let timer;

const UPDATE_PRODUCTS_INTERVAL = 1000*60*5 // every 5 minutes

class Checkout extends React.Component {

    constructor(props) {
        super(props)
    }

    doUpdateProducts() {
        const {dispatch} = this.props
        dispatch(updateProducts(this.props.cart.products.filter(pp => pp.quantity > 0).map(pp => pp.product.item_id)))
    }

    doFetchAddonProducts() {
        let idList = []
        Object.keys(this.props.cart.products).map((k) => {
            this.props.cart.products[k].product.addon_products.map(r => idList.push(r))
        })
        this.setState({fetchedAddonProducts: true})
        this.props.dispatch(fetchAddonProducts(idList))
    }

    dismissError() {
        const {dispatch} = this.props
        dispatch(dimissSalesError())
    }

    componentDidMount() {
        const {dispatch} = this.props
        dispatch(this.doUpdateProducts.bind(this))
        dispatch(fetchShipping())
        this.doFetchAddonProducts()

        this.doUpdateProducts()
        
        timer = setInterval(() => {
            dispatch(this.doUpdateProducts.bind(this))
        }, UPDATE_PRODUCTS_INTERVAL)
    }

    componentWillMount() {
        const {dispatch} = this.props
        this.props.dispatch(fetchLists())
    }

    componentWillUnMount() {
        clearTimeout(timer)
    }

    componentDidUpdate() {
        $('[data-toggle="tooltip"]').tooltip()
    }

    renderError() {
        const {dispatch} = this.props

        let productList = []
        let errorText = "Ekki tókst að senda inn pöntun þar sem lagerstaða á eftirfarandi vörum hefur breyst. Vinsamlegast farðu yfir pöntunina og reyndu aftur:"
        if (this.props.checkout.order_error && this.props.checkout.order_error.products) {
            for (var product of this.props.checkout.order_error.products) {
                productList.push(<li>{product['product_name']} ({product['item_id']})</li>)
            }
        }
        else {
            errorText = "Villa kom upp við að senda inn pöntun. Vinsamlegast reyndu aftur, en ef vandamálið heldur áfram værum við þakklát ef þú myndir láta okkur vita. Þannig getum við bætt upplifun þína af vefverslun Garra."
        }
        
        
        let modalProps = {
            title: null,
            body: Warning,
            bodyProps: {
                title: "Athugið",
                jsx: <div>{errorText}<ul>{productList}</ul></div>,
                confirm: () => {
                    Promise.resolve(dispatch(this.dismissError.bind(this)))
                    .then(() => {
                        dispatch(this.doUpdateProducts.bind(this))
                    })
                },
                confirmText: "Í lagi",
            }
        }
        ReactDOM.render(React.createElement(Modal, modalProps), document.getElementById('modal-container'));
        return null
    }

    apply() {
        window.location.href = '/nýskrá/'
    }

    render() {
        if (this.props.user.isAuthenticated) {
            if (!this.props.user.canPurchase) {
                return <div className="text-justify">
                    <h2>Innkauparéttindi ekki til staðar</h2>
                    <p>Þú ert ekki með innkauparéttindi og getur því ekki lagt inn pöntun.</p>
                </div>
            }
            if (this.props.checkout.order_error)  {
                this.renderError()
            }
            return <this.props.currentStep {...this.props} />
        } else {
            return <div className="text-justify">
                <h2>Viðskiptavinir Garra ehf</h2>
                <p>Aðeins skráðir viðskiptavinir Garra ehf geta pantað í gegnum þessa gátt.</p>
                <Button classNames={"checkout btn-success mb-4 mw-100"} innerHTML={(<div>Sækja um aðgang<img src={window.garriConfig.icons.arrow} alt="Sæktu um aðgang" width="22" /></div>)} handleClick={this.apply.bind(this)} />
            </div>
        }
    }
}


function mapStateToProps(state) {

    var f = {
        user: {},
        cart: {},
        lists: {},
        shipping: {},
    }
    f.user = (state.user === undefined) ? {} : state.user
    f.cart = (state.cart === undefined) ? {} : state.cart,
    f.lists = (state.lists === undefined) ? {} : state.lists.lists
    f.search_products = (state.search_products === undefined) ? {} : state.search_products
    f.checkout = (state.checkout === undefined) ? {} : state.checkout
    f.contacts = (state.contacts === undefined) ? {} : state.contacts
    f.addonProducts = (state.addonProducts === undefined) ? {} : state.addonProducts
    f.shipping = (state.shipping === undefined) ? {} : state.shipping

    f.currentStep = ProductsView
    if(f.checkout.step === 2){
        f.currentStep = DeliveryView
    }
    if(f.checkout.step === 3){
        f.currentStep = ConfirmView
    }
    if(f.checkout.step === 307) {
        f.currentStep = ValitorConfirm
    }
    if(f.checkout.submitted_order){
        f.currentStep = OrderPurchased
    }

    return f;
}

export default connect(mapStateToProps)(Checkout);
