import React from "react";
import Product from '../../../components/product'
import ProductLarge from '../../../components/product/productLarge'
import { connectWithStore } from '../../../utils'
import { store } from '../../../store'

class AddonProductsContainer extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        const {
            addonProducts,
            user,
            dispatch
        } = this.props

        return (
            <div className="col-12 col-xxl-3 order-suggestions-container">
                <h2>Hefur þú prófað þessar vörur?</h2>
                <div className="relative">
                    <div className="order-suggestions">
                        {addonProducts && addonProducts.productList && addonProducts.productList.length > 0 && addonProducts.productList.filter((product) => product.avail_physical > 0)
                            .map((product) =>
                                <Product
                                    dispatch={dispatch}
                                    key={`product-small-checkout-${product.item_id}`}
                                    product={product}
                                    viewName={ProductLarge}
                                    user={user}
                                />
                            )}
                    </div>
                    <div className="overflower"></div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {

    var f = {}
    f.user = (state.user === undefined) ? {} : state.user
    f.addonProducts = (state.addonProducts === undefined) ? {} : state.addonProducts


    return f;
}

export default connectWithStore(store, AddonProductsContainer, mapStateToProps);
