import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { user } from './api/user/reducers'
import { users } from './api/users/reducers'
import { lists } from './api/lists/reducers'
import { sales } from './api/sales/reducers'
import { contacts } from './api/contacts/reducers'
import { claims } from './api/claims/reducers'
import { report } from './api/salesReport/reducers'
import { transactions } from './api/transactions/reducers'
import { checkout } from './api/checkout/reducers'
import { invoices } from './api/invoices/reducers'
import { shipping } from './api/shipping/reducers'
import { companies, search_companies } from './api/companies/reducers'
import {
    products,
    search_products,
    product,
    prices,
    addonProducts,
    quantityGroupProducts,
    selected_products,
    restockedProductNotification
} from './api/products/reducers'
import { cart, carts } from './api/cart/reducers'
import { carousel } from './api/carousel/reducers'
import { userLoginSuccess, AUTH_LOGIN_SUCCESS, AUTH_LOGOUT } from './api/user/actions'
import { composeWithDevTools } from 'redux-devtools-extension';
import TokenStorage from './storages/token-storage'

const loggerMiddleware = createLogger()

const reducers = combineReducers({
    user,
    users,
    products,
    cart,
    carts,
    lists,
    checkout,
    search_products,
    carousel,
    sales,
    contacts,
    claims,
    report,
    transactions,
    companies,
    search_companies,
    product,
    selected_products,
    prices,
    addonProducts,
    invoices,
    quantityGroupProducts,
    restockedProductNotification,
    shipping,
})



function redirectMiddleware({getState, dispatch}) {
    return (next) => (action) => {
        if (typeof action === 'object' && action.hasOwnProperty('type')) {
            
            let url = action.type === AUTH_LOGOUT ? garriConfig.storeUrl : window.location.pathname;
            if (action.type === AUTH_LOGOUT || (action.type === AUTH_LOGIN_SUCCESS && action.initiatedByUser)) {
                if (window.location.pathname === "/") {
                    url = garriConfig.storeUrl;
                }
                next(action);
                window.location = url
            }
        }

        return next(action);
    };
}

let middleware = null
// if(process.env.NODE_ENV === 'production') {
//     middleware = applyMiddleware(thunkMiddleware, redirectMiddleware)
// } else {
    middleware = composeWithDevTools(applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
        redirectMiddleware,
    ))
// }

export const store = createStore(reducers, middleware)

const token = TokenStorage.getItem('token');
if (token !== null) {
    store.dispatch(userLoginSuccess(token));
}

