import fetch from 'isomorphic-fetch'
import jwtDecode from 'jwt-decode';
import qs from 'qs'
import TokenStorage from '../../storages/token-storage'

import { checkHttpStatus,
    handleNetworkError,
    parseJSON,
    getStore,
} from '../../utils'

export const REQUEST_FETCH_TRANSACTIONS = 'REQUEST_FETCH_TRANSACTIONS'
export const RECEIVE_TRANSACTIONS_SUCCESS = 'RECEIVE_TRANSACTIONS_SUCCESS'
export const RECEIVE_TRANSACTIONS_ERROR = 'RECEIVE_TRANSACTIONS_ERROR'

const requestFetchTransactions = () => {
    return {
        type: REQUEST_FETCH_TRANSACTIONS
    }
}

const receiveTransactionsSuccess = (response) => {
    return {
        type: RECEIVE_TRANSACTIONS_SUCCESS,
        data: response
    }
}

const receiveTransactionsError = (response) => {
    return {
        type: RECEIVE_TRANSACTIONS_ERROR
    }
}

export const fetchTransactions = (data) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        dispatch(requestFetchTransactions());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/transactions/?${qs.stringify(data)}`, {
            method: 'GET',
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveTransactionsSuccess(response));
            } catch (e) {
                dispatch(receiveTransactionsError(response))
            }


        })
        .catch(handleNetworkError);
    };
}
