import React from 'react';
import SettingsBox from '../settingsBox'
import { NumToIsk } from '../../utils'
import Sale from '../sale'
import SaleLine from '../sale/saleLine'
import SaleComplete from '../sale/saleComplete'
import { fetchSales } from '../../api/sales/actions'
import moment from 'moment'
import ReactPaginate from 'react-paginate'

class Orders extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
          initialPage: 0
        }
    }

    renderSelectYears() {
      var years = [];
      for(var i = moment().year(); i >= 2004 ; i--){
        years.push(<option key={`year-select-${i}`} value={`year-select-${i}`}>{i}</option>)
      }
      return (
        <select name="year">
          <option value="all">Öll ár</option>
          {years}
        </select>
      )
    }

    renderSelectMonths() {
      var months = ['Janúar','Febrúar','Mars','Apríl','Maí','Júní','Júlí','Ágúst','September','Október','Nóvember','Desember']
      months = months.map((el) => <option key={`month-select-${el}`} value={`month-select-${el}`}>{el}</option>
      )
      return (
        <select name="month">
          <option name="all">Allir mánuðir</option>
          {months}
        </select>
      )
    }

    handlePageClick(e) {
      var idx = e.selected
      if(this.state.initialPage === idx){
        return
      }
      this.setState({initialPage: idx}, () => {
        this.props.dispatch(fetchSales(idx))
      })
    }

    render() {
        return (
          <div>{/*
            <h2>Notendastillingar</h2>
            <SettingsBoxes {...this.props} />*/}
            {/*<h3>Leit í eldri pöntunum</h3>
            <div className="row oler-orders-filter">
              {this.renderSelectYears()}
              {this.renderSelectMonths()}
            </div>*/}
            <h2>Eldri pantanir</h2>
            <table className="table older-orders-table ">
              <thead>
                <tr>
                  <th>Dags</th>
                  <th>ID</th>
                  <th>Staða</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.props.sales.sales.map((sale,i) => 
                  [<Sale
                    key={`sale-line-${sale.sale_id}`}
                    sale={sale}
                    viewName={SaleLine}
                    idx={i}
                    dispatch={this.props.dispatch}
                    plusIcon={window.garriConfig.icons.plusIcon}
                    minusIcon={window.garriConfig.icons.minusIcon}
                  />,<SaleComplete
                    key={`sale-line-complete-${sale.sale_id}`}
                    dispatch={this.props.dispatch}
                    sale={sale}
                    complete_sale={sale.complete_sale}
                    user={this.props.user}
                  />]
                )}
              </tbody>
            </table>
            {(this.props.sales.next || this.props.sales.previous) &&
              <div className="orders-pagination">
                <ReactPaginate
                  previousLabel={<i className="fa fa-chevron-left"></i>}
                  nextLabel={<i className="fa fa-chevron-right"></i>}
                  breakLabel={<a className="dots">...</a>}
                  breakClassName={"break-me"}
                  pageCount={this.props.sales.pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick.bind(this)}
                  initialPage={this.state.initialPage}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            }
          </div>
        )
    }
}

class SettingsBoxes extends React.Component {

  render() {
    return (
      <div className="row settings-box-container">
        <SettingsBox
            image={this.props.home}
            innerHTML={
                <div>
                    <p className="title">Afhendingarstaður<i className="fa fa-pencil"></i></p>
                    <p>Veitingastaður Gumma</p>
                    <p>Búvöllum 3</p>
                    <p>310 Akranesi</p>
                    <p>Ísland</p>
                </div>
            }
        />
        <SettingsBox
            image={this.props.userIcon}
            innerHTML={
                <div>
                    <p className="title">Tengiliður<i className="fa fa-pencil"></i></p>
                    <p>Guðmundur Guðmundsson</p>
                    <p>gummi@veitingastadur.is</p>
                    <p>(+354) 555 5555</p>
                </div>
            }
        />
        <SettingsBox
            image={this.props.calendar}
            innerHTML={
                <div>
                    <p className="title">Dagsetning</p>
                    <input type="text" />
                    <input type="text" />
                </div>
            }
        />
        <SettingsBox
            image={this.props.building}
            innerHTML={
                <div>
                    <p className="title">Afhendingarmáti</p>
                    <label><input type="checkbox" />Senda</label>
                    <label><input type="checkbox" />Sækja í vöruhús</label>
                </div>
            }
        />
      </div>
    )
  }
}


export default Orders;