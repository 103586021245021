import React from 'react';

class ProductTiny extends React.Component {

    constructor(props) {
        super(props)
    }

    onClick(product, e) {
        this.props.onClick(product)
    }


    render() {
        const {product} = {...this.props}
        return (
            <div className="tiny-product row" onClick={this.onClick.bind(this, this.props.product)} title={product.product_name}>
                <div className="product-name">
                    {window.garriConfig.displayProductLinks ?
                        <a href={`/vara/${product.item_id }`}>{ product.product_name }</a>
                    :
                        product.product_name
                    }
                </div>
                <div className="product-description">{product.item_weight ? `${product.item_weight} ` : ''}{product.qty_unit}</div>
            </div>
        )
    }
}

ProductTiny.defaultProps = {
    classNames: '',
}


export default ProductTiny;