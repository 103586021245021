import $ from 'jquery';
import React from 'react';
import { clearSearchProducts, fetchSearchProducts } from '../../api/products/actions';
import { store } from '../../store';
import { connectWithStore, Spinner } from '../../utils';
import SearchResults from './searchResults';
var _ = require('lodash');

class SearchInput extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            search_text: "" + this.props.q || ""
        }
        this.onChange = this.onChange.bind(this)
        this.onType = this.onType.bind(this)
        this.callSearch = this.callSearch.bind(this)
        this.callSearchThrottle = _.debounce(value => this.callSearch(value), 300);
    }

    componentDidMount() {
        if (!this.props.modalSearch) {
            $(this.refs[this.props.searchResultsAttributeName]).focus()
        }
        if (window.history.state && window.history.state.search_params !== "" && this.state.search_text == '' && (($('.modal').length > 0 && this.props.modalSearch) || ($('.modal').length == 0 && !this.props.modalSearch))) {
            this.setState({ search_text: window.history.state.search_params });
            this.callSearchThrottle(window.history.state.search_params);
        } else {
            this.doSearch();
        }


        setTimeout(function () {
            $('.search-box').each(function () {
                if ($(this).val() !== '') {
                    $(this).select();
                }
            });
        }, 100)
    }

    clearSearch() {
        clearTimeout(this.timer)
        this.setState({ search_text: "" })
        window.history.pushState(null, '', window.location)
        this.props.dispatch(this.props.clearMethod())
    }

    doSearch() {
        if (this.props.searchOnLoad && this.state.search_text.length > 2) {
            const { dispatch } = this.props
            dispatch(this.props.fetchMethod(this.state.search_text))
        }
        if (this.props.searchOnLoad && this.state.search_text.length <= 2 && this.props.allowEmptyString) {
            const { dispatch } = this.props
            dispatch(this.props.fetchMethod(this.state.search_text))
        }
    }

    callSearch() {
        const { dispatch } = this.props
        let search_text = this.state.search_text


        if (search_text.length >= 2) {
            dispatch(this.props.fetchMethod(search_text, this.props.modalSearch))
        } else {
            if (this.props.allowEmptyString) {
                dispatch(this.props.fetchMethod(search_text, this.props.modalSearch))
            }
        }

    }

    callSearchThrottle(value) {
        this.callSearch(value)
    }

    onChange(e) {
        const { dispatch } = this.props
        var search_text = e.target.value;
        this.setState({ search_text: search_text })
        if (!search_text) {
            window.history.pushState(null, '', window.location)
            return dispatch(this.props.clearMethod())
        }

        if (search_text.length >= 2) {
            this.callSearchThrottle(search_text)
        } else {
            if (this.props.allowEmptyString) {
                this.callSearchThrottle("")
            }
        }
    }

    onType(e) {
        const { dispatch } = this.props
        var search_text = e.target.value;
        this.setState({ search_text: search_text })
        if (!search_text) {
            return dispatch(this.props.clearMethod())
        }
    }

    doSubmit(e) {
        return
        if (e.key == 'Enter' && !this.props.skipRedirect && window.location.pathname !== "/leitarniðurstöður/") {
            window.location.href = '/leitarniðurstöður/?q=' + e.target.value
        }
    }

    render() {
        return (
            <div className="search-input">
                <div className="input-container">
                    {this.props.searchOnType ?
                        <input autoFocus className="search-box" ref={this.props.searchResultsAttributeName} type="text" placeholder={this.props.placeholder} onChange={this.onChange} onKeyPress={this.doSubmit.bind(this)} value={this.state.search_text} />
                        :
                        <input autoFocus className="search-box" ref={this.props.searchResultsAttributeName} type="text" placeholder={this.props.placeholderMobile} onChange={this.onType} onKeyPress={this.doSubmit.bind(this)} value={this.state.search_text} />
                    }

                    <span className="clear-search" onClick={this.clearSearch.bind(this)}>
                        {!this.props.hideClear && this.state.search_text && this.state.search_text.length !== 0 &&
                            <i className="fa fa-times-circle" aria-hidden="true"></i>
                        }
                    </span>
                    {this.props.isFetching[this.props.searchResultsAttributeName] && this.props.showSpinner &&
                        <Spinner />
                    }
                </div>
                {!this.props.searchOnType &&
                    <button onClick={this.callSearch}>Leita</button>
                }
                {this.props.showResults &&
                    <SearchResults search_results={this.props.search_results[this.props.searchResultsAttributeName]} addProduct={this.props.addProduct} dispatch={this.props.dispatch} />
                }
            </div>
        )
    }
}

SearchInput.defaultProps = {
    fetchMethod: fetchSearchProducts,
    clearMethod: clearSearchProducts,
    placeholder: "Sláðu inn vöruheiti, innihald eða lýsingu",
    placeholderMobile: "Sláðu inn leitarorð",
    searchResultsAttributeName: 'search_products',
    showResults: false,
    searchOnLoad: false,
    showSpinner: false,
    allowEmptyString: false,
    q: "",
    searchOnType: true,
}

function mapStateToProps(state) {
    var f = {
        search_results: {},
        isFetching: {},
    }

    f.search_results['search_products'] = (state.search_products === undefined) ? {} : state.search_products.search_products
    f.search_results['search_companies'] = (state.search_companies === undefined) ? {} : state.search_companies.search_companies
    f.isFetching['search_products'] = (state.search_products === undefined) ? false : state.search_products.isFetching
    f.isFetching['search_companies'] = (state.search_companies === undefined) ? false : state.search_companies.isFetching

    return f;
}

export default connectWithStore(store, SearchInput, mapStateToProps);