import React from 'react'

class ProductImageModal extends React.Component {

  render(){

    return (
      <div className="text-center" style={{padding: '30px'}}>
        <h3 style={{marginBottom: '50px'}}>Mynd fyrir <b>{this.props.name}</b></h3>
        <img src={this.props.image} alt={this.props.alt} {...this.props.attrs} />
      </div>
    )
  }
}

module.exports = ProductImageModal;