import moment from 'moment'
import { SESSION_STORAGE_NAME } from '../constants'

export const StateToSessionStorage = (state, stateName='cart') => {
    let currStates = window.localStorage.getItem(SESSION_STORAGE_NAME)
    if(currStates){
        currStates = JSON.parse(currStates)
    }else{
        currStates = {}
    }
    if (stateName === 'cart') {
        if (currStates.user && currStates.user.id) {
            stateName = `cart-${currStates.user.id}`
        }
    }
    currStates[stateName] = {...state}
    currStates.expires = moment().add(1, 'days')
    window.localStorage.setItem(SESSION_STORAGE_NAME, JSON.stringify(currStates))
}

export const GetSessionStorageState = (defaultState, stateName='cart') => {
    let localState = window.localStorage.getItem(SESSION_STORAGE_NAME)
    let currStates = {}
    if(localState){
        localState = JSON.parse(localState)
        if (stateName === 'cart') {
            if (localState.user && localState.user.id) {
                stateName = `cart-${localState.user.id}`
            }
        }
        currStates = localState[stateName] || defaultState
    }else{
        currStates = defaultState
    }
    
    if (stateName === 'cart') {
        if (currStates && currStates.products && JSON.stringify(currStates.products) === '{}') {
            currStates.products = []
        }
    }

    if(localState && moment(localState.expires) < moment()){
        let newState = {...defaultState, expires: moment().add(1, 'days')}
        // if(localState){
        //     newState.shops = {...localState.shops}
        // }
        currStates = newState
        window.localStorage.setItem(SESSION_STORAGE_NAME, JSON.stringify(newState))
    }
    return currStates
}

export const ClearSessionStorage = () => {
    delete localStorage[SESSION_STORAGE_NAME]
}
