import React from 'react';

import { NumToIsk, animateCart, getProductQty } from '../../utils'
import Ribbon from '../ribbon'
import Badge from "../badge";
import Favorite from '../favorite'
import VeganIcon from '../vegan-icon'
import WeightIcon from '../weight-icon'
import Price from './price'
import QuantityGroup from './quantityGroup'
import ProductImage from './productImage'
import OrderButton from './orderButton'
import QuantityGroupToggler from './quantityGroupToggler'
import { useShallowEqual } from 'shouldcomponentupdate-children';
import { setProductQuantityGroup } from '../../api/products/actions'
import ReplacementButton from './replacementButton'
import EnvironmentIcon from '../environment-icon';

class ProductSingle extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            quantity: "-",
            showAll: false
        }

        this.hasAnyExtraData = this.hasAnyExtraData.bind(this)
        // this.hasProductDescription = this.hasProductDescription.bind(this)
    }

    componentDidMount() {
        $(".block-file").click(function () {
            if ($(this).find('a').length > 0) {
                window.location = $(this).find('a').attr('href')
            }
        })
        $(".block-image").click(function () {
            if ($(this).find('img').length > 0) {
                window.location = $(this).find('img').attr('src')
            }
        })
        $(".block-video_url").each(function () {
            $(this).data('videoUrl', $(this).html())
            $(this).html('')
        })
        $(".block-video_url").click(function () {
            window.location = $(this).data('videoUrl')
        })

        if (this.props.product.product_description && this.props.product.product_description.length < 430) {
            this.setState({ showAll: true })
        }

    }


    setQuantity(qty, increment = false) {
        const { product } = this.props
        qty = getProductQty(product, qty, increment)
        this.setState({ quantity: qty })
    }

    quantityGroupIncrement(checked) {
        const { dispatch } = this.props
        dispatch(setProductQuantityGroup(this.props.product.item_id, checked))
    }

    hasAnyExtraData() {
        return (this.props.product.product_description !== '' || this.props.product.ingredients !== '')
    }

    render() {
        const { product, user } = { ...this.props }
        if (product.item_id) {
            return (
                <div className={`col-12 product productSingle p-4  ${this.props.classNames} ${product.promotion_expired_product ? 'is-expired' : ''}`} ref="productElement">
                    <Favorite product={product} enabled={this.props.user.isAuthenticated} />
                    <VeganIcon product={product} />
                    <WeightIcon product={product} inList={false} />
                    <Badge product={product} />
                    <Ribbon product={product} />
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                {/* <div className={`${this.hasAnyExtraData() ? 'col-sm-9 col-12' : 'col-sm-12'}`}> */}
                                <div className={`${this.hasAnyExtraData() ? 'col-sm-7 col-12' : 'col-sm-12'}`}>
                                    <div className="flex-row flex top-container">
                                        <div className="image-container">
                                            <ProductImage product={product} zoomable={true} size={"thumb_large"} />
                                            <EnvironmentIcon product={product} />
                                        </div>
                                        <div className={`product-info ${!this.hasAnyExtraData() && 'd-flex wider'}`}>
                                            <div className="spacer">
                                                <h2 className="productname">{product.product_name}</h2>
                                                <div className="priceHeader flex-row">
                                                    <div className="title">Vöru&shy;númer</div>
                                                    <div className="title">
                                                        {this.props.user.isAuthenticated && product.item_weight && ((this.props.user.independentUser) || (this.props.user.activeCompany && this.props.user.activeCompany.independent_company)) ?
                                                            <span>Áætluð þyngd</span>
                                                            :
                                                            'Eining'
                                                        }
                                                    </div>
                                                </div>
                                                <div className="price flex-row">
                                                    <div className="info-content">
                                                        <a className={`${product.new_product_number && 'new-number'}`} href={`/vara/${product.item_id}`}>
                                                            <div className="d-flex align-items-center">
                                                                {product.new_product_number &&
                                                                <span>
                                                                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" baseProfile="tiny" version="1.2" viewBox="0 0 35.2 35.2" width="15" height="15">
                                                                        <circle id="Oval" cx="17.6" cy="17.6" r="17.6" fill="#60a52d" stroke-width="0" />
                                                                        <g id="_" data-name="!">
                                                                            <path id="Shape" d="M16.2,22.7v-13.9h2.8v13.9h-2.8Z" fill="#fff" stroke-width="0" />
                                                                        </g>
                                                                        <circle cx="17.6" cy="25.7" r="1.8" fill="#fff" stroke-width="0" />
                                                                    </svg>
                                                                </span>
                                                                }
                                                                {product.item_id} 
                                                             </div>
                                                            {product.new_product_number && 
                                                            <span className="new-number-info">
                                                                <span className="description-text">Nýtt vörunúmer</span>
                                                            </span>
                                                            }
                                                        </a>
                                                    </div>
                                                    <div className="info-content">{product.item_weight && ((this.props.user.independentUser) || (this.props.user.activeCompany && this.props.user.activeCompany.independent_company)) ? `${product.item_weight} ` : ''}{product.qty_unit}</div>
                                                </div>
                                            </div>
                                            <div className={`spacer ${!this.hasAnyExtraData() && 'pt-lg-5'}`}>
                                                <div className="priceHeader flex-row">
                                                    <div className="title">Pakkning</div>
                                                    {this.props.product.document !== '' && <div className="title">Ítarupplýsingar</div>}
                                                </div>
                                                <div className="price flex-row">
                                                    <div className="info-content">{product.package_description}</div>
                                                    {this.props.product.document !== '' &&
                                                        <div className="info-content">
                                                            <a className="block-file" href={product.document}> Sækja PDF</a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            {this.props.user.isAuthenticated &&
                                                <div className={`spacer ${!this.hasAnyExtraData() && 'pt-lg-5'}`}>
                                                    {((this.props.user.independentUser) || (this.props.user.activeCompany && this.props.user.activeCompany.independent_company)) &&
                                                        <div className="priceHeader flex-row">
                                                            {this.props.user.activeCompany && this.props.user.activeCompany.vsk_active ?
                                                                <div className="title">{product.item_weight ? 'Áætlað einingarverð m/vsk' : 'Verð m/vsk'}</div> :
                                                                <div className="title">{product.item_weight ? 'Áætlað einingarverð' : 'Verð'}</div>
                                                            }
                                                        </div>
                                                    }
                                                    {(!((this.props.user.independentUser) || (this.props.user.activeCompany && this.props.user.activeCompany.independent_company)) && !product.offer) &&
                                                        <div className="priceHeader flex-row">
                                                            <div className="title">Verð</div>
                                                            <div className="title">Mitt verð</div>
                                                            {this.props.product.promotion_expired_product &&
                                                                <div className="title red">Best fyrir</div>
                                                            }
                                                        </div>
                                                    }
                                                    {(!((this.props.user.independentUser) || (this.props.user.activeCompany && this.props.user.activeCompany.independent_company)) && product.offer) &&
                                                        <div className="priceHeader flex-row">
                                                            <div className="title red">Tilboðsverð</div>
                                                            <div className="title d-none">Síðast pantað</div>
                                                        </div>
                                                    }
                                                    <Price product={product} showPrice={this.props.user.isAuthenticated} user={this.props.user} showBestBefore={this.props.product.promotion_expired_product} showItemWeightUnitPrice={true} />
                                                    {product.item_weight > 0.01 &&
                                                        <React.Fragment>
                                                            {(this.props.user.isAuthenticated && !user.activeCompany.independent_company) &&
                                                                <b>Hér birtist kílóverð á vörunni.</b>
                                                            }
                                                            <p>Endanlegt verð á vigtarvörum mun breytast eftir að vara hefur verið tekin til og vigtuð.</p>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            }

                                        </div>
                                    </div>


                                </div>
                                <div className={`${this.hasAnyExtraData() ? 'col-12 col-lg-5 product-description' : 'd-none'}`}>
                                    {product && product.product_description &&
                                        <div className={` ${(product.product_description === '') && "d-none"}`}>
                                            <div className="title pb-2">Vörulýsing</div>
                                            <div className="description pb-4">{this.state.showAll ?
                                                <div>
                                                    {product.product_description}
                                                    {product.product_description.length >= 430 &&
                                                        <p className="see" onClick={() => this.setState({ showAll: false })}>Sjá minna</p>
                                                    }
                                                </div>
                                                :
                                                <div>
                                                    {product.product_description.substring(0, 430)}
                                                    <p className="see" onClick={() => this.setState({ showAll: true })}>Sjá meira</p>
                                                </div>
                                            }</div>
                                        </div>
                                    }
                                    {product && product.ingredients &&
                                        <div className={` ${(product.ingredients === '') ? "d-none" : "pt-lg-5"}`}>
                                            <div className="title">Innihald</div>
                                            <div className="description pb-4" dangerouslySetInnerHTML={{ __html: product.ingredients }} />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="product-footer">
                                        <QuantityGroupToggler
                                            key={`productSingle-qty-group-toggler-${product.item_id}`}
                                            product={product}
                                            quantityGroupIncrement={this.quantityGroupIncrement.bind(this)}
                                            dispatch={this.props.dispatch}
                                            classNames={'smaller xs'} />
                                        <div className="right-block">
                                            <QuantityGroup product={product} quantity={this.state.quantity} setQuantity={this.setQuantity.bind(this)} />
                                            {/* <OrderButton product={product} quantity={this.state.quantity} user={this.props.user} enabled={this.props.user.isAuthenticated && this.props.user.canPurchase} callbackFn={() => animateCart(this.refs.productElement)} /> */}
                                            {(product.avail_physical === 0 && product.replacement_products.length > 0) &&
                                                this.props.user.isAuthenticated && (
                                                    <ReplacementButton
                                                        product={product}
                                                        enabled={
                                                            this.props.user.isAuthenticated &&
                                                            this.props.user.canPurchase
                                                        }
                                                        replaceProduct={this.props.replaceProduct}
                                                    />
                                                )}
                                            <OrderButton product={product} quantity={this.state.quantity} user={this.props.user} enabled={this.props.user.isAuthenticated && this.props.user.canPurchase} callbackFn={() => animateCart(this.refs.productElement)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
        } else { return <React.Fragment /> }
    }
}

ProductSingle.defaultProps = {
    classNames: '',
}


export default useShallowEqual(ProductSingle);