import React from 'react';

import { NumToIsk, animateCart, getProductQty } from '../../utils'
import Price from './price'
import PriceHeader from './priceHeader'
import ProductImage from './productImage'
import OrderButton from './orderButton'
import QuantityGroup from './quantityGroup'
import QuantityGroupToggler from './quantityGroupToggler'
import Ribbon from '../ribbon'
import Badge from "../badge";
import { removeProduct, setProductQuantity } from '../../api/cart/actions'
import {useShallowEqual} from 'shouldcomponentupdate-children';
import {setProductQuantityGroup} from '../../api/products/actions';
import { store } from '../../store'

class ProductSmall extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            quantity: 1
        }
    }

    setQuantity(qty, increment=false) {
        const {product} = this.props
        qty = getProductQty(product, qty, increment)
        if(qty === 0){
            this.props.dispatch(removeProduct(this.props.cartProduct.product.item_id))
            return this.setState({quantity: 1})
        }
        this.props.dispatch(setProductQuantity(qty, this.props.cartProduct.product.item_id))
        this.setState({quantity: qty})
    }

    quantityGroupIncrement(checked) {
        const {dispatch} = this.props
        dispatch(setProductQuantityGroup(this.props.product.item_id, checked))
    }

    render() {
        const {product, cartProduct} = {...this.props}
        return (
            <div className={`product productSmall ${this.props.classNames}`}>
                <Badge product={product} />
                <Ribbon product={product} />
                <div className="desktop-info" ref="productElement2">
                    <div className="flex-row">
                        <div className="image">
                            <ProductImage product={product} />
                        </div>
                        <div className="productname">
                            {window.garriConfig.displayProductLinks ?
                                <a href={`/vara/${product.item_id }`}>{ product.product_name }</a>
                            :
                                product.product_name
                            }
                        </div>
                    </div>
                    <div className="flex-row button-container">
                        { this.props.user.isAuthenticated &&
                            <div className="price-container">
                                <PriceHeader product={product} offer={product.offer} deleted={product.deleted} user={this.props.user}/>
                                <Price product={product} showPrice={this.props.user.isAuthenticated} user={this.props.user} showItemWeightUnitPrice={true}/>
                            </div>
                        }
                        <div className="quantity-group-container">
                            <QuantityGroupToggler
                                key={`productSmall-qty-group-toggler-${product.item_id}`}
                                product={product}
                                quantityGroupIncrement={this.quantityGroupIncrement.bind(this)}
                                store={store}
                                dispatch={this.props.dispatch}
                                classNames={'xs smaller'}
                            />
                        </div>
                        {cartProduct &&
                            <QuantityGroup key={"productSearchResult-desk-small-qty-group-"+cartProduct.product.item_id} product={cartProduct.product} quantity={cartProduct.quantity} setQuantity={this.setQuantity.bind(this)} />
                        }
                        {(!cartProduct && !product.deleted) &&
                            <OrderButton product={product} enabled={this.props.user.isAuthenticated && this.props.user.canPurchase} callbackFn={() => animateCart(this.refs.productElement2)} />
                        }
                        {(!cartProduct && product.deleted) &&
                            <ReplacementButton product={product} enabled={this.props.user.isAuthenticated && this.props.user.canPurchase} callbackFn={() => animateCart(this.refs.productElement2)} />
                        }
                    </div>
                </div>
            </div>

        )
    }
}

ProductSmall.defaultProps = {
    classNames: '',
}


export default useShallowEqual(ProductSmall);