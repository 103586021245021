import jwtDecode from 'jwt-decode';
import { checkHttpStatus, handleNetworkError, parseJSON } from '../../utils'
import TokenStorage from '../../storages/token-storage'

export const RECEIVE_CONTACTS_SUCCESS = 'RECEIVE_CONTACTS_SUCCESS'
export const RECEIVE_CONTACTS_ERROR = 'RECEIVE_CONTACTS_ERROR'
export const REQUEST_CONTACTS = 'REQUEST_CONTACTS'
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS'
export const CREATE_CONTACT_ERROR = 'CREATE_CONTACT_ERROR'


const requestContacts = () => {
    return {
        type: REQUEST_CONTACTS
    }
}

const receiveContactsError = (res) => {
    return {
        type: RECEIVE_CONTACTS_ERROR,
        data: res
    }
}

const receiveContactsSuccess = (res) => {
    return {
        type: RECEIVE_CONTACTS_SUCCESS,
        data: res
    }
}

export const fetchContacts = (account_id) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        dispatch(requestContacts());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/contacts${account_id ? `/?company_id=${account_id}` : ''}`, {
            method: 'GET',
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveContactsSuccess(response));
            } catch (e) {
                dispatch(receiveContactsError(response))
            }
        });
    };
}
