
import {
    RECEIVE_CONTACTS_SUCCESS,
    RECEIVE_CONTACTS_ERROR,
    REQUEST_CONTACTS
} from './actions'


const initialState = {
    contacts: {},
    error: null,
    default_contact: null,
    isFetching: false
}

export function contacts(state = initialState, action) {

    switch(action.type) {
        case REQUEST_CONTACTS:
            state = {...initialState,
                isFetching: true
            }
            return state
        case RECEIVE_CONTACTS_SUCCESS:
            state = {...state,
                contacts: action.data,
                isFetching: false
            }
            state.contacts.results = state.contacts.results.map(c => {
                return {
                    ...c,
                    id: +c.id,
                }
            })
            return state
        case RECEIVE_CONTACTS_ERROR:
            state = {...state,
                contacts: {},
                error: action.data,
                isFetching: false
            }
            return state
        default:
            return state
    }
}
