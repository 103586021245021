import {
    REQUEST_FETCH_CLAIMS,
    RECEIVE_CLAIMS_SUCCESS,
    RECEIVE_CLAIMS_ERROR
} from './actions'
import { SET_ACTIVE_COMPANY } from '../companies/actions'
import { NumToIsk } from '../../utils';
import moment from 'moment'


const initialState = {
    claims: [],
    isFetching: false
}

const claimStatuses = function(status) {
    switch(status) {
        case 0:
            return '';
        case 5:
            return 'Í vinnslu';
        case 10:
            return 'Hafnað';
        case 15:
            return 'Ógreitt';
        case 20:
            return 'Greitt';
        case 25:
            return 'Hlutagreiðsla';
        case 29:
            return 'Til að breyta';
        case 30:
            return 'Bíður breytinga';
        case 90:
            return 'Hætt við';
        default:
            return '';
    }
}

export function claims(state = initialState, action) {

    switch(action.type) {
        case REQUEST_FETCH_CLAIMS:
            state = {...initialState,
                isFetching: true
            }
            return state

        case RECEIVE_CLAIMS_SUCCESS:
            var data = action.data.map((el) => {
                el.claimAmount = `${NumToIsk(el.claimAmount)},00`
                el.dueDate = moment(el.dueDate).format('D.M.YYYY')
                el.finalDueDate = moment(el.finalDueDate).format('D.M.YYYY')
                el.claimStatusDisplay = claimStatuses(el.claimStatus)
                el.claimTransIdDisplay = el.claimTransId.replace("0000000000", "")
                el.customerTransactions = el.customerTransactions.map((t) => {
                    t.amountCur = `${NumToIsk(t.amountCur)},00`
                    t.dueDate = moment(t.dueDate).format('D.M.YYYY')
                    t.transDateOriginal = t.transDate
                    t.transDate = moment(t.transDate).format('D.M.YYYY')
                    return t
                })
                return el
            }).sort((a,b) => {
                var _a = moment(a.dueDate, 'D.M.YYYY')
                var _b = moment(b.dueDate, 'D.M.YYYY')
                if(_a > _b){
                    return -1
                }
                if(_a < _b){
                    return 1
                }
                return 0
            })
            state = {...state,
                claims: data,
                isFetching: false
            }
            return state
        
        case SET_ACTIVE_COMPANY:
            state = {
                ...state,
                claims: [],
                isFetching: false
            }

            return state

        case RECEIVE_CLAIMS_ERROR:
            state = {...state,
                claims: [],
                isFetching: false
            }
            return state

        default:
            return state
    }
}
