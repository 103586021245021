import fetch from 'isomorphic-fetch'
import Cookie from 'js-cookie'
import { checkHttpStatus, handleNetworkError, parseJSON, checkPrices, getActiveCompany } from '../../utils'
import jwtDecode from 'jwt-decode'
import { userRefreshLogin } from '../user/actions'
import { store } from '../../store'
import TokenStorage from '../../storages/token-storage'

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS'
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const REQUEST_ADDON_PRODUCTS = 'REQUEST_ADDON_PRODUCTS'
export const RECEIVE_ADDON_PRODUCTS = 'RECEIVE_ADDON_PRODUCTS'
export const REQUEST_SINGLE_PRODUCT = 'REQUEST_SINGLE_PRODUCT'
export const RECEIVE_SINGLE_PRODUCT = 'RECEIVE_SINGLE_PRODUCT'
export const RECEIVE_PRODUCTS_ERROR = 'RECEIVE_PRODUCTS_ERROR'
export const RECEIVE_ADDON_PRODUCTS_ERROR = 'RECEIVE_ADDON_PRODUCTS_ERROR'
export const REQUEST_SEARCH_PRODUCTS = 'REQUEST_SEARCH_PRODUCTS'
export const RECEIVE_SEARCH_PRODUCTS = 'RECEIVE_SEARCH_PRODUCTS'
export const RECEIVE_SEARCH_PRODUCTS_ERROR = 'RECEIVE_SEARCH_PRODUCTS_ERROR'
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS'
export const CLEAR_SEARCH_PRODUCTS = 'CLEAR_SEARCH_PRODUCTS'
export const REQUEST_PRODUCT_PRICES = 'REQUEST_PRODUCT_PRICES'
export const RECEIVE_PRODUCT_PRICES = 'RECEIVE_PRODUCT_PRICES'
export const RECEIVE_PRODUCT_PRICES_ERROR = 'RECEIVE_PRODUCT_PRICES_ERROR'
export const REQUEST_PRODUCT_AVAIL_UPDATE = 'REQUEST_PRODUCT_AVAIL_UPDATE'
export const RECEIVE_PRODUCT_AVAIL_UPDATE = 'RECEIVE_PRODUCT_AVAIL_UPDATE'
export const RECEIVE_PRODUCT_AVAIL_UPDATE_ERROR = 'RECEIVE_PRODUCT_AVAIL_UPDATE_ERROR'
export const SET_PRODUCT_QUANTITY_GROUP = 'SET_PRODUCT_QUANTITY_GROUP'
export const SELECT_PRODUCT = 'SELECT_PRODUCT'
export const REMOVE_SELECTED_PRODUCT = 'REMOVE_SELECTED_PRODUCT'
export const CLEAR_SELECTED_PRODUCTS = 'CLEAR_SELECTED_PRODUCTS'
export const UPDATE_SELECTED_PRODUCT_QUANTITY = 'UPDATE_SELECTED_PRODUCT_QUANTITY'
export const CLEAR_RESTOCKED_NOTIFICATION = 'CLEAR_RESTOCKED_NOTIFICATION'
export const CREATE_RESTOCKED_NOTIFICATION_SUCCESS = 'CREATE_RESTOCKED_NOTIFICATION_SUCCESS'


export const requestProducts = (namespace="productList") => {
    return {
        type: REQUEST_PRODUCTS,
        namespace: namespace,
    };
};

export const receiveProducts = (response, getNext=false, namespace="productList") => {
    return {
        type: RECEIVE_PRODUCTS,
    get_next: getNext,
        data: response,
        namespace: namespace,
    };
};

export const receiveSingleProduct = (response, getNext=false) => {
    return {
        type: RECEIVE_SINGLE_PRODUCT,
    get_next: getNext,
        data: response,
    };
};

export const requestSingleProduct = (response) => {
    return {
        type: REQUEST_SINGLE_PRODUCT,
        data: response,
    };
};


export const receiveProductsError = () => {
    return {
        type: RECEIVE_PRODUCTS_ERROR,
    };
};

export const selectProduct = (product, quantity) => {
    return {
        type: SELECT_PRODUCT,
        product: product,
        quantity: quantity
    }
}

export const removeSelectedProduct = (product) => {
    return {
        type: REMOVE_SELECTED_PRODUCT,
        product: product
    }
}

export const clearSelectedProducts = () => {
    return {
        type: CLEAR_SELECTED_PRODUCTS
    }
}

export const updateSelectedProductQuantity = (product, quantity) => {
    return {
        type: UPDATE_SELECTED_PRODUCT_QUANTITY,
        product: product,
        quantity: quantity
    }
}


export const fetchProduct = (item_id) => {
    return dispatch => {

        
        let account_id = ''
        try {
            let state = store.getState()
            account_id = getActiveCompany(state)
        } catch(e) {}


        dispatch(userRefreshLogin(TokenStorage.getItem("token")))

        dispatch(requestSingleProduct());

        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/products/${item_id}/${account_id !== '' ? `?company=${account_id}`: ''}`, {
            headers: headers
        })
        .then(parseJSON)
        .then(checkPrices(dispatch))
        .then(response => {

            try {
                dispatch(receiveSingleProduct(response));
            } catch (e) {
                dispatch(receiveProductsError(response))
            }


        })
        .catch(handleNetworkError);
    };
};

export const fetchProducts = (parameters, namespace="productList") => {
    return dispatch => {
        

        dispatch(userRefreshLogin(TokenStorage.getItem("token")));
        dispatch(requestProducts(namespace));

        let account_id = ''
        try {
            let state = store.getState()
            account_id = getActiveCompany(state)
        } catch(e) {}

        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }
        if (account_id !== '' || account_id !== undefined || account_id !== null) {
            parameters += `&company=${account_id}`
        }

        return fetch(`/api/v1/products/?${parameters}`, {
            headers: headers
        })
        .then(parseJSON)
        .then(checkPrices(dispatch))
        .then(response => {

            try {
                dispatch(receiveProducts(response, false, namespace));
            } catch (e) {
                dispatch(receiveProductsError(response))
            }


        })
        .catch(handleNetworkError);
    };
};

export const fetchNextProducts = (path) => {
    return dispatch => {

        let account_id = ''
        try {
            let state = store.getState()
            account_id = getActiveCompany(state)
        } catch(e) {}

        dispatch(userRefreshLogin(TokenStorage.getItem("token")));
        dispatch(requestProducts());

        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        if (account_id !== '' || account_id !== undefined || account_id !== null) {
            path += `&company=${account_id}`
        }


        fetch(path, {
            headers: headers
        })
        .then(parseJSON)
        .then(checkPrices(dispatch))
        .then(response => {
            dispatch(receiveProducts(response, true));
        })
        .catch(handleNetworkError);
    }
}


export const requestSearchProducts = (params) => {
    return {
        type: REQUEST_SEARCH_PRODUCTS,
        data: params,
    };
};

export const receiveSearchProducts = (response, getNext=false, modalSearch=false) => {
    return {
        type:       RECEIVE_SEARCH_PRODUCTS,
        data:       response,
    get_next:       getNext,
    modal_search:   modalSearch
    };
};

export const clearSearchProducts = () => {
    return {
        type: CLEAR_SEARCH_PRODUCTS,
    }
}

export const receiveSearchProductsError = () => {
    return {
        type: RECEIVE_SEARCH_PRODUCTS_ERROR,
    };
};

export const fetchSearchProducts = (parameters, modalSearch=false) => {
    return dispatch => {

        let account_id = ''
        try {
            let state = store.getState()
            account_id = getActiveCompany(state)
        } catch(e) {}

        dispatch(userRefreshLogin(TokenStorage.getItem("token")));
        dispatch(requestSearchProducts(parameters));

        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        if (account_id !== '' || account_id !== undefined || account_id !== null) {
            parameters += `&company=${account_id}`
        }

        fetch(`/api/v1/products/?search=${parameters}`, {
            headers: headers
        })
        .then(parseJSON)
        .then(checkPrices(dispatch))
        .then(response => {

            try {
                dispatch(receiveSearchProducts(response, false, modalSearch));
            } catch (e) {
                dispatch(receiveSearchProductsError(response))
            }


        })
        .catch(handleNetworkError);
    };
};

export const fetchNextSearchProducts = (path) => {
    return dispatch => {
        dispatch(userRefreshLogin(TokenStorage.getItem("token")));
        dispatch(requestSearchProducts());
        
        let account_id = ''
        try {
            let state = store.getState()
            account_id = getActiveCompany(state)
        } catch(e) {}

        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        if (account_id !== '' || account_id !== undefined || account_id !== null) {
            path += `&company=${account_id}`
        }

        fetch(path, {
            headers: headers
        })
        .then(parseJSON)
        .then(checkPrices(dispatch))
        .then(response => {
            dispatch(receiveSearchProducts(response, true));
        })
        .catch(handleNetworkError);
    }
}




export const requestProductPrices = () => {
    return {
        type: REQUEST_PRODUCT_PRICES,
    };
};

export const receiveProductPrices = (response) => {
    return {
        type: RECEIVE_PRODUCT_PRICES,
        data: response,
    };
};

export const receiveProductPricesError = () => {
    return {
        type: RECEIVE_PRODUCT_PRICES_ERROR,
    };
};


export const fetchProductPrices = (id_list, custAccount='') => {
    return dispatch => {


        dispatch(userRefreshLogin(TokenStorage.getItem("token")));

        dispatch(requestProductPrices());

        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        fetch(`/api/v1/products/prices/`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify({id: id_list, account_id: custAccount})
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveProductPrices(response));
            } catch (e) {
                dispatch(receiveProductPricesError(response))
            }


        })
        .catch(handleNetworkError);
    };
};


export const requestAddonProducts = () => {
    return {
        type: REQUEST_ADDON_PRODUCTS,
    };
};

export const receiveAddonProducts = (response, user) => {
    return {
        type: RECEIVE_ADDON_PRODUCTS,
        user: user,
        data: response,
    };
};


export const receiveAddonProductsError = () => {
    return {
        type: RECEIVE_ADDON_PRODUCTS_ERROR,
    };
};



export const fetchAddonProducts = (products) => {
    return dispatch => {

        let user = {}
        try {
            let state = store.getState()
            user = state.user
        } catch(e) {}

        dispatch(userRefreshLogin(TokenStorage.getItem("token")));

        dispatch(requestAddonProducts());

        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        let parameters = ""
        for (let id of products) {
            if (id !== "") {
                parameters += `&ids=${id}`
            }
        }

        return fetch(`/api/v1/products/?${parameters}`, {
            headers: headers
        })
        .then(parseJSON)
        .then(checkPrices(dispatch))
        .then(response => {
            try {
                dispatch(receiveAddonProducts(response, user));
            } catch (e) {
                dispatch(receiveAddonProductsError(response))
            }


        })
        .catch(handleNetworkError);
    };
};



export const requestProductsAvailabilityUpdate = () => {
    return {
        type: REQUEST_PRODUCT_AVAIL_UPDATE,
    };
};

export const receiveProductsAvailabilityUpdate = (response) => {
    return {
        type: RECEIVE_PRODUCT_AVAIL_UPDATE,
        data: response,
    };
};


export const receiveProductsAvailabilityUpdateError = () => {
    return {
        type: RECEIVE_PRODUCT_AVAIL_UPDATE_ERROR,
    };
};


export const updateProducts = (productList) => {
    return dispatch => {
        dispatch(userRefreshLogin(TokenStorage.getItem("token")));

        dispatch(requestProductsAvailabilityUpdate());

        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        let parameters = ""
        for (let id of productList) {
            parameters += `&id=${id}`
        }

        return fetch(`/api/v1/products/product_availability/?${parameters}`, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveProductsAvailabilityUpdate(response));
            } catch (e) {
                dispatch(receiveProductsAvailabilityUpdateError(response))
            }


        })
        .catch(handleNetworkError);
    }
}

export const setProductQuantityGroup = (item_id, checked=false) => {
    return {
        type: SET_PRODUCT_QUANTITY_GROUP,
        item_id: item_id,
        checked: checked
    };
}

export const clearRestockedNotification = () => {
    return {
        type: CLEAR_RESTOCKED_NOTIFICATION
    }
}

export const createRestockedNotificationSuccess = (data) => {
    return {
        type: CREATE_RESTOCKED_NOTIFICATION_SUCCESS,
        data: data
    }
}

export const createRestockedNotificationFailure = (data) => {
    return {
        type: CREATE_RESTOCKED_NOTIFICATION_FAILURE,
        data: data
    }
}

export const createRestockedNotification = (data) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");
        
        dispatch(clearRestockedNotification());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.CSRF_TOKEN,
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/create_restocked_notification/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(createRestockedNotificationSuccess(response));
            } catch (e) {
                dispatch(createRestockedNotificationFailure(response))
            }
        })
        .catch(handleNetworkError);
    };
}