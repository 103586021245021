import React from 'react';

import { NumToIsk } from '../../utils'
import Product from '../product'
import ProductSale from '../product/productSale'

class SaleComplete extends React.Component {

    totalPrice(){
        var total = 0;
        if(this.props.complete_sale.products){
            for(var product of this.props.complete_sale.products){
                total += (product.quantity * (+product.product.sales_price))
            }
        }
        return <b>{`${NumToIsk(total)} kr.`}</b>
    }


    render() {
        let {sale, complete_sale} = this.props
        return (
            <tr key={`sale-line-addon-${sale.id}-complete`} className="complete-sale-row">
                { complete_sale !== undefined &&
                <td colSpan="6">
                    <table className="table-sale-complete">
                        <thead>
                            <tr>
                                <th>Mynd</th>
                                <th>Vöruheiti</th>
                                <th>ID</th>
                                <th>Stærð</th>
                                <th>Eining</th>
                                <th>Verð</th>
                            </tr>
                        </thead>
                        <tbody>
                            {complete_sale.products && complete_sale.products.map((productObject) =>
                                <Product
                                    dispatch={this.props.dispatch}
                                    key={`sale-${sale.id}-complete-${productObject.product.item_id}`}
                                    product={productObject}
                                    viewName={ProductSale}
                                    user={this.props.user}
                                />
                            )}
                            <tr>
                                <td colSpan="3"><b>Samtals:</b></td>
                                <td colspan="3" className="total">{this.totalPrice()}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                }
            </tr>
        )
    }
}

export default SaleComplete;