
import {
  RECEIVE_REPORT_SUCCESS,
  RECEIVE_REPORT_ERROR,
  REQUEST_FETCH_REPORT
} from './actions'


const initialState = {
  report: {},
  error: null,
  isFetching: false
}

export function report(state = initialState, action) {

  switch(action.type) {
      case REQUEST_FETCH_REPORT:
          state = {...initialState,
              isFetching: true
          }
          return state
      case RECEIVE_REPORT_SUCCESS:
          state = {...state,
              report: action.data,
              isFetching: false
          }
          return state
      case RECEIVE_REPORT_ERROR:
          state = {...state,
              contacts: {},
              error: action.data,
              isFetching: false
          }
          return state
      default:
          return state
  }
}
