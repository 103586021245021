import fetch from 'isomorphic-fetch'
import Cookie from 'js-cookie'
import { checkHttpStatus, handleNetworkError, parseJSON } from '../../utils'
import jwtDecode from 'jwt-decode'
import { userRefreshLogin } from '../user/actions'
import TokenStorage from '../../storages/token-storage'
import { fetchServerCartItems } from '../cart/actions'

export const REQUEST_COMPANIES = 'REQUEST_COMPANIES'
export const RECEIVE_COMPANIES = 'RECEIVE_COMPANIES'
export const RECEIVE_COMPANIES_ERROR = 'RECEIVE_COMPANIES_ERROR'
export const REQUEST_SEARCH_COMPANIES = 'REQUEST_SEARCH_COMPANIES'
export const RECEIVE_SEARCH_COMPANIES = 'RECEIVE_SEARCH_COMPANIES'
export const RECEIVE_SEARCH_COMPANIES_ERROR = 'RECEIVE_SEARCH_COMPANIES_ERROR'
export const CLEAR_SEARCH_COMPANIES = 'CLEAR_SEARCH_COMPANIES'
export const SET_ACTIVE_COMPANY = 'SET_ACTIVE_COMPANY'


export const _setActiveCompany = (account_id) => {
    return {
        type: SET_ACTIVE_COMPANY,
        data: account_id,
    }
}

export const setActiveCompany = (account_id) => {
    return dispatch => {
        Promise.resolve(
            dispatch(_setActiveCompany(account_id))
        )
        .then(dispatch(fetchServerCartItems(account_id)))
    }
}

export const requestCompanies = () => {
    return {
        type: REQUEST_COMPANIES,
    };
};

export const receiveCompanies = (response, getNext=false) => {
    return {
        type: RECEIVE_COMPANIES,
    get_next: getNext,
        data: response,
    };
};

export const receiveCompaniesError = () => {
    return {
        type: RECEIVE_COMPANIES_ERROR,
    };
};


export const fetchCompanies = (parameters) => {
    return dispatch => {

        dispatch(userRefreshLogin(TokenStorage.getItem("token")));

        dispatch(requestCompanies());


        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/companies/?${parameters}`, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveCompanies(response));
            } catch (e) {
                dispatch(receiveCompaniesError(response))
            }


        })
        .catch(handleNetworkError);
    };
};

export const fetchNextCompanies = (path) => {
    return dispatch => {
        dispatch(userRefreshLogin(TokenStorage.getItem("token")));
        dispatch(requestCompanies());

        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        fetch(path, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {
            dispatch(receiveCompanies(response, true));
        })
        .catch(handleNetworkError);
    }
}


export const requestSearchCompanies = (params) => {
    return {
        type: REQUEST_SEARCH_COMPANIES,
        data: params,
    };
};

export const receiveSearchCompanies = (response, getNext=false) => {
    return {
        type: RECEIVE_SEARCH_COMPANIES,
        data: response,
    get_next: getNext
    };
};

export const clearSearchCompanies = () => {
    return {
        type: CLEAR_SEARCH_COMPANIES,
    }
}

export const receiveSearchCompaniesError = () => {
    return {
        type: RECEIVE_SEARCH_COMPANIES_ERROR,
    };
};


export const fetchSearchCompanies = (parameters) => {
    return dispatch => {

        dispatch(userRefreshLogin(TokenStorage.getItem("token")));
        dispatch(requestSearchCompanies(parameters));

        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        fetch(`/api/v1/companies/?search=${parameters}&limit=100`, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveSearchCompanies(response));
            } catch (e) {
                dispatch(receiveSearchCompaniesError(response))
            }


        })
        .catch(handleNetworkError);
    };
};

export const fetchNextSearchCompanies = (path) => {
    return dispatch => {
        dispatch(userRefreshLogin(TokenStorage.getItem("token")));
        dispatch(requestSearchCompanies());

        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        fetch(path, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {
            dispatch(receiveSearchCompanies(response, true));
        })
        .catch(handleNetworkError);
    }
}

